import { Main, SwitchApp, SwitchWorkspace, Workflows } from 'commandbar/pages';

type PageProps = {
  name: string;
  placeholder: string;
  component: () => JSX.Element;
};

export const PageRegistry = [
  {
    name: 'main',
    placeholder: 'What do you need?',
    component: Main,
  },
  {
    name: 'workflows',
    placeholder: 'Search workflows',
    component: Workflows,
  },
  {
    name: 'switch-workspace',
    placeholder: 'Switch workspace',
    component: SwitchWorkspace,
  },
  {
    name: 'switch-app',
    placeholder: 'Switch app',
    component: SwitchApp,
  },
] as const satisfies PageProps[];

export const defaultPage = PageRegistry[0];

export type PageDefinition = (typeof PageRegistry)[number];

export type Page = PageDefinition['name'];
