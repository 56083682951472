import type { Middleware } from 'openapi-fetch';

type LoggerMiddlewareOptions = {
  logger?: Partial<typeof console>;
};

export function createLoggerMiddleWare(options: LoggerMiddlewareOptions = {}) {
  const logger = options.logger ?? console;

  const useLogger: Middleware = {
    async onRequest(req) {
      return req;
    },
    async onResponse(res, options) {
      let data;
      try {
        // Try getting the error message from the response
        if (options.parseAs === 'json') {
          data = await res.clone().json();
        }
      } catch (err) {
        logger?.error?.('Error while calling API', res, options);
      }
      if (res.ok) {
        logger?.info?.(res.url, res, data);
      } else {
        logger?.error?.('Error while calling API', res.url, res, data);
      }
      return res;
    },
  };
  return useLogger;
}
