import React from 'react';

import { initI18n } from '@/locales/i18n';

export const useInitializeTranslations = ({
  apiKey,
  userId,
  disableTextTranslation,
  baseUrl,
  debug,
}: {
  apiKey?: string;
  userId?: string;
  disableTextTranslation?: boolean;
  baseUrl: string;
  debug?: boolean;
}) => {
  React.useLayoutEffect(() => {
    if (!disableTextTranslation && apiKey && userId) {
      initI18n({
        apiKey,
        userId,
        baseUrl,
        debug,
      });
    }
  }, [apiKey, userId, disableTextTranslation, baseUrl, debug]);
};
