import { API_BASE_URLS } from '../data/apiBaseUrls';
import { defaults } from '../data/defaults';
import {
  handleIncomingRealtimeMessage,
  type RealTimeEvent,
  type RealTimeMessage,
} from '../helpers/handleIncomingRtmMessage';
import { createRtmClient } from '../modules/createRtmClient';
import type { InitServiceFn, ServiceArgs } from '../modules/service';

type RealTimeServiceArgs = ServiceArgs<
  'connectService' | 'notificationService'
>;

type RtmClient = ReturnType<typeof createRtmClient>;

export function realTimeService({
  dependencies: {
    options: {
      baseUrl,
      apiKey,
      userId,
      userToken,
      userSignature,
      disableRtm,
      disableAutoStart,
      dataRegion,
    },
    events,
    log,
  },
  requiredServices: {
    notificationService: {
      api: { createNotification },
    },
  },
}: RealTimeServiceArgs) {
  let rtmClient: RtmClient = null;

  const connect = async () => {
    if (disableRtm) {
      log.info('RtmService is currently disabled.');
      return true;
    }

    const rtmServerUrl = `${baseUrl ?? (dataRegion && API_BASE_URLS[dataRegion]) ?? defaults.apiBaseUrl}`;
    log.info('Rtm url', rtmServerUrl);

    rtmClient = createRtmClient({
      apiKey,
      userId,
      userSignature,
      userToken,
      rtmServerUrl,
      log,
    });
    if (!rtmClient) {
      return;
    }
    log.info('RTM client created', rtmClient, apiKey, userId);

    rtmClient.onAny((event: RealTimeEvent, data: RealTimeMessage) => {
      handleIncomingRealtimeMessage(
        {
          name: event,
          // TODO(hemandev): Fix this :/
          data: data as any,
        },
        { log, events, createNotification },
      );
    });
    return rtmClient;
  };

  if (!disableAutoStart) {
    connect();
  }

  const disconnect = () => {
    if (!rtmClient) return;
    log.info('Disconnecting RTM client', userId, apiKey);
    rtmClient.disconnect();
  };

  return {
    api: { connect, disconnect },
    publicApi: { connect, disconnect },
  };
}

realTimeService.key = 'realTimeService' as const;

export const initRealTimeService = <T extends InitServiceFn>(
  initService: T,
) => {
  const app = initService({
    key: 'realTimeService',
    requiredServiceKeys: ['connectService', 'notificationService'],
  });
  return app;
};
