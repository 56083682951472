import type { DeepPartial } from '@engagespot/utils';
import merge from 'lodash.merge';

import themeConfig from './themeConfig';

export type Theme = typeof themeConfig;
export type ThemeOverrides = DeepPartial<Theme>;

export function getTheme(themeOverrides: ThemeOverrides = {}) {
  return merge(themeConfig, themeOverrides) as Theme;
}
