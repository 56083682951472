export const handleFocusOnEmailEditorInput = () => {
  setTimeout(() => {
    const input = document.querySelector(
      '[data-id="email-editor-text-field-input"]',
    ) as HTMLInputElement | null;

    if (input) {
      input.focus();
      const length = input.value.length;
      input.setSelectionRange(length, length);
    }
  }, 100);
};
