export enum ACCOUNT_STATUS {
  SUSPENDED = 'suspended',
  ACTIVE = 'active',
}

export enum USAGE_TYPE {
  LICENSED = 'licensed',
  METERED = 'metered',
}

export enum BILLING_SCHEME {
  TIERED = 'tiered',
}

export enum PAYMENT_STATUS {
  SUCCESS = 'success',
}
