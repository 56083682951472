import React, { useCallback, useEffect, useRef } from 'react';
import { Command } from 'cmdk';

import { useCommandBar } from 'commandbar/provider';
import { Key } from 'commandbar/components';
import { PageRoute } from 'commandbar/pages';

import './style.css';

export function CommandBar() {
  const ref = useRef<HTMLDivElement | null>(null);

  const {
    setOpen,
    isOpen,
    close,
    setInputValue,
    clearInput,
    popPage,
    activePage: { name: activePageName, placeholder },
    inputValue,
  } = useCommandBar();

  const isMain = activePageName === 'main';

  const bounce = useCallback(() => {
    if (ref.current) {
      ref.current.style.transform = 'scale(0.96)';
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = '';
        }
      }, 100);

      clearInput();
    }
  }, [clearInput]);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(open => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [setOpen]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter') {
        bounce();
      }

      if (isMain || inputValue.length) {
        return;
      }

      if (e.key === 'Backspace') {
        e.preventDefault();
        popPage();
        bounce();
      }
    },
    [bounce, popPage, inputValue.length, isMain],
  );

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="eg-cmdk" ref={containerRef}>
      <Command.Dialog
        ref={ref}
        open={isOpen}
        onOpenChange={setOpen}
        onKeyDown={onKeyDown}
        container={containerRef.current}
      >
        <div className="relative flex items-center">
          <Command.Input
            autoFocus
            placeholder={placeholder}
            value={inputValue}
            onValueChange={value => {
              setInputValue(value);
            }}
          />
          <Key
            as="button"
            name="Esc"
            onClick={close}
            className="absolute right-0 mb-6"
          />
        </div>
        <Command.List>
          <Command.Empty>No results found.</Command.Empty>
          <PageRoute activePage={activePageName} />
        </Command.List>
      </Command.Dialog>
    </div>
  );
}
