import { Engagespot, EngagespotProps } from '@engagespot/react-component';
import { useAuthStore } from 'store/authStore';
import { useEngagespotComponentProps } from './InAppProvider';
import { useUser } from 'components/user/UserProvider';

//Region alias
const regionAlias: { [key: string]: string } = {
  'us-west-2': 'us',
  'eu-central-1': 'eu',
  us: 'us',
  eu: 'eu',
};

export const EngageSpotInAppComponent = ({
  ENGAGESPOT_API_KEY,
  ENGAGESPOT_BASE_URL,
}: {
  ENGAGESPOT_API_KEY: string;
  ENGAGESPOT_BASE_URL?: string;
}) => {
  const engagespotComponentProps = useEngagespotComponentProps();
  const { email } = useUser();
  const { region } = useAuthStore(state => state);

  return (
    <>
      {ENGAGESPOT_API_KEY && (
        <Engagespot
          apiKey={ENGAGESPOT_API_KEY}
          baseUrl={ENGAGESPOT_BASE_URL}
          userId={email}
          dataRegion={
            region
              ? (regionAlias[region] as EngagespotProps['dataRegion'])
              : 'us' // default
          }
          {...engagespotComponentProps}
        ></Engagespot>
      )}
    </>
  );
};
