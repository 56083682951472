import { renderLinkItem } from 'commandbar/routes';
import { ScanFace } from 'lucide-react';

const ProfileAttrs = {
  name: 'Profile',
  icon: ScanFace,
  path: '/admin/profile',
};

export const Profile = () => renderLinkItem(ProfileAttrs);
