import useGetLogs from 'api/logs/get-logs';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'components/shadcn/tabs';
import { usePaginationApiState } from 'components/table/pagination-state';
import React from 'react';
import { WorkflowRuns } from '../workflow-overview/components/WorkflowRun';
import LogsTable from './components/LogsTable';
import PageHeader from 'components/header/PageHeader';
import { Button } from 'components/shadcn/button';
import { Filter } from 'lucide-react';
import {
  defaultLogFilterValues,
  MessageLogsFilters,
} from './components/MessageLogsFilters';
import { formatISO } from 'date-fns';

const Logs = () => {
  const { pagination, setPagination } = usePaginationApiState();
  const {
    dateRange: { from, to },
    ...filters
  } = defaultLogFilterValues;

  const [logFilters, setLogFilters] = React.useState({
    ...filters,
    fromDate: formatISO(new Date(from)),
    toDate: formatISO(new Date(to)),
  });

  const getLogs = useGetLogs({
    ...pagination,
    ...logFilters,
  });

  const [workflowRunId, setWorkflowRunId] = React.useState<number>(null);
  const [workflowId, setWorkflowId] = React.useState<number>(null);
  const [tab, setTab] = React.useState(1);
  const [showFilters, setShowFilters] = React.useState(false);

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      {/* Header */}
      <PageHeader
        heading={'Message Delivery Logs'}
        text={
          <>
            <p className="mb-2">
              Provides a unified view of all sent messages, showing real-time
              status updates across different providers
            </p>
          </>
        }
      />

      {/* Tables */}
      <div className="col-span-12">
        <Tabs
          onValueChange={value => setTab(Number(value))}
          value={String(tab)}
          defaultValue="1"
          className=""
        >
          <div className="flex items-center justify-between">
            <TabsList>
              <TabsTrigger value="1">Delivery Logs</TabsTrigger>
              <TabsTrigger value="2">Workflow Runs</TabsTrigger>
            </TabsList>

            {tab === 1 && (
              <div className="flex justify-end text-white">
                <Button
                  onClick={() => setShowFilters(!showFilters)}
                  variant="outline"
                >
                  <Filter className="mr-2 h-4 w-4" />
                  Filters
                </Button>
              </div>
            )}
          </div>

          {tab === 1 && showFilters && (
            <MessageLogsFilters setLogFilters={setLogFilters} />
          )}

          <TabsContent value="1">
            <LogsTable
              tableData={getLogs?.data?.results ?? []}
              totalPages={getLogs?.data?.totalPages}
              loading={getLogs.isLoading}
              pagination={pagination}
              setPagination={setPagination}
            />
          </TabsContent>

          <TabsContent value="2">
            <WorkflowRuns
              workflowRunId={workflowRunId}
              setWorkflowRunId={setWorkflowRunId}
              inLogsPage={true}
              workflowId={workflowId}
              setWorkflowId={setWorkflowId}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Logs;
