import { Button } from './components/button';
import { Image } from './components/image';
import { RatingStar } from './components/ratingStar';
import { TextArea } from './components/textArea';

export const componentMapping: any = {
  image: Image,
  button: Button,
  textarea: TextArea,
  'rating-star': RatingStar,
};
