import type useGetMe from 'api/profile/get-me';
import React, { createContext, useContext } from 'react';

export type User = ReturnType<typeof useGetMe>['data']['clientUser'];

export type UserProviderProps = {
  children: React.ReactNode;
  user: User;
};

export type UserContextProps = UserProviderProps['user'];

const UserContext = createContext<UserContextProps>(null);

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children, user }: UserProviderProps) {
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
