import { getActions } from './actions';
import type { InitServiceFn, ServiceArgs } from '../../utils/service';
import { createNotificationFetcherBuilder } from '../notificationFeedService/notificationFetcher';

type NotificationActionsService = ServiceArgs<'notificationActionsService'> &
  ServiceArgs<'notificationFeedService'>;

export function notificationActionsService({
  dependencies: { log, instance },
  requiredServices: { notificationFeedService },
}: NotificationActionsService) {
  const { stores } = notificationFeedService;

  const { createMutatorStore } = createNotificationFetcherBuilder({
    instance,
    log,
    dependentStores: {
      $currentPage: stores.$currentPage,
      $limit: stores.$limit,
    },
  });

  const actions = getActions({
    createMutatorStore,
    instance,
    stores,
    log,
  });

  return actions;
}

notificationActionsService.key = 'notificationActionsService' as const;

export const initNotificationActionsService = <T extends InitServiceFn>(
  initService: T,
) => {
  const app = initService({
    key: 'notificationActionsService',
    requiredServiceKeys: ['notificationFeedService'],
  });
  return app;
};
