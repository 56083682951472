import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn/avatar';
import { RegionSelectOption } from './WorkspaceSwitcher';
import { CheckIcon } from '@radix-ui/react-icons';
import { cn } from 'utils/class-merge';
import Chip, { chipBgColors } from 'components/chip';
import { REGIONS } from 'variables/regions';

export type WorkspaceItemProps = {
  active: boolean;
  team: RegionSelectOption;
};

const DataRegionChip = ({
  isUSRegion,
  className,
}: {
  isUSRegion?: boolean;
  className?: string;
}) => {
  return (
    <Chip
      text={isUSRegion ? 'US' : 'EU'}
      className={cn(
        `py-1 rounded-md text-xs font-semibold ml-3`,
        isUSRegion ? chipBgColors['green'] : chipBgColors['blue'],
        className,
      )}
    />
  );
};

export function WorkspaceItem({ active, team }: WorkspaceItemProps) {
  const isUSRegion =
    team.region === REGIONS['US_WEST_2'] || team.region === null;
  return (
    <div className={cn('text-sm contents', active && 'bg-muted/50')}>
      <Avatar className="mr-2 h-5 w-5">
        <AvatarImage src={''} alt={team.label} className="grayscale" />
        <AvatarFallback className="text-xs border border-white/20">
          {team?.label?.charAt(0).toUpperCase()}
        </AvatarFallback>
      </Avatar>

      <span>{team.label}</span>

      <CheckIcon
        className={cn('ml-2 h-4 w-4', active ? 'opacity-100' : 'opacity-0')}
      />

      <DataRegionChip className="ml-auto" isUSRegion={isUSRegion} />
    </div>
  );
}
