// AppSwitcher.tsx
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from '@radix-ui/react-icons';
import * as React from 'react';
import { Button } from 'components/shadcn/button';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/shadcn/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';
import { cn } from 'utils/class-merge';
import { useApp } from './AppProvider';

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface AppSwitcherProps extends PopoverTriggerProps {
  className?: string;
}

export default function AppSwitcher({ className }: AppSwitcherProps) {
  const [open, setOpen] = React.useState(false);

  const { selectedApp, apps, onCreateApp, onAppSelect, isOldVersion } =
    useApp();

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select an app"
            className={cn(
              'w-[200px] text-white hover:bg-muted/50 justify-between border border-[#3A3F47]',
              className,
            )}
          >
            {selectedApp?.label}
            <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandList>
              <CommandGroup heading={isOldVersion ? 'Apps' : 'Environments'}>
                <CommandItem value="-" className="hidden" />
                {apps?.map(team => (
                  <CommandItem
                    key={team.value}
                    onSelect={() => {
                      setOpen(false);
                      onAppSelect(team);
                    }}
                    className={cn(
                      'text-sm',
                      selectedApp?.value === team.value && 'bg-muted/50',
                    )}
                  >
                    {team.label}
                    <CheckIcon
                      className={cn(
                        'ml-auto h-4 w-4',
                        selectedApp?.value === team.value
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>

            <CommandSeparator />

            <CommandList>
              <CommandGroup>
                <CommandItem
                  className="cursor-pointer"
                  onSelect={() => {
                    setOpen(false);
                    onCreateApp();
                  }}
                >
                  <PlusCircledIcon className="mr-2 h-5 w-5" />
                  {isOldVersion ? 'Create App' : 'Create Environment'}
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
