import { defaultHeaders } from '../data/defaultHeaders';

export type CreateCommonHeaders = {
  apiKey: string;
  userToken?: string;
  userId: string;
  userSignature?: string;
  deviceId?: string;
} & Record<string, string>;

export const createCommonHeaders = ({
  apiKey,
  userToken,
  userId,
  userSignature,
  deviceId,
  ...additionalHeaders
}: CreateCommonHeaders) => {
  const headers = {
    ...(userToken && { Authorization: `Bearer ${userToken}` }),
    ...(!userToken && apiKey && { 'X-ENGAGESPOT-API-KEY': apiKey }),
    ...(!userToken && userId && { 'X-ENGAGESPOT-USER-ID': userId }),
    ...(!userToken &&
      userSignature && {
        'X-ENGAGESPOT-USER-SIGNATURE': userSignature,
      }),
    'X-ENGAGESPOT-DEVICE-ID': deviceId,
    ...defaultHeaders,
    ...additionalHeaders,
  };
  return headers;
};
