import { yupResolver } from '@hookform/resolvers/yup';
import usePostForgotPassword from 'api/forgot-password/post-forgot-password';
import {
  AuthHeading,
  AuthSubmitButton,
  AuthText,
  AuthTextWithLink,
} from 'components/auth/AuthComponents';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';

type TFormForgotPassword = {
  email: string;
};

const schema = yup.object({
  email: yup
    .string()
    .trim()
    .required('email is required')
    .email('email must be valid'),
});

function ForgotPasswordDefault() {
  const toast = useCustomToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TFormForgotPassword>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const postForgotPassword = usePostForgotPassword<TFormForgotPassword>();
  const [searchParams] = useSearchParams();
  const redirect = new URLSearchParams(searchParams).get('redirect');
  const [resetTimer, setResetTimer] = React.useState(0);

  const onSubmit: SubmitHandler<TFormForgotPassword> = values => {
    const data = {
      ...values,
      ...(redirect && {
        redirect: `?redirect=${redirect}`,
      }),
    };
    postForgotPassword.mutate(data, {
      onSuccess: () => {
        setResetTimer(30);
        toast.success('password reset link send to email');
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  React.useEffect(() => {
    if (resetTimer === 0) return;

    const timerId = setTimeout(() => {
      setResetTimer(resetTimer - 1);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [resetTimer]);

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-[16vh] flex flex-col rounded-[20px] pb-16 pt-12 md:pr-6 lg:max-w-[90%] lg:pr-0 xl:max-w-[42%] xl:pr-24"
          >
            <AuthHeading text="Reset password" />

            <div className="mb-2 mt-2 text-lg">
              <AuthText text="Please provide your email address, and we’ll send you a password reset link." />
            </div>

            <AuthTextWithLink
              text="Don’t need to reset password ?"
              linkText="Login"
              linkTo={
                redirect ? `/auth/login?redirect=${redirect}` : '/auth/login'
              }
              parentClass="mb-7 mt-2"
            />

            {/* Email */}
            <InputField
              variant="dark"
              label="Email"
              placeholder="Enter email"
              type="text"
              register={register}
              name={'email'}
            />
            {errors.email && <ErrorBox error={errors.email} />}
            {/*ErrorBox button */}
            <AuthSubmitButton
              isLoading={postForgotPassword.isLoading}
              disabled={postForgotPassword.isLoading || resetTimer !== 0}
              text={
                resetTimer === 0
                  ? 'Reset password'
                  : `Resend again in ${resetTimer} seconds`
              }
            />

            {postForgotPassword.isSuccess && (
              <div className="mt-4 dark:text-navy-300">
                Please check your email inbox to find instructions to reset your
                password
              </div>
            )}
          </form>
        </div>
      }
    />
  );
}

export default ForgotPasswordDefault;
