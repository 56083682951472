import { useOptions } from '@engagespot/react-hooks';

import { API_BASE_URLS } from '../../../core-frontend/src';

export const useGetBaseUrl = () => {
  const options = useOptions();
  const baseUrl =
    options.baseUrl ??
    (options?.dataRegion && API_BASE_URLS[options?.dataRegion]) ??
    API_BASE_URLS['us']; // default

  return baseUrl;
};
