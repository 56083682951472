import type { ApiPaths } from '@engagespot/api-client';

import { supportedChannels } from '../data/supportedChannels';
import type { InitServiceFn, ServiceArgs } from '../modules/service';

export type Category = {
  id: number;
  identifier: string;
};

type SetPreferences = NonNullable<
  ApiPaths['/v3/preferences']['patch']['requestBody']['content']['application/json']['preference']
>;

export type SetUserAttributes = Record<string, any>;

type PreferenceServiceArgs = ServiceArgs<'connectService'>;

export function preferencesService({
  dependencies: { apiClient },
}: PreferenceServiceArgs) {
  /**
   * Returns the preferences of this user
   */
  const getPreferences = async () => {
    const response = await apiClient.GET('/v3/preferences');
    return response;
  };

  /**
   * Sets preferences of this user
   */
  const setPreferences = async (preferences: SetPreferences) => {
    const response = await apiClient.PATCH('/v3/preferences', {
      body: {
        preference: preferences,
      },
      // response is coming as text for some reason
      parseAs: 'text',
    });
    return response;
  };

  /**
   * Sets attributes to user's profile
   */
  const setProfileAttributes = async (attributes: SetUserAttributes) => {
    const { response } = await apiClient.PATCH('/v3/profile', {
      body: {
        ...attributes,
      } as any,
      // response is coming as text for some reason
      parseAs: 'text',
    });
    return response?.ok;
  };

  /**
   * Gets notification categories of this app.
   */
  const getCategories = async () => {
    const response = await apiClient.GET('/v3/categories');
    return response;
  };

  // TODO(hemandev): This should be changed once we have the actual api
  const getSupportedChannels = async () => {
    return Promise.resolve(supportedChannels);
  };

  const returnValues = {
    getSupportedChannels,
    getPreferences,
    setPreferences,
    setProfileAttributes,
    getCategories,
  };
  return {
    ...returnValues,
    publicApi: { ...returnValues },
  };
}

preferencesService.key = 'preferencesService' as const;

export const initPreferencesService = <T extends InitServiceFn>(
  initService: T,
) => {
  const app = initService({
    key: 'preferencesService',
    requiredServiceKeys: [],
  });
  return app;
};
