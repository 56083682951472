import { CustomModal } from 'components/modal';
import { StripePayment, StripePromise } from '../types';
import StripePaymentForm from './StripePayment';

function StripePaymentModal({
  isOpen,
  onClose,
  paymentDetails,
  promise,
}: {
  onClose: () => void;
  isOpen: boolean;
  paymentDetails: StripePayment;
  promise: StripePromise;
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <CustomModal
      modalContentClassName="!max-w-[35vw] !top-[32%]"
      modalCardClassName="!p-8 !pt-6"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h3 className="mb-5 font-semibold text-lg">Add Payment Method</h3>

      <StripePaymentForm {...paymentDetails} promise={promise} />
    </CustomModal>
  );
}

export default StripePaymentModal;
