import { LinkItem } from 'commandbar/itemTypes';
import { sidebarRoutes } from 'sidebar-routes';

type LinkItemType = (typeof sidebarRoutes)[number] & { group?: string };

export const getGroup = (groupName: string) => {
  const group = sidebarRoutes.find(route => {
    return route.children && route.name === groupName;
  });

  if (!group) {
    console.error('No group named ', groupName);
    return;
  }
  return group;
};

export const getLinkItem = (itemName: string) => {
  const item = sidebarRoutes.find(route => {
    return route.name === itemName;
  });

  if (!item) {
    console.error('No item named ', itemName);
    return;
  }
  return item;
};

export const renderLinkItem = (props: LinkItemType, group?: string) => {
  return (
    <LinkItem key={props.name} {...props} group={group}>
      {props.name}
    </LinkItem>
  );
};

export const renderLinkItems = (items: LinkItemType[], group?: string) => {
  return (
    <>
      {items.map(props => {
        return renderLinkItem(props, group);
      })}
    </>
  );
};
