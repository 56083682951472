import {
  AccessorFnColumnDef,
  AccessorKeyColumnDef,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import CustomTablePagination from './pagination';
import CustomTableApiPagination from './pagination-api';
import { LOADING_PAGE_SIZE } from './pagination-state';
import { useParseTableData } from './parse-table-data';
import CustomTableBody from './table-body';
import { cn } from 'utils/class-merge';

type PaginationProps = {
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  totalPages: number;
};

function CustomTable<T extends 'table' | 'api'>(
  props: {
    showSelectedRows?: boolean;
    rowClick?: (prop: any) => void;
    extraTdClass?: string;
    emptyTableMessage?: string;
    emptyAdditionalJsx?: () => JSX.Element;
    emptyTableMessageClassName?: string;
    loading: boolean;
    tableData: any;
    globalFilter?: string;
    setSelectedRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
    columnVisibility?: Record<string, boolean>;
    columns: (
      | AccessorFnColumnDef<any, unknown>
      | AccessorKeyColumnDef<any, unknown>
    )[];
    paginationType: T;
    containerClassName?: string;
  } & (T extends 'api' ? PaginationProps : Partial<PaginationProps>),
) {
  const [rowSelection, setRowSelection] = React.useState({});

  const {
    loading,
    pagination,
    tableData,
    columns,
    setPagination,
    totalPages,
    paginationType = 'table',
    globalFilter,
    setSelectedRows,
    showSelectedRows,
    columnVisibility,
  } = props;

  const parsedTableData = useParseTableData({
    tableColumns: columns,
    tableData,
    loading,
  });

  const table = useReactTable({
    data: parsedTableData.data,
    columns: parsedTableData.columns,
    onRowSelectionChange: setRowSelection,
    state: {
      ...(paginationType === 'api' && {
        pagination: {
          pageIndex: 0,
          pageSize: loading ? LOADING_PAGE_SIZE : pagination?.pageSize,
        },
      }),
      ...(paginationType === 'table' && {
        globalFilter,
      }),
      rowSelection,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  React.useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows?.(table?.getFilteredSelectedRowModel?.().rows);
    }
  }, [setSelectedRows, table, rowSelection]);

  return (
    <div className={cn('mt-6 col-span-12', props?.containerClassName)}>
      <CustomTableBody table={table} {...props} />

      <div className="relative pr-14">
        {paginationType === 'table' ? (
          <CustomTablePagination
            showSelectedRows={showSelectedRows}
            table={table}
          />
        ) : (
          <CustomTableApiPagination
            pagination={pagination}
            setPagination={setPagination}
            totalPages={totalPages}
          />
        )}
      </div>
    </div>
  );
}

export default CustomTable;
