export const supportedChannels = {
  inApp: {
    name: 'In-App',
    id: 'inApp',
  },
  webPush: {
    name: 'Web Push',
    id: 'webPush',
  },
  email: {
    name: 'Email',
    id: 'email',
  },
  mobilePush: {
    name: 'Mobile Push',
    id: 'mobilePush',
  },
  sms: {
    name: 'SMS',
    id: 'sms',
  },
  whatsapp: {
    name: 'WhatsApp',
    id: 'whatsapp',
  },
  slack: {
    name: 'Slack',
    id: 'slack',
  },
  chat: {
    name: 'Chat',
    id: 'chat',
  },
} as const;
