import useGetProviders from 'api/providers/get-providers';
import Card from 'components/card';
import { FileInputSelect } from 'components/fields/FileInputSelect';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import TextField from 'components/fields/TextField';
import { Label } from 'components/shadcn/label';
import { RadioGroup, RadioGroupItem } from 'components/shadcn/radio-group';
import { MessageCircleCode } from 'lucide-react';
import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormReturn,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTemplateStore } from 'store/templateStore';
import { capitalizeFirstLetter } from 'utils/functions';
import { useGetTemplateChannelData } from '../../hooks/getTemplateChannelData';
import { useGetTemplateEditorOnSubmit } from '../../hooks/getTemplateEditorOnSubmit';
import { useRefillTemplateEditorData } from '../../hooks/refillTemplateEditorData';
import { TemplateEditorAvailabeChannels } from '../../variables/inputs';
import TemplateEditorComponents from '../TemplateEditorComponents';
import TemplateEditorPreviewHeading from '../TemplateEditorPreviewHeading';
import TemplateEditorPreview from '../TemplatePreview';
import ErrorBox from 'components/error/ErrorBox';

type WhatsAppProvider = 'twilio' | 'whatsapp_cloud_api' | 'others';
type DifferenConfigWhatsAppProvider = 'cloudapi_whatsapp' | 'twilio_whatsapp';

const WhatsAppEditor = ({
  saveRef,
  form,
}: {
  saveRef: React.MutableRefObject<HTMLButtonElement>;
  form: UseFormReturn<FieldValues, any>;
}) => {
  const differentConfigProviderMap: Record<
    DifferenConfigWhatsAppProvider,
    WhatsAppProvider
  > = React.useMemo(() => {
    return {
      cloudapi_whatsapp: 'whatsapp_cloud_api',
      twilio_whatsapp: 'twilio',
    };
  }, []);
  const differentConfigProviderIdentifierMap = Object.keys(
    differentConfigProviderMap,
  );
  const params = useParams();
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const { loading } = useTemplateStore(state => state);
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = form;
  const getProviders = useGetProviders(channel);
  const configuredProviders = getProviders.data?.configuredProviders;
  const enabledConfiguredProviders = React.useMemo(() => {
    return configuredProviders?.filter(provider => Boolean(provider.enabled));
  }, [configuredProviders]);
  const availableProviders = getProviders.data?.availableProviders;
  const providerIconMap = availableProviders?.reduce(
    (acc, provider) => {
      if (differentConfigProviderIdentifierMap.includes(provider.identifier)) {
        acc[provider.identifier as DifferenConfigWhatsAppProvider] =
          provider.icon;
        return acc;
      }

      return acc;
    },
    {} as Record<keyof typeof differentConfigProviderMap, string>,
  );
  const getTemplateContent = useGetTemplateChannelData();
  const savedTemplateData = React.useMemo(
    () => getTemplateContent,
    [getTemplateContent],
  );
  const isBatchingEnabled = useWatch({
    control,
    name: 'batchingEnabled',
  });
  const whatsappProvider: WhatsAppProvider = useWatch({
    control,
    name: 'whatsapp_provider',
  });

  // Same as template editor input fields
  useRefillTemplateEditorData({ reset });
  const { onSubmit } = useGetTemplateEditorOnSubmit({
    reset,
    isBatchingEnabled,
  });

  React.useEffect(() => {
    const onlyOneProvider = enabledConfiguredProviders?.length === 1;
    const hasNoContent = !savedTemplateData?.content;

    if (onlyOneProvider && hasNoContent) {
      const providerIdentifier =
        enabledConfiguredProviders?.[0]?.provider?.identifier;
      const providerValue =
        differentConfigProviderMap[
          providerIdentifier as DifferenConfigWhatsAppProvider
        ] || 'others';
      setValue('whatsapp_provider', providerValue);
    }
  }, [
    enabledConfiguredProviders,
    differentConfigProviderMap,
    setValue,
    savedTemplateData,
  ]);

  return (
    <>
      {/* Left Side */}
      <Card extra={`w-full p-6`}>
        {/* Preview Heading */}
        <TemplateEditorPreviewHeading />

        {/* Content */}
        <div className="mt-6 flex justify-between gap-10">
          {/* Inputs */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`flex w-[55%] flex-col gap-3`}
          >
            <Controller
              name={'whatsapp_provider'}
              control={control}
              render={({ field: { ...field } }) => {
                return (
                  <RadioGroup
                    onValueChange={field.onChange}
                    value={field.value}
                    className="flex gap-5 mb-3"
                  >
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="twilio" id="r2" />
                      <Label htmlFor="r2" className="flex gap-0 items-center">
                        {providerIconMap?.['twilio_whatsapp'] && (
                          <img
                            width={24}
                            src={providerIconMap['twilio_whatsapp']}
                            alt=""
                          />
                        )}
                        Twilio
                      </Label>
                    </div>

                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="whatsapp_cloud_api" id="r3" />
                      <Label htmlFor="r3" className="flex gap-0 items-center">
                        {providerIconMap?.['cloudapi_whatsapp'] && (
                          <img
                            width={24}
                            src={providerIconMap['cloudapi_whatsapp']}
                            alt=""
                          />
                        )}
                        WhatsApp Cloud API
                      </Label>
                    </div>

                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="others" id="r1" />
                      <Label htmlFor="r1" className="flex gap-2 items-center">
                        <MessageCircleCode size={14} />
                        Others
                      </Label>
                    </div>
                  </RadioGroup>
                );
              }}
            />

            {whatsappProvider === 'others' && (
              <OtherProviders
                register={register}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            )}

            {whatsappProvider === 'twilio' && (
              <TwilioProvider errors={errors} register={register} />
            )}

            {whatsappProvider === 'whatsapp_cloud_api' && (
              <WhatsAppCloudProvider errors={errors} register={register} />
            )}

            <button
              type="submit"
              disabled={loading}
              hidden={true}
              ref={saveRef}
            >
              submit
            </button>
          </form>

          {/* Preview */}
          <TemplateEditorPreview form={form} />
        </div>
      </Card>

      {/* Right Side */}
      <Card extra={'w-[42.5%] relative p-4'}>
        {/* Components */}
        <TemplateEditorComponents />
      </Card>
    </>
  );
};

const OtherProviders = ({
  control,
  register,
  setValue,
  errors,
}: {
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: FieldErrors<FieldValues>;
}) => {
  const { tab } = useTemplateStore(state => state);
  const isBatchingEnabled = useWatch({
    control,
    name: 'batchingEnabled',
  });
  const messageType = useWatch({
    control,
    name: 'messageType',
  });
  const whatsAppMessageOptions = [
    {
      label: 'Text',
      value: 'text',
    },
    {
      label: 'Image',
      value: 'image',
    },
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'File',
      value: 'file',
    },
  ];

  return (
    <>
      <SelectField
        variant="styled"
        control={control}
        extra={'mb-3'}
        label={'Message Type'}
        placeholder={'Enter Message Type'}
        showIsRequiredAsterisk={true}
        required={true}
        name={'messageType'}
        options={whatsAppMessageOptions}
      />
      {errors['messageType'] && (
        <ErrorBox
          className="my-0 relative bottom-3"
          error={
            errors['messageType'].type === 'required'
              ? `This field is required`
              : errors['messageType'].type
          }
        />
      )}

      <TextField
        register={register}
        extraInputClass={
          'border dark:bg-night-100 focus:border border-[#525151]'
        }
        label={'Content'}
        placeholder={'Content'}
        name={'message'}
        extraLabelClass={'font-medium'}
        rows={3}
        extra={'mb-3'}
        helperText="You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>"
      />

      {messageType !== 'text' && (
        <FileInputSelect
          name={'url'}
          placeholder={'https://example.com'}
          required={false}
          register={register}
          control={control}
          setValue={setValue}
          label={`${capitalizeFirstLetter(
            tab === 'template' ? messageType ?? '' : '',
          )} Url`}
          disabled={
            tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false
          }
        ></FileInputSelect>
      )}
    </>
  );
};

const TwilioProvider = ({
  register,
  errors,
}: {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
}) => {
  return (
    <>
      <InputField
        variant="auth"
        label={'Content Template ID'}
        placeholder={'Enter Content Template ID'}
        type="text"
        showIsRequiredAsterisk={true}
        register={register}
        name={'contentSid'}
        extraLabelClass={'font-medium'}
        extraInputClass={`border dark:bg-night-100 focus:border border-[#525151]`}
        required={true}
      />
      {errors['contentSid'] && (
        <ErrorBox
          className="my-0"
          error={
            errors['contentSid'].type === 'required'
              ? `This field is required`
              : errors['contentSid'].type
          }
        />
      )}

      <InputField
        variant="auth"
        label={'Message Service ID'}
        placeholder={'Enter Message Service ID'}
        type="text"
        showIsRequiredAsterisk={true}
        register={register}
        name={'messagingServiceSid'}
        extraLabelClass={'font-medium'}
        extraInputClass={`border dark:bg-night-100 focus:border border-[#525151]`}
        required={true}
      />
      {errors['messagingServiceSid'] && (
        <ErrorBox
          className="my-0"
          error={
            errors['messagingServiceSid'].type === 'required'
              ? `This field is required`
              : errors['messagingServiceSid'].type
          }
        />
      )}
    </>
  );
};

const WhatsAppCloudProvider = ({
  register,
  errors,
}: {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
}) => {
  return (
    <>
      <InputField
        variant="auth"
        label={'Template Name'}
        placeholder={'Enter Template Name'}
        type="text"
        showIsRequiredAsterisk={true}
        register={register}
        name={'templateName'}
        extraLabelClass={'font-medium'}
        extraInputClass={`border dark:bg-night-100 focus:border border-[#525151]`}
        required={true}
      />
      {errors['templateName'] && (
        <ErrorBox
          className="my-0"
          error={
            errors['templateName'].type === 'required'
              ? `This field is required`
              : errors['templateName'].type
          }
        />
      )}

      <InputField
        variant="auth"
        label={'Language Code'}
        placeholder={'Enter Language Code'}
        type="text"
        showIsRequiredAsterisk={true}
        register={register}
        name={'languageCode'}
        extraLabelClass={'font-medium'}
        extraInputClass={`border dark:bg-night-100 focus:border border-[#525151]`}
        required={true}
      />
      {errors['languageCode'] && (
        <ErrorBox
          className="my-0"
          error={
            errors['languageCode'].type === 'required'
              ? `This field is required`
              : errors['languageCode'].type
          }
        />
      )}
    </>
  );
};

export default WhatsAppEditor;
