import type React from 'react';
import styled, { css } from 'styled-components';

export type RatingStarStyledProps = {
  icon?: React.ReactNode;
  activeIcon?: React.ReactNode;
  onChange?: (value: number) => void;
};

export const StarContainer = styled.div`
  display: flex;
  gap: 0px;
  cursor: pointer;
`;

export const StarWrapper = styled.div`
  && {
    ${({
      theme: {
        actionableElement: { ratingStar },
      },
    }) => css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${ratingStar.size};
      height: ${ratingStar.size};

      &:hover {
        transform: scale(1.25);
      }
    `}
  }
`;
