import { getLinkItem, renderLinkItem } from '../routes';

export const QuickStart = () => renderLinkItem(getLinkItem('Quick Start'));
export const APICredentials = () =>
  renderLinkItem(getLinkItem('API Credentials'));
export const UsersRecipients = () =>
  renderLinkItem(getLinkItem('Users (Recipients)'));
export const Channels = () => renderLinkItem(getLinkItem('Channels'));
export const Logs = () => renderLinkItem(getLinkItem('Logs'));
export const VersionControl = () =>
  renderLinkItem(getLinkItem('Version Control'));
export const Docs = () => renderLinkItem(getLinkItem('Docs'));
export const GetSupport = () => renderLinkItem(getLinkItem('Get Support'));
export { Profile } from './Profile';
