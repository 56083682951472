import { useHideBranding } from '@engagespot/react-hooks';
import { Fragment } from 'react';

import {
  NotificationFooterGearButtonStyled,
  NotificationFooterLinkStyled,
  NotificationFooterStyled,
  NotificationFooterTextStyled,
} from './NotificationFooter.styled';
import { useEngagespotContext } from '@/engagespotProvider';
import { EngagespotLogo as EngagespotIcon } from '@/icons/EngagespotLogo';
import { Gear } from '@/icons/Gear';
import type { defaultRenderFn } from '@/types/panel';
import type { customRenderFn } from '@/utils/renderCustom';
import { renderCustom } from '@/utils/renderCustom';

const defaultFooterContent = () => {
  return (
    <Fragment>
      <NotificationFooterLinkStyled
        href="https://engagespot.co"
        target="__blank"
        aria-label="Engagespot Logo"
      >
        <EngagespotIcon />

        <NotificationFooterTextStyled>
          Powered by engagespot
        </NotificationFooterTextStyled>
      </NotificationFooterLinkStyled>
    </Fragment>
  );
};

const footerContent = ({
  hideBranding,
  renderFooterContent,
}: {
  hideBranding: boolean;
  renderFooterContent: customRenderFn<defaultRenderFn>;
}) => {
  if (hideBranding && renderFooterContent) {
    return renderCustom(renderFooterContent);
  }

  if (hideBranding && !renderFooterContent) {
    return null;
  }

  return defaultFooterContent();
};

export function NotificationFooter({
  showPreferences,
}: {
  showPreferences: boolean | undefined;
}) {
  const { route, setRoute, renderFooterContent } = useEngagespotContext();
  const hideBranding = useHideBranding();

  const onPreferenceClick = () => {
    setRoute(route === 'preference' ? 'home' : 'preference');
  };

  return (
    <NotificationFooterStyled>
      {footerContent?.({ hideBranding, renderFooterContent })}

      {showPreferences ? (
        <NotificationFooterGearButtonStyled onClick={onPreferenceClick}>
          <Gear />
        </NotificationFooterGearButtonStyled>
      ) : null}
    </NotificationFooterStyled>
  );
}
