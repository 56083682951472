import styled, { css } from 'styled-components';

export type ButtonStyledProps = {
  variant: 'primary' | 'secondary';
};

export const ButtonStyled = styled.button.withConfig({
  shouldForwardProp: prop => !['variant'].includes(prop),
})<ButtonStyledProps>`
  && {
    ${({
      theme: {
        actionableElement: { button },
      },
      variant,
    }) => css`
      border-radius: ${variant === 'primary'
        ? button.primaryButtonBorderRadius
        : button.secondaryButtonBorderRadius};
      padding: ${variant === 'primary'
        ? button.primaryButtonPadding
        : button.secondaryButtonPadding};
      font-weight: ${variant === 'primary'
        ? button.primaryButtonFontWeight
        : button.secondaryButtonFontWeight};
      font-size: ${variant === 'primary'
        ? button.primaryButtonFontSize
        : button.secondaryButtonFontSize};
      background: ${variant === 'primary'
        ? button.primaryButtonBackground
        : button.secondaryButtonBackground};
      border: ${variant === 'primary'
        ? button.primaryButtonBorder
        : button.secondaryButtonBorder};
      color: ${variant === 'primary'
        ? button.primaryButtonColor
        : button.secondaryButtonColor};
      margin: ${variant === 'primary'
        ? button.primaryButtonMargin
        : button.secondaryButtonMargin};

      &:hover {
        transition: ${variant === 'primary'
          ? button.primaryButtonTransition
          : button.secondaryButtonTransition};
        background: ${variant === 'primary'
          ? button.primaryButtonHoverBackground
          : button.secondaryButtonHoverBackground};
      }
    `}
  }
`;
