import { useQuery } from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { SubscriptionDetails } from './types';
import { routes } from 'api/routes';

export type GetPlansParams = {
  billableComponent: 'notification';
};

const get = async (): Promise<SubscriptionDetails> => {
  const { data } = await axiosLicensingInstance.get(
    `${routes['v1_client']}/subscription`,
  );

  return data;
};

const useGetSubscriptionDetails = (enabled: boolean) => {
  return useQuery({
    queryKey: [`${routes['v1_client']}/subscription`],
    queryFn: get,
    enabled,
    staleTime: 1000 * 5, // 5 second
  });
};

export default useGetSubscriptionDetails;
