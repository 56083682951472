import { useTranslation } from 'react-i18next';

import { useEngagespotContext } from '../engagespotProvider/index';
import type { TextOverrides } from '../types/text';
import { getNestedObjectValue } from '../utils/objectUtils';
import { englishFallbackText } from '@/locales/en';

type RecursiveKeys<T> =
  T extends Record<string, any>
    ? {
        [K in keyof T]: K extends string
          ? T[K] extends Record<string, any>
            ? `${K}` | `${K}.${RecursiveKeys<T[K]>}`
            : `${K}`
          : never;
      }[keyof T]
    : never;

type LocaleKeysType = RecursiveKeys<TextOverrides>;

export const useText = () => {
  const context = useEngagespotContext();
  const textOverrides = context?.textOverrides;
  const { t: translate, ready } = useTranslation(undefined, {
    useSuspense: false,
  });

  const t = (key: LocaleKeysType) => {
    const overWrittenText = getNestedObjectValue(textOverrides, key);
    const fallbackText = getNestedObjectValue(englishFallbackText, key);

    return overWrittenText
      ? overWrittenText
      : ready
        ? translate(key, fallbackText)
        : fallbackText;
  };

  return {
    t,
  };
};
