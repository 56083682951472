import Cookies from 'js-cookie';
import { isEnterpriseEdition } from './get-editions';
import { useGetOwnerWorkspace, useGetSelectedWorkspace } from './get-workspace';
import useGetSubscriptionDetails from 'api/billing/get-subscription-details';
import { ACCOUNT_STATUS } from 'views/admin/dashboards/billing/enums';

export const useSubscriptionStatus = () => {
  const { data: ownerWorkspace, isLoading } = useGetOwnerWorkspace();
  const selectedWorkspace = useGetSelectedWorkspace();
  const enterpriseEdition = isEnterpriseEdition();
  const licenseAuthToken = Cookies.get('license_access_token');
  const getSubscriptionDetails = useGetSubscriptionDetails(
    enterpriseEdition && Boolean(licenseAuthToken),
  );
  const selectedWorkspaceSuspended =
    selectedWorkspace?.client?.status === 'suspended';

  const subscriptionStatus =
    getSubscriptionDetails.data?.subscription?.stripeStatus;
  const accountSuspended =
    selectedWorkspace?.client?.status === ACCOUNT_STATUS['SUSPENDED'];
  const remindAccountIsSuspended =
    !isLoading &&
    !getSubscriptionDetails.isLoading &&
    getSubscriptionDetails.data &&
    accountSuspended;

  return {
    subscriptionStatus,
    accountSuspended,
    remindAccountIsSuspended,
    selectedWorkspaceSuspended,
  };
};
