import { usePreferences } from '@engagespot/react-hooks';
import { useEffect, useState } from 'react';

import { useEngagespotContext } from '@/engagespotProvider';

export const useSetProfileAttributes = () => {
  const { profile } = useEngagespotContext();
  const { setProfileAttributes } = usePreferences();
  const [hasEffectRun, setHasEffectRun] = useState(false);

  useEffect(() => {
    if (profile && !hasEffectRun) {
      setProfileAttributes(profile);
      setHasEffectRun(true);
    }
  }, [profile, setProfileAttributes, hasEffectRun]);
};
