import { LampDesk, List, FileBoxIcon } from 'lucide-react';

import { Group } from './Group';
import { ActionItem, ListItem } from 'commandbar/itemTypes';
import { createWorkspaceAction } from 'components/sidebar/components/WorkspaceProvider';
import { useIsEditorPage } from 'utils/use-is-editor-page';

const GROUP_NAME = 'Workspace';

export function Workspace() {
  const isEditorPage = useIsEditorPage();

  return (
    <Group heading={GROUP_NAME} icon={LampDesk}>
      <ListItem page="switch-workspace" icon={List} group={GROUP_NAME}>
        Switch Workspace
      </ListItem>
      <ActionItem
        icon={FileBoxIcon}
        action={createWorkspaceAction}
        group={GROUP_NAME}
        path={isEditorPage ? '/admin/api-credentials' : ''}
      >
        Create Workspace
      </ActionItem>
    </Group>
  );
}
