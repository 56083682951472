import type { Instance } from '@engagespot/core';
import type { createLogger } from '@engagespot/utils';
import { nanoquery } from '@nanostores/query';
import type { WritableAtom } from 'nanostores';

import { convertArrayArgsToParams } from '../../utils/convertArrayArgsToParams';

type NotificationFetcherDependencies = {
  $currentPage: WritableAtom<number>;
  $limit: WritableAtom<number>;
};

type NotificationFetcherOptions = {
  instance: Instance;
  log: ReturnType<typeof createLogger>;
  dependentStores: NotificationFetcherDependencies;
};

type NotificationMethods = keyof Instance['notification'];

/*
 * TODO(hemandev) Make this type-safe
 */
type NotificationMethodArgsArray = any[];

export function createNotificationFetcherBuilder({
  instance,
}: NotificationFetcherOptions) {
  const [createFetcherStore, createMutatorStore] = nanoquery({
    /*
     * TODO: give correct type to methodName prop
     */
    fetcher: async (methodName: any, ...args: NotificationMethodArgsArray) => {
      const params = convertArrayArgsToParams(args);
      return await instance.notification[methodName as NotificationMethods](
        params as any,
      );
    },
  });

  return {
    createFetcherStore,
    createMutatorStore,
  };
}
