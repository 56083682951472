import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useIsEditorPage() {
  const location = useLocation();

  const isEditorPage = useMemo(() => {
    const { pathname } = location;
    return (
      pathname.includes('template-editor') ||
      pathname.includes('workflow-editor') ||
      pathname.includes('layout-editor') ||
      pathname.includes('translation-editor')
    );
  }, [location]);

  return isEditorPage;
}
