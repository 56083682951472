import type { Instance } from '@engagespot/core';
import type { createLogger } from '@engagespot/utils';
import { nanoquery } from '@nanostores/query';

type PreferenceFetcherOptions = {
  instance: Instance;
  log: ReturnType<typeof createLogger>;
};
type AllPreferenceMethods = keyof Instance['preferences'];
type PreferenceMethods = Extract<
  AllPreferenceMethods,
  'getPreferences' | 'getCategories'
>;

export function createPreferenceFetchBuilder({
  instance,
}: PreferenceFetcherOptions) {
  const [createFetcherStore, createMutatorStore] = nanoquery({
    fetcher: async (methodName: any) => {
      return await instance.preferences[methodName as PreferenceMethods]();
    },
  });

  return {
    createFetcherStore,
    createMutatorStore,
  };
}
