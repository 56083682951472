import type { Instance } from '@engagespot/core';
import type { createLogger } from '@engagespot/utils';
import { nanoquery } from '@nanostores/query';

type WebPushFetcherOptions = {
  instance: Instance;
  log: ReturnType<typeof createLogger>;
};

export function createWebPushFetchBuilder({ instance }: WebPushFetcherOptions) {
  const [createFetcherStore, createMutatorStore] = nanoquery({
    fetcher: async () => {
      const webPushConnectPromise = instance.webPush.connect();

      return await webPushConnectPromise;
    },
  });

  return {
    createFetcherStore,
    createMutatorStore,
  };
}
