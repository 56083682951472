import React, { useState } from 'react';
import { StarEmpty } from 'src/icons/StarEmpty';
import { StarFilled } from 'src/icons/StarFilled';

import type { RatingStarStyledProps } from './RatingStar.styled';
import { StarContainer, StarWrapper } from './RatingStar.styled';

export const RatingStar: React.FC = ({
  icon,
  activeIcon,
  onChange,
  ...props
}: RatingStarStyledProps) => {
  const [rating, setRating] = useState<number>(0);
  const [hoveredRating, setHoveredRating] = useState<number>(0);

  const handleClick = (index: number) => {
    setRating(index + 1);
    onChange?.(index);
  };

  const handleMouseEnter = (index: number) => {
    setHoveredRating(index + 1);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  return (
    <StarContainer {...props} data-value={rating}>
      {Array.from({ length: 5 }).map((_, index) => (
        <StarWrapper
          key={index}
          onClick={() => handleClick(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {index < (hoveredRating || rating)
            ? activeIcon ?? <StarFilled />
            : icon ?? <StarEmpty />}
        </StarWrapper>
      ))}
    </StarContainer>
  );
};
