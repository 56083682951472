import type { EngagespotChannels } from '@engagespot/core';

export const channelNameMapping: Record<EngagespotChannels, string> = {
  discord: 'Discord',
  email: 'Email',
  inApp: 'In-App',
  mobilePush: 'Mobile Push',
  slack: 'Slack',
  sms: 'SMS',
  webPush: 'Web Push',
  webhook: 'Webhook',
  whatsapp: 'WhatsApp',
  chat: 'Chat',
};
