import { useStore } from '@nanostores/react';

import { useInternalInstance } from './useInstance';

export function useHideBranding() {
  const {
    connect: { stores },
  } = useInternalInstance();
  const { $hideBranding } = stores;
  const hideBranding = useStore($hideBranding);

  return hideBranding;
}
