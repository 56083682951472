import { yupResolver } from '@hookform/resolvers/yup';
import { usePublishChanges } from 'api/commits';
import CustomDiffViewer from 'components/diff-viewer';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import useWorkflowStore from 'store/workflowStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import { createWorkflowDiffFromJson } from '../../functions';
import React from 'react';
import { getHashidsInstance } from 'utils/use-hash-id';

type FormChanges = {
  message: string;
};

const schema = yup.object({
  message: yup.string().required('Commit Message is required'),
});

function WorkflowDiffModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const params = useParams();
  const { appId } = useAuthStore(state => state);
  const hashIds = getHashidsInstance();
  const hashedWorkflowId = params.workflowId;
  const workflowId = hashIds.decode(hashedWorkflowId);
  const {
    workflowJson,
    publishedWorkflowJson,
    setHasJsonChanges,
    setPublishedWorkflowJson,
    setWorkflowJson,
  } = useWorkflowStore(useShallow(store => store));
  const toast = useCustomToast();
  const publishChanges = usePublishChanges();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormChanges>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
    reset({ message: '' });
  };

  const { publishedCode, latestCode } = createWorkflowDiffFromJson({
    publishedWorkflowJson,
    workflowJson,
  });

  const onSubmit: SubmitHandler<FormChanges> = values => {
    const data = {
      message: values.message,
      appId,
      changes: [
        {
          entityType: 'workflow' as const,
          entityId: Number(workflowId),
        },
      ],
    };

    publishChanges.mutate(data, {
      onSuccess: () => {
        toast.success('Worklow Published Successfully');
        setPublishedWorkflowJson(workflowJson);
        setWorkflowJson(workflowJson);
        setHasJsonChanges(false);
        handleClose();
      },
      onError: error => {
        toast.showError(error);
        handleClose();
      },
    });
  };

  React.useEffect(() => {
    setValue('message', `Edited ${workflowJson?.name} `);
  }, [isOpen, setValue, workflowJson?.name]);

  return (
    <CustomModal
      modalContentClassName="min-w-[92vw] h-[92vh] top-[8%]"
      modalCardClassName={'max-h-[92vh] h-[92vh] px-0 py-0'}
      isOpen={isOpen}
      onClose={handleClose}
      showCloseButton={false}
      modalOverlayClick={handleClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col border-b mb-6  border-gray-880 px-8 py-6"
      >
        <div className="flex justify-between  items-center ">
          <h1 className="text-lg">Preview Changes</h1>

          <div className="flex gap-3">
            <button
              type="button"
              onClick={handleClose}
              className="bg-night-400 text-white border border-gray-440 px-4 py-2 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              cancel
            </button>

            <button
              type="submit"
              className="bg-white text-night-400 border border-gray-440 px-4 py-2 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={publishChanges.isLoading}
            >
              Publish
              {publishChanges.isLoading && (
                <LoaderSpinner
                  strokeColor="black"
                  parentClass="relative left-1"
                />
              )}
            </button>
          </div>
        </div>

        <div className="mt-2">
          <InputField<FormChanges>
            variant="modal"
            extra=""
            label={''}
            placeholder="Enter commit message"
            showIsRequiredAsterisk={true}
            type="text"
            register={register}
            name={'message'}
          />

          {errors.message && (
            <ErrorBox className="my-0 mt-3" error={errors.message} />
          )}
        </div>
      </form>

      <div className="h-[76%] csm-react-diff overflow-auto rounded-lg mx-8 border border-gray-880">
        <CustomDiffViewer oldValue={publishedCode} newValue={latestCode} />
      </div>
    </CustomModal>
  );
}

export default WorkflowDiffModal;
