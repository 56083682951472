import type { CSSProperties as ReactCSSProperties } from 'react';

export type CircleProps = {
  style?: ReactCSSProperties | undefined;
};

export function ChevronDown({ style = {} }: CircleProps = {}) {
  return (
    <svg
      style={{ fill: 'currentcolor', ...style }}
      width="14"
      height="9"
      viewBox="0 0 14 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.45372 0.22329L0.223657 6.45347C0.0794465 6.59757 0 6.78992 0 6.99503C0 7.20013 0.0794465 7.39249 0.223657 7.53658L0.682352 7.99539C0.981244 8.29394 1.46703 8.29394 1.76547 7.99539L6.9971 2.76376L12.2345 8.0012C12.3787 8.14529 12.571 8.22485 12.776 8.22485C12.9812 8.22485 13.1734 8.14529 13.3178 8.0012L13.7763 7.54239C13.9206 7.39818 14 7.20593 14 7.00083C14 6.79573 13.9206 6.60337 13.7763 6.45927L7.54059 0.22329C7.39592 0.0788524 7.20277 -0.000480059 6.99744 -2.47782e-05C6.79131 -0.000480059 6.59827 0.0788524 6.45372 0.22329Z" />
    </svg>
  );
}
