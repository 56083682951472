import { BaseItem, type BaseItemProps } from './BaseItem';
import { useCommandBar } from '../provider';
import { Page } from 'commandbar/pages';

export type ListItemProps = Omit<BaseItemProps, 'onSelect' | 'type'> & {
  page: Page;
};

export function ListItem({
  children,
  icon,
  page,
  keywords,
  ...props
}: ListItemProps) {
  const { setPage, clearInput } = useCommandBar();

  return (
    <BaseItem
      {...props}
      keywords={keywords}
      icon={icon}
      type="List"
      onSelect={() => {
        setPage(page);
        clearInput();
      }}
    >
      {children}
    </BaseItem>
  );
}
