import { yupResolver } from '@hookform/resolvers/yup';
import {
  usePatchUpdateLayoutJson,
  usePostCreateLayout,
  usePostSaveEmailLayout,
  usePutEditLayout,
} from 'api/layouts';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { useLayoutStore } from 'store/layoutStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { handleEmailLayoutSave } from '../../templateEditor/functions';
import {
  DEFAULT_EMAIL_LAYOUT_HTML,
  DEFAULT_EMAIL_LAYOUT_JSON,
} from 'email-editor/getConfiguration/sample/default-email-layout';
import { getHashidsInstance } from 'utils/use-hash-id';

type FormAddLayouts = {
  name: string;
  appId: string;
  identifier: string;
};

function LayoutModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const hashIds = getHashidsInstance();
  const { appId } = useAuthStore(state => state);
  const postCreateLayout = usePostCreateLayout();
  const putEditLayout = usePutEditLayout();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const postSaveEmailLayout = usePostSaveEmailLayout();
  const patchUpdateLayoutJson = usePatchUpdateLayoutJson();

  const {
    layout: selectedLayout,
    setLayout,
    setLayoutModalState,
    layoutModalState,
  } = useLayoutStore(state => state);

  const defaultValues = React.useMemo(
    () => ({
      name: '',
      identifier: '',
    }),
    [],
  );

  const schema = yup.object({
    name: yup.string().required('name is required'),
    ...(!selectedLayout && {
      identifier: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_]*$/,
          'Identifier can only contain letters, numbers, and underscores',
        )
        .required('identifier is required'),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormAddLayouts>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  React.useEffect(() => {
    if (selectedLayout) {
      reset({
        name: selectedLayout.name,
        identifier: String(selectedLayout.identifier),
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, reset, selectedLayout, layoutModalState]);

  const handleClose = () => {
    reset(defaultValues);
    setLayout(null);
    setLayoutModalState('add');
    onClose();
  };

  const onSubmit: SubmitHandler<FormAddLayouts> = async values => {
    if (layoutModalState === 'edit') {
      const data = {
        ...values,
        layoutId: selectedLayout.id,
      };

      putEditLayout.mutate(data, {
        onSuccess: () => {
          toast.success('Layout edited successfully');
          handleClose();
        },
        onError: error => {
          toast.showError(error);
        },
      });
    } else {
      const data = {
        ...values,
        appId,
      };

      try {
        const response = await postCreateLayout.mutateAsync(data);
        const layoutId = response.data.id;
        const hashedLayoutId = hashIds.encode(layoutId);
        setLayout(null);

        await handleEmailLayoutSave({
          postSaveEmailLayout,
          patchUpdateLayoutJson,
          layoutName: values.name,
          bodyJsonTemplate: JSON.stringify(DEFAULT_EMAIL_LAYOUT_JSON),
          bodyHtmlTemplate: btoa(
            encodeURIComponent(DEFAULT_EMAIL_LAYOUT_HTML.html),
          ),
          id: layoutId,
        });

        toast.success('Layout saved successfully');
        return navigate(`/admin/layout-editor/${hashedLayoutId}`);
      } catch (e) {
        toast.showError(e);
      }
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-3 text-2xl font-bold">
        {layoutModalState === 'add' ? 'Create' : 'Edit'} Layout
      </h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <InputField<FormAddLayouts>
            variant="modal"
            extra="mb-3"
            label={'Name'}
            placeholder={'Eg: Email with header'}
            showIsRequiredAsterisk={true}
            type="text"
            register={register}
            name={'name'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue('name', e.target.value, {
                shouldValidate: true,
              });

              if (!selectedLayout) {
                setValue(
                  'identifier',
                  e.target.value.toLowerCase().replace(/ /g, '_'),
                  {
                    shouldValidate: true,
                  },
                );
              }
            }}
          />
          {errors.name && <ErrorBox error={errors.name} />}
        </div>

        {!selectedLayout && (
          <>
            <InputField<FormAddLayouts>
              variant="modal"
              extra="mb-3"
              label={'Identifier'}
              placeholder="Eg: emaill_with_header"
              showIsRequiredAsterisk={true}
              type="text"
              register={register}
              name={'identifier'}
            />
            {errors.identifier && <ErrorBox error={errors.identifier} />}
          </>
        )}

        <CustomModalButtons
          primaryButtonText="Create"
          handleClose={handleClose}
          loading={postCreateLayout.isLoading || putEditLayout.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default LayoutModal;
