import useGetClientDetails from 'api/client/get-client-details.ts';
import { useAuthStore } from 'store/authStore';
import { usePremiumStore } from 'store/premiumStore';

const texts = {
  no_exceeded:
    'You have reached the maximum limit of your current plan. To enjoy the full benefits of the platform, please upgrade your plan.',
  not_supported:
    'Your plan does not allow you to use this feature of our platform. Upgrade your plan to get unlimited access to everything',
  invite_members:
    'Your free plan permits a maximum of two members in your workspace. To invite additional users, please consider upgrading your subscription',
};

export const usePlanLimit = () => {
  const { clientId } = useAuthStore(state => state);
  const getClientDetails = useGetClientDetails(clientId);
  const premiumModalActions = usePremiumStore(state => state);

  return { limits: getClientDetails.data, actions: premiumModalActions, texts };
};
