import Cookies from 'js-cookie';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useResetStore } from 'store/reset';

export const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const resetAllStores = useResetStore();

  const logout = () => {
    resetAllStores();
    Cookies.remove('access_token');
    Cookies.remove('license_access_token');
    queryClient.clear();
    navigate('/auth/login');
  };

  return logout;
};
