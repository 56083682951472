import {
  createFilteredFeed,
  useActions,
  useOptions,
} from '@engagespot/react-hooks';
import type {
  CSSProperties as ReactCSSProperties,
  MouseEvent as ReactMouseEvent,
  ReactNode,
} from 'react';
import React, { Fragment } from 'react';
import { InView } from 'react-intersection-observer';
import { Transition } from 'react-transition-group';

import {
  JumpToTopPositioning,
  NotificationEmptyPlaceholderText,
  NotificationFeedStyled,
} from './NotificationFeed.styled';
import { renderCustom } from '../../utils/renderCustom';
import { JumpToTop } from '../jumpToTop';
import type { NotificationFeedItemProps } from '../notificationFeedItem';
import {
  FeedItemPlaceholder,
  NotificationFeedItem,
} from '../notificationFeedItem';
import { useEngagespotContext } from '@/engagespotProvider';
import { useJumpToTop } from '@/hooks/useJumpToTop';
import { useText } from '@/hooks/useText';
import { NotificationEmpty as NotificationEmptyPlaceholder } from '@/icons/NotificationEmpty';
import type { customRenderFn } from '@/types/panel';

export type customPlaceholderContentType =
  | (() => ReactNode | string)
  | undefined;

export type customNotificationContentType =
  | ((notification: NotificationFeedItemProps) => ReactNode)
  | undefined;

const duration = 150;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};
const transitionStyles: { [id: string]: ReactCSSProperties } = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export type NotificationFeedProps = {
  empty?: boolean;
  placeholderText?: string;
};

const RenderPlaceholderContent = (): ReactNode => {
  const { t } = useText();

  return (
    <React.Fragment>
      <NotificationEmptyPlaceholder />
      <NotificationEmptyPlaceholderText>
        {t('notificationFeed.placeholderText')}
      </NotificationEmptyPlaceholderText>
    </React.Fragment>
  );
};

const renderNotificationContent = (
  notification: NotificationFeedItemProps['notification'],
  customRenderer: customRenderFn<customNotificationContentType>,
): ReactNode => {
  return (
    renderCustom(customRenderer, notification) || (
      <NotificationFeedItem notification={notification} />
    )
  );
};

export function NotificationFeed() {
  const engagespotContext = useEngagespotContext();
  const options = useOptions();
  const { tenantIdentifier } = options;
  const {
    renderNotificationContent: renderCustomNotificationContent,
    renderEmptyPlaceholderImage: renderCustomPlaceholderContent,
    category,
    hideJumpToTop,
  } = engagespotContext;
  const { onNotificationScroll, jumpToTop, showJumpToTop } = useJumpToTop();

  const onJumpToTopClick = (
    evt: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const notificationFeedEl = evt.currentTarget.parentNode?.parentElement;
    jumpToTop?.(notificationFeedEl as HTMLElement);
  };

  const [currentPage, setCurrentPage] = React.useState(1);
  const useFeed = createFilteredFeed({
    category,
    tenantIdentifier,
  });
  const { notifications, loadMore, hasMore, loading } = useFeed();
  const empty = notifications?.length === 0;
  const actions = useActions();

  return (
    <NotificationFeedStyled
      isLoading={loading}
      empty={empty}
      onScroll={onNotificationScroll}
    >
      {!hideJumpToTop && (
        <Transition in={showJumpToTop} timeout={duration}>
          {state => (
            <JumpToTopPositioning
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              <JumpToTop onClick={onJumpToTopClick} />
            </JumpToTopPositioning>
          )}
        </Transition>
      )}

      {empty &&
        !loading &&
        (renderCustom(renderCustomPlaceholderContent) || (
          <RenderPlaceholderContent />
        ))}

      {!empty && (
        <>
          {notifications.map(notification => {
            return (
              <Fragment key={notification.id}>
                {renderNotificationContent(
                  notification,
                  renderCustomNotificationContent,
                )}
              </Fragment>
            );
          })}
        </>
      )}

      {loading && (
        <>
          {[...Array(4).keys()].map((_, index) => (
            <FeedItemPlaceholder key={`loader_${index}`} />
          ))}
        </>
      )}

      {/* on scroll load more */}
      {hasMore && (
        <InView
          as="span"
          onChange={inView => {
            if (inView) {
              setCurrentPage(currentPage + 1);
              actions.markAsSeen({
                pageNo: currentPage + 1,
              });
              loadMore();
            }
          }}
        ></InView>
      )}
    </NotificationFeedStyled>
  );
}
