import { yupResolver } from '@hookform/resolvers/yup';
import {
  useGetLocales,
  usePostCreateTranslations,
  usePutEditTranslations,
} from 'api/translations';
import { GetLocales } from 'api/translations/types';
import { AxiosResponse } from 'axios';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import {
  SelectSearchField,
  useSelectSearch,
} from 'components/fields/SelectSearchField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { useTranslationStore } from 'store/translationStore';
import { getHashidsInstance } from 'utils/use-hash-id';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';

type FormAddTranslations = {
  category: string;
  name: string;
  appId: string;
  identifier: string;
  localeId: string;
};

function TranslationModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { appId } = useAuthStore(state => state);
  const postCreateTranslations = usePostCreateTranslations();
  const putEditTranslations = usePutEditTranslations();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState('');
  const getLocales = useGetLocales({ search: filter });
  const [identifier, setIdentifier] = React.useState('');
  const hashId = getHashidsInstance();

  const {
    translation: selectedTranslation,
    setTranslation,
    setTranslationModalState,
    translationModalState,
  } = useTranslationStore(state => state);

  const defaultValues = React.useMemo(
    () => ({
      name: '',
      identifier: '',
    }),
    [],
  );

  const schema = yup.object({
    name: yup.string().required('name is required'),
    localeId: yup.string().required('locale is required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<FormAddTranslations>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onBlur',
  });

  React.useEffect(() => {
    if (selectedTranslation) {
      reset({
        name: selectedTranslation.name,
        localeId: String(selectedTranslation.localeId),
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, reset, selectedTranslation, translationModalState]);

  const handleClose = () => {
    reset(defaultValues);
    setTranslation(null);
    setTranslationModalState('add');
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: (
        response: AxiosResponse<{
          id: string;
        }>,
      ) => {
        toast.success(message);

        handleClose();

        const translationId = response.data.id;
        const hashedTranslationId = hashId.encode(Number(translationId));

        if (translationModalState === 'add') {
          setTranslation(null);
          return navigate(`/admin/translation-editor/${hashedTranslationId}`);
        }
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormAddTranslations> = values => {
    if (translationModalState === 'edit') {
      const finalActions = getFinalActions('translation edited successfully');
      const data = {
        ...values,
        translationId: selectedTranslation.id,
      };

      putEditTranslations.mutate(data, finalActions);
    } else {
      const data = {
        ...values,
        appId,
        localeId: Number(values.localeId),
        identifier: `${identifier}`,
      };

      const finalActions = getFinalActions('translation created successfully');
      postCreateTranslations.mutate(data, finalActions);
    }
  };

  const createLocaleOptions = (item: GetLocales['results'][0]) => {
    return {
      label: `${item.language} (${item.countryRegion}) - ${item.localeCode}`,
      value: `${item.id}`,
    };
  };

  const { setSelectedOption, options } = useSelectSearch<
    GetLocales['results'][0]
  >({
    results: getLocales.data?.results,
    isLoading: getLocales.isLoading,
    createOptions: createLocaleOptions,
  });

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-3 text-2xl font-bold">
        {translationModalState === 'add' ? 'Create' : 'Edit'} Translation
      </h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <InputField<FormAddTranslations>
            variant="modal"
            extra="mb-3"
            label={'Name'}
            placeholder={'Enter name'}
            showIsRequiredAsterisk={true}
            type="text"
            register={register}
            name={'name'}
          />
          {errors.name && <ErrorBox error={errors.name} />}
        </div>

        {translationModalState === 'add' && (
          <div className="">
            <Controller
              name="localeId"
              control={control}
              render={({ field }) => (
                <SelectSearchField
                  value={field.value}
                  label="Locale"
                  onChange={value => {
                    // reset filter
                    setFilter('');

                    // same select case
                    if (value === '') {
                      setIdentifier('');
                      field.onChange('');
                      setSelectedOption(null);
                      return;
                    }

                    // new select case
                    const selectedLocale = getLocales.data?.results?.find(
                      locale => locale.id === Number(value),
                    );
                    const selectedOption = createLocaleOptions(selectedLocale);

                    setIdentifier(selectedLocale?.localeCode);
                    field.onChange(value);
                    setSelectedOption(selectedOption);
                  }}
                  onBlur={field.onBlur}
                  dataOptions={options}
                  setFilter={setFilter}
                  filter={filter}
                  isFetching={getLocales.isFetching}
                  placeholder="Select Locale"
                ></SelectSearchField>
              )}
            />
            {errors.localeId && <ErrorBox error={errors.localeId} />}
          </div>
        )}

        <CustomModalButtons
          primaryButtonText="Create"
          handleClose={handleClose}
          loading={
            postCreateTranslations.isLoading || putEditTranslations.isLoading
          }
        />
      </form>
    </CustomModal>
  );
}

export default TranslationModal;
