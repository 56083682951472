import { formatDistance } from 'date-fns';

export const relativeDateFormatter = (dateString: string) => {
  try {
    return formatDistance(new Date(dateString), new Date(), {
      addSuffix: true,
    });
  } catch (e) {
    return 'date error';
  }
};
