import { useDisclosure } from '@chakra-ui/hooks';
import useGetAppSecret from 'api/app/get-app-secret';
import useGetRsaKey from 'api/app/get-rsa-key';
import usePostRsaKey from 'api/app/post-rsa-key';
import Card from 'components/card';
import CopyButton from 'components/copy';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { buttonVariants } from 'components/shadcn/button';
import Skeleton from 'components/skeleton';
import { JSEncrypt } from 'jsencrypt';
import React from 'react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useAuthStore } from 'store/authStore';
import { cn } from 'utils/class-merge';
import { saveAsFile } from 'utils/functions';
import useCustomToast from 'utils/use-toast';
import Header from './Header';
import SiginKeyModal from './SiginKeyModal';
import SigninKeyConfirmModal from './SigninKeyConfirmModal';
import TextContent from './TextContent';

const Content = ({
  parentClass = '',
  code,
  header,
  text,
  isLoaded,
  canFetchCode,
}: {
  parentClass?: string;
  header: string;
  text: string;
  code: string;
  isLoaded: boolean;
  canFetchCode: boolean;
}) => {
  const { appId } = useAuthStore(state => state);
  const getAppSecret = useGetAppSecret(appId);
  const [show, setShow] = React.useState(false);
  const togglePasswordView = () => {
    if (!getAppSecret.data?.apiSecret) {
      getAppSecret.refetch();
    }

    setShow(!show);
  };

  const dummyCode = '************************************************';
  const isVisible =
    !canFetchCode && isLoaded
      ? true
      : !getAppSecret.isLoading && show
        ? true
        : false;

  return (
    <div className={parentClass}>
      <Header>{header}</Header>
      <TextContent>{text}</TextContent>

      <div className="flex items-center gap-4">
        {isLoaded ? (
          <>
            <div
              className={`mt-5 flex h-8 w-fit items-center justify-center rounded-[3px]  bg-night-400 border border-[#3A3F47] p-3 py-4 text-sm font-bold uppercase ${
                canFetchCode && getAppSecret.isFetching ? 'csm-pulse' : ''
              }`}
            >
              <span
                className={`min-w-[150px] lowercase text-gray-10 font-medium ${
                  !isVisible ? 'relative top-[2px] text-base' : ''
                }`}
              >
                {isVisible ? code : dummyCode}
              </span>

              {canFetchCode && (
                <button
                  disabled={getAppSecret.isFetching}
                  type="button"
                  onClick={togglePasswordView}
                  className="ml-4 scale-125 text-white"
                >
                  {show ? <RiEyeCloseLine /> : <MdOutlineRemoveRedEye />}
                </button>
              )}

              {isVisible && (
                <div className="ml-4 relative left-1 top-1 lowercase flex justify-center items-center">
                  <CopyButton className="h-full" value={code} />
                </div>
              )}
            </div>
          </>
        ) : (
          <Skeleton className="mt-5 rounded-[3px] !w-3/5" />
        )}
      </div>
    </div>
  );
};

function ApiKey({
  apiKey,
  apiSecret,
  isLoaded,
}: {
  apiKey: string;
  apiSecret: string;
  isLoaded: boolean;
}) {
  const toast = useCustomToast();
  const postRsaKey = usePostRsaKey();
  const { appId } = useAuthStore(state => state);
  const getRsaKey = useGetRsaKey(appId);

  const signInKeyModalActions = useDisclosure();
  const signInKeyConfirmModalActions = useDisclosure();

  const [loading, setLoading] = React.useState(false);
  const defaultPublicPrivateKey = {
    publicKey: '',
    privateKey: '',
  };
  const [publicPrivateKey, setPublicPrivateKey] = React.useState(
    defaultPublicPrivateKey,
  );

  const handleGenerate = async () => {
    try {
      setLoading(true);

      const crypt = new JSEncrypt({
        default_key_size: '2048',
      });
      const publicKey = crypt.getPublicKey();
      const privateKey = crypt.getPrivateKey();

      const publicKeyAsFile = saveAsFile({
        fileContent: publicKey,
        fileName: '',
        returnFileContentWithoutSaving: true,
      });

      await postRsaKey.mutateAsync({
        appId,
        rsa_public_key: publicKeyAsFile,
      });

      if (!Boolean(getRsaKey.data?.rsaPublicKey)) {
        getRsaKey.refetch();
      }

      setPublicPrivateKey({
        privateKey,
        publicKey,
      });

      setLoading(false);

      if (signInKeyConfirmModalActions.isOpen) {
        signInKeyConfirmModalActions.onClose();
      }

      signInKeyModalActions.onOpen();
    } catch (e) {
      setLoading(false);
      toast.showError(e);
    }
  };

  const handleCreate = () => {
    if (getRsaKey.data?.rsaPublicKey) {
      return signInKeyConfirmModalActions.onOpen();
    } else {
      handleGenerate();
    }
  };

  const handleSiginKeyModalClose = () => {
    setPublicPrivateKey(defaultPublicPrivateKey);
    signInKeyModalActions.onClose();
  };

  return (
    <>
      {/* Modal */}
      <SiginKeyModal
        isOpen={signInKeyModalActions.isOpen}
        onClose={handleSiginKeyModalClose}
        publicPrivateKey={publicPrivateKey}
      />

      <SigninKeyConfirmModal
        isOpen={signInKeyConfirmModalActions.isOpen}
        onClose={signInKeyConfirmModalActions.onClose}
        handleGenerate={handleGenerate}
        loading={loading}
      />

      {/* Cards */}
      <Card extra={'pb-8 px-6 pt-6'}>
        {/* Api Key */}
        <Content
          code={apiKey}
          header="Api Key"
          text="This is required to authenticate your In-App <Inbox/> front-end component."
          parentClass="mb-9"
          isLoaded={isLoaded}
          canFetchCode={false}
        />

        {/* Api Secret */}
        <Content
          code={apiSecret}
          header="Api Secret"
          text="This is a secret key used to authenticate your backend applications, via X-ENGAGESPOT-API-SECRET header."
          parentClass="mb-9"
          isLoaded={isLoaded}
          canFetchCode={true}
        />

        {/* Sigin Key */}
        <>
          <div className={''}>
            <Header>{'Signing Key'}</Header>
            <TextContent>
              <p>
                Generate a secret signing key that you'll use to sign your
                user's authentication tokens. Use this instead of the legacy
                HMAC Auth.
                <a
                  className="text-decoration-line: underline"
                  href="https://docs.engagespot.com/docs/features/in-app-inbox/authentication#signing-the-jwt-using-your-secret-signing-key"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </p>
            </TextContent>

            {getRsaKey.isFetched && (
              <div className="flex gap-3">
                {getRsaKey.data?.rsaPublicKey && (
                  <button
                    // className="linear mt-4 flex gap-1 rounded-md border border-input bg-night-100 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer  dark:text-white "
                    className={cn(
                      buttonVariants({
                        size: 'sm',
                        className:
                          'mt-4 rounded-md text-sm font-semibold bg-night-100 text-white border border-input py-3 hover:bg-night-120',
                      }),
                    )}
                    type="button"
                    onClick={() =>
                      saveAsFile({
                        fileContent: getRsaKey.data.rsaPublicKey,
                        fileName: 'engagespot_rsa_public_key.txt',
                      })
                    }
                  >
                    Download Public Key
                  </button>
                )}

                <button
                  onClick={handleCreate}
                  disabled={!signInKeyConfirmModalActions.isOpen && loading}
                  className={cn(
                    buttonVariants({
                      size: 'sm',
                      className:
                        'mt-4 rounded-md text-sm font-semibold border border-input',
                    }),
                  )}
                >
                  {getRsaKey.data?.rsaPublicKey ? 'Regenerate' : 'Generate '}
                  {!signInKeyConfirmModalActions.isOpen && loading && (
                    <LoaderSpinner parentClass="relative left-2" />
                  )}
                </button>
              </div>
            )}
          </div>
        </>
      </Card>
    </>
  );
}

export default ApiKey;
