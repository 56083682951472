import useGetSubscriptionDetails from 'api/billing/get-subscription-details';
import { getAbbreviateNumber } from 'utils/get-abbreviate-format';
import { isEnterpriseEdition } from 'utils/get-editions';
import { USAGE_TYPE } from './enums';

export const useGetCurrentBillingPlanDetails = () => {
  const enterpriseEdition = isEnterpriseEdition();
  const getSubscriptionDetails = useGetSubscriptionDetails(enterpriseEdition);
  const currentPlan = getSubscriptionDetails.data?.subscription?.plan;
  const licensedBillingPrice = currentPlan?.prices?.find(
    item => item.usageType === USAGE_TYPE['LICENSED'],
  );
  const freePlanMeteredBillingPrice = currentPlan?.prices?.find(
    item => item.usageType === USAGE_TYPE['METERED'],
  );
  const billingPrice = licensedBillingPrice || freePlanMeteredBillingPrice;

  const billingPriceAmount = billingPrice?.amount;
  const billingCycle = billingPrice?.interval;
  const abbreviatedBillingPricePerMonth =
    getAbbreviateNumber(billingPriceAmount);

  return {
    data: getSubscriptionDetails.data,
    subscription: getSubscriptionDetails.data?.subscription,
    stripeSubscription: getSubscriptionDetails.data?.stripeSubscription,
    currentPlan,
    billingPriceAmount,
    abbreviatedBillingPricePerMonth,
  };
};
