import type { ApiClient } from '@engagespot/api-client';

import type { Events, PermissionState } from './events';
import { defaults } from '../data/defaults';

type WebPushPermissionChangeListenerOptions = {
  events: Events;
};

/**
 * Initialize a listener for Web Push Permission Changes
 */
export const listenForWebPushPermissionChanges = ({
  events,
}: WebPushPermissionChangeListenerOptions) => {
  navigator.permissions
    .query({ name: defaults.serviceWorkerQueryKey })
    .then(permission => {
      // Initial status is available at permission.state
      permission.onchange = e => {
        const target = e.target as PermissionStatus;
        const state = target.state as PermissionState;

        events.webPushPermissionChange.fire({
          permission: state,
        });
      };
    });
};

type AttachPushSubscriptionOptions = {
  deviceId: string;
  apiClient: ApiClient;
};
/**
 * Attach the push subscription for this device
 */
export const attachPushSubscription = async (
  subscription: PushSubscription,
  { deviceId, apiClient }: AttachPushSubscriptionOptions,
) => {
  const response = await apiClient.POST(
    '/v3/devices/{deviceId}/webPushSubscription',
    {
      params: {
        path: {
          deviceId,
        },
      },
      body: subscription as any,
    },
  );

  return response;
};
