import { cn } from 'utils/class-merge';

type TagType = 'success' | 'warn' | 'info' | 'error';

type TagProps = {
  type: TagType;
  children: React.ReactNode;
};

export function Tag({ type, children }: TagProps) {
  const typeStyles = {
    success:
      'bg-green-750 text-green-20 border-green-750 hover:bg-green-750/80',
    warn: 'bg-yellow-750 text-yellow-20 border-yellow-750 hover:bg-yellow-750/80',
    info: 'bg-blue-750 text-blue-20 border-blue-750 hover:bg-blue-750/80',
    error: 'bg-red-750 text-red-20 border-red-750 hover:bg-red-750/80',
  };

  return (
    <span
      className={cn(
        'inline-flex items-center border rounded-full px-2.5 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
        typeStyles[type],
      )}
    >
      {children}
    </span>
  );
}
