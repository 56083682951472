import { HASH_ID_SALT } from 'config';
import Hashids from 'hashids';

export const getHashidsInstance = () => {
  const hashids = new Hashids(HASH_ID_SALT);

  return {
    encode: (id: number) => hashids.encode(id),
    decode: (id: string) => hashids.decode(id)[0],
  };
};
