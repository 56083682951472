import createFetchClient from 'openapi-fetch';

import type { paths } from './types/engagespot.v3';
import { createCommonHeaders } from './utils/createHeaders';
import type { OmitHeadersFromPaths } from './utils/omitHeaderFromType';

type CommonHeaders = 'X-ENGAGESPOT-USER-ID' | 'X-ENGAGESPOT-API-KEY';

export type ApiClientOptions = {
  baseUrl: string;
  apiKey: string;
  userToken?: string;
  userId: string;
  userSignature?: string;
  deviceId?: string;
  additionalHeaders?: Record<string, string>;
};

/**
 * This sucks but `openapi-fetch` is not able to filter out headers that were already passed in the `createApiClient` function.
 * https://github.com/drwpow/openapi-typescript/issues/1617
 */
export type ApiPaths = OmitHeadersFromPaths<paths, CommonHeaders>;

export type ApiClient = ReturnType<typeof createApiClient>;

/**
 * Create an instance of the Engagespot API client. Only to be used internally.
 * Just a wrapper over `openapi-fetch`
 * @param options - The options to create the API client.
 * @returns openapi-fetch client
 */
export function createApiClient(options: ApiClientOptions) {
  const { baseUrl, additionalHeaders, ...clientOptions } = options;

  const headers = createCommonHeaders({
    ...clientOptions,
    ...additionalHeaders,
  });
  const apiClient = createFetchClient<ApiPaths>({
    baseUrl,
    headers,
  });

  return apiClient;
}
