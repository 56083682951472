/**
 * Convert an array of strings to a string of parameters
 * @example convertArrayArgsToParams(['id', '30', 'markAsRead', false])
 * => {id: 30, markAsRead: false}
 */
export function convertArrayArgsToParams(params: any[]) {
  const result = {} as any;
  for (let i = 0; i < params.length; i += 2) {
    if (params[i + 1]) result[params[i]] = params[i + 1];
  }
  return result;
}
