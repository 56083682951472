import type { Instance } from '@engagespot/core';
import type { createLogger } from '@engagespot/utils';
import { nanoquery } from '@nanostores/query';

type ConnectFetcherOptions = {
  instance: Instance;
  log: ReturnType<typeof createLogger>;
};

export function createConnectFetcherBuilder({
  instance,
}: ConnectFetcherOptions) {
  const [createFetcherStore, createMutatorStore] = nanoquery({
    fetcher: async () => {
      const appConnectPromise = instance.app.connect();

      return await appConnectPromise;
    },
  });

  return {
    createFetcherStore,
    createMutatorStore,
  };
}
