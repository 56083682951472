import Download from '@assets/svg/billing/download.svg';
import { GetInvoices } from 'api/billing/get-invoices/types';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import { format, fromUnixTime } from 'date-fns';
import { getCurrencySymbol } from 'utils/get-currency';
import PageHeader from 'components/header/PageHeader';

function InvoiceTable(props: { tableData: any; loading: boolean }) {
  const currencySymbol = getCurrencySymbol();
  const { tableData, loading } = props;

  const downloadPdf = (url: string) => {
    return window.open(url, '_blank').focus();
  };

  const columnConfig: ColumnConfig<GetInvoices['data'][0]> = [
    {
      accessorKey: 'id',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Identifier
        </p>
      ),
      cellFunction: info => (
        <p className="py-[10px]  text-sm text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    },
    {
      accessorKey: 'status_transitions',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Bill Date
        </p>
      ),
      cellFunction: info => {
        const finalizedDateUnix = info.getValue().finalized_at;
        let finalizedDateInString = '';

        if (finalizedDateUnix) {
          const finalizedDate = fromUnixTime(finalizedDateUnix);

          if (finalizedDate) {
            finalizedDateInString = format(finalizedDate, 'd MMMM yyyy');
          }
        }

        return (
          <p className="py-[10px]  text-sm text-navy-700 dark:text-white">
            {finalizedDateInString}
          </p>
        );
      },
    },
    {
      accessorKey: 'amount_due',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Amount
        </p>
      ),
      cellFunction: info => (
        <p className="py-[10px]  text-sm text-navy-700 dark:text-white">
          {`${currencySymbol} ${Number(info.getValue()) / 100}`}
        </p>
      ),
    },
    {
      accessorKey: 'status',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Payment Status
        </p>
      ),
      cellFunction: info => {
        const isPaid = info.getValue() === 'paid';

        return (
          <p
            className={`relative left-4 flex w-min items-center justify-center rounded-md tracking-wider ${
              isPaid ? 'bg-green-50 text-[#7DD155]' : 'bg-red-50 text-[#d16255]'
            } px-3 py-2 text-sm capitalize`}
          >
            {info.getValue()}
          </p>
        );
      },
    },
    {
      accessorKey: 'hosted_invoice_url',
      header: () => null,
      cellFunction: info => {
        const status = info.row.original.status;

        if (status !== 'paid') {
          return (
            // <p
            //     className={`relative left-4 flex w-24 items-center justify-center rounded-md tracking-wider bg-green-50 font-bold text-[#7DD155] px-3 py-2 text-sm capitalize`}
            //   >
            //     <a href={`{info.getValue()}`}>Pay &gt;</a>
            //   </p>

            <button
              className="relative flex w-20 items-center justify-center rounded-md bg-brand-500 px-3 py-2 text-sm font-sm text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
              onClick={() => window.open(info.getValue(), '_blank')}
            >
              Pay
            </button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      accessorKey: 'invoice_pdf',
      header: () => null,
      cellFunction: info => (
        <div className="flex w-full justify-center">
          <button
            className="flex gap-2 rounded-lg border border-[#E1E1E133] px-3 py-2 font-medium text-black"
            onClick={() => downloadPdf(info.getValue())}
          >
            <img src={Download} width={17} alt="" />
          </button>
        </div>
      ),
    },
  ];

  const columns = columnGenerator({
    columnConfig,
  });

  return (
    <div className="csm_invoice_table mt-8 overflow-x-scroll xl:overflow-x-hidden">
      <PageHeader
        heading={'Invoice History'}
        text={'View and download your past invoices'}
        showSearchBar={false}
      />

      {/* table */}
      <CustomTable
        loading={loading}
        emptyTableMessage="No worries! Buy a subscription plan"
        paginationType="table"
        tableData={tableData ?? []}
        columns={columns}
        containerClassName="mt-0"
      />
    </div>
  );
}

export default InvoiceTable;
