import type { EngagespotNotification, Instance } from '@engagespot/core';
import { relativeDateFormatter, uniqBy } from '@engagespot/utils';
import {
  atom,
  computed,
  onMount,
  onNotify,
  type ReadableAtom,
  type WritableAtom,
} from 'nanostores';

import { createNotificationFetcherBuilder } from './notificationFetcher';
import { defaults } from '../../data/defaults';
import type { InitServiceFn, ServiceArgs } from '../../utils/service';

type notificationFeedServiceArgs = ServiceArgs<'notificationFeedService'> &
  ServiceArgs<'connectService'>;

type NotificationFetcherStore = Awaited<
  ReturnType<Instance['notification']['getByPage']>
>;

export type NotificationFeedService = ReturnType<
  typeof notificationFeedService
>;

export type EngagespotStoreNotification = EngagespotNotification & {
  createdAtRelative?: string;
  clickedAtRelative?: string;
  seenAtRelative?: string;
};

export type FeedOptions = {
  category?: string;
  tenantIdentifier?: string;
  key?: string;
};

type FeedStore = {
  stores: {
    $currentPage: WritableAtom<number>;
    $limit: WritableAtom<number>;
    $notifications: WritableAtom<EngagespotStoreNotification[]>;
    $unreadCount: WritableAtom<number>;
    $loading: ReadableAtom<boolean>;
    $hasMore: ReadableAtom<boolean>;
  };
  actions: {
    loadMore: () => void;
    refresh: () => void;
  };
  key: string;
};

type UpdateStoreOptions = {
  fireAll?: boolean;
  additionalFilterKeys?: string[];
  keyResolver?: (key: string) => string;
};

type UpdateStoreCallback = (stores: FeedStore['stores']) => void;

const getNotificationsWithRelativeTime = (
  notifications: EngagespotNotification[],
) => {
  const notificationsWithRelativeTime = notifications?.map(notification => {
    return {
      ...notification,
      ...(notification.createdAt && {
        createdAtRelative: relativeDateFormatter(notification.createdAt),
      }),
      ...(notification.clickedAt && {
        clickedAtRelative: relativeDateFormatter(notification.clickedAt),
      }),
      ...(notification.seenAt && {
        seenAtRelative: relativeDateFormatter(notification.seenAt),
      }),
    };
  });

  return notificationsWithRelativeTime;
};

export function notificationFeedService({
  dependencies: { options, log, instance },
  requiredServices: {
    connectService: {
      stores: { $unreadCount },
    },
  },
}: notificationFeedServiceArgs) {
  const feedStoreMap = new Map<string, FeedStore>();

  function createFeedService(
    {
      key = defaults.defaultStoreKey,
      category = '',
      tenantIdentifier = '',
    }: FeedOptions = {
      key: defaults.defaultStoreKey,
    },
  ) {
    if (feedStoreMap.has(key)) return feedStoreMap.get(key) as FeedStore;

    const {
      itemsPerPage = defaults.defaultItemsPerPage,
      relativeTimeUpdateInterval = defaults.defaultRelativeTimeUpdateInterval,
    } = options;

    const $currentPage = atom(1);
    const $limit = atom(itemsPerPage);
    const $category = atom(category);
    const $tenantIdentifier = atom(tenantIdentifier);
    const $filter = atom('');
    const { createFetcherStore } = createNotificationFetcherBuilder({
      instance,
      log,
      dependentStores: { $currentPage, $limit },
    });

    const $notificationFetcherStore =
      createFetcherStore<NotificationFetcherStore>([
        'getByPage',
        'pageNo',
        $currentPage,
        'limit',
        $limit,
        'category',
        $category,
        'tenantIdentifier',
        $tenantIdentifier,
        'filter',
        $filter,
      ]);

    const $loading = computed(
      $notificationFetcherStore,
      notificationFetcherStore => notificationFetcherStore.loading,
    );

    const $notificationMetadata = atom(defaults.defaultNotificationMetadata);

    const $hasMore = computed(
      [$currentPage, $notificationMetadata],
      (currentPage, metadata) => {
        return currentPage < metadata.totalPages;
      },
    );

    const $notifications = atom<EngagespotStoreNotification[]>(
      defaults.defaultNotificationsValue,
    );

    const loadMore = () => {
      $currentPage.set($currentPage.get() + 1);
    };

    const refresh = () => {
      if ($currentPage.value === 1) return;

      $notifications.set(defaults.defaultNotificationsValue);
      $currentPage.set(1);
    };

    onMount($notifications, () => {
      const relativeTimeInterval = setInterval(() => {
        const notifications = $notifications.value;

        if (!notifications) return;

        const notificationsWithRelativeTime =
          getNotificationsWithRelativeTime(notifications);

        $notifications.set(notificationsWithRelativeTime);
      }, relativeTimeUpdateInterval);

      return () => {
        clearInterval(relativeTimeInterval);
      };
    });

    /**
     * There's a bug in @nanostores/query where `onNotify` breaks if $store.get() is called
     * The workaround is to use $store.value instead.
     * https://github.com/nanostores/query/issues/39
     */
    onNotify($notificationFetcherStore, () => {
      const response = $notificationFetcherStore.value;
      if (!response || !response.data || response.loading) {
        return;
      }
      const metaData = response?.data?.data;
      const newNotifications = response.data?.data?.data ?? [];
      const newNotificationsWithRelativeTime =
        getNotificationsWithRelativeTime(newNotifications);

      const currentNotifications = $notifications.value ?? [];

      const latestNotificationInStore = currentNotifications[0];
      const latestNotificationInResponse = newNotifications[0];
      /**
       * onNotify gets called twice for the same response in react 18 and above,
       * so we need to check if the response is already in the store.
       * This is not a foolproof solution, but it works for now.
       */
      if (latestNotificationInStore === latestNotificationInResponse) return;

      log.info('Notifications Store Updated', $notificationFetcherStore.value);
      $notifications.set(
        uniqBy(
          [...currentNotifications, ...newNotificationsWithRelativeTime],
          'id',
        ),
      );
      const unreadCount = metaData?.unreadCount ?? 0;
      const totalCount = metaData?.pagination?.totalCount ?? 0;
      const totalPages = Math.ceil(totalCount / itemsPerPage);

      $notificationMetadata.set({
        unreadCount,
        totalCount,
        totalPages,
      });

      $unreadCount.set(unreadCount);
    });

    const stores = {
      $currentPage,
      $notifications,
      $unreadCount,
      $loading,
      $hasMore,
      $limit,
    };

    const actions = {
      loadMore,
      refresh,
    };

    const feed = { stores, actions, key };
    feedStoreMap.set(key, feed);
    return feed;
  }

  const updateStores = (
    cb: UpdateStoreCallback,
    {
      fireAll = false,
      additionalFilterKeys = [],
      keyResolver,
    }: UpdateStoreOptions = {
      fireAll: false,
      additionalFilterKeys: [],
    },
  ) => {
    for (const feedObject of feedStoreMap) {
      const [key, feed] = feedObject;
      const supportedKeys =
        key === defaults.defaultStoreKey || additionalFilterKeys.includes(key);
      if (fireAll || supportedKeys || keyResolver?.(key)) {
        log.info('Updating Store', key, feed.stores);
        cb(feed.stores);
      }
    }
  };

  const { stores, actions } = createFeedService({
    key: defaults.defaultStoreKey,
  });

  return {
    stores,
    actions,
    createFeedService,
    feedStoreMap,
    updateStores,
  };
}

notificationFeedService.key = 'notificationFeedService' as const;

export const initNotificationFeedService = <T extends InitServiceFn>(
  initService: T,
) => {
  const app = initService({
    key: 'notificationFeedService',
    requiredServiceKeys: ['connectService'],
  });
  return app;
};
