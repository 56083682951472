import styled, { css } from 'styled-components';

export type ImageStyledProps = {
  src?: string | (string & Blob[]);
};

export const ImageStyled = styled.img<ImageStyledProps>`
  && {
    ${({
      theme: {
        actionableElement: { image },
      },
      src,
    }) => css`
      display: ${src ? 'block' : 'none'};
      height: ${image.height};
      object-fit: ${image.objectFit};
    `}
  }
`;
