import { createLogger } from '@engagespot/utils';

import { validateIncomingArgs } from './helpers/validateIncomingArgs';
import { createInitService } from './modules/service';
import { initConnectService } from './services/connectService';
import { initNotificationService } from './services/notificationService';
import { initPreferencesService } from './services/preferencesService';
import { initRealTimeService } from './services/realTimeService';
import { initWebPushService } from './services/webPushService';

export type DataRegion = 'us' | 'eu';

export type InstanceOptions = {
  /**
   * Unique user identifier
   */
  userId: string;

  /**
   * Unique tenant identifier
   */
  tenantIdentifier?: string;

  /**
   * Engagespot API key
   */
  apiKey: string;

  /**
   *  signature for the user
   * @deprecated
   */
  userSignature?: string;

  /**
   * Enabling secure mode enforces token based authentication for the front-end in-app inbox.
   * Production apps must enable secure mode to prevent unauthorised access to your users in-app inbox.
   *
   * https://docs.engagespot.co/docs/features/in-app-inbox/authentication/#enabling-the-secure-mode
   */
  userToken?: string;

  /**
   * disable web push notifications.
   * Default is false
   */
  disableWebPush?: boolean;

  /**
   * Allow non-HTTPS web push. Useful for development environments.
   * Default is false
   */
  allowNonHttpsWebPush?: boolean;

  // service worker registration object
  serviceWorkerRegistration?: ServiceWorkerRegistration;

  /**
   * Disables realtime messages
   * Default is false
   */
  disableRtm?: boolean;

  /**
   * optional base URL for Engagespot server.
   * Should be set if the Engagespot server is hosted on a different domain.
   */
  baseUrl?: string;

  /**
   * Enable debug mode
   * Default is false
   */
  debug?: boolean;

  /**
   * Disabling this will prevent Engagespot from calling the `connect()` and `realTime()` services automatically.
   */
  disableAutoStart?: boolean;

  /**
   * Region where the Engagespot data is hosted, the baseUrl will be automatically set based on the region.
   */
  dataRegion?: DataRegion;
};

export type Instance = ReturnType<typeof createInstance>;

/**
 *
 *
 * @param {InstanceOptions} instanceOptions
 * @returns {Instance}
 */
export function createInstance(instanceOptions: InstanceOptions) {
  const { debug = false, ...options } = instanceOptions ?? {};
  const log = createLogger({
    debug,
    prefix: '[Engagespot Core]',
    debugPrefix: '[DEBUG]',
  });
  validateIncomingArgs(options);

  log.info('Creating Engagespot instance with options:', instanceOptions);

  const { apiKey, userId } = options;

  const {
    initService,
    dependencies: { events },
  } = createInitService(options, log);

  const app = initConnectService(initService);
  const notification = initNotificationService(initService);
  const preferences = initPreferencesService(initService);
  const webPush = initWebPushService(initService);
  const realTime = initRealTimeService(initService);

  return {
    apiKey,
    userId,
    app,
    realTime,
    notification,
    preferences,
    events,
    webPush,
  };
}
