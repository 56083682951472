import { Switch } from 'components/shadcn/switch';
import { yupResolver } from '@hookform/resolvers/yup';
import usePostCategories from 'api/categories/post-categories';
import usePutOrDeleteCategories from 'api/categories/put-delete-categories';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import { useCategoryStore } from 'store/categoryStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { FormAddCategories } from '../types';
import { cn } from 'utils/class-merge';
import { R } from 'utils/remeda-utils';

function CategoryModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const postAddCategories = usePostCategories();
  const putAddCategories = usePutOrDeleteCategories();
  const toast = useCustomToast();
  const { appId } = useAuthStore(state => state);
  const { category: selectedCategory, setCategory } = useCategoryStore(
    state => state,
  );

  const schema = yup.object({
    name: yup.string().required('name is required'),
    ...(!selectedCategory && {
      identifier: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_]*$/,
          'Identifier can only contain letters, numbers, and underscores',
        )
        .required('identifier is required'),
    }),
  });

  const defaultValues = React.useMemo(
    () => ({
      name: '',
      identifier: '',
      hideInInApp: true,
      hideInPreferenceManager: true,
    }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm<FormAddCategories>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (selectedCategory) {
      reset({
        name: selectedCategory.name,
        hideInInApp: R.isNot(R.isTruthy)(selectedCategory.hideInInApp),
        hideInPreferenceManager: R.isNot(R.isTruthy)(
          selectedCategory.hideInPreferenceManager,
        ),
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, reset, selectedCategory, isOpen]);

  const handleClose = () => {
    reset(defaultValues);
    setCategory(null);
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: () => {
        toast.success(message);
        handleClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormAddCategories> = values => {
    const commonValues = {
      ...values,
      hideInInApp: R.isNot(R.isTruthy)(values.hideInInApp),
      hideInPreferenceManager: R.isNot(R.isTruthy)(
        values.hideInPreferenceManager,
      ),
    };

    if (selectedCategory) {
      const data = {
        ...commonValues,
        categoryId: selectedCategory.id,
        type: 'put' as const,
      };

      const finalActions = getFinalActions('category updated successfully');
      putAddCategories.mutate(data, finalActions);
      return;
    }

    const data = {
      ...commonValues,
      identifier: values.identifier,
      appId,
    };

    const finalActions = getFinalActions('category added successfully');
    postAddCategories.mutate(data, finalActions);
  };

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-3 text-2xl font-bold">
        {selectedCategory ? 'Edit' : 'Add'} Category
      </h1>

      <p className="mb-6 text-sm text-[#BFBFBF]">
        Read
        <a
          href={
            'https://docs.engagespot.co/docs/features/category/what-are-categories'
          }
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          docs
        </a>
        to learn more about Categories
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Name */}
        <InputField<FormAddCategories>
          variant="modal"
          extra="mb-3"
          label={'Name'}
          placeholder="Enter name"
          showIsRequiredAsterisk={true}
          type="text"
          register={register}
          name={'name'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue('name', e.target.value, {
              shouldValidate: true,
            });

            if (!selectedCategory) {
              setValue(
                'identifier',
                e.target.value.toLowerCase().replace(/ /g, '_'),
                {
                  shouldValidate: true,
                },
              );
            }
          }}
        />
        {errors.name && <ErrorBox error={errors.name} />}

        {/* Identifier */}
        {!selectedCategory && (
          <>
            <InputField<FormAddCategories>
              variant="modal"
              extra="mb-3"
              label={'Identifier'}
              placeholder="Enter Identifier"
              showIsRequiredAsterisk={true}
              type="text"
              register={register}
              name={'identifier'}
            />
            {errors.identifier && <ErrorBox error={errors.identifier} />}
          </>
        )}

        <div>
          <h2 className="text-sm text-navy-700 dark:text-white ml-1.5 font-medium mb-3">
            InApp Settings
          </h2>

          <div
            className={cn(
              'flex justify-between gap-3 py-3  text-white/90 border border-gray-440 px-2 rounded-md text-sm',
            )}
          >
            <Controller
              control={control}
              name="hideInInApp"
              render={({ field }) => (
                <div className="flex gap-3 items-center">
                  <span className="cursor-default">Show in feed tab</span>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </div>
              )}
            />

            <Controller
              control={control}
              name="hideInPreferenceManager"
              render={({ field }) => (
                <div className="flex gap-3 items-center">
                  <span className="cursor-default">Show in preferences</span>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </div>
              )}
            />
          </div>
        </div>

        <CustomModalButtons
          handleClose={handleClose}
          loading={postAddCategories.isLoading || putAddCategories.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default CategoryModal;
