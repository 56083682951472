import { useEditWorkflowJson } from 'api/workflows';
import { produce } from 'immer';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import {
  findSelectedNodeFromJson,
  getChannelKeyFromId,
  handleJsonDiffCheck,
  handleNavigateToChannelEditor,
} from '../../functions';
import {
  useGetWorkflowChannels,
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import { workflowDocLinks, workflowErrorTypes } from '../../variables';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import { LearnMore } from '../common';
import { getHashidsInstance } from 'utils/use-hash-id';

type FormWorkflowChannels = {
  triggerCondition?: string;
};

const WorkflowChannelForm = () => {
  const params = useParams();
  const hashIds = getHashidsInstance();
  const hashedWorkflowId = params.workflowId;
  const workflowId = Number(hashIds.decode(hashedWorkflowId));
  const navigate = useNavigate();
  const { dataLoaded, setDataLoaded } = useIsDataLoaded();
  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );
  const { allChannels, isLoading } = useGetWorkflowChannels();
  const editWorkflowJson = useEditWorkflowJson();
  const channelKeyFromId = getChannelKeyFromId({ id: selectedNode?.id });

  const selectedJson = findSelectedNodeFromJson({
    steps: workflowJson.steps,
    selectedNodeId: selectedNode?.id,
  });

  const selectedChannel = allChannels?.find(
    channel => channel.channel === channelKeyFromId,
  );

  const notConfigured = selectedChannel?.configuredProviders?.length === 0;

  const formMethods = useForm<FormWorkflowChannels>({
    mode: 'onChange',
  });
  const { reset, getValues, control, setError } = formMethods;

  const handleChange = () => {
    const { triggerCondition } = getValues();
    const selectedNodeId = selectedNode?.id;
    const jsonResult = produce(workflowJson, draft => {
      const selected = findSelectedNodeFromJson({
        steps: draft?.steps,
        selectedNodeId,
      });

      selected.triggerCondition = triggerCondition;
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });
    const selectedConfig = selectedNodeJson?.config;

    reset({
      ...selectedConfig,
      triggerCondition: selectedNodeJson?.triggerCondition,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useWatchWorkflowFormErrors({
    control,
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });

  React.useEffect(() => {
    if (!isLoading && selectedChannel && notConfigured) {
      setError('root', {
        type: workflowErrorTypes.NOT_CONFIGURED,
        message: 'Channel is not configured',
      });
    }
  }, [isLoading, notConfigured, selectedChannel, setError]);

  return (
    <div>
      <div className="flex justify-between mb-3 items-center">
        <div className="text-xl font-medium">
          Manage <span className="capitalize">{selectedJson?.channelKey}</span>{' '}
          Template
        </div>

        <LearnMore link={workflowDocLinks.channels} />
      </div>

      <div className="text-[#ABB0B8] font-medium mb-3">
        Edit the template associated with this step
      </div>

      {selectedChannel?.configuredProviders?.length === 0 ? (
        <button
          onClick={() => {
            editWorkflowJson.mutateAsync({
              workflowId: Number(workflowId),
              jsonSpec: workflowJson,
            });

            navigate(
              `/admin/provider/${selectedChannel.channel}?redirectBack=true`,
            );
          }}
          className="border w-fit border-gray-440  h-12 mt-2 rounded-md p-3 px-6 text-white"
        >
          Configure Channel
        </button>
      ) : (
        <>
          <button
            onClick={() =>
              handleNavigateToChannelEditor({
                selectedNodeId: selectedNode.id,
                workflowId,
                navigate,
                editWorkflowJson,
              })
            }
            className="border w-fit border-gray-440  h-12 mt-2 rounded-md p-3 px-6 text-white"
          >
            Edit Template
          </button>

          <FormProvider {...formMethods}>
            <form className="mt-6">
              <WorkflowConditionForm />
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default WorkflowChannelForm;
