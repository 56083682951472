import { useStore } from '@nanostores/react';

import { useInternalInstance } from './useInstance';

export function useConnect() {
  const {
    connect: { stores },
  } = useInternalInstance();

  const { $connectFetcher } = stores;
  const metadata = useStore($connectFetcher);

  return metadata;
}
