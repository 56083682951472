import type { EventCallbackMap } from '@engagespot/store';
import { useEffect } from 'react';

import { useInternalInstance } from './useInstance';

type EventNames = keyof EventCallbackMap;

export function useEvent<T extends EventNames>(
  eventName: T,
  callback: EventCallbackMap[T],
) {
  const storeInstance = useInternalInstance();

  useEffect(() => {
    storeInstance.instance.events[eventName].subscribe(callback as any);

    return () => {
      storeInstance.instance.events[eventName].unsubscribe(callback as any);
    };
  }, [
    callback,
    eventName,
    storeInstance?.instance?.apiKey,
    storeInstance?.instance?.events,
    storeInstance?.instance?.userId,
  ]);
}
