import { useStore } from '@nanostores/react';

import { useInternalInstance } from './useInstance';

export function useOptions() {
  const {
    connect: { stores },
  } = useInternalInstance();
  const { $options } = stores;
  const options = useStore($options);

  return options;
}
