import type { EngagespotChannels } from '@engagespot/core';
import { uniqBy } from '@engagespot/utils';
import { atom, computed, onNotify } from 'nanostores';
import type { StoreValue } from 'nanostores';

import { channelNameMapping } from './constants';
import { createPreferenceFetchBuilder } from './preferenceFetcher';
import type {
  CategoriesFetcherStore,
  PreferenceFetcherStore,
  PreferenceServiceArgs,
  SetPreferencesParams,
  SetPreferencesResponse,
  SetProfileAttributesParams,
  SetProfileAttributesResponse,
} from './types';
import type { InitServiceFn } from '../../utils/service';

/*
 * This is a temporary to fix the issue of mutate not being called after the first time
 */
const stopLoading = ($store: any) => {
  $store.set({ loading: false });
};

export function preferenceService({
  dependencies: { log, instance },
  requiredServices: {
    connectService: {
      stores: { $connectFetcher },
    },
  },
}: PreferenceServiceArgs) {
  type Preference = StoreValue<typeof $computedPreferences>;

  const { createFetcherStore, createMutatorStore } =
    createPreferenceFetchBuilder({
      instance,
      log,
    });

  const $preferenceFetcherStore = createFetcherStore<PreferenceFetcherStore>([
    'getPreferences',
  ]);

  const $categoriesFetcherStore = createFetcherStore<CategoriesFetcherStore>([
    'getCategories',
  ]);

  const $channels = computed($connectFetcher, connectFetcher => {
    const channels = connectFetcher?.data?.data?.app
      ?.channels as EngagespotChannels[];
    return channels;
  });

  const $preferences = atom<Preference>({ categories: [] });
  const $computedPreferences = computed(
    [$connectFetcher, $preferenceFetcherStore, $categoriesFetcherStore],
    (connectFetcher, preferenceFetcher, categoriesFetcherStore) => {
      const preferences = preferenceFetcher.data?.data;
      const app = connectFetcher?.data?.data?.app;
      const categories = categoriesFetcherStore?.data?.data;
      const channels = app?.channels as EngagespotChannels[];

      const preferenceCategories = categories?.map(category => {
        const savedPreference = preferences?.find(
          preference => preference.category?.id === category.id,
        );
        const channelsMapping = channels?.map(channel => {
          const channelPreference = savedPreference?.channelPreferences?.find(
            savedChannel => savedChannel.channel === channel,
          );

          const enabled = !channelPreference
            ? true
            : Boolean(channelPreference.enabled);

          return {
            id: channel,
            name: channelNameMapping[channel],
            enabled,
          };
        });

        return {
          ...category,
          channels: uniqBy(channelsMapping, 'id'),
        };
      });

      return { categories: preferenceCategories };
    },
  );

  onNotify($computedPreferences, () => {
    if ($computedPreferences.value) {
      $preferences.set($computedPreferences.value);
    }
  });

  const $setProfileAttributesMutator = createMutatorStore<
    SetProfileAttributesParams,
    SetProfileAttributesResponse
  >(async ({ data }) => {
    return instance.preferences.setProfileAttributes(data);
  });

  const $setPreferencesMutator = createMutatorStore<
    SetPreferencesParams,
    SetPreferencesResponse
  >(async ({ data }) => {
    return instance.preferences.setPreferences(data);
  });

  const setProfileAttributes = (data: SetProfileAttributesParams) => {
    stopLoading($setProfileAttributesMutator);
    $setProfileAttributesMutator.mutate(data);
  };

  const setPreferences = async (data: SetPreferencesParams) => {
    const currentPreferences = $preferences?.value;
    const updatedPreferences = currentPreferences?.categories?.map(
      preference => {
        const selectedPreference = data.find(
          item => item.categoryId === preference.id,
        );

        if (selectedPreference) {
          return {
            ...preference,
            channels: preference.channels.map(channel => {
              const selectedChannel = selectedPreference.channels?.find(
                item => item.channel === channel.id,
              );

              if (selectedChannel) {
                return {
                  ...channel,
                  enabled: Boolean(selectedChannel.enabled),
                };
              }

              return channel;
            }),
          };
        }

        return preference;
      },
    );

    $preferences.set({ categories: updatedPreferences });

    stopLoading($setPreferencesMutator);

    const { error } = await $setPreferencesMutator.mutate(data);

    if (error && currentPreferences) {
      $preferences.set(currentPreferences);
    }
  };

  const stores = {
    $preferenceFetcherStore,
    $computedPreferences,
    $preferences,
    $channels,
  };

  const actions = { setProfileAttributes, setPreferences };

  return { stores, actions };
}

preferenceService.key = 'preferenceService' as const;

export const initPreferenceService = <T extends InitServiceFn>(
  initService: T,
) => {
  const app = initService({
    key: 'preferenceService',
    requiredServiceKeys: ['connectService'],
  });
  return app;
};
