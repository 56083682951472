import { useDisclosure } from '@chakra-ui/hooks';
import useGetCategories from 'api/categories/get-categories';
import HeaderDoc from 'components/header/HeaderDoc';
import PageHeader from 'components/header/PageHeader';
import React from 'react';
import { useCategoryStore } from 'store/categoryStore';
import CategoryModal from './components/CategoryModal';
import CategoryTable from './components/CategoryTable';
import DeleteCategoryModal from './components/DeleteModal';

const Categories = () => {
  const [filter, setFilter] = React.useState('');
  const getCategories = useGetCategories();
  const addCategoryModalActions = useDisclosure();
  const { setCategory } = useCategoryStore(state => state);
  const deleteCategoryModalActions = useDisclosure();

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <CategoryModal
        isOpen={addCategoryModalActions.isOpen}
        onClose={addCategoryModalActions.onClose}
      />

      <DeleteCategoryModal
        isOpen={deleteCategoryModalActions.isOpen}
        onClose={deleteCategoryModalActions.onClose}
      />

      {/* Header */}
      <PageHeader
        heading={'Categories'}
        text={
          <>
            Categories are used to tag notifications.
            <HeaderDoc
              link="https://docs.engagespot.co/docs/features/category/what-are-categories"
              text="Learn more"
            />
          </>
        }
        buttonText="Create Category"
        filter={filter}
        setFilter={setFilter}
        paginationType="table"
        handleCreate={() => {
          setCategory(null);
          addCategoryModalActions.onOpen();
        }}
      />

      {/* Tables */}
      <CategoryTable
        loading={getCategories.isLoading}
        tableData={getCategories?.data ?? []}
        editOpen={addCategoryModalActions.onOpen}
        deleteOpen={deleteCategoryModalActions.onOpen}
        globalFilter={filter}
        setGlobalFilter={setFilter}
      />
    </div>
  );
};

export default Categories;
