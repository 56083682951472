import { useNavigate } from 'react-router-dom';

import { BaseItem, type BaseItemProps } from './BaseItem';
import { useCommandBar } from '../provider';

export type LinkItemProps = Omit<BaseItemProps, 'onSelect' | 'type'> & {
  path: string;
  externalLink?: boolean;
};

export function LinkItem({
  children,
  path,
  icon,
  externalLink,
  keywords,
  ...props
}: LinkItemProps) {
  const navigate = useNavigate();

  const navigateTo = (path: string, external?: boolean) => {
    if (external) {
      return window.open(path, '_blank').focus();
    }

    navigate(path);
  };

  const { close } = useCommandBar();

  return (
    <BaseItem
      {...props}
      keywords={keywords}
      icon={icon}
      type="Link"
      onSelect={() => {
        navigateTo(path, externalLink);
        close();
      }}
    >
      {children}
    </BaseItem>
  );
}
