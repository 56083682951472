import type {
  EngagespotChannels,
  EngagespotWebPushPermissionState,
} from '@engagespot/react-hooks';
import { usePreferences } from '@engagespot/react-hooks';
import React from 'react';
import type { ChangeEvent as ReactChangeEvent } from 'react';

import {
  NotificationCategories,
  NotificationPreferenceLabelStyled,
  NotificationPreferenceModalCloseButton,
  NotificationPreferenceModalContent,
  NotificationPreferenceModalHeader,
  NotificationPreferenceModalHeading,
  NotificationPreferenceModalNoButton,
  NotificationPreferenceModalPrimaryTextContent,
  NotificationPreferenceModalSecondaryTextContent,
  NotificationPreferenceModalStyled,
  NotificationPreferenceModalYesButton,
  NotificationPreferenceStyled,
  NotificationProviderHeading,
  NotificationProviderHeadingContainer,
  UserPreferenceContainer,
} from './NotificationPreference.styled';
import { Toggle } from '../toggle/Toggle';
import { useEngagespotContext } from '@/engagespotProvider';
import { useText } from '@/hooks/useText';
import { ChevronDown } from '@/icons/ChevronDown';
import { Close } from '@/icons/Close';
import themeConfig from '@/theme/themeConfig';

export type NotificationPreferenceProps = {
  empty?: boolean;
  placeholderText?: string;
  enableWebPush: () => void;
  webPushState: EngagespotWebPushPermissionState;
};

type UserPreference = NonNullable<
  ReturnType<typeof usePreferences>['preferences']['categories']
>[number];

const UserPrefernces = ({
  preference,
  webPushState,
  handleToggleChange,
}: {
  preference: UserPreference;
  webPushState: EngagespotWebPushPermissionState;
  handleToggleChange: (
    changedPreference: ChangedPreference,
    e: ReactChangeEvent<HTMLInputElement>,
  ) => void;
}) => {
  const engagespotContext = useEngagespotContext();
  const { category, setCategory } = engagespotContext;
  const { t } = useText();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <UserPreferenceContainer>
      <NotificationProviderHeadingContainer onClick={handleOpen}>
        <NotificationProviderHeading>
          {preference?.name}
        </NotificationProviderHeading>

        <ChevronDown
          style={{
            width: '16px',
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            fill: themeConfig.preference.itemToggleColor,
          }}
        />
      </NotificationProviderHeadingContainer>

      <NotificationCategories open={open}>
        {preference?.channels?.map(channel => (
          <NotificationPreferenceLabelStyled
            key={`${preference.id}-${channel.id}`}
          >
            <label htmlFor={`${preference.id}-${channel.id}`}>
              {t(`channels.${channel.id}`)}
            </label>
            <Toggle
              title={
                channel.id === 'webPush' && webPushState === 'denied'
                  ? 'Web Push is currently disabled on the browser. Enable it manually by going into the browser settings'
                  : ''
              }
              type="checkbox"
              id={`${preference.id}-${channel.id}`}
              checked={channel.enabled}
              disabled={
                channel.id === 'webPush' && ['denied'].includes(webPushState)
              }
              onChange={evt => {
                if (preference.id) {
                  if (
                    channel.id === 'inApp' &&
                    channel.enabled &&
                    category === preference.identifier
                  ) {
                    setCategory('');
                  }

                  handleToggleChange(
                    {
                      categoryId: preference?.id,
                      channel: channel.id,
                    },
                    evt,
                  );
                }
              }}
            />
          </NotificationPreferenceLabelStyled>
        ))}
      </NotificationCategories>
    </UserPreferenceContainer>
  );
};

export type ChangedPreference = {
  categoryId: number;
  channel: EngagespotChannels;
};

export function NotificationPreference({
  enableWebPush,
  webPushState,
}: NotificationPreferenceProps) {
  const { preferences, setPreferences } = usePreferences();

  const handleToggleChange = (
    changedPreference: ChangedPreference,
    e: ReactChangeEvent<HTMLInputElement>,
  ) => {
    const enabled = e.target.checked;
    if (changedPreference.channel === 'webPush' && webPushState !== 'granted') {
      enableWebPush();
    }
    setPreferences?.([
      {
        categoryId: changedPreference.categoryId,
        channels: [{ enabled, channel: changedPreference.channel }],
      },
    ]);
  };

  const userPreferences = preferences?.categories;

  return (
    <NotificationPreferenceStyled>
      {userPreferences?.map(preference => {
        return (
          <UserPrefernces
            key={preference.id}
            preference={preference}
            webPushState={webPushState}
            handleToggleChange={handleToggleChange}
          ></UserPrefernces>
        );
      })}
    </NotificationPreferenceStyled>
  );
}

export type NotificationPreferenceModalProps = {
  closeModal: () => void;
  allowNotifications: () => void;
};

export function NotificationPreferenceModal({
  closeModal,
  allowNotifications,
}: NotificationPreferenceModalProps) {
  const { t } = useText();

  return (
    <NotificationPreferenceModalStyled>
      <NotificationPreferenceModalContent>
        <NotificationPreferenceModalHeader>
          <NotificationPreferenceModalHeading>
            {t('preferenceModal.heading')}
          </NotificationPreferenceModalHeading>
          <NotificationPreferenceModalCloseButton onClick={closeModal}>
            <Close />
          </NotificationPreferenceModalCloseButton>
        </NotificationPreferenceModalHeader>
        <NotificationPreferenceModalPrimaryTextContent>
          {t('preferenceModal.text')}
        </NotificationPreferenceModalPrimaryTextContent>
        <NotificationPreferenceModalYesButton onClick={allowNotifications}>
          {t('preferenceModal.yesButtonText')}
        </NotificationPreferenceModalYesButton>
        <NotificationPreferenceModalNoButton onClick={closeModal}>
          {t('preferenceModal.noButtonText')}
        </NotificationPreferenceModalNoButton>
        <NotificationPreferenceModalSecondaryTextContent>
          {t('preferenceModal.secondaryText')}
        </NotificationPreferenceModalSecondaryTextContent>
      </NotificationPreferenceModalContent>
    </NotificationPreferenceModalStyled>
  );
}
