import {
  EngagespotCore,
  EngagespotCoreProps,
  EngagespotProvider,
} from '@engagespot/react-component';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getAcceptInvitation } from 'api/invitation/get-accept-invitation';
import { routes } from 'api/routes';
import { useUser } from 'components/user/UserProvider';
import { ENGAGESPOT_API_KEY } from 'config';
import { useAuthStore } from 'store/authStore';
import { getDataFromUrlString } from 'utils/functions';
import useCustomToast from 'utils/use-toast';
import { darkTheme } from './darkTheme';

export function EngagespotInAppProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { email } = useUser() ?? {};

  return (
    <>
      {email && (
        <EngagespotProvider apiKey={ENGAGESPOT_API_KEY} userId={email}>
          {children}
        </EngagespotProvider>
      )}
    </>
  );
}

export function useEngagespotComponentProps(): EngagespotCoreProps {
  const toast = useCustomToast();
  const [acceptInvitationLoading, setAcceptInvitationLoading] =
    React.useState(false);
  const queryClient = useQueryClient();
  const { setAppId, setClientId } = useAuthStore(state => state);

  return {
    hideNotificationAvatar: true,
    disableNotificationChime: false,
    renderEmptyPlaceholderImage() {
      return (
        <>
          <div className="relative !mt-9 !px-2">
            <p className="!mb-3 !text-center font-bold py-2 px-5 !text-black/70">
              You are all up to date
            </p>

            <p className="!text-center !text-[#807b81] px-8">
              New notification will appear here whenever there is activity
            </p>
          </div>
        </>
      );
    },
    theme: darkTheme,
    eventListenersToRun: [
      {
        blockId: 'existing_user_invite_b0_state_1_i0',
        event: 'onClick',
        onEvent: async ({ notification, changeNotificationState }: any) => {
          if (notification.data?.invitationUrl && !acceptInvitationLoading) {
            try {
              setAcceptInvitationLoading(true);

              const invitationCode = getDataFromUrlString(
                notification.data.invitationUrl,
                'invitationCode',
              );

              const workSpaceId = getDataFromUrlString(
                notification.data.invitationUrl,
                'workSpaceId',
              );

              await getAcceptInvitation(invitationCode);
              changeNotificationState({ state: 'state_2' });
              setAcceptInvitationLoading(false);

              // reset app and client
              await queryClient.invalidateQueries([routes['clients']]);
              setAppId(0);
              setClientId(Number(workSpaceId));
              toast.success('invitation accepted sucessfully');
            } catch (e) {
              setAcceptInvitationLoading(false);
              toast.showError(e);
            }
          }
        },
      },
      {
        blockId: 'welcome-message_b0_state_1_i0',
        event: 'onClick',
        onEvent: () => {
          window.open(
            'https://docs.engagespot.co/docs/introduction/quick-start/',
            '_blank',
          );
        },
      },
      {
        blockId: 'welcome-message_b0_state_1_i1',
        event: 'onClick',
        onEvent: () => {
          window.open(
            'https://calendly.com/anand-engagespot/engagespot-demo',
            '_blank',
          );
        },
      },
    ],
  };
}

export function EngagespotNavComponent() {
  const engagespotComponentProps = useEngagespotComponentProps();

  return <EngagespotCore {...engagespotComponentProps} />;
}
