import { Elements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect } from 'react';
import { isEnterpriseEdition } from 'utils/get-editions';
import useCustomToast from 'utils/use-toast';
import { StripePromise } from '../types';
import PaymentMethodStatusModal from './PaymentMethodStatusModal';

const PaymentCheck = () => {
  const stripe = useStripe();
  const toast = useCustomToast();
  const [isOpen, setIsOpen] = React.useState(false);
  const paymentStatusRef = React.useRef(null);

  useEffect(() => {
    const retrieveSetupIntent = () => {
      if (!stripe) {
        return;
      }

      // Retrieve the "setup_intent_client_secret" query parameter appended to
      // your return_url by Stripe.js
      const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret',
      );

      if (!clientSecret) return;

      // Retrieve the SetupIntent
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        if (setupIntent.status) {
          paymentStatusRef.current = setupIntent.status;
          setIsOpen(true);
        }
      });
    };

    const timer = setTimeout(() => {
      retrieveSetupIntent();
    }, 500);

    return () => clearTimeout(timer);
  }, [stripe, toast]);

  return (
    <>
      <PaymentMethodStatusModal
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        paymentStatus={paymentStatusRef.current}
      />
    </>
  );
};

const PaymentMethodStatus = ({ promise }: { promise: StripePromise }) => {
  const enterpriseEdition = isEnterpriseEdition();
  return (
    <>
      {enterpriseEdition && promise ? (
        <Elements stripe={promise} options={{}}>
          <PaymentCheck />
        </Elements>
      ) : null}
    </>
  );
};

export default PaymentMethodStatus;
