import * as React from 'react';

import { PopoverTrigger } from 'components/shadcn/popover';

import useGetClient from 'api/client/get-client';
import { SelectOptions } from 'components/fields/SelectField/types';
import { useAuthStore } from 'store/authStore';
import { useDisclosure } from '@chakra-ui/hooks';
import { createCommandbarActionEvent } from 'commandbar';
import AddWorkspaceModal from './AddWorkspaceModal';

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface WorkSpaceSwitcherProps extends PopoverTriggerProps {
  className?: string;
}

export const [createWorkspaceAction, useCreateWorkspace] =
  createCommandbarActionEvent('createWorkspace');
export const [switchWorkspaceAction, useSwitchWorkspaceAction] =
  createCommandbarActionEvent('switchWorkspace');

export type RegionSelectOption = SelectOptions & { region: string };

export type WorkspaceSwitcherProvider = {
  selectedWorkspace: RegionSelectOption | undefined;
  teams: RegionSelectOption[] | undefined;
  onSwitchWorkspace: (team: RegionSelectOption) => void;
  onCreateWorkspace: () => void;
};

const WorkspaceSwitcherContext =
  React.createContext<WorkspaceSwitcherProvider | null>(null);

export function useWorkspace() {
  return React.useContext(WorkspaceSwitcherContext);
}

export function WorkspaceProvider({ children }: WorkSpaceSwitcherProps) {
  const { setAppId, setClientId, clientId, setRegion } = useAuthStore(
    state => state,
  );
  const getClient = useGetClient();
  const [selectedWorkspace, setSelectedWorkspace] =
    React.useState<RegionSelectOption>();
  const addWorkspaceModalActions = useDisclosure();

  const teams = getClient?.data?.reduce((acc, curr) => {
    return [
      ...acc,
      {
        label: curr.client.name,
        value: String(curr.client.id),
        region: curr.client.region,
      },
    ];
  }, [] as RegionSelectOption[]);

  React.useEffect(() => {
    if (selectedWorkspace) return;

    const currentWorkspace = teams?.find(
      team => team.value === String(clientId),
    );

    if (currentWorkspace) {
      setSelectedWorkspace(currentWorkspace);
    }
  }, [clientId, teams, selectedWorkspace]);

  const handleSwitchWorkspaces = React.useCallback(
    (id: number, region?: string | null) => {
      /**
       * Reset the app id to 0, so that in layouts > admin > index.tsx, custom useEffect hooks will fireoff
       * and fetch all the apps data for the selected client
       */
      setAppId(0);
      setClientId(id);
      setRegion(region);
    },
    [setAppId, setClientId, setRegion],
  );

  const onCreateWorkspace = React.useCallback(() => {
    addWorkspaceModalActions.onOpen();
  }, [addWorkspaceModalActions]);

  useCreateWorkspace(onCreateWorkspace);

  const onSwitchWorkspace = React.useCallback(
    (team: RegionSelectOption) => {
      const selectedClient = teams?.find(
        item => Number(item.value) === Number(team.value),
      );

      setSelectedWorkspace(team);
      handleSwitchWorkspaces(
        Number(team.value),
        team?.region || selectedClient?.region,
      );
    },
    [teams, handleSwitchWorkspaces, setSelectedWorkspace],
  );

  useSwitchWorkspaceAction(onSwitchWorkspace);

  const value = React.useMemo(
    () => ({
      selectedWorkspace,
      teams,
      onSwitchWorkspace,
      onCreateWorkspace,
    }),
    [teams, selectedWorkspace, onSwitchWorkspace, onCreateWorkspace],
  );

  return (
    <WorkspaceSwitcherContext.Provider value={value}>
      <AddWorkspaceModal
        isOpen={addWorkspaceModalActions.isOpen}
        onClose={addWorkspaceModalActions.onClose}
        setSelectedWorkSpace={setSelectedWorkspace}
        handleSwitchWorkSpaces={handleSwitchWorkspaces}
      />
      {children}
    </WorkspaceSwitcherContext.Provider>
  );
}
