import { ActionItem } from 'commandbar/itemTypes';
import { WorkspaceItem } from 'components/sidebar/components/WorkspaceItem';
import {
  switchWorkspaceAction,
  useWorkspace,
} from 'components/sidebar/components/WorkspaceProvider';
import { useIsEditorPage } from 'utils/use-is-editor-page';

export function SwitchWorkspace() {
  const { selectedWorkspace, teams } = useWorkspace();
  const isEditorPage = useIsEditorPage();

  return (
    <>
      {teams.map(team => {
        return (
          <ActionItem
            key={team.value}
            action={switchWorkspaceAction}
            args={[team]}
            path={isEditorPage ? '/admin/api-credentials' : ''}
          >
            <WorkspaceItem
              team={team}
              active={selectedWorkspace?.value === team.value}
            />
          </ActionItem>
        );
      })}
    </>
  );
}
