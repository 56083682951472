import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { FormAddCategories } from 'views/admin/dashboards/categories/types';

type PutAddApps = FormAddCategories & {
  appId: number;
};

const post = async (values: PutAddApps) => {
  const { appId, ...data } = values;

  return axiosInstance.post(`${routes['apps']}/${appId}/categories`, data);
};

const usePostCategories = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: post,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/categories`]);
    },
  });
};

export default usePostCategories;
