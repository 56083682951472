import { useEffect, useState } from 'react';

import { useEngagespotContext } from '@/engagespotProvider';

export const usePanelOpenByDefault = ({
  setVisible,
}: {
  setVisible: (isOpen: boolean) => void;
}) => {
  const { panelOpenByDefault } = useEngagespotContext();
  const [hasEffectRun, setHasEffectRun] = useState(false);

  useEffect(() => {
    if (panelOpenByDefault && !hasEffectRun) {
      setVisible(true);
      setHasEffectRun(true);
    }
  }, [hasEffectRun, panelOpenByDefault, setVisible]);
};
