import { useDisclosure } from '@chakra-ui/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import usePostResendVerificationEmail from 'api/resend-verification-email/post-resend-verification-email';
import usePostSignInEmail from 'api/signin/post-sigin-email';
import usePostSignInGithub from 'api/signin/post-sigin-github';
import EngageSpotSquare from '@assets/img/others/engagespot-square.png';
import {
  AuthAgreeToTerms,
  AuthGithubButton,
  AuthHeading,
  AuthORBlock,
  AuthSubmitButton,
  AuthTextWithLink,
} from 'components/auth/AuthComponents';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { isEnterpriseEdition } from 'utils/get-editions';
import useCustomToast from 'utils/use-toast';
import VerificationModal from './modal';
import { schema } from './schema';
import { IFormSignIn, ISignInGithub } from './types';
import LoaderModal from 'components/loader/LoaderModal';

function SignInDefault() {
  const loaderModalActions = useDisclosure();
  const postResendVerificationEmail = usePostResendVerificationEmail();
  const postSignInEmail = usePostSignInEmail<IFormSignIn>();
  const postSignInGithub = usePostSignInGithub<ISignInGithub>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toast = useCustomToast();
  const { email, setEmail, setPassword } = useAuthStore(state => state);

  const [show, setShow] = React.useState(false);
  const togglePasswordView = () => setShow(!show);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const githubCode = new URLSearchParams(searchParams).get('code');
  const redirect = new URLSearchParams(searchParams).get('redirect');
  const enterpriseEdition = isEnterpriseEdition();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormSignIn>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<IFormSignIn> = values => {
    postSignInEmail.mutate(values, {
      onSuccess: response => {
        if (response.data.furtherAuthRequired) {
          setEmail(values.email);
          setPassword(values.password);
          return navigate(
            `/auth/two-factor${redirect ? `?redirect=${redirect}` : ``}`,
          );
        }

        if (Boolean(redirect) && redirect !== 'null') {
          return navigate(redirect);
        } else {
          return navigate('/admin/api-credentials');
        }
      },
      onError: (error: any) => {
        const errStatus = error.response.status;
        if (errStatus === 403) {
          onOpen();
          setEmail(values.email);
          toast.showError(error.response.data);
        }
      },
    });
  };

  const resendVerificationEmail = () => {
    const data = {
      email,
    };

    postResendVerificationEmail.mutate(data, {
      onSuccess: () => {
        toast.success('verification email resend');
        onClose();
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  // Github Login
  React.useEffect(() => {
    const githubLogin = async () => {
      const data = {
        code: githubCode,
      };

      postSignInGithub.mutate(data, {
        onSuccess: () => {
          navigate('/admin/api-credentials');
        },
        onError: error => {
          toast.showError(error, 'error_description');
          navigate('/auth/login');
        },
      });
    };

    if (githubCode && !postSignInGithub.isLoading && enterpriseEdition) {
      githubLogin();
    }
  }, [enterpriseEdition, githubCode, navigate, postSignInGithub, toast]);

  return (
    <Default
      maincard={
        <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2  md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          <VerificationModal
            isLoading={postResendVerificationEmail.isLoading}
            isOpen={isOpen}
            onClose={onClose}
            resendVerificationEmail={resendVerificationEmail}
          />

          <LoaderModal
            isOpen={postSignInGithub.isLoading}
            onClose={loaderModalActions.onClose}
            type="animation"
          />

          {/* Sign in section */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-[10vh] min-h-[605px] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
          >
            <img className="relative bottom-14" src={EngageSpotSquare} alt="" />

            <AuthHeading text="Welcome back!" />

            <AuthTextWithLink
              text="First time here?"
              linkText="Sign up for free"
              linkTo={
                redirect
                  ? `/auth/register?redirect=${redirect}`
                  : '/auth/register'
              }
              parentClass="mb-7 mt-2"
            />

            {/* Email */}
            <div className="mb-7">
              <InputField<IFormSignIn>
                variant="dark"
                extra=""
                label="Email"
                placeholder="Your email"
                id="email"
                type="text"
                showIsRequiredAsterisk={true}
                register={register}
                name={'email'}
              />
              {errors.email && <ErrorBox error={errors.email} />}
            </div>

            {/* Password */}
            <div className="relative mb-7">
              <div className="relative">
                <InputField<IFormSignIn>
                  variant="dark"
                  extra=""
                  label="Password"
                  placeholder="******"
                  id="password"
                  type={show ? 'text' : 'password'}
                  showIsRequiredAsterisk={true}
                  register={register}
                  name={'password'}
                />

                <button
                  type="button"
                  onClick={togglePasswordView}
                  className="absolute right-4 top-[55%] text-white"
                >
                  {show ? (
                    <RiEyeCloseLine size={22} />
                  ) : (
                    <MdOutlineRemoveRedEye size={22} />
                  )}
                </button>
              </div>

              <div className="absolute right-0 top-0 flex items-center justify-between px-2">
                <Link
                  className="mt-2 text-sm font-medium text-yellow-450 hover:text-yellow-600"
                  to={
                    redirect
                      ? `/auth/forgot-password?redirect=${redirect}`
                      : '/auth/forgot-password'
                  }
                >
                  Forgot Password?
                </Link>
              </div>

              {errors.password && <ErrorBox error={errors.password} />}
            </div>

            {(postSignInEmail.error as any)?.response?.status !== 403 &&
              postSignInEmail.error && (
                <ErrorBox error={postSignInEmail.error} />
              )}

            <AuthSubmitButton
              isLoading={postSignInEmail.isLoading}
              disabled={postSignInEmail.isLoading}
              text="Sign In"
            />

            {enterpriseEdition && (
              <>
                <AuthORBlock />
                <AuthGithubButton />
              </>
            )}

            <AuthAgreeToTerms text="By signing in ,you agree to our" />
          </form>
        </div>
      }
    />
  );
}

export default SignInDefault;
