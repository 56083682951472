import { AnimatePresence, motion } from 'framer-motion';

export const LoaderAnimation = () => (
  <div className="w-[150px] h-[100px] flex justify-center items-center overflow-hidden rounded-[30px]">
    <AnimatePresence>
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 59"
        className="w-[56%] overflow-visible"
      >
        {/* First Path Animation */}
        <motion.path
          d="M20.893 58.9824L-2.49742 44.5118L-3.18762 13.4262L19.436 -3.18848L42.8265 11.2824L43.5165 42.368L20.893 58.9824Z"
          fill="#BE185D"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 1 }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'loop',
          }}
        />
        {/* Second Path Animation */}
        <motion.path
          d="M45.7169 60.3338L22.4031 45.9525L21.6362 14.8669L44.3366 -1.7478L67.7269 12.723L68.4173 43.7194L45.7169 60.3338Z"
          fill="#BE185D"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 1 }}
          transition={{
            duration: 1,
            delay: 0.25,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'loop',
          }}
        />
        {/* Third Path Animation */}
        <motion.path
          d="M32.9724 69.4518L9.65857 55.0705L8.89172 23.9848L31.5919 7.37012L49.1743 20.1513V52.6021L32.9724 69.4518Z"
          fill="#BE185D"
          initial={{ pathLength: 0, opacity: 0 }}
          animate={{ pathLength: 1, opacity: 1 }}
          transition={{
            duration: 1,
            delay: 0.5,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'loop',
          }}
        />
      </motion.svg>
    </AnimatePresence>
  </div>
);
