import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { CommandBar } from '../components';
import {
  PageRegistry,
  type PageDefinition,
  type Page,
  defaultPage,
} from 'commandbar/pages';

export type CommandBarProviderProps = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: () => void;
  close: () => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  clearInput: () => void;
  pages: Page[];
  activePage: PageDefinition;
  setPages: React.Dispatch<React.SetStateAction<Page[]>>;
  setPage: (page: Page) => void;
  popPage: () => void;
};

const CommandBarContext = createContext<CommandBarProviderProps>(null);

export const useCommandBar = () => {
  return useContext(CommandBarContext);
};

export function CommandBarProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const clearInput = useCallback(() => {
    setInputValue('');
  }, [setInputValue]);

  const [pages, setPages] = useState<Page[]>([defaultPage.name]);

  const popPage = useCallback(() => {
    setPages(pages => {
      const x = [...pages];
      x.splice(-1, 1);
      return x;
    });
  }, []);

  const setPage = useCallback(
    (page: Page) => {
      setPages([...pages, page]);
      setInputValue('');
    },
    [pages, setPages],
  );

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const open = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const activePage = useMemo(() => {
    const currentPage = pages[pages.length - 1];
    const pageDefinition = PageRegistry.find(page => page.name === currentPage);
    return pageDefinition;
  }, [pages]);

  return (
    <CommandBarContext.Provider
      value={{
        isOpen,
        setOpen,
        close,
        open,
        pages,
        popPage,
        setPages,
        setPage,
        activePage,
        inputValue,
        setInputValue,
        clearInput,
      }}
    >
      <CommandBar />
      {children}
    </CommandBarContext.Provider>
  );
}
