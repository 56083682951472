import { type Notification } from '../services/notificationService';
import { createEvent } from '../utils/createEvent';

type EventCallback<T> = (params: T) => void;
export type Events = ReturnType<typeof initializeEvents>;

export type EventCallbackMap = {
  notificationReceive: EventCallback<NotificationReceiveEvent>;
  notificationDelete: EventCallback<NotificationDeleteEvent>;
  notificationRead: EventCallback<NotificationClickEvent>;
  notificationSeen: EventCallback<NotificationSeenEvent>;
  notificationReadAll: EventCallback<NotificationReadAll>;
  notificationDeleteAll: EventCallback<NotificationDeleteAll>;
  notificationStateChange: EventCallback<NotificationStateChange>;
};

type NotificationReceiveEvent = { notification: Notification };
type NotificationDeleteEvent = { id: string };
type NotificationClickEvent = { id: string };
type NotificationSeenEvent = { unseenCount: number };
type NotificationReadAll = void;
type NotificationDeleteAll = void;
type NotificationStateChange = {
  notification: Notification;
};
export type PermissionState = 'denied' | 'granted' | 'default' | 'unsupported';
type WebPushPermissionChange = { permission: PermissionState };

export function initializeEvents() {
  const events = {
    notificationReceive: createEvent<EventCallback<NotificationReceiveEvent>>(),
    notificationDelete: createEvent<EventCallback<NotificationDeleteEvent>>(),
    notificationRead: createEvent<EventCallback<NotificationClickEvent>>(),
    notificationSeen: createEvent<EventCallback<NotificationSeenEvent>>(),
    notificationReadAll: createEvent<EventCallback<NotificationReadAll>>(),
    notificationDeleteAll: createEvent<EventCallback<NotificationDeleteAll>>(),
    notificationStateChange:
      createEvent<EventCallback<NotificationStateChange>>(),
    webPushPermissionChange:
      createEvent<EventCallback<WebPushPermissionChange>>(),
  };
  return events;
}
