import { useQueryClient } from '@tanstack/react-query';
import useStopSubscriptionCancellation from 'api/billing/delete-stop-plan-cancellation';
import { routes } from 'api/routes';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { Button } from 'components/shadcn/button';
import { Dialog, DialogContent } from 'components/shadcn/dialog';
import { CheckCircle2, X } from 'lucide-react';
import { cn } from 'utils/class-merge';
import useCustomToast from 'utils/use-toast';
import { useGetCurrentBillingPlanDetails } from '../hooks';

function StopCancelationModal({
  isOpen,
  onOpenChange,
}: {
  onOpenChange: (open: boolean) => void;
  isOpen: boolean;
}) {
  const stopCancelSubscription = useStopSubscriptionCancellation();
  const { subscription } = useGetCurrentBillingPlanDetails();
  const queryClient = useQueryClient();
  const toast = useCustomToast();

  const handleClose = () => {
    onOpenChange(false);
  };

  const handleResubscribe = () => {
    const data = {
      subscription_id: subscription?.id,
    };

    stopCancelSubscription.mutate(data, {
      onSuccess: () => {
        toast.success('resubscription successfull');

        // It takes some time to update the subscription details
        setTimeout(() => {
          queryClient.invalidateQueries([
            `${routes['v1_client']}/subscription`,
          ]);
        }, 1000);

        handleClose();
      },
      onError: err => {
        toast.showError(err);
      },
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        closeButtonClassName="text-white"
        className="sm:max-w-[500px] p-0"
      >
        <div className="relative flex flex-col items-center p-6 pt-12 sm:p-10">
          <button
            onClick={handleClose}
            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>

          <div
            className={cn(
              'rounded-full p-3 mb-6',
              'bg-green-100 text-green-600',
            )}
          >
            <CheckCircle2 className="h-12 w-12 sm:h-16 sm:w-16" />
          </div>

          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-4 text-white">
            We're happy to see you stay!
          </h2>

          <p className="text-muted-foreground text-center mb-6 max-w-[400px]">
            Resubscribe to enjoy engagespot features.
          </p>

          <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto text-white">
            <Button
              variant="outline"
              className="sm:w-[200px]"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              className="sm:w-[200px]"
              onClick={handleResubscribe}
              disabled={stopCancelSubscription.isLoading}
            >
              Resubscribe
              {stopCancelSubscription.isLoading && (
                <LoaderSpinner
                  strokeColor={'#23BF6E'}
                  parentClass="relative left-3"
                  width="18"
                />
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default StopCancelationModal;
