export function Bell() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      role="presentation"
    >
      <g transform="translate(4.615 2.514)">
        <path
          d="M7.382,0C2.947,0,1.021,4.015,1.021,6.67c0,1.984.288,1.4-.81,3.82-1.341,3.449,4.051,4.858,7.171,4.858s8.511-1.41,7.171-4.858c-1.1-2.42-.81-1.836-.81-3.82C13.743,4.015,11.815,0,7.382,0Z"
          transform="translate(0 0)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5px"
          fill="transparent"
        ></path>
        <path
          d="M4.62,0A2.992,2.992,0,0,1,0,0"
          transform="translate(5.071 17.998)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.5px"
          fill="transparent"
        ></path>
      </g>
    </svg>
  );
}
