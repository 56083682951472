import { createInstance, type Instance } from '@engagespot/core';
import type { Logger } from '@engagespot/utils';

import type { StoreOptions } from '../createStore';

export type Dependencies = {
  log: Logger;
  options: StoreOptions;
  instance: Instance;
};

export function createDependencies(
  options: StoreOptions,
  log: Logger,
): Dependencies {
  const instance = createInstance({ ...options });

  const deps = {
    log,
    options,
    instance,
  };
  return deps;
}
