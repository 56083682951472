import styled, { css } from 'styled-components';

export const ErrorHeaderContainer = styled.div`
  && {
    ${() => css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    `}

    svg {
      height: 20px;
      width: 20px;
      stroke: red;
    }
  }
`;

export const ErrorHeader = styled.p`
  && {
    ${() => css`
      color: #34353b;
      font-size: 0.85rem;
      font-weight: bold;
    `}
  }
`;

export const ErrorBox = styled.p`
  && {
    ${() => css`
      font-size: 0.75rem;
      border: 0.0625rem solid #f0cec3;
      border-radius: 0.25rem;
      padding: 0.3125rem;
      background: #fbefeb;
      color: #736d6b;
      width: 100%;
    `}
  }
`;

export const InfoMessage = styled.p`
  && {
    ${() => css`
      font-size: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.2rem;
    `}

    svg {
      height: 12px;
      width: 12px;
    }
  }
`;
