import {
  addDays,
  differenceInDays,
  endOfDay,
  format,
  isAfter,
  max,
  min,
  startOfDay,
  subDays,
} from 'date-fns';
import { CalendarIcon } from 'lucide-react';

import { Button } from 'components/shadcn/button';
import { Calendar } from 'components/shadcn/calendar';
import { FormItem, FormLabel } from 'components/shadcn/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';
import { DateRange } from 'react-day-picker';
import { LogFilterFormValues } from './index';
import { UseFormReturn } from 'react-hook-form';

const MIN_RANGE_DAYS = 2;
const MAX_RANGE_DAYS = 7;
const today = endOfDay(new Date());
const sevenDaysAgo = startOfDay(subDays(today, MAX_RANGE_DAYS - 1));
export const defaultDateRange = { from: sevenDaysAgo, to: today };

export const DateRangeFilter = ({
  form,
}: {
  form: UseFormReturn<LogFilterFormValues>;
}) => {
  const dateRange = form.watch('dateRange');

  const adjustRangeToFitConstraints = (
    from: Date,
    to: Date,
    today: Date,
  ): DateRange => {
    const daysDifference = differenceInDays(to, from) + 1;

    if (daysDifference < MIN_RANGE_DAYS) {
      to = endOfDay(addDays(from, MIN_RANGE_DAYS - 1));
    } else if (daysDifference > MAX_RANGE_DAYS) {
      if (isAfter(to, dateRange?.to || today)) {
        from = startOfDay(subDays(to, MAX_RANGE_DAYS - 1));
      } else {
        to = endOfDay(addDays(from, MAX_RANGE_DAYS - 1));
      }
    }

    to = min([to, today]);
    from = max([from, subDays(to, MAX_RANGE_DAYS - 1)]);

    return { from, to };
  };

  const handleSingleDateSelection = (
    selectedDate: Date,
    today: Date,
  ): DateRange => {
    const from = startOfDay(selectedDate);
    const to = min([endOfDay(addDays(from, MAX_RANGE_DAYS - 1)), today]);
    return { from, to };
  };

  const handleDateRangeChange = (newRange: DateRange | undefined) => {
    if (!newRange?.from && !newRange?.to) return;

    const today = endOfDay(new Date());
    let { from, to } = newRange;

    if (from && !to) {
      ({ from, to } = handleSingleDateSelection(from, today));
    } else if (!from && to) {
      from = startOfDay(
        max([
          subDays(to, MAX_RANGE_DAYS - 1),
          subDays(today, MAX_RANGE_DAYS - 1),
        ]),
      );
    }

    const adjustedRange = adjustRangeToFitConstraints(
      startOfDay(from!),
      endOfDay(to!),
      today,
    );
    form.setValue('dateRange', adjustedRange);
  };

  return (
    <div className="flex-1 min-w-[200px]">
      <FormItem>
        <FormLabel>Date Range</FormLabel>

        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="w-full justify-start text-left font-normal"
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {dateRange?.from && dateRange?.to ? (
                <>
                  {format(dateRange.from, 'LLL dd, y')} -{' '}
                  {format(dateRange.to, 'LLL dd, y')}
                </>
              ) : (
                <span>Pick a date range</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={dateRange?.from}
              selected={dateRange as unknown as DateRange}
              onSelect={handleDateRangeChange}
              numberOfMonths={2}
              disabled={date => isAfter(date, new Date())}
            />
          </PopoverContent>
        </Popover>
      </FormItem>
    </div>
  );
};
