type LogParams = Parameters<typeof console.log>;
type LogTypes = 'log' | 'warn' | 'error';
type ConsoleLogType = typeof console.log;

const createLogMessage = (logType: LogTypes) => {
  const logMessage: ConsoleLogType = message => {
    const [prefix, ...params] = message;

    // Using console.log for logging
    // eslint-disable-next-line no-console
    console[logType](prefix, ...params);
  };
  return logMessage;
};

const logInfo = createLogMessage('log');
const logWarn = createLogMessage('warn');
const logError = createLogMessage('error');

/**
 * Wrapper for this._log which considers this.debug value
 * @param message
 */
const logObject: ConsoleLogType = message => {
  logInfo(...message);
};

export const log = Object.assign(logObject, {
  error: logError,
  info: logInfo,
  warn: logWarn,
});

export type Logger = ReturnType<typeof createLogger>;

type LoggerOptions = {
  debug?: boolean;
  prefix?: string;
  debugPrefix?: string;
};

const defaultLoggerOptions = {
  prefix: '[Engagespot] ',
  debug: false,
  debugPrefix: '',
};
/**
 * Creates a logger function that logs only if debug is true.
 * To log always, use the logger.errorAlways, logger.warnAlways, logger.infoAlways
 * @param debug
 * @returns logger function
 */
export const createLogger = ({
  prefix = defaultLoggerOptions.prefix,
  debug = defaultLoggerOptions.debug,
  debugPrefix = defaultLoggerOptions.debugPrefix,
}: LoggerOptions = defaultLoggerOptions) => {
  debugPrefix = debugPrefix ? `${debugPrefix} ${prefix}` : prefix;

  const loggerObject = (...params: LogParams) => {
    if (debug) {
      log([debugPrefix, ...params]);
    }
  };
  const logger = Object.assign(loggerObject, {
    error: (...params: LogParams) => {
      if (debug) {
        logError([debugPrefix, ...params]);
      }
    },
    warn: (...params: LogParams) => {
      if (debug) {
        logWarn([debugPrefix, ...params]);
      }
    },
    info: (...params: LogParams) => {
      if (debug) {
        logInfo([debugPrefix, ...params]);
      }
    },
    errorAlways: (...params: LogParams) => logError([prefix, ...params]),
    warnAlways: (...params: LogParams) => logWarn([prefix, ...params]),
    infoAlways: (...params: LogParams) => logInfo([prefix, ...params]),
  });

  return logger;
};
