import { Search } from 'lucide-react';
import { cn } from 'utils/class-merge';

import { Key } from './Key';
import { useCommandBar } from 'commandbar';

export function SearchBar() {
  const { open } = useCommandBar();

  return (
    <div
      className="flex items-center w-48 md:w-72 lg:96 rounded-md border border-gray-750 border-input dark:bg-background px-3 py-1.5"
      onClick={open}
    >
      <Search className={cn('h-5 w-5 text-gray-750')} />

      <input
        placeholder="Search"
        className="flex-grow dark:bg-background text-sm text-gray-750 placeholder:text-muted-foreground focus:outline-none px-3"
      />

      <div className="ml-2 flex items-center gap-1 text-xs text-gray-750 ">
        <Key name="⌘" />
        <Key name="K" />
      </div>
    </div>
  );
}
