import { useUnseenCount } from '@engagespot/react-hooks';
import type { ReactNode } from 'react';

import type { ButtonTypes } from './NotificationButton.styled';
import { NotificationButtonStyled } from './NotificationButton.styled';
import { UnreadBadgeCount } from '@/components/unreadBadgeCount';
import type { usePopover } from '@/hooks/usePopover';
import { Bell as NotificationBellIcon } from '@/icons/Bell';
import { renderCustom } from '@/utils/renderCustom';

export type customNotificationIcon = (() => ReactNode) | undefined;
export type usePopOverReturnType = ReturnType<typeof usePopover>;
export type NotificationButtonProps = {
  type?: ButtonTypes;
  buttonProps: usePopOverReturnType['referenceProps'];
  panelOpen: boolean;
  renderNotificationIcon?: customNotificationIcon;
};

export function NotificationButton({
  type = 'normal',
  buttonProps,
  panelOpen,
  renderNotificationIcon,
}: NotificationButtonProps) {
  const unseenCount = useUnseenCount();
  const { setRef, getReferenceProps } = buttonProps;

  return (
    <NotificationButtonStyled
      buttonType={type}
      aria-label="Notifications"
      ref={setRef}
      {...getReferenceProps()}
    >
      {unseenCount && !panelOpen ? (
        <UnreadBadgeCount count={unseenCount} />
      ) : null}
      {renderNotificationIcon ? (
        renderCustom(renderNotificationIcon)
      ) : (
        <NotificationBellIcon />
      )}
    </NotificationButtonStyled>
  );
}
