import * as Items from 'commandbar/items';
import * as Groups from 'commandbar/groups';

export function Main() {
  return (
    <>
      <Items.QuickStart />
      <Items.APICredentials />
      <Groups.Notification />
      <Items.Channels />
      <Items.Logs />
      <Items.VersionControl />
      <Groups.App />
      <Groups.Workspace />
      <Groups.WorkspaceSettings />
      <Items.Docs />
      <Items.GetSupport />
      <Items.Profile />
    </>
  );
}
