import {
  createApiClient,
  createLoggerMiddleWare,
} from '@engagespot/api-client';
import type { Logger } from '@engagespot/utils';

type ApiInstanceOptions = {
  apiKey: string;
  userId: string;
  baseUrl: string;
  userSignature?: string;
  userToken?: string;
  deviceId: string;
  additionalHeaders?: Record<string, string>;
};

type ApiClientCustomOptions = {
  log: Logger;
};

/**
 *
 * @param options {ApiInstanceOptions}
 * @returns apiClient
 */
export function initializeApiClient(
  options: ApiInstanceOptions,
  { log }: ApiClientCustomOptions,
) {
  const apiClient = createApiClient(options);

  // Set middleware for logging
  const loggerMiddleware = createLoggerMiddleWare({ logger: log });
  apiClient.use(loggerMiddleware);
  return apiClient;
}
