import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/shadcn/hover-card';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { PaginationState } from '@tanstack/react-table';
import { TemplateNotificationAnalytics } from 'api/analytics/get-template-notification-analytics/types';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import { HiOutlineEye } from 'react-icons/hi';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { eventChipBg, eventChipColor } from '../../logs/variables';

function AnalyticsLogsTable(props: {
  tableData: any;
  totalPages: number;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  loading: boolean;
}) {
  const { tableData, pagination, setPagination, totalPages, loading } = props;
  const navigate = useNavigate();

  const columnConfig: ColumnConfig<
    TemplateNotificationAnalytics['results'][0]
  > = [
    {
      accessorKey: 'requestId',
      header: 'RequestId',
      cellFunction: info => info.getValue() || '',
    },
    {
      accessorKey: 'identifier',
      header: 'Recipient',
      cellFunction: info =>
        info.getValue() && (
          <button
            onClick={e => {
              e.stopPropagation();
              navigate(`/admin/users?search=${info.getValue()}`);
            }}
            className="group flex items-center justify-between gap-3 rounded-md px-4   text-base text-navy-700 hover:bg-night-100 dark:text-white"
          >
            {info.getValue()}
            <div className="opacity-0 group-hover:opacity-100">
              <HiOutlineEye size={20} />
            </div>
          </button>
        ),
    },
    {
      accessorKey: 'workflowIdentifier',
      header: 'Workflow',

      cellFunction: info => info.getValue() || '',
    },
    {
      accessorKey: 'channel',
      header: 'Channel',
      cellFunction: info => info.getValue() || '',
    },
    {
      accessorKey: 'logCreatedAt',
      header: 'Timestamp',
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMM yyyy, h:mm aaa');
        return <p className=" text-sm text-navy-700 dark:text-white">{date}</p>;
      },
    },
    {
      accessorKey: 'event',
      header: 'Event',
      cellFunction: info => {
        const event = info.row.original.event;
        const reason = info.row.original.reason;
        return (
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex gap-2">
                <div
                  style={{
                    background: eventChipBg[event],
                    color: eventChipColor[event],
                  }}
                  className="relative  flex gap-1 select-none items-center whitespace-nowrap rounded-lg px-[10px] py-[5px] text-sm font-medium capitalize"
                >
                  {info.getValue().replace('_', ' ')}
                  {reason && <IoMdInformationCircleOutline />}
                </div>
              </div>
            </HoverCardTrigger>
            {reason && (
              <HoverCardContent>
                <h4 className="mb-1">Reason</h4>
                <p className="text-sm">{reason}</p>
              </HoverCardContent>
            )}
          </HoverCard>
        );
      },
    },
  ];

  const columns = columnGenerator<TemplateNotificationAnalytics['results'][0]>({
    columnConfig,
  });

  return (
    <>
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        {/* table */}
        <CustomTable
          loading={loading}
          columns={columns}
          emptyTableMessageClassName="text-lg"
          emptyTableMessage="No worries! Send a notification"
          tableData={tableData}
          paginationType="api"
          pagination={pagination}
          setPagination={setPagination}
          totalPages={totalPages}
        />
      </div>
    </>
  );
}

export default AnalyticsLogsTable;
