import React, { useRef } from 'react';

import { TextAreaStyled, type TextAreaStyledProps } from './TextArea.styled';

export const TextArea: React.FC<TextAreaStyledProps> = ({
  autoGrow = true,
  onChange,
  ...props
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textAreaRef.current;

    if (autoGrow && textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }

    onChange?.(e);
  };

  return (
    <TextAreaStyled
      ref={textAreaRef}
      autoGrow={autoGrow}
      onChange={handleChange}
      {...props}
    />
  );
};
