import { useDisclosure } from '@chakra-ui/hooks';
import { Plus } from 'lucide-react';
import StripePaymentModal from 'views/admin/dashboards/billing/components/StripePaymentModal';
import { StripePromise } from 'views/admin/dashboards/billing/types';
import { Card, CardContent } from 'components/shadcn/card';
import React from 'react';
import { useMutateGetSetupIntent } from 'api/billing/get-setup-intent';
import useGetLicense from 'api/billing/get-license';
import { useAuthStore } from 'store/authStore';
import { cn } from 'utils/class-merge';

function NewCard({ promise }: { promise: StripePromise }) {
  const paymentModalActions = useDisclosure();
  const mutateGetSetupIntent = useMutateGetSetupIntent();
  const { clientId: clientUserId } = useAuthStore(state => state);
  const license = useGetLicense(clientUserId);
  const [loading, setLoading] = React.useState(false);
  const paymentIntentRef = React.useRef();

  const handleGetSetupIntent = () => {
    if (loading) return;

    setLoading(true);

    const data = {
      clientId: license.data?.InstanceClientIdentifer,
    };

    mutateGetSetupIntent.mutate(data, {
      onSuccess: response => {
        setLoading(false);
        paymentIntentRef.current = response;
        paymentModalActions.onOpen();
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  return (
    <>
      <StripePaymentModal
        isOpen={paymentModalActions.isOpen}
        onClose={paymentModalActions.onClose}
        paymentDetails={{
          mode: 'setup',
          paymentIntent: paymentIntentRef.current,
          newSubscription: null,
        }}
        promise={promise}
      />

      <Card
        className={cn(
          'flex h-full cursor-pointer items-center justify-center transition-colors hover:bg-accent',
          loading && 'csm-pulse',
        )}
        role="button"
        onClick={handleGetSetupIntent}
      >
        <CardContent className="p-6 py-14">
          <div className="flex flex-col items-center space-y-2">
            <div className="rounded-full bg-primary/10 p-4">
              <Plus className="h-6 w-6 text-primary" />
            </div>
            <p className="text-sm font-medium">Add new payment method</p>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default NewCard;
