import useGetClient from 'api/client/get-client';
import { useAuthStore } from 'store/authStore';

export const useGetSelectedWorkspace = () => {
  const { clientId } = useAuthStore(state => state);
  const getClient = useGetClient();
  const selectedWorkspace = getClient.data?.find(
    data => data.client.id === clientId,
  );

  return selectedWorkspace;
};

export const useGetOwnerWorkspace = () => {
  const getClient = useGetClient();
  const ownerWorkspace = getClient.data?.find(data => data.role === 'owner');

  return { data: ownerWorkspace, isLoading: getClient.isLoading };
};
