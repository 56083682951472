import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { routes } from 'api/routes';
import { PutChangePlanResponse } from '../put-change-plan/types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<
  PutChangePlanResponse['paymentIntent']
> => {
  const [, clientId] = queryKey;

  const { data } = await axiosLicensingInstance.get(
    `${routes['client']}/${clientId}/setupIntent`,
  );

  return data;
};

const useGetSetupIntent = (clientId: string, enabled: boolean = false) => {
  return useQuery({
    queryKey: [`${routes['client']}/setupIntent`, clientId],
    queryFn: get,
    enabled: Boolean(clientId) && Boolean(enabled),
  });
};

const getSetupIntent = async ({ clientId }: { clientId: string }) => {
  const { data } = await axiosLicensingInstance.get(
    `${routes['client']}/${clientId}/setupIntent`,
  );

  return data;
};

export const useMutateGetSetupIntent = () => {
  return useMutation({
    mutationFn: getSetupIntent,
  });
};

export default useGetSetupIntent;
