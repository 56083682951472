import { useParams } from 'react-router-dom';
import { TemplateEditorAvailabeChannels } from '../variables/inputs';
import React from 'react';
import { GetTemplateContent } from 'api/templates/get-template/types';
import { DEFAULT_TEMPLATE_STATE } from 'templates/utils';
import { capitalizeFirstLetter } from 'utils/functions';
import { useGetTemplateChannelData } from './getTemplateChannelData';

export const useRefillTemplateEditorData = ({
  reset,
}: {
  reset: (data: any) => void;
}) => {
  const params = useParams();
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const getTemplateContent = useGetTemplateChannelData();
  const savedTemplateData = React.useMemo(
    () => getTemplateContent,
    [getTemplateContent],
  );

  React.useEffect(() => {
    // sometimes content and  batchingContent strings, sometimes objects
    // we need to handle both cases
    // in batchingContent case we also need to add batching infront of keys
    // Eg: content -> batchingContent, since this is name in the form

    // initial file values, for whatsapp
    const defaultFileValues = {
      url_select: 'url',
      batchingUrl_select: 'url',
    };
    reset(defaultFileValues);

    if (!savedTemplateData) return;

    const { content, batchingContent, ...rest } = savedTemplateData;
    const inAppSpec =
      savedTemplateData?.states?.[0]?.stateSpec?.[DEFAULT_TEMPLATE_STATE];
    const inAppTemplateWithoutStates = savedTemplateData?.states?.length === 0;

    const inAppTemplateWithoutStatesValues = Object.fromEntries(
      Object.entries(content ?? {})?.map(([key, value]) => {
        return [`${DEFAULT_TEMPLATE_STATE}_${key}`, value];
      }),
    );

    const batchingContentWithCorrectKeys =
      batchingContent &&
      Object.fromEntries(
        Object.entries(batchingContent)?.map(([key, value]) => {
          const capitalisedKey = capitalizeFirstLetter(key);
          return [`batching${capitalisedKey}`, value];
        }),
      );

    reset({
      ...rest,
      ...savedTemplateData,
      ...(typeof content === 'object' && content),
      ...(inAppSpec ?? {}),
      ...(typeof batchingContentWithCorrectKeys === 'object' &&
        batchingContentWithCorrectKeys),
      ...defaultFileValues,
      ...(channel === 'sms' && {
        content: content?.message,
        batchingContent:
          typeof batchingContent === 'string'
            ? batchingContent
            : batchingContent?.message,
      }),
      ...(channel === 'whatsapp' && {
        url: (content as any)?.[`${(content as any)?.['messageType']}Url`],
        batchingUrl: (batchingContent as any)?.[
          `${(batchingContent as any)?.['messageType']}Url`
        ],
        whatsapp_provider: Boolean(content?.messagingServiceSid)
          ? 'twilio'
          : Boolean(content?.template?.name)
            ? 'whatsapp_cloud_api'
            : 'others',
        //  whatsapp_cloud_api data
        ...(Boolean(content?.template?.name) && {
          templateName: content?.template?.name,
          languageCode: content?.template?.language?.code,
        }),
      }),
      ...(channel === 'discord' && {
        embeds: JSON.stringify(
          (content as GetTemplateContent)?.embeds,
          undefined,
          4,
        ),
        batchingEmbeds: JSON.stringify(
          (batchingContentWithCorrectKeys as GetTemplateContent)
            ?.batchingEmbeds,
          undefined,
          4,
        ),
      }),
      ...(channel === 'slack' && {
        blocks: JSON.stringify(
          (content as GetTemplateContent)?.blocks,
          undefined,
          4,
        ),
        batchingBlocks: JSON.stringify(
          (batchingContentWithCorrectKeys as GetTemplateContent)
            ?.batchingBlocks,
          undefined,
          4,
        ),
      }),
      ...(channel === 'inApp' &&
        inAppTemplateWithoutStates &&
        inAppTemplateWithoutStatesValues),
    });
  }, [channel, reset, savedTemplateData]);
};
