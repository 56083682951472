import { WorkflowRun } from 'api/workflows-run/type';
import { useDisclosure } from '@chakra-ui/hooks';
import { HoverCard, HoverCardTrigger } from '@radix-ui/react-hover-card';
import { PaginationState } from '@tanstack/react-table';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import TableMenu from 'components/table/table-menu';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWorkflowStore from 'store/workflowStore';
import { useGetWorkflowOverviewPageParams } from '../hooks';
import {
  WorkflowRunState,
  workflowRunChipBg,
  workflowRunChipColor,
} from '../variables';
import WorkflowCancelModal from './WorkflowCancelModal';
import WorkflowInfoModal from './WorkflowInfoModal';
import EmptyTableMessage from 'components/table/empty-table-message';
import { Workflow } from 'lucide-react';
import CopyCell from 'components/copy/CopyCell';
import { getHashidsInstance } from 'utils/use-hash-id';

function WorkflowRunTable(props: {
  tableData: any;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
  inLogsPage?: boolean;
  totalCount?: number;
  pagination?: PaginationState;
  setWorkflowId?: React.Dispatch<React.SetStateAction<number>>;
  setPagination?: React.Dispatch<React.SetStateAction<PaginationState>>;
  loading: boolean;
}) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<WorkflowRun>(null);
  const {
    tableData,
    inLogsPage,
    loading,
    totalCount,
    pagination,
    setPagination,
  } = props;
  const workflowInfoModalActions = useDisclosure();
  const { workflowId, currentView } = useGetWorkflowOverviewPageParams();
  const { setCancelWorkflowFor } = useWorkflowStore(state => state);
  const workflowCancelModalActions = useDisclosure();
  const hashId = getHashidsInstance();

  const handleView = (selected: WorkflowRun) => {
    const worklowId = selected?.workflow?.id;
    const worklowRunId = selected.id;
    const hashedWorklowRunId = hashId.encode(worklowRunId);
    const hashedWorkflowId = hashId.encode(workflowId);

    if (inLogsPage) {
      props.setWorkflowId?.(worklowId);
      props.setSelected?.(worklowRunId);
    } else {
      navigate(
        `/admin/workflow-overview/${hashedWorkflowId}?view=${currentView}&workflowRunId=${hashedWorklowRunId}`,
      );
    }
  };

  const columnConfig: ColumnConfig<WorkflowRun> = [
    {
      accessorKey: 'id',
      header: 'Run ID',
      cellFunction: info => (
        <p className="py-[10px]  text-base text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
      size: 110,
    },
    {
      accessorKey: 'workflow',
      header: 'Workflow Identifier',
      cellFunction: info => {
        const workflowIdentifier = info.getValue()?.identifier;
        return workflowIdentifier;
      },
    },
    {
      accessorKey: 'user.identifier',
      header: 'Recipient',
      cellFunction: info => <CopyCell value={info.getValue()} />,
    },
    {
      accessorKey: 'state',
      header: 'State',
      cellFunction: info => {
        const state = info.row.original.state;

        return (
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex gap-2">
                <div
                  style={{
                    background: workflowRunChipBg[state],
                    color: workflowRunChipColor[state],
                  }}
                  className="relative  flex gap-1 select-none items-center whitespace-nowrap rounded-lg px-[10px] py-[5px] text-sm font-medium capitalize text-white"
                >
                  {info.getValue().replace('_', ' ')}
                </div>
              </div>
            </HoverCardTrigger>
          </HoverCard>
        );
      },
    },
    {
      accessorKey: 'cancellationKey',
      header: 'Cancellation Key',
    },
    {
      accessorKey: 'createdAt',
      header: 'Started At',
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMM yyyy, h:mm aaa');

        return <p className=" text-sm text-navy-700 dark:text-white">{date}</p>;
      },
    },
    {
      accessorKey: 'user',
      header: 'Action',
      cellFunction: info => {
        const originalData = info.row.original;
        const cancelFor = originalData?.user?.identifier;
        const isCancelled = originalData?.state === WorkflowRunState.CANCELLED;
        const cancellationKey = originalData?.cancellationKey;

        return (
          <div className="flex gap-0">
            <TableMenu
              config={[
                {
                  text: 'View Info',
                  action: () => {
                    setSelected(info.row.original);
                    workflowInfoModalActions.onOpen();
                  },
                },
                {
                  text: "View Recipient's Runs",
                  action: () => {},
                },
                {
                  text:
                    cancellationKey && !isCancelled
                      ? 'Cancel Workflow'
                      : isCancelled
                        ? 'Already Cancelled'
                        : 'No Cancellation Key',
                  action: () => {
                    setCancelWorkflowFor(cancelFor);
                    workflowCancelModalActions.onOpen();
                  },
                  disabled: !cancellationKey || isCancelled,
                },
              ]}
            />
          </div>
        );
      },
    },
  ];

  const columns = columnGenerator<WorkflowRun>({ columnConfig });

  return (
    <>
      <WorkflowInfoModal
        isOpen={workflowInfoModalActions.isOpen}
        onClose={workflowInfoModalActions.onClose}
        selected={selected}
      />

      <WorkflowCancelModal
        isOpen={workflowCancelModalActions.isOpen}
        onClose={workflowCancelModalActions.onClose}
      />

      {/* table */}
      <CustomTable
        loading={loading}
        tableData={tableData}
        columns={columns}
        emptyTableMessage="You have no workflow runs"
        extraTdClass={'hover:cursor-pointer'}
        paginationType={inLogsPage ? 'api' : 'table'}
        totalPages={totalCount}
        pagination={pagination}
        setPagination={setPagination}
        rowClick={row => {
          const selected = row.original;

          handleView(selected);
        }}
        emptyAdditionalJsx={() => (
          <EmptyTableMessage
            handleCreate={() => {
              navigate('/admin/compose/send');
            }}
            docsLink={
              'https://docs.engagespot.co/docs/features/workflows/sending'
            }
            heading={'No workflow runs have been executed yet'}
            message={
              'A workflow run is an instance of your workflow being executed. You can trigger a workflow run manually, or through an event or API call.'
            }
            Icon={Workflow}
          />
        )}
      />
    </>
  );
}

export default WorkflowRunTable;
