import { useDisclosure } from '@chakra-ui/hooks';
import { PaginationState } from '@tanstack/react-table';
import { GetTranslations } from 'api/translations/types';
import CopyCell from 'components/copy/CopyCell';
import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn/avatar';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { Languages } from 'lucide-react';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from 'react-router-dom';
import { useTranslationStore } from 'store/translationStore';
import { cn } from 'utils/class-merge';
import { useAppLevel } from 'utils/use-app-level';
import {
  findPublishStatus,
  PublishStatusBadge,
} from '../../commits/components/PublishStatusBadge';
import DefaultModal from './DefaultModal';
import DeleteWorkflowModal from './DeleteModal';
import { usePlanLimit } from 'utils/use-plan-limit';
import { getHashidsInstance } from 'utils/use-hash-id';

type Actions =
  | 'edit_workflows'
  | 'delete_workflows'
  | 'view_workflows'
  | 'make_default';

function TranslationTable(props: {
  tableData: any;
  editOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  totalPages: number;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
}) {
  const {
    tableData,
    editOpen: onOpen,
    globalFilter,
    loading,
    totalPages,
    pagination,
    setPagination,
  } = props;
  const deleteWorkflowModalActions = useDisclosure();
  const toggleWOrkflowModalActions = useDisclosure();
  const { setTranslation, setTranslationModalState } = useTranslationStore(
    state => state,
  );
  const navigate = useNavigate();
  const planLimit = usePlanLimit();
  const hashId = getHashidsInstance();
  const { isDefaultApp } = useAppLevel();

  const handleCreate = () => {
    if (!planLimit.limits.translations) {
      planLimit.actions.setText(planLimit.texts.not_supported);
      return planLimit.actions.onOpen();
    }

    setTranslation(null);
    setTranslationModalState('add');
    onOpen();
  };

  const handleMenuClick = (
    selected: GetTranslations['results'][0],
    actionType: Actions,
  ) => {
    setTranslation(selected);

    if (actionType === 'view_workflows') {
      const id = hashId.encode(selected.id);
      return navigate(`/admin/translation-editor/${id}`);
    }

    if (actionType === 'delete_workflows') {
      return deleteWorkflowModalActions.onOpen();
    }

    if (actionType === 'edit_workflows') {
      setTranslationModalState('edit');
    }

    if (actionType === 'make_default') {
      return toggleWOrkflowModalActions.onOpen();
    }

    onOpen();
  };

  const columnConfig: ColumnConfig<GetTranslations['results'][0]> = [
    {
      accessorKey: 'identifier',
      header: 'Identifier',
      cellFunction: info => {
        const identifier = info.getValue();
        return <CopyCell value={identifier} />;
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cellFunction: info => {
        const isDefault = Boolean(info.row.original?.isDefault);

        return (
          <div className="flex gap-2 items-center">
            {info.getValue()}

            {isDefault && (
              <p
                className={`w-min text-xs rounded-lg bg-green-750 text-green-20 px-2 py-1 flex justify-center items-center`}
              >
                Default
              </p>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'clientUser',
      header: 'Saved By',
      cellFunction: info => {
        const clientUser = info.getValue();
        const firstName = clientUser?.firstName;
        const profilePicture = clientUser?.profilePicture;

        return (
          <div className={cn('flex gap-2 items-center')}>
            <span>
              <Avatar className="h-6 w-6">
                <AvatarImage src={profilePicture} alt={firstName} />
                <AvatarFallback className="text-white text-xs">
                  {firstName?.charAt(0).toUpperCase()}
                </AvatarFallback>
              </Avatar>
            </span>
            <span>{firstName}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'locale',
      header: 'Locale',
      cellFunction: info => {
        const locale = info.getValue();
        const countryCode = locale?.regionCode;
        return (
          <div className="flex items-center gap-3">
            {countryCode && (
              <ReactCountryFlag
                style={{
                  width: '1.2rem',
                  height: '1.2rem',
                }}
                countryCode={countryCode}
                svg
              />
            )}

            {`${locale?.language} (${locale?.countryRegion})`}
          </div>
        );
      },
    },
    {
      accessorKey: 'isDefault',
      header: 'Publish Status',
      cellFunction: info => {
        const item = info.row.original;
        const publishStatus = findPublishStatus({
          hasPublishedJsonSpec: Boolean(item?.publishedJsonSpec),
          hasUnpublishedChanges: Boolean(item.hasUnpublishedChanges),
        });

        return <PublishStatusBadge publishStatus={publishStatus} />;
      },
    },

    {
      accessorKey: 'clientId',
      cellFunction: info => {
        const isDefault = Boolean(info.row.original?.isDefault);

        return (
          <TableMenu
            config={[
              ...(isDefaultApp
                ? [
                    {
                      text: 'View',
                      action: () =>
                        handleMenuClick(info.row.original, 'view_workflows'),
                    },
                    {
                      text: 'Edit',
                      action: () =>
                        handleMenuClick(info.row.original, 'edit_workflows'),
                    },
                    {
                      text: 'Delete',
                      action: () =>
                        handleMenuClick(info.row.original, 'delete_workflows'),
                    },
                    ...(!isDefault
                      ? [
                          {
                            text: 'Make Default',
                            action: () =>
                              handleMenuClick(
                                info.row.original,
                                'make_default',
                              ),
                          },
                        ]
                      : []),
                  ]
                : [
                    {
                      text: 'View',
                      action: () =>
                        handleMenuClick(info.row.original, 'view_workflows'),
                    },
                  ]),
            ]}
          />
        );
      },
    },
  ];

  const columns = columnGenerator({ columnConfig });

  return (
    <>
      <DeleteWorkflowModal
        isOpen={deleteWorkflowModalActions.isOpen}
        onClose={deleteWorkflowModalActions.onClose}
      />

      <DefaultModal
        isOpen={toggleWOrkflowModalActions.isOpen}
        onClose={toggleWOrkflowModalActions.onClose}
      />

      {/* table */}
      <CustomTable
        pagination={pagination}
        setPagination={setPagination}
        totalPages={totalPages}
        loading={loading}
        globalFilter={globalFilter}
        columns={columns}
        tableData={tableData}
        rowClick={row => {
          const selected = row.original;
          setTranslation(null);
          handleMenuClick(selected, 'view_workflows');
        }}
        extraTdClass={'hover:cursor-pointer'}
        emptyTableMessage="It looks like you haven't set up any workflow yet. Kickstart your automation journey by creating your first workflow now!"
        paginationType="api"
        emptyAdditionalJsx={() => (
          <EmptyTableMessage
            handleCreate={() => {
              handleCreate();
            }}
            searchInput={''}
            docsLink={
              'https://docs.engagespot.co/docs/features/translations/overview'
            }
            heading={"You haven't created any translations yet"}
            message={
              'Translations are key to global reach. Support multiple languages and ensure your content connects. Create your first translation now.'
            }
            searchEmptyHeading={getSearchEmptyHeading('notifications')}
            searchEmptyMessage={getSearchEmptyMessage('notifications')}
            hideSecondary={!isDefaultApp}
            Icon={Languages}
          />
        )}
      />
    </>
  );
}

export default TranslationTable;
