import type { Logger } from '@engagespot/utils';
import { io } from 'socket.io-client';

type RtmClientOptions = {
  apiKey: string;
  userId: string;
  userSignature?: string;
  userToken?: string;
  rtmServerUrl: string;
  log: Logger;
};

type RtmClient = ReturnType<typeof io>;

export function createRtmClient(options: RtmClientOptions): RtmClient | null {
  const { apiKey, userId, userSignature, userToken, rtmServerUrl, log } =
    options;
  let rtmClient: RtmClient;
  try {
    rtmClient = io(rtmServerUrl || '', {
      query: {
        apiKey,
        userId,
        userSignature,
      },
      transports: ['websocket'],
      path: '',
      ...(userToken && {
        auth: {
          token: userToken,
        },
      }),
    });
  } catch (err) {
    log.errorAlways('Failed to connect to rtm server');
    return null;
  }
  return rtmClient;
}
