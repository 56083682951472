import { X } from 'lucide-react';
import { Button } from 'components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/dialog';

function CancelMessageModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent showCloseButton={false} className="sm:max-w-md">
        <DialogHeader className="gap-1">
          <Button
            variant="link"
            className="absolute right-2 top-3 h-fit w-fit rounded-full flex items-center justify-center"
            onClick={() => handleClose()}
          >
            <X className="h-4 w-4 text-white" />
          </Button>

          <div className="mx-auto mb-6 flex h-20 w-20 items-center justify-center rounded-full bg-primary/10">
            <svg
              className="h-10 w-10 text-primary"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={3}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>

          <DialogTitle className="text-center text-2xl text-white">
            We&apos;re sorry to see you go!
          </DialogTitle>
          <DialogDescription className="text-center text-base">
            You&apos;ve successfully canceled your subscription. We hope
            you&apos;ve had a positive experience with our service so far.
          </DialogDescription>
        </DialogHeader>
        <div className="mt-4 flex justify-center">
          <Button size="lg" className="px-8" onClick={handleClose}>
            Return to Billing
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default CancelMessageModal;
