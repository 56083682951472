import { type InitServiceFn, type ServiceArgs } from '../modules/service';

type ConnectServiceArgs = ServiceArgs<'notificationService'>;

export function connectService({
  dependencies: {
    options: { disableAutoStart },
    apiClient,
    deviceMetadata: { deviceType, browserType },
  },
}: ConnectServiceArgs) {
  let connectPromise: ReturnType<typeof connect> | null = null;

  type ConnectData = Awaited<ReturnType<typeof connect>>;

  const connect = async () => {
    const promise = apiClient.POST('/v3/sdk/connect', {
      body: { browserType, deviceType },
    });
    connectPromise = promise;
    const response = await promise;

    return response;
  };

  const getInfo = async () => {
    const response = await connect();
    return response;
  };

  const getInfoCached = async () => {
    const response = (await connectPromise) as ConnectData;
    return response;
  };

  const initiate = async () => {
    if (connectPromise) {
      const { error } = await connectPromise;
      return !error;
    }
    connectPromise = connect();
    const { error } = await connectPromise;
    return !error;
  };

  const ready = async () => {
    return await initiate();
  };

  const isWebPushEnabled = async () => {
    const { data, error } = (await connectPromise) as ConnectData;
    if (error) return false;
    return data?.app?.enableWebPush;
  };

  if (!disableAutoStart) {
    initiate();
  }

  return {
    api: {
      ready,
      initiate,
      getInfo,
      getInfoCached,
      isWebPushEnabled,
    },
    publicApi: {
      connect,
      getInfo,
    },
  };
}

connectService.key = 'connectService' as const;

export const initConnectService = <T extends InitServiceFn>(initService: T) => {
  const app = initService({
    key: 'connectService',
    requiredServiceKeys: [],
  });
  return app;
};
