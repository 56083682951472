import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetLogs } from './types';
import { PaginationState } from '@tanstack/react-table';
import { pickBy, mapValues } from 'remeda';

export type LogFilters = PaginationState & {
  fromDate: string;
  toDate: string;
  providerIdentifier?: string;
  channel?: string;
  event?: string;
  userIdentifier?: string[];
};

type Params = Record<string, any>;

function filterParams(params: Params): Params {
  return pickBy(
    mapValues(params, value =>
      Array.isArray(value) ? value.join(',') : value,
    ),
    value => value !== '' && value !== undefined && value !== null,
  );
}

const get = async ({
  queryKey,
}: QueryFunctionContext<
  [
    string,
    number,
    number,
    number,
    string,
    string,
    string,
    string,
    string,
    string[],
  ]
>): Promise<GetLogs> => {
  const [
    ,
    appId,
    pageNo,
    limit,
    fromDate,
    toDate,
    channel,
    event,
    providerIdentifier,
    userIdentifier,
  ] = queryKey;

  const params = {
    page: pageNo,
    limit,
    fromDate,
    toDate,
    channel,
    event,
    providerIdentifier,
    userIdentifier,
  };

  const filteredParams = filterParams(params);
  const { data } = await axiosInstance.get(`${routes['apps']}/${appId}/logs`, {
    params: filteredParams,
  });

  return data;
};

const useGetLogs = ({
  pageIndex,
  pageSize,
  fromDate,
  toDate,
  channel,
  event,
  providerIdentifier,
  userIdentifier,
}: LogFilters) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [
      `${routes['apps']}/logs`,
      appId,
      pageIndex,
      pageSize,
      fromDate,
      toDate,
      channel,
      event,
      providerIdentifier,
      userIdentifier,
    ],
    queryFn: get,
    enabled: Boolean(appId),
  });
};

export default useGetLogs;
