import { relativeDateFormatter } from '@engagespot/utils';

import { notificationFeedService } from './notificationFeedService';
import type { InitServiceFn, ServiceArgs } from '../utils/service';

type RtmService = ServiceArgs<'rtmService'> &
  ServiceArgs<typeof notificationFeedService.key>;

export function rtmService({
  dependencies: { instance, options },
  requiredServices: { notificationFeedService },
}: RtmService) {
  const {
    stores: { $unreadCount },
    updateStores,
  } = notificationFeedService;

  if (!options.disableRealTimeStoreUpdates) {
    instance.events.notificationReceive.subscribe(data => {
      const newNotification = {
        ...data.notification,
        ...(data.notification.createdAt && {
          createdAtRelative: relativeDateFormatter(data.notification.createdAt),
        }),
      };
      $unreadCount.set(($unreadCount.value ?? 0) + 1);

      updateStores(
        stores => {
          stores.$notifications.set([
            newNotification,
            ...stores.$notifications.get(),
          ]);
        },
        {
          additionalFilterKeys: [data.notification.category?.name || ''],
        },
      );
    });

    instance.events.notificationDelete.subscribe(data => {
      updateStores(
        stores => {
          const { $notifications } = stores;
          $notifications.set(
            $notifications.get().filter(item => item.id !== Number(data.id)),
          );
        },
        { fireAll: true },
      );
    });

    instance.events.notificationDeleteAll.subscribe(() => {
      updateStores(
        stores => {
          stores.$notifications.set([]);
        },
        { fireAll: true },
      );
    });

    instance.events.notificationRead.subscribe(data => {
      const notificationId = Number(data.id);

      /**  TODO(hemandev) - Right now we are just firing all stores, but we should only filter the stores that are affected.
       * Notification read event currently does not provide the category information, so we are firing all stores.
       */
      updateStores(
        stores => {
          const { $notifications } = stores;
          $notifications.set(
            $notifications.get().map(notification => {
              if (notification?.id === notificationId) {
                return {
                  ...notification,
                  clickedAt: new Date().toUTCString(),
                  clickedAtRelative: relativeDateFormatter(
                    new Date().toUTCString(),
                  ),
                };
              }

              return notification;
            }),
          );
        },
        {
          fireAll: true,
        },
      );
    });

    instance.events.notificationReadAll.subscribe(() => {
      updateStores(
        stores => {
          const { $notifications } = stores;
          $notifications.set(
            $notifications.get().map(notification => {
              return {
                ...notification,
                clickedAt: new Date().toUTCString(),
                clickedAtRelative: relativeDateFormatter(
                  new Date().toUTCString(),
                ),
              };
            }),
          );
        },
        { fireAll: true },
      );
    });

    instance.events.notificationSeen.subscribe(data => {
      const unreadCount = data.unseenCount;
      $unreadCount.set(unreadCount);
    });

    instance.events.notificationStateChange.subscribe(data => {
      const notificationId = Number(data.notification?.id);

      updateStores(
        stores => {
          const { $notifications } = stores;

          $notifications.set(
            $notifications.get().map(notification => {
              if (notification?.id === notificationId) {
                return {
                  ...notification,
                  ...data.notification,
                };
              }

              return notification;
            }),
          );
        },
        { fireAll: true },
      );
    });
  }

  return {};
}

rtmService.key = 'rtmService' as const;

export const initRtmService = <T extends InitServiceFn>(initService: T) => {
  const app = initService({
    key: 'rtmService',
    requiredServiceKeys: [notificationFeedService.key],
  });
  return app;
};
