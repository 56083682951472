import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetPlanUsage } from './types';
import { axiosLicensingInstance } from 'api/axios/licensing';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetPlanUsage> => {
  const [, clientId] = queryKey;
  const { data } = await axiosLicensingInstance.get(
    `${routes['v1_client']}/${clientId}/usages`,
  );

  return data;
};

const useGetPlanUsage = () => {
  const { clientId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['v1_client']}/usage`, clientId],
    queryFn: get,
    enabled: Boolean(clientId),
  });
};

export default useGetPlanUsage;
