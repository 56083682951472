import styled, { css } from 'styled-components';

export type ContainerStyledProps = {
  flexDirection: 'row' | 'column';
  hidden?: boolean;
};

export const ContainerStyled = styled.div.withConfig({
  shouldForwardProp: prop => !['flexDirection', 'hidden'].includes(prop),
})<ContainerStyledProps>`
  && {
    ${({ flexDirection, hidden }) => css`
      display: ${hidden ? 'none' : 'flex'};
      flex-direction: ${flexDirection};
      gap: ${flexDirection === 'row' ? '0.75rem' : ''};
    `}
  }
`;
