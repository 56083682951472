export const getAbbreviateNumber = (
  number: string | number = 0,
  maximumFractionDigits: number = 0,
) => {
  const abbreviateNumber = new Intl.NumberFormat('en-GB', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits,
  });

  if (isNaN(Number(number))) return '0';
  return abbreviateNumber.format(Number(number));
};
