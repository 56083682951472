import LoaderSpinner from 'components/loader/LoaderSpinner';
import React from 'react';

export function StartupLoader(): React.ReactElement {
  return (
    <div className="flex items-center justify-center  min-h-80 h-lvh w-full">
      <LoaderSpinner />
    </div>
  );
}
