import CreditCardMenu from './CreditCardMenu';
import { CreditCard } from 'lucide-react';
import { Card, CardContent } from 'components/shadcn/card';
import { cn } from 'utils/class-merge';
import { chipBgColors } from 'components/chip';

function MasterCard({
  number,
  brand,
  paymentMethodId,
  isDefault,
  expiryMonth,
  expiryYear,
}: {
  number: string;
  brand: string;
  paymentMethodId: string;
  isDefault: boolean;
  expiryMonth: number;
  expiryYear: number;
}) {
  return (
    <Card className="relative overflow-hidden transition-colors">
      <CardContent className="p-6">
        <div className="flex items-start justify-between">
          <div className="space-y-1">
            <CreditCard className="h-8 w-8 text-primary" />
            <div className="mt-4 space-y-1">
              <p className="font-mono text-xl">{number}</p>
              <p className="capitalize text-muted-foreground">{brand}</p>
            </div>
          </div>

          {!isDefault && (
            <div className="relative left-2 bottom-2">
              <CreditCardMenu
                isDefault={isDefault}
                paymentMethodId={paymentMethodId}
              />
            </div>
          )}

          {isDefault && (
            <span
              className={cn(
                'rounded-full bg-green-500 px-2.5 py-0.5 text-sm font-medium text-green-350',
                chipBgColors['green'],
              )}
            >
              Default
            </span>
          )}
        </div>

        <div className="mt-4 flex items-end justify-between">
          <div>
            <p className="text-sm text-muted-foreground">Expires</p>
            <p className="font-medium">
              {expiryMonth < 10 ? `0${expiryMonth}` : expiryMonth}/{expiryYear}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default MasterCard;
