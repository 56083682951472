import { CaretSortIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import * as React from 'react';

import { Button } from 'components/shadcn/button';

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/shadcn/command';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';

import { SelectOptions } from 'components/fields/SelectField/types';
import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn/avatar';
import { cn } from 'utils/class-merge';
import Chip, { chipBgColors } from 'components/chip';
import { REGIONS } from 'variables/regions';
import { WorkspaceItem } from './WorkspaceItem';
import { useWorkspace } from './WorkspaceProvider';

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface WorkSpaceSwitcherProps extends PopoverTriggerProps {
  className?: string;
}

export type RegionSelectOption = SelectOptions & { region: string };

const DataRegionChip = ({
  isUSRegion,
  className,
}: {
  isUSRegion?: boolean;
  className?: string;
}) => {
  return (
    <Chip
      text={isUSRegion ? 'US' : 'EU'}
      className={cn(
        `py-1 rounded-md text-xs font-semibold ml-3`,
        isUSRegion ? chipBgColors['green'] : chipBgColors['blue'],
        className,
      )}
    />
  );
};

export default function WorkSpaceSwitcher({
  className,
}: WorkSpaceSwitcherProps) {
  const { teams, selectedWorkspace, onSwitchWorkspace, onCreateWorkspace } =
    useWorkspace() ?? {};

  const [open, setOpen] = React.useState(false);

  const handleAddWorkspace = React.useCallback(() => {
    setOpen(false);
    onCreateWorkspace();
  }, [onCreateWorkspace]);

  const handleWorkspaceItemSelected = React.useCallback(
    (team: RegionSelectOption) => {
      setOpen(false);
      onSwitchWorkspace(team);
    },
    [setOpen, onSwitchWorkspace],
  );

  const isSelectedUSRegion =
    selectedWorkspace?.region === REGIONS['US_WEST_2'] ||
    selectedWorkspace?.region === null;

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a workspace"
            className={cn(
              'w-[235px] text-white  justify-between hover:bg-muted/50 border border-[#3A3F47]',
              className,
            )}
          >
            <Avatar className="mr-2 h-6 w-6 grayscale">
              <AvatarImage
                // src={`https://avatar.vercel.sh/1.png`}
                src={''}
                alt={'avatar'}
                className="grayscale"
              />
              <AvatarFallback className="text-xs grayscale border border-white/20">
                {selectedWorkspace?.label?.charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>

            <div className="ml-1 text-left text-white font-semibold flex flex-col justify-center items-center gap-0">
              <span>{selectedWorkspace?.label}</span>
            </div>

            {selectedWorkspace?.region && (
              <DataRegionChip isUSRegion={isSelectedUSRegion} />
            )}

            <div className="ml-auto flex gap-2 items-center">
              <CaretSortIcon className="h-4 w-4 shrink-0 opacity-50" />
            </div>
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-[235px] p-0">
          <Command>
            <CommandList>
              <CommandGroup className="text-lg" heading={'Workspace'}>
                <CommandItem value="-" className="hidden" />
                {teams?.map(team => {
                  return (
                    <CommandItem
                      key={team.value}
                      onSelect={() => {
                        handleWorkspaceItemSelected(team);
                      }}
                    >
                      <WorkspaceItem
                        active={selectedWorkspace?.value === team.value}
                        team={team}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>

            <CommandSeparator />

            <CommandList>
              <CommandGroup>
                <CommandItem
                  className="cursor-pointer"
                  onSelect={handleAddWorkspace}
                >
                  <PlusCircledIcon className="mr-2 h-5 w-5" />
                  Create Workspace
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
