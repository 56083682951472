import { useStore } from '@nanostores/react';

import { useInternalInstance } from './useInstance';

function useUnreadCount() {
  const {
    notificationFeed: { stores },
  } = useInternalInstance();

  const { $unreadCount } = stores;
  const unreadCount = useStore($unreadCount);

  return unreadCount;
}

export { useUnreadCount as useUnseenCount };
