import type { Instance } from '@engagespot/core';

import type {
  ChangeState,
  ChangeStateResponse,
  DeleteAllResponse,
  DeleteResponse,
  FetcherStore,
  MarkAllAsReadResponse,
  MarkAsReadResponse,
  MarkAsSeenResponse,
  Pagination,
} from './types';

export const getActionMutators = ({
  createMutatorStore,
  instance,
}: {
  createMutatorStore: FetcherStore['createMutatorStore'];
  instance: Instance;
}) => {
  const $deleteMutator = createMutatorStore<{ id: string }, DeleteResponse>(
    async ({ data: id }) => {
      return instance.notification.remove(id);
    },
  );

  const $deleteAllMutator = createMutatorStore<void, DeleteAllResponse>(
    async () => {
      return instance.notification.removeAll();
    },
  );

  const $markAsReadMutator = createMutatorStore<
    { id: string },
    MarkAsReadResponse
  >(async ({ data: id }) => {
    return instance.notification.markAsRead(id);
  });

  const $markAllAsReadMutator = createMutatorStore<void, MarkAllAsReadResponse>(
    async () => {
      return instance.notification.markAllAsRead();
    },
  );

  const $markAsSeenMutator = createMutatorStore<Pagination, MarkAsSeenResponse>(
    async ({ data }) => {
      return instance.notification.markAllAsSeen({
        limit: data.limit,
        page: data.pageNo,
      });
    },
  );

  const $changeStateMutator = createMutatorStore<
    ChangeState,
    ChangeStateResponse
  >(async ({ data }) => {
    return instance.notification.changeState(data);
  });

  return {
    $markAllAsReadMutator,
    $changeStateMutator,
    $deleteAllMutator,
    $markAsSeenMutator,
    $markAsReadMutator,
    $deleteMutator,
  };
};
