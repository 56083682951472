import { type InstanceOptions } from '@engagespot/core';
import { createLogger } from '@engagespot/utils';

import { initConnectService } from './services/connectService';
import { initNotificationActionsService } from './services/notificationActionsService/index';
import { initNotificationFeedService } from './services/notificationFeedService';
import { initPreferenceService } from './services/preferenceService';
import { initRtmService } from './services/rtmService';
import { initWebPushService } from './services/webPushService';
import { createInitService } from './utils/service';

export type StoreOptions = InstanceOptions & {
  /**
   * If disabled, the store won't update automatically on real-time events.
   */
  disableRealTimeStoreUpdates?: boolean;

  /**
   * If disabled, the store won't fetch notifications on start.
   */
  disableFetchOnStart?: boolean;

  /**
   * Limit the no of data to be fetched
   */
  itemsPerPage?: number;

  /**
   * The interval in milliseconds to recall the relative time update function
   */
  relativeTimeUpdateInterval?: number;
};

export type StoreInstance = ReturnType<typeof createStore>;

/**
 * TODO(hemandev)
 * There's an issue related to inferred types currently
 * https://github.com/microsoft/TypeScript/issues/42873
 * TypeScript 5.5 will have a fix for this issue.
 *
 */
export function createStore(storeOptions: StoreOptions) {
  const { debug, disableFetchOnStart, ...options } = storeOptions;
  const log = createLogger({
    debug,
    prefix: '[Engagespot Store]',
    debugPrefix: '[DEBUG]',
  });
  log.info('Initializing store with options', options);

  const {
    initService,
    dependencies: { instance },
  } = createInitService({ debug, ...options }, log);

  const connect = initConnectService(initService);
  const notificationFeed = initNotificationFeedService(initService);
  const actions = initNotificationActionsService(initService);
  const rtm = initRtmService(initService);
  const webpush = initWebPushService(initService);
  const preferences = initPreferenceService(initService);

  if (!disableFetchOnStart) {
    // loadMore();
  }

  return {
    instance,
    notificationFeed,
    webpush,
    connect,
    actions,
    rtm,
    preferences,
  };
}
