import { cn } from 'utils/class-merge';

function Skeleton({ className = '' }: { className?: string }) {
  return (
    <div
      className={cn(
        `csm-pulse h-[32px] w-48  max-w-sm rounded-[10px] dark:bg-gray-930/40`,
        className,
      )}
    ></div>
  );
}

export default Skeleton;
