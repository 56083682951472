import { AppWindow, List, FilePlus } from 'lucide-react';

import { Group } from './Group';
import { ActionItem, ListItem } from 'commandbar/itemTypes';
import { createAppAction } from 'components/navbar/AppProvider';
import { useIsEditorPage } from 'utils/use-is-editor-page';

const GROUP_NAME = 'Apps';

export function App() {
  const isEditorPage = useIsEditorPage();
  console.log('isEditor', isEditorPage);

  return (
    <Group heading={GROUP_NAME} icon={AppWindow}>
      <ListItem page="switch-app" icon={List} group={GROUP_NAME}>
        Switch App
      </ListItem>
      <ActionItem
        icon={FilePlus}
        path={isEditorPage ? '/admin/api-credentials' : ''}
        action={createAppAction}
        group={GROUP_NAME}
      >
        Create App
      </ActionItem>
    </Group>
  );
}
