import { useStore } from '@nanostores/react';

import { useInternalInstance } from './useInstance';

export type Preferences = ReturnType<typeof usePreferences>;

export type PreferenceChannel = NonNullable<
  NonNullable<Preferences['preferences']['categories']>[number]['channels']
>[number];

export type EngagespotChannels = PreferenceChannel['id'];

export const usePreferences = () => {
  const {
    preferences: { stores, actions },
  } = useInternalInstance();

  const { setProfileAttributes, setPreferences } = actions;
  const { $preferences, $channels, $computedPreferences } = stores;

  useStore($computedPreferences);
  const preferences = useStore($preferences);
  const channels = useStore($channels);

  return {
    preferences,
    setProfileAttributes,
    setPreferences,
    channels,
  };
};
