import { useDisclosure } from '@chakra-ui/hooks';
import { GetTemplate } from 'api/templates/get-template/types';
import AppCopy from 'assets/svg/template/app_copy.svg';
import Copy from 'assets/svg/template/copy.svg';
import Delete from 'assets/svg/template/delete.svg';
import Edit from 'assets/svg/template/edit.svg';
import CopyPopOver from 'components/popover/copy';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/shadcn/hover-card';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import TableMenu from 'components/table/table-menu';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { useTemplateStore } from 'store/templateStore';
import { channelsLogoBlackAndWhite } from 'variables/channelsLogo';
import DeleteTemplateModal from './DeleteModal';
import { getHashidsInstance } from 'utils/use-hash-id';

function TemplatesTable(props: {
  tableData: any;
  editOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  const hashIds = getHashidsInstance();
  const { tableData, editOpen: onOpen, loading } = props;
  const deleteTemplateModalActions = useDisclosure();
  const { setTemplate, setTemplateModalState } = useTemplateStore(
    state => state,
  );
  const navigate = useNavigate();
  const { appId } = useAuthStore(state => state);

  type TemplateAction =
    | 'edit_templates'
    | 'delete_templates'
    | 'view_templates'
    | 'duplicate_templates'
    | 'app_copy_templates';

  const handleMenuClick = (
    selected: GetTemplate,
    actionType: TemplateAction,
  ) => {
    setTemplate(selected);

    if (actionType === 'view_templates') {
      const id = hashIds.encode(selected.id);
      const hashedAppId = hashIds.encode(appId);
      return navigate(`/admin/template/${id}?appId=${hashedAppId}`);
    }

    if (actionType === 'delete_templates') {
      return deleteTemplateModalActions.onOpen();
    }

    if (actionType === 'edit_templates') {
      setTemplateModalState('edit');
    }

    if (actionType === 'duplicate_templates') {
      setTemplateModalState('duplicate');
    }

    if (actionType === 'app_copy_templates') {
      setTemplateModalState('app_copy');
    }

    onOpen();
  };

  const menuIcons: {
    icon: any;
    text: string;
    actionType: TemplateAction;
  }[] = [
    { icon: Edit, text: 'View', actionType: 'view_templates' },
    { icon: Edit, text: 'Edit', actionType: 'edit_templates' },
    { icon: AppCopy, text: 'Transfer App', actionType: 'app_copy_templates' },
    { icon: Copy, text: 'Duplicate', actionType: 'duplicate_templates' },
    { icon: Delete, text: 'Delete', actionType: 'delete_templates' },
  ];

  const columnConfig: ColumnConfig<GetTemplate> = [
    // {
    //   accessorKey: 'id',
    //   header: 'ID',
    //   cellFunction: info => info.getValue(),
    //   size: 50,
    // },
    {
      accessorKey: 'identifier',
      header: 'Identifier',
      cellFunction: info =>
        info.getValue() && (
          <button
            onClick={e => e.stopPropagation()}
            className="group flex items-center justify-between gap-3 rounded-md px-4 py-[10px]  text-base text-navy-700 hover:bg-night-100 dark:text-white"
          >
            {info.getValue()}
            {info.getValue() && (
              <div className="relative top-1 opacity-0 group-hover:opacity-100">
                <CopyPopOver value={info.getValue()} />
              </div>
            )}
          </button>
        ),
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'category.name',
      header: 'Category',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'configuredChannels',
      header: 'Channels',
      cellFunction: info => {
        const channels = info.getValue();
        return (
          <div className="flex gap-1">
            {channels?.map((item: any) => (
              <HoverCard key={item}>
                <HoverCardTrigger>
                  <img
                    width={25}
                    src={(channelsLogoBlackAndWhite as any)?.[item]}
                    alt=""
                  />
                </HoverCardTrigger>
                <HoverCardContent className="!w-auto p-2 text-sm">
                  {item}
                </HoverCardContent>
              </HoverCard>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: 'category.updatedAt',
      header: 'Action',
      cellFunction: info => (
        <TableMenu
          config={menuIcons.map(item => ({
            text: item.text,
            action: () => handleMenuClick(info.row.original, item.actionType),
            icon: item.icon,
            iconType: item.actionType,
          }))}
        />
      ),
    },
  ];

  const columns = columnGenerator<GetTemplate>({ columnConfig });

  return (
    <>
      <DeleteTemplateModal
        isOpen={deleteTemplateModalActions.isOpen}
        onClose={deleteTemplateModalActions.onClose}
      />

      <div className="mt-8 overflow-visible">
        {/* table */}
        <CustomTable
          loading={loading}
          tableData={tableData}
          columns={columns}
          emptyTableMessage="You haven't created any templates. Read <a class='text-decoration-line: underline' target='_blank' href='https://docs.engagespot.com/docs/features/templates/introduction'>this guide</a> to learn how to create templates"
          paginationType="table"
          rowClick={row => {
            const selected = row.original;
            handleMenuClick(selected, 'view_templates');
          }}
          extraTdClass={'hover:cursor-pointer'}
        />
      </div>
    </>
  );
}

export default TemplatesTable;
