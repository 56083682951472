import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { License } from './types';
import React from 'react';
import Cookies from 'js-cookie';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<License> => {
  const [, clientId] = queryKey;

  const { data } = await axiosInstance.get(
    `${routes['clients']}/${clientId}/${routes['license']}`,
  );

  return data;
};

const useGetLicense = (clientId: number, enabled = true) => {
  const query = useQuery({
    queryKey: [routes['license'], clientId],
    queryFn: get,
    enabled: Boolean(clientId) && enabled,
  });

  React.useEffect(() => {
    if (query.data) {
      const token = query.data?.license?.licenseAuthToken;
      Cookies.set('license_access_token', token);
    }
  }, [query.data]);

  return query;
};

export default useGetLicense;
