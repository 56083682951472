import Card from 'components/card';
import TemplateEditorComponents from './components/TemplateEditorComponents';
import TemplateEditorHeader from './components/TemplateEditorHeader';
import TemplateEditorInputs from './components/TemplateEditorInputs';
import TemplateEditorPreviewHeading from './components/TemplateEditorPreviewHeading';
import TemplateEditorPreview from './components/TemplatePreview';
import { useParams, useBlocker } from 'react-router-dom';
import { Channels } from 'api/channels/get-channels/types';
import EmailPreview from './components/preview/EmailPreview';
import React from 'react';
import { useForm } from 'react-hook-form';
import UnsavedChangesModal from './components/UnsavedChangesModal';
import { useDisclosure } from '@chakra-ui/hooks';
import WebHookPreview from './components/preview/WebHookPreview';
import { webHookDefaultValue } from './variables/defaultValue';
import { yupResolver } from '@hookform/resolvers/yup';
import { webhookSchema } from './schema';
import { useGetWorkflowJson } from 'api/workflows';
import useWorkflowStore from 'store/workflowStore';
import { useGetEditorType } from './hooks/getEditorType';
import { cn } from 'utils/class-merge';
import { getHashidsInstance } from 'utils/use-hash-id';
import WhatsAppEditor from './components/preview/WhatsAppEditor';

const TemplateEditor = () => {
  const params = useParams();
  const channel = params.channel as Channels;
  const hashIds = getHashidsInstance();
  const templateId = hashIds.decode(params.templateId);
  const saveRef = React.useRef<HTMLButtonElement>(null);
  const emailLayoutRef = React.useRef<HTMLButtonElement>(null);
  const emailEditorRef = React.useRef(null);
  const [isEmailDirty, setIsEmailDirty] = React.useState(false);
  const { editorTypeWorkflow, editorTypeTemplate } = useGetEditorType();
  const { setWorkflowJson } = useWorkflowStore(state => state);
  const isFetchChannel = channel === ('fetch' as Channels);
  const getWorkflowJson = useGetWorkflowJson(
    Number(templateId),
    editorTypeWorkflow,
  );

  const form = useForm<any>({
    reValidateMode: 'onChange',
    ...(channel === 'webhook' && {
      defaultValues: webHookDefaultValue,
      resolver: yupResolver(webhookSchema),
    }),
  });

  const {
    formState: { dirtyFields },
  } = form;
  const isDirty = !!Object.keys(dirtyFields).length;

  const { onClose, onOpen, isOpen } = useDisclosure();

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const goingToAnotherPage =
      currentLocation.pathname !== nextLocation.pathname;

    return (
      (goingToAnotherPage && isDirty) || (goingToAnotherPage && isEmailDirty)
    );
  });

  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      onOpen();
    }
  }, [blocker.state, onOpen]);

  React.useEffect(() => {
    const handleOnRefresh = (event: any) => {
      if (isDirty || isEmailDirty) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleOnRefresh);

    return () => {
      window.removeEventListener('beforeunload', handleOnRefresh);
    };
  }, [isDirty, isEmailDirty]);

  React.useEffect(() => {
    const jsonSpec = getWorkflowJson.data?.jsonSpec;
    // if user refreshes the page we still have fresh data
    // for computations directly from the json store
    if (editorTypeWorkflow && jsonSpec) {
      setWorkflowJson(jsonSpec);
    }
  }, [getWorkflowJson?.data, setWorkflowJson, editorTypeWorkflow]);

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      {/* Header */}
      <TemplateEditorHeader
        isDirty={isDirty || isEmailDirty}
        saveRef={saveRef}
        emailEditorRef={emailEditorRef}
        form={form}
      />

      <UnsavedChangesModal
        isOpen={isOpen}
        onClose={onClose}
        proceed={() => blocker.proceed()}
        reset={() => blocker.reset()}
        setIsEmailDirty={setIsEmailDirty}
      />

      {/* Body */}
      <div
        className={cn(
          'col-span-12 flex h-[90vh] w-full gap-4',
          'col-span-12 flex w-full gap-4',
          channel === 'email' && 'h-[calc(100vh-64px)]',
        )}
      >
        {channel === 'email' ? (
          <EmailPreview
            setIsEmailDirty={setIsEmailDirty}
            form={form}
            saveRef={saveRef}
            emailLayoutRef={emailLayoutRef}
            actingAsTemplateEditor={editorTypeTemplate ? true : false}
            emailEditorRef={emailEditorRef}
          />
        ) : // fetch special case for workflow
        channel === 'webhook' || isFetchChannel ? (
          <WebHookPreview form={form} saveRef={saveRef} />
        ) : channel === 'whatsapp' ? (
          <WhatsAppEditor form={form} saveRef={saveRef} />
        ) : (
          <>
            {/* Left Side */}
            <Card extra={'w-[70%]  p-6 overflow-auto'}>
              {/* Preview Heading */}
              <TemplateEditorPreviewHeading />

              {/* Content */}
              <div className="mt-6 flex justify-between gap-10">
                {/* Inputs */}
                <TemplateEditorInputs form={form} saveRef={saveRef} />

                {/* Preview */}
                {/* Some of the automatic data store updations are done from this component */}
                <TemplateEditorPreview form={form} />
              </div>
            </Card>

            {/* Right Side */}
            <Card extra={'w-[30%] relative p-4 overflow-auto'}>
              {/* Components */}
              <TemplateEditorComponents />
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default TemplateEditor;
