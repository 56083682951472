import type { Logger } from '@engagespot/utils';

import type { Events } from '../modules/events';
import type {
  CreateNotification,
  Notification,
} from '../services/notificationService';

type RealtimeNotificationData = Notification & {
  created_at: string;
  description: string;
};

type RealtimeNotificationObject = {
  notification: RealtimeNotificationData;
};

type RealTimeNotificationId = {
  id: string;
};

type RealTimeNotificationUnreadCount = {
  unreadNotificationCount: number;
};

type RealTimeNotificationVoidData = void;

type RealTimeNotificationData =
  | RealtimeNotificationObject
  | RealTimeNotificationId
  | RealTimeNotificationUnreadCount
  | RealTimeNotificationVoidData;

export type RealTimeMessage = {
  data: RealTimeNotificationData;
};
type NewNotificationEvent = {
  name: 'NEW_NOTIFICATION';
  data: RealtimeNotificationObject;
};

type NotificationDeletedEvent = {
  name: 'NOTIFICATION_DELETED';
  data: RealTimeNotificationId;
};

type NotificationSeenEvent = {
  name: 'NOTIFICATION_SEEN';
  data: RealTimeNotificationUnreadCount;
};

type AllNotificationDeletedEvent = {
  name: 'ALL_NOTIFICATION_DELETED';
  data: void;
};

type AllNotificationMarkAsReadEvent = {
  name: 'ALL_NOTIFICATION_READ';
  data: void;
};

type NotificationReadEvent = {
  name: 'NOTIFICATION_READ';
  data: RealTimeNotificationId;
};

type NotificationInteractedEvent = {
  name: 'NOTIFICATION_INTERACTED';
  data: RealtimeNotificationData;
};

type RealTimeEventProps =
  | NotificationReadEvent
  | NewNotificationEvent
  | NotificationDeletedEvent
  | NotificationSeenEvent
  | NotificationInteractedEvent
  | AllNotificationDeletedEvent
  | AllNotificationMarkAsReadEvent;

export type RealTimeEvent =
  | 'NEW_NOTIFICATION'
  | 'NOTIFICATION_DELETED'
  | 'NOTIFICATION_SEEN'
  | 'ALL_NOTIFICATION_DELETED'
  | 'ALL_NOTIFICATION_READ'
  | 'NOTIFICATION_INTERACTED'
  | 'NOTIFICATION_READ';

type HandleIncomingMessageOptions = {
  log: Logger;
  events: Events;
  createNotification: CreateNotification;
};

/**
 * Incoming Realtime Message Handler. This function is used by realtimeConnect() function
 */
export const handleIncomingRealtimeMessage = (
  message: RealTimeEventProps,
  { log, events, createNotification }: HandleIncomingMessageOptions,
) => {
  log.info(`Received ${message.name} event with`, message);

  switch (message.name) {
    case 'NEW_NOTIFICATION': {
      const notification = message.data?.notification;
      const transformedNotification = createNotification(notification);
      events.notificationReceive.fire({
        notification: transformedNotification,
      });
      break;
    }

    case 'ALL_NOTIFICATION_DELETED': {
      events.notificationDeleteAll.fire();
      break;
    }

    case 'NOTIFICATION_DELETED': {
      events.notificationDelete.fire({
        id: message?.data?.id,
      });
      break;
    }

    case 'NOTIFICATION_READ': {
      events.notificationRead.fire({
        id: message?.data?.id,
      });
      break;
    }

    case 'ALL_NOTIFICATION_READ': {
      events.notificationReadAll.fire();
      break;
    }

    case 'NOTIFICATION_SEEN': {
      events.notificationSeen.fire({
        unseenCount: message?.data?.unreadNotificationCount,
      });
      break;
    }

    case 'NOTIFICATION_INTERACTED': {
      events.notificationStateChange.fire({
        notification: message?.data,
      });
      break;
    }
  }
};
