import { useStore } from '@nanostores/react';

import { useInternalInstance } from './useInstance';

export type { EngagespotWebPushPermissionState } from '@engagespot/store';

export function useWebPush() {
  const {
    webpush: { stores, actions },
  } = useInternalInstance();

  const { $webPushFetcher, $webPushState } = stores;

  const { subscribe, clearWebPushSubscription, isSupported } = actions;

  const metadata = useStore($webPushFetcher);
  const webPushState = useStore($webPushState);

  return {
    metadata,
    subscribe,
    clearWebPushSubscription,
    isSupported,
    webPushState,
  };
}
