import type { Logger } from '@engagespot/utils';

type ErrorObject = {
  code: string;
  message: string;
  severity: 'error' | 'warn';
};

type ErrorCodes<T extends Record<string, object>> = Record<
  keyof T,
  ErrorObject
>;

export const createErrorHandlerWithErrorCodes = <T extends ErrorCodes<T>>(
  errorCodes: T,
  log: Logger,
) => {
  const handleError = <U extends keyof ErrorCodes<T>>(
    errorType: U,
    additionalParams?: Parameters<typeof console.log>,
  ) => {
    const { code, message, severity } = errorCodes[errorType];
    const errorMessage = `[${code}]: ${message}`;
    const additionalInfo = additionalParams ?? '';

    log[`${severity}Always`](errorMessage, additionalInfo.toString());
  };

  return handleError;
};
