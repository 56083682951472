import * as React from 'react';
import { useDisclosure } from '@chakra-ui/hooks';
import useGetClientApps from 'api/client/get-client-apps';
import { SelectOptions } from 'components/fields/SelectField/types';
import { useAuthStore } from 'store/authStore';
import { usePlanLimit } from 'utils/use-plan-limit';
import { useIsOldVersion } from 'utils/use-old-version';
import { createCommandbarActionEvent } from 'commandbar';
import NavbarModal from './modal';

export const [createAppAction, useCreateApp] =
  createCommandbarActionEvent('createApp');
export const [switchAppAction, useSwitchApp] =
  createCommandbarActionEvent('switchApp');

interface AppSwitcherContextType {
  selectedApp: SelectOptions | undefined;
  apps: SelectOptions[] | undefined;
  onCreateApp: () => void;
  onAppSelect: (app: SelectOptions) => void;
  isOldVersion: boolean;
}

const AppSwitcherContext = React.createContext<
  AppSwitcherContextType | undefined
>(undefined);

export function AppProvider({ children }: { children: React.ReactNode }) {
  const planLimit = usePlanLimit();
  const {
    isOpen: modalIsOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const { clientId, setAppId, appId } = useAuthStore(state => state);
  const getClientApps = useGetClientApps(clientId);
  const { isOldVersion } = useIsOldVersion();

  const [selectedApp, setSelectedApp] = React.useState<SelectOptions>();

  const apps = getClientApps?.data?.reduce((acc, curr) => {
    return [
      ...acc,
      {
        label: curr.name,
        value: String(curr.id),
      },
    ];
  }, [] as SelectOptions[]);

  const handleAddApp = React.useCallback(() => {
    if (planLimit.limits.noOfApps <= getClientApps.data.length) {
      planLimit.actions.onOpen();
      return planLimit.actions.setText(planLimit.texts.no_exceeded);
    }

    onModalOpen();
  }, [
    onModalOpen,
    getClientApps?.data?.length,
    planLimit.actions,
    planLimit.limits?.noOfApps,
    planLimit.texts?.no_exceeded,
  ]);

  const onCreateApp = React.useCallback(() => {
    handleAddApp();
  }, [handleAddApp]);

  const onAppSelect = React.useCallback(
    (app: SelectOptions) => {
      setSelectedApp(app);
      setAppId(Number(app.value));
    },
    [setAppId, setSelectedApp],
  );

  useSwitchApp(onAppSelect);
  useCreateApp(onCreateApp);

  React.useEffect(() => {
    if (getClientApps.isLoading) return;

    const currentApp = getClientApps?.data?.find(app => app.id === appId);

    if (currentApp) {
      setSelectedApp({
        label: String(currentApp.name),
        value: String(currentApp.id),
      });
    }
  }, [appId, getClientApps?.data, getClientApps.isLoading]);

  const value = React.useMemo(() => {
    return {
      selectedApp,
      apps,
      onCreateApp,
      onAppSelect,
      isOldVersion,
    };
  }, [selectedApp, apps, onAppSelect, onCreateApp, isOldVersion]);

  return (
    <AppSwitcherContext.Provider value={value}>
      <NavbarModal
        setSelectedApp={setSelectedApp}
        isOpen={modalIsOpen}
        onClose={onModalClose}
      />
      {children}
    </AppSwitcherContext.Provider>
  );
}

export function useApp() {
  const context = React.useContext(AppSwitcherContext);
  if (context === undefined) {
    throw new Error(
      'useAppSwitcher must be used within an AppSwitcherProvider',
    );
  }
  return context;
}
