import type { ApiPaths } from '@engagespot/api-client';

import type { InitServiceFn, ServiceArgs } from '../modules/service';

type NotificationServiceArgs = ServiceArgs<'connectService'>;

type GetNotificationParams =
  ApiPaths['/v3/notifications']['get']['parameters']['query'];

export type Notification = NonNullable<
  ApiPaths['/v3/notifications']['get']['responses']['200']['content']['application/json']['data']
>[number];

export type Channels = Exclude<
  NonNullable<
    ApiPaths['/v3/preferences']['get']['responses']['200']['content']['application/json'][number]['channelPreferences']
  >[number]['channel'],
  undefined
>;

export type NotificationState = NonNullable<Notification['state']>;

export type CreateNotification = ReturnType<
  typeof notificationService
>['api']['createNotification'];

type ParamsById =
  | string
  | {
      id: string;
    };

const extractId = (id: ParamsById) => {
  if (typeof id === 'string') return id;
  const notificationId = id.id;
  return notificationId;
};

export function notificationService({
  dependencies: { apiClient },
}: NotificationServiceArgs) {
  const createNotification = (notification: Notification) => {
    return notification;
  };

  const getById = async (id: ParamsById) => {
    const notificationId = extractId(id);
    const response = await apiClient.GET('/v3/notifications/{notificationId}', {
      params: {
        path: {
          notificationId,
        },
      },
    });
    return response;
  };

  /**
   * Returns all notifications from current page
   * @returns
   */
  const getByPage = async (
    {
      pageNo = 1,
      limit = 15,
      category,
      filter,
      tenantIdentifier,
    }: GetNotificationParams = {
      pageNo: 1,
      limit: 15,
    },
  ) => {
    const response = await apiClient.GET('/v3/notifications', {
      params: {
        query: {
          pageNo,
          limit,
          category,
          tenantIdentifier,
          filter,
        },
      },
    });

    return response;
  };

  /**
   * Mark notification as read
   * @param notificationId
   * @returns
   */
  const markAsRead = async (id: ParamsById) => {
    const notificationId = extractId(id);
    const response = await apiClient.POST(
      '/v3/notifications/{notificationId}/click',
      {
        params: {
          path: {
            notificationId,
          },
        },
      },
    );

    return response;
  };

  /**
   * Remove notification by id
   * @param id
   * @returns
   */
  const remove = async (id: ParamsById) => {
    const notificationId = extractId(id);
    const response = await apiClient.DELETE(
      '/v3/notifications/{notificationId}',
      {
        params: { path: { notificationId } },
      },
    );

    return response;
  };

  /**
   * Marks all notifications as seen
   * TODO(hemandev): Fix it properly
   */
  const markAllAsSeen = async ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => {
    const response = await apiClient.POST(
      '/v3/notifications/markAllNotificationsAsSeen',
      {
        params: {
          query: {
            limit,
            page,
          },
        },
        // response is coming as text for some reason
        parseAs: 'text',
      },
    );

    return response;
  };

  /**
   * Marks all notifications as read
   */
  const markAllAsRead = async () => {
    const response = await apiClient.POST(
      '/v3/notifications/markAllNotificationsAsRead',
      {
        // response is coming as text for some reason
        parseAs: 'text',
      },
    );
    return response;
  };

  /**
   * Remove all notification
   */
  const removeAll = async () => {
    const response = await apiClient.POST(
      '/v3/notifications/markAllNotificationsAsDeleted',
      {
        // response is coming as text for some reason
        parseAs: 'text',
      },
    );

    return response;
  };

  const changeState = async ({ id, state }: { id: string; state: string }) => {
    const response = await apiClient.PUT(
      '/v3/notifications/{notificationId}/state',
      {
        params: {
          path: {
            notificationId: id,
          },
        },
        body: { state },
      },
    );

    return response;
  };

  const returnValues = {
    getByPage,
    getById,
    markAsRead,
    remove,
    removeAll,
    markAllAsSeen,
    markAllAsRead,
    changeState,
    createNotification,
  };
  return {
    api: { ...returnValues },
    publicApi: { ...returnValues },
  };
}

notificationService.key = 'notificationService' as const;

export const initNotificationService = <T extends InitServiceFn>(
  initService: T,
) => {
  const app = initService({
    key: 'notificationService',
    requiredServiceKeys: ['connectService'],
  });
  return app;
};
