import type { StoreInstance, StoreOptions } from '@engagespot/store';
import { createLogger } from '@engagespot/utils';
import { createContext, type ReactNode } from 'react';

import { useConnect } from '../hooks/useConnect';
import { useStoreInstance } from '../hooks/useStoreInstance';
import { useWebPush } from '../hooks/useWebPush';

export type EngagespotProviderProps = {
  options: StoreOptions;
  children: ReactNode;
};

export const EngagespotContext = createContext<StoreInstance>(
  {} as StoreInstance,
);

export function EngagespotProvider({
  options,
  children,
}: EngagespotProviderProps) {
  const log = createLogger({
    debug: options.debug,
    prefix: '[Engagespot Hooks]',
    debugPrefix: '[DEBUG]',
  });

  const instance = useStoreInstance(options, { log });

  log.info('Initializing hooks with ', options);

  return (
    <EngagespotContext.Provider value={instance}>
      <>
        {instance && <EngagespotConnector />}
        {children}
      </>
    </EngagespotContext.Provider>
  );
}

/**
 * Hack to avoid the rules of hooks
 */
function EngagespotConnector() {
  useConnect();
  useWebPush();
  return null;
}
