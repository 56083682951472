import type { Placement } from '@floating-ui/react';
import {
  arrow,
  flip,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import React from 'react';

import { useIsMobile } from './useIsMobile';
import { useEngagespotContext } from '@/engagespotProvider';

export const usePopover = ({
  isOpen,
  setIsOpen,
  placement = 'bottom-end',
  onOpenChange,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  placement?: Placement;
  onOpenChange: (isOpen: boolean) => void;
}) => {
  const { panel } = useEngagespotContext();
  const arrowRef = React.useRef(null);
  const isMobile = useIsMobile();
  const highestZIndex = 99999999999999;

  const mobileStyles = {
    poper: {
      position: 'fixed' as const,
      top: 0,
      left: 0,
      zIndex: highestZIndex,
      width: '100%',
      height: '100%',
    },
    panel: { height: '100%', width: '100%', borderRadius: '0' },
    arrow: { display: 'none' },
  };

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement,
    open: isOpen,
    onOpenChange,
    middleware: [
      offset(isMobile ? 0 : 10),
      flip(),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    enabled: !panel?.disableDismiss,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
  ]);

  return {
    isOpen,
    setIsOpen,
    referenceProps: {
      setRef: refs.setReference,
      getReferenceProps: () => getReferenceProps(),
    },
    floatingProps: {
      setRef: refs.setFloating,
      getFloatingProps: () => getFloatingProps(),
      styles: {
        ...(isMobile ? mobileStyles.poper : floatingStyles),
        zIndex: highestZIndex,
      },
    },
    panelProps: {
      styles: {
        ...(isMobile && {
          ...mobileStyles.panel,
        }),
      },
    },
    middlewareProps: {
      arrow: {
        setRef: arrowRef,
        middlewareData: middlewareData.arrow,
        styles: {
          ...(isMobile && {
            ...mobileStyles.arrow,
          }),
        },
      },
    },
  };
};
