import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { Channels } from 'api/channels/get-channels/types';
import usePostOnboarding from 'api/get-started/post-onboarding';
import { routes } from 'api/routes';
import useGetUserDetails from 'api/users/get-users-details';
import { GetUserResult } from 'api/users/get-users/types';
import usePutAddUsers from 'api/users/put-add-users';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import { TextEditor } from 'components/text-editor';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { FormAddUsers } from '../types';
import {
  SelectSearchField,
  useSelectSearch,
} from 'components/fields/SelectSearchField';
import { GetLocales } from 'api/translations/types';
import { useGetLocales } from 'api/translations';

function AddUserModal({
  isOpen,
  onClose,
  selectedUser,
  setSelectedUser,
  requiredField,
  currentOnboardinStep = null,
  handleTestChannel,
  selectedChannel,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedUser?: GetUserResult;
  setSelectedUser?: React.Dispatch<React.SetStateAction<GetUserResult>>;
  requiredField?: string;
  currentOnboardinStep?: number;
  handleTestChannel?: (
    channel: Channels,
    user?: GetUserResult,
  ) => Promise<void>;
  selectedChannel?: Channels;
}) {
  const [loading, setLoading] = React.useState(false);
  const putAddUsers = usePutAddUsers();
  const toast = useCustomToast();
  const [filter, setFilter] = React.useState('');
  const getLocales = useGetLocales({ search: filter });
  const postOnboarding = usePostOnboarding();
  const { appId } = useAuthStore(state => state);
  const queryClient = useQueryClient();
  const getUserDetails = useGetUserDetails({
    identifier: selectedUser?.identifier,
    enabled: isOpen,
  });
  const requiredFieldErrorMessage =
    'This field is required to send notifications from this channel';

  const schema = yup.object({
    ...(!selectedUser && {
      identifier: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_.@-]*$/,
          'Identifier can only contain letters, numbers, and underscores',
        )
        .required('identifier is required'),
    }),

    email: yup.string().trim().email('must be a valid email'),

    phoneNumber: yup.string().trim(),

    ...(Boolean(requiredField) &&
      (requiredField === 'email'
        ? {
            email: yup
              .string()
              .trim()
              .email('must be a valid email')
              .required(requiredFieldErrorMessage),
          }
        : {
            [requiredField]: yup.string().required(requiredFieldErrorMessage),
          })),
  });

  const defaultValues = {
    identifier: '',
    email: '',
    custom_data: `{}`,
    locale: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setError,
    clearErrors,
  } = useForm<FormAddUsers>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (getUserDetails.data && selectedUser && isOpen && !loading) {
      const {
        email,
        phoneNumber,
        webhookUrl,
        slack,
        discord,
        locale,
        ...rest
      } = getUserDetails.data;

      reset({
        email: email,
        phoneNumber: phoneNumber,
        webhookUrl,
        custom_data: JSON.stringify(rest, undefined, 4),
        ...(slack?.webhookUrl && {
          slack: slack.webhookUrl,
        }),
        ...(discord?.webhookUrl && {
          discord: discord.webhookUrl,
        }),
        locale: locale ? locale : '',
      });

      if (Boolean(requiredField)) {
        setError(requiredField as any, {
          message: requiredFieldErrorMessage,
        });
      }
    }
  }, [
    loading,
    reset,
    selectedUser,
    isOpen,
    getUserDetails.data,
    setError,
    requiredField,
  ]);

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const onSubmit: SubmitHandler<FormAddUsers> = values => {
    // for some reason, onSubmit resets errors.custom_data
    // so we manually just enter it again
    if (errors.custom_data) {
      return setError('custom_data', {
        message: errors.custom_data.message,
      });
    }

    const customData = JSON.parse(values.custom_data);
    const { custom_data, slack, discord, locale, ...necessaryValues } = values;

    const data = {
      ...necessaryValues,
      ...customData,
      appId,
      ...(selectedUser && {
        identifier: selectedUser.identifier,
      }),
      ...(slack && {
        slack: {
          webhookUrl: slack,
        },
      }),
      ...(discord && {
        discord: {
          webhookUrl: discord,
        },
      }),
      ...(locale && {
        locale,
      }),
    };

    setLoading(true);

    putAddUsers.mutate(data, {
      onSuccess: async response => {
        const { data } = response;
        setSelectedUser?.(data);
        toast.success(
          `user ${selectedUser ? 'edited' : 'added'}  successfully`,
        );

        if (requiredField && selectedChannel) {
          try {
            await handleTestChannel?.(selectedChannel);
          } catch (error) {
            toast.showError(error);
            setLoading(false);
          }
        }

        if (currentOnboardinStep && currentOnboardinStep < 2) {
          return postOnboarding.mutate(
            {
              step: 2,
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries([routes['onboarding']]);
                setLoading(false);
                handleClose();
              },
              onError: error => {
                toast.showError(error);
                setLoading(false);
              },
            },
          );
        } else {
          setLoading(false);
          handleClose();
        }

        setLoading(false);
      },
      onError: error => {
        setLoading(false);
        toast.showError(error);
      },
    });
  };

  const createLocaleOptions = (item: GetLocales['results'][0]) => {
    return {
      label: `${item.localeCode}`,
      value: `${item.localeCode}`,
    };
  };

  const { setSelectedOption, options } = useSelectSearch<
    GetLocales['results'][0]
  >({
    results: getLocales.data?.results,
    isLoading: getLocales.isLoading,
    createOptions: createLocaleOptions,
  });

  return (
    <CustomModal
      modalContentClassName="!min-w-[800px] !top-[8%]"
      modalCardClassName="!max-w-[800px] !max-h-[788px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-3 text-2xl font-bold">
        {selectedUser ? 'Edit' : 'Add'} User
      </h1>
      <p className="mb-6 text-sm text-[#BFBFBF]">
        Users(recipients) are usually created programatically from your backend.
        Read{' '}
        <a
          href="https://docs.engagespot.com/docs/features/user/who-is-user"
          className="text-decoration-line: underline"
          target="blank"
        >
          this guide
        </a>
      </p>

      <form className="" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-x-4 gap-y-4 mb-4">
          {!selectedUser && (
            <div>
              {/* Identifier */}
              <InputField<FormAddUsers>
                variant="modal"
                label={'Identifier'}
                placeholder="Unique id used to identify this user in your system"
                showIsRequiredAsterisk={true}
                type="text"
                register={register}
                name={'identifier'}
                helperText="This will be used to uniquely identify the user"
              />
              {errors.identifier && <ErrorBox error={errors.identifier} />}
            </div>
          )}

          {/* Email */}
          <div>
            <InputField<FormAddUsers>
              variant="modal"
              label={'Email'}
              placeholder="Email id of the user. Eg: user@yahoomail.com"
              helperText="Email Notifications will be send to this email address"
              type="text"
              register={register}
              name={'email'}
            />
            {errors.email && <ErrorBox error={errors.email} />}
          </div>

          {/* Phone */}
          <div>
            <InputField<FormAddUsers>
              variant="modal"
              label={'Phone'}
              placeholder="Phone number in international E.164 format. Eg: +16175551212"
              helperText="SMS will be send to this phone number"
              type="text"
              register={register}
              name={'phoneNumber'}
            />
            {errors.phoneNumber && <ErrorBox error={errors.phoneNumber} />}
          </div>

          {/* Slack */}
          <div>
            <InputField<FormAddUsers>
              variant="modal"
              label={'Slack Webhook Url'}
              placeholder="Webhook url of your Slack app"
              type="text"
              register={register}
              name={'slack'}
              helperText="Slack messages will be send to the slack channel with this url"
            />
            {errors.slack && <ErrorBox error={errors.slack} />}
          </div>

          {/* Discord */}
          <div>
            <InputField<FormAddUsers>
              variant="modal"
              label={'Discord Webhook Url'}
              placeholder="Webhook url of your Discord channel"
              type="text"
              register={register}
              name={'discord'}
              helperText="Discord messages will be send to the channel with this url"
            />
            {errors.discord && <ErrorBox error={errors.discord} />}
          </div>

          {/* Webhook */}
          <div>
            <InputField<FormAddUsers>
              variant="modal"
              label={'Webhook Url'}
              placeholder="Webhook url the user"
              type="text"
              register={register}
              name={'webhookUrl'}
              helperText="Default Webhook notifications will be send to this url"
            />
            {errors.webhookUrl && <ErrorBox error={errors.webhookUrl} />}
          </div>

          {/* locale */}
          <div className="">
            <Controller
              name="locale"
              control={control}
              render={({ field }) => (
                <SelectSearchField
                  value={field.value}
                  label="Locale"
                  onChange={value => {
                    // reset filter
                    setFilter('');

                    // same select case
                    if (value === '') {
                      field.onChange('');
                      setSelectedOption(null);
                      return;
                    }

                    // new select case
                    const selectedLocale = getLocales.data?.results?.find(
                      locale => locale.localeCode === value,
                    );
                    const selectedOption = createLocaleOptions(selectedLocale);
                    field.onChange(value);
                    setSelectedOption(selectedOption);
                  }}
                  onBlur={field.onBlur}
                  dataOptions={options}
                  setFilter={setFilter}
                  filter={filter}
                  isFetching={getLocales.isFetching}
                  placeholder="Select Locale"
                  helperText={`Defines the user's regional location or setting. <a href="https://docs.engagespot.com/docs/features/translations/overview#how-to-set-users-locale" target="_blank" rel="noreferrer" style="color:#7d7f82;opacity:100%;border-bottom:1px dotted #7d7f82;padding:1px 0px;font-size:12px">Learn more</a>`}
                ></SelectSearchField>
              )}
            />
            {errors.locale && <ErrorBox error={errors.locale} />}
          </div>
        </div>

        <div>
          <Controller
            name="custom_data"
            control={control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => {
              return (
                <TextEditor<FormAddUsers>
                  {...field}
                  label="Custom Data (JSON Format)"
                  setError={setError}
                  clearErrors={clearErrors}
                  error={error}
                  editorBackground="#16171C"
                  helperText="You can add any additional custom profile attributes you want here"
                  height="100px"
                />
              );
            }}
          />
          {errors.custom_data && <ErrorBox error={errors.custom_data} />}
        </div>

        <CustomModalButtons
          handleClose={handleClose}
          loading={loading}
          primaryButtonText={
            requiredField ? 'Save & Send Test Notification' : 'Save'
          }
        />
      </form>
    </CustomModal>
  );
}

export default AddUserModal;
