import { zodResolver } from '@hookform/resolvers/zod';
import { formatISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import useGetChannels from 'api/channels/get-channels';
import { Button } from 'components/shadcn/button';
import { Form, FormField, FormItem, FormLabel } from 'components/shadcn/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/select';
import { events } from '../../variables';
import { DateRangeFilter, defaultDateRange } from './DateRange';
import { RecipientSelect } from './RecipientSelect';
import { LogFilters } from 'api/logs/get-logs';
import { usePaginationApiState } from 'components/table/pagination-state';

const logFilterFormSchemaSchema = z.object({
  channel: z.string().optional(),
  provider: z.string().optional(),
  event: z.string().optional(),
  userIdentifier: z.array(z.string()).optional(),
  dateRange: z.object({
    from: z.date(),
    to: z.date(),
  }),
});

export type LogFilterFormValues = z.infer<typeof logFilterFormSchemaSchema>;

export const defaultLogFilterValues: LogFilterFormValues = {
  channel: '',
  provider: '',
  event: '',
  userIdentifier: [],
  dateRange: defaultDateRange,
};

const {
  dateRange: { from, to },
  ...filters
} = defaultLogFilterValues;
export const defaultLogApiFilterValues: Omit<
  LogFilters,
  'pageIndex' | 'pageSize'
> = {
  ...filters,
  userIdentifier: [] as string[],
  fromDate: formatISO(new Date(from)),
  toDate: formatISO(new Date(to)),
};

export function MessageLogsFilters({
  setLogFilters,
}: {
  setLogFilters: React.Dispatch<
    React.SetStateAction<{
      fromDate: string;
      toDate: string;
      channel?: string;
      provider?: string;
      event?: string;
      userIdentifier?: string[];
    }>
  >;
}) {
  const { pagination } = usePaginationApiState();
  const form = useForm<LogFilterFormValues>({
    resolver: zodResolver(logFilterFormSchemaSchema),
    defaultValues: defaultLogFilterValues,
  });

  const handleClear = () => {
    form.reset({ ...defaultLogFilterValues, event: '' });
    setLogFilters({
      ...defaultLogApiFilterValues,
      ...pagination,
    });
  };

  const selectedChannel = form.watch('channel');
  const getChannels = useGetChannels();
  const configuredChannels = getChannels.data?.filter(
    channel => channel.configuredProviders?.length > 0,
  );

  const onSubmit = (values: LogFilterFormValues) => {
    const data = {
      fromDate: formatISO(new Date(values.dateRange.from)),
      toDate: formatISO(new Date(values.dateRange.to)),
      providerIdentifier: values.provider,
      channel: values.channel,
      event: values.event,
      userIdentifier: values.userIdentifier,
    };

    setLogFilters(data);
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-wrap items-center gap-4 text-white mt-6"
      >
        <div className="flex-1 min-w-[200px]">
          <FormField
            control={form.control}
            name="channel"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Channel</FormLabel>
                <Select
                  onValueChange={value => {
                    field.onChange(value);
                    form.setValue('provider', '');

                    /**
                     * If you want to set to select first Provider
                     */

                    // const firstProvider = configuredChannels?.find(
                    //   configuredChannel => configuredChannel.channel === value,
                    // )?.configuredProviders?.[0]?.identifier;

                    // setTimeout(() => {
                    //   form.setValue('provider', firstProvider, {
                    //     shouldValidate: true,
                    //   });
                    // }, 750);
                  }}
                  value={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Channel" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem className="capitalize" key={''} value={''}>
                      {'Select Channel'}
                    </SelectItem>

                    {configuredChannels?.map(configuredChannel => (
                      <SelectItem
                        key={configuredChannel.channel}
                        value={configuredChannel.channel}
                      >
                        {configuredChannel.channel}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1 min-w-[200px]">
          <FormField
            control={form.control}
            name="provider"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Provider</FormLabel>
                <Select
                  onValueChange={value => {
                    field.onChange(value);
                  }}
                  value={field.value}
                  disabled={!selectedChannel}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select Provider" />
                  </SelectTrigger>

                  <SelectContent>
                    <SelectItem className="capitalize" key={''} value={''}>
                      {'Select Provider'}
                    </SelectItem>

                    {selectedChannel &&
                      configuredChannels
                        ?.find(
                          configuredChannel =>
                            configuredChannel.channel === selectedChannel,
                        )
                        ?.configuredProviders?.map(provider => (
                          <SelectItem
                            key={provider.identifier}
                            value={provider.identifier}
                          >
                            {provider.identifier}
                          </SelectItem>
                        ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1 min-w-[200px]">
          <FormField
            control={form.control}
            name="event"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Event</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger className="capitalize">
                    <SelectValue placeholder="Event" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem className="capitalize" key={''} value={''}>
                      {'Select event'}
                    </SelectItem>

                    {events?.map(event => (
                      <SelectItem
                        className="capitalize"
                        key={event}
                        value={event}
                      >
                        {event.replaceAll('_', ' ')}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>

        <RecipientSelect form={form} />

        <DateRangeFilter form={form} />

        <div className="flex items-center gap-4 ml-auto">
          <Button
            type="button"
            variant="outline"
            onClick={handleClear}
            className="whitespace-nowrap"
          >
            Clear Filters
          </Button>
          <Button type="submit" className="whitespace-nowrap">
            Apply Filters
          </Button>
        </div>
      </form>
    </Form>
  );
}
