import NewCard from './NewCard';
import CreditCard from 'views/admin/dashboards/billing/components/CreditCard';
import useGetPaymentMethods from 'api/billing/get-payment-methods';
import { StripePromise } from '../types';
import useGetLicense from 'api/billing/get-license';
import { useAuthStore } from 'store/authStore';
import PageHeader from 'components/header/PageHeader';

function PaymentMethod({ promise }: { promise: StripePromise }) {
  const { clientId: clientUserId } = useAuthStore(state => state);
  const license = useGetLicense(clientUserId);
  const getPaymentMethods = useGetPaymentMethods(
    license.data?.InstanceClientIdentifer,
  );

  return (
    <>
      <PageHeader
        heading={'Payment Methods'}
        text={'Manage your saved payment options'}
        showSearchBar={false}
      />

      <div className="grid grid-cols-3 gap-6 px-[5px] md:px-40 xl:px-1">
        {getPaymentMethods.data?.data?.map(method => (
          <CreditCard
            key={method.id}
            number={`**** **** **** ${method?.card?.last4}`}
            brand={method?.card?.brand}
            paymentMethodId={method.id}
            isDefault={method.isDefault}
            expiryMonth={method?.card?.exp_month}
            expiryYear={method?.card?.exp_year}
          />
        ))}
        <NewCard promise={promise} />
      </div>
    </>
  );
}

export default PaymentMethod;
