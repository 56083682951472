import { useMutation } from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { PutChangePlanResponse } from './types';
import { routes } from 'api/routes';

type PutChangePlan = {
  plan_id: number;
  price_id: number;
};

const put = (values: PutChangePlan) => {
  const { plan_id, ...data } = values;

  return axiosLicensingInstance.put<PutChangePlanResponse>(
    `${routes['v1_plans']}/${plan_id}/subscription`,
    data,
  );
};

const usePutChangePlans = () => {
  return useMutation({
    mutationFn: put,
  });
};

export default usePutChangePlans;
