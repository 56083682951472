import type { ReactNode } from 'react';

import { NotificationHeaderStyled } from './NotificationHeader.styled';

export type NotificationHeaderProps = {
  children: ReactNode;
};

export function NotificationHeader({ children }: NotificationHeaderProps) {
  return <NotificationHeaderStyled>{children}</NotificationHeaderStyled>;
}
