import { WorkflowIcon, File, Workflow } from 'lucide-react';

import { Group } from './Group';
import { getGroup, renderLinkItems } from '../routes';
import { ActionItem, ListItem } from 'commandbar/itemTypes';
import { createTopicsAction } from 'views/admin/dashboards/topics';
import { createWorkflowAction } from 'views/admin/dashboards/workflow';

const { name, icon, children } = getGroup('Notifications');

const Workflows = {
  name: 'All Workflows',
  icon: WorkflowIcon,
  type: 'List',
};

export function Notification() {
  return (
    <Group heading={name} icon={icon}>
      {renderLinkItems(children ?? [], name)}
      <ListItem page="workflows" icon={Workflows.icon} group={name}>
        {Workflows.name}
      </ListItem>
      <ActionItem
        icon={File}
        path="/admin/topics"
        action={createTopicsAction}
        group={name}
      >
        Create Topic
      </ActionItem>
      <ActionItem
        icon={Workflow}
        path="/admin/workflow"
        action={createWorkflowAction}
        group={name}
      >
        Create Workflow
      </ActionItem>
    </Group>
  );
}
