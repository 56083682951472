import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SubscribableEvent from 'subscribableevent';

import { BaseItem, type BaseItemProps } from 'commandbar/itemTypes';
import { useCommandBar } from 'commandbar/provider';

const actionEventMap = new Map<
  string,
  SubscribableEvent<(...args: any[]) => void>
>();

export const createActionEvent = (name: string) => {
  if (!actionEventMap.has(name)) {
    actionEventMap.set(name, new SubscribableEvent<(...args: any[]) => void>());
  }
  const event = actionEventMap.get(name);

  function useActionEvent(cb: (...args: any[]) => void) {
    useEffect(() => {
      const token = event.subscribe(cb);

      return () => {
        token.unsubscribe();
      };
    }, [cb]);
  }

  return [event, useActionEvent] as const;
};

type Action = ReturnType<typeof createActionEvent>[0];

export type ActionItemProps = Omit<BaseItemProps, 'onSelect' | 'type'> & {
  path?: string;
  action: Action;
  args?: any[];
};

export function ActionItem({
  action,
  children,
  icon,
  path,
  args = [],
  keywords,
  ...props
}: ActionItemProps) {
  const navigate = useNavigate();

  const { close, clearInput } = useCommandBar();

  return (
    <BaseItem
      {...props}
      keywords={keywords}
      icon={icon}
      type="Action"
      onSelect={() => {
        path && navigate(path);
        // Hack :/ Allow time to navigate to the correct route and hydrate the component
        setTimeout(() => {
          action.fire(...args);
        }, 100);
        clearInput();
        close();
      }}
    >
      {children}
    </BaseItem>
  );
}
