import Calendar from '@assets/svg/billing/calendar.svg';
import CommentInfo from '@assets/svg/billing/comment-info.svg';
import SpeedoMeter from '@assets/svg/billing/speedo-meter.svg';
import useGetPlanUsage from 'api/billing/get-plan-usage';
import Card from 'components/card';
import PageHeader from 'components/header/PageHeader';
import { CustomHoverCard } from 'components/shadcn/hover-card';
import { format, fromUnixTime, parseISO } from 'date-fns';
import React from 'react';
import { cn } from 'utils/class-merge';
import { getAbbreviateNumber } from 'utils/get-abbreviate-format';
import { useGetCurrentBillingPlanDetails } from '../hooks';
import { converCentsToDollars } from '../utils';
import { BILLING_SCHEME, USAGE_TYPE } from '../enums';

const UsuageBar = ({
  widthPercentage,
  exceededPlanLimits,
}: {
  widthPercentage: number;
  exceededPlanLimits: boolean;
}) => {
  const maxContainerWidthPercentage = 100;
  const width =
    Number(widthPercentage) < maxContainerWidthPercentage
      ? widthPercentage
      : maxContainerWidthPercentage;

  return (
    <div className="h-3 w-full rounded-full  bg-white">
      <div
        style={{
          width: `${width}%`,
          backgroundColor: exceededPlanLimits ? '#F53939' : '#0FB57D',
        }}
        className={`h-full rounded-full`}
      />
    </div>
  );
};

const UsageHeading = ({
  heading,
  info,
  className,
}: {
  heading: string;
  info?: string;
  className?: string;
}) => {
  return (
    <div className="flex gap-2 items-center">
      <h5 className={cn('font-medium text-lg text-[#ABB1B8]', className)}>
        {heading}
      </h5>
      {info && (
        <CustomHoverCard
          children={<img className="cursor-pointer" src={CommentInfo} alt="" />}
          content={<p className="text-sm">{info}</p>}
        />
      )}
    </div>
  );
};

const UsageData = ({
  data,
  className,
}: {
  data: string;
  className?: string;
}) => {
  return <p className={cn('text-lg font-semibold', className)}>{data}</p>;
};

const UsageCard = ({
  icon,
  heading,
  headingInfo,
  content,
  headingClassName,
}: {
  icon: string;
  heading: string;
  headingInfo?: string;
  content?: React.ReactNode;
  headingClassName?: string;
}) => {
  return (
    <Card
      extra={
        'p-7 flex flex-col gap-4 border border=[#3A3F47] rounded-[3px] mb-6'
      }
    >
      <img height={30} width={30} src={icon} alt="" />

      <UsageHeading
        className={headingClassName}
        heading={heading}
        info={headingInfo}
      />

      {content}
    </Card>
  );
};

export const Usage = () => {
  const getPlanUsage = useGetPlanUsage();
  const planUsage = getPlanUsage.data;
  const { subscription } = useGetCurrentBillingPlanDetails();

  // Plan price
  const licensedBillingPrice = planUsage?.lines?.data?.find(
    item => item.plan?.usage_type === USAGE_TYPE['LICENSED'],
  );
  const freePlanMeteredBillingPrice = planUsage?.lines?.data?.find(
    item => item.plan?.usage_type === USAGE_TYPE['METERED'],
  );
  const billingPrice = licensedBillingPrice || freePlanMeteredBillingPrice;
  const billingPriceAmount = converCentsToDollars(Number(billingPrice?.amount));

  const discount = converCentsToDollars(
    Number(getPlanUsage.data?.lines?.data[0]?.discount_amounts[0]?.amount || 0),
  );
  const discountedPrice = Number((billingPriceAmount - discount).toFixed(2));

  console.log('DiscountedPrice is ', discountedPrice);

  // per plan triggers
  const planTriggers = subscription?.plan?.metadata?.limits?.triggers;
  const abbrevPlanTriggers = getAbbreviateNumber(planTriggers);

  // used triggers
  const usedTriggers = getPlanUsage.data?.uniquePrices?.find(
    uniqPrice => uniqPrice?.price?.billing_scheme === BILLING_SCHEME['TIERED'],
  )?.totalQuantity;
  const abbrevUsedTriggers = getAbbreviateNumber(usedTriggers, 3);

  // renew date and amount
  const amountRemaining = converCentsToDollars(
    getPlanUsage.data?.amount_remaining,
  );

  console.log('amountRemaininig is ', amountRemaining);

  const nextPaymentDate = getPlanUsage.data?.next_payment_attempt;
  const nextPaymentDateUnix = Boolean(nextPaymentDate)
    ? fromUnixTime(nextPaymentDate)
    : null;
  const formattedNextPaymentDate = Boolean(nextPaymentDateUnix)
    ? format(nextPaymentDateUnix, 'dd-MMM-yyyy')
    : '--';

  // overage triggers
  const overageTriggers = usedTriggers - planTriggers;
  const hasOverageTriggers = overageTriggers > 0;
  const abbrevOverageTriggers = hasOverageTriggers
    ? `${getAbbreviateNumber(overageTriggers, 3)}`
    : '--';

  // overage charge
  const overageCharge = (amountRemaining - discountedPrice).toFixed(2);

  // usage percentage
  const exceededPlanLimits = Number(usedTriggers) > Number(planTriggers);
  const planUsagePercentage =
    (Number(usedTriggers) / Number(planTriggers)) * 100;

  // cancellation date
  const cancellationDate = subscription?.endsAt;
  const cancellationDateISO = Boolean(cancellationDate)
    ? parseISO(cancellationDate)
    : null;
  const formattedCancellationDate = Boolean(cancellationDateISO)
    ? format(cancellationDateISO, 'dd-MMM-yyyy')
    : '--';

  return (
    <div>
      <PageHeader
        heading={'Usage'}
        text={'Your usage will renew with each billing cycle'}
        showSearchBar={false}
      />

      {/* Items container */}
      <div className="grid grid-cols-3 gap-6 items-center">
        {/* No of Triggers */}
        <UsageCard
          heading="No of Triggers"
          headingInfo="Usage data updates may be delayed by a few minutes."
          icon={SpeedoMeter}
          content={
            <>
              <p className="f;nt-semibold text-xl ">
                {abbrevUsedTriggers} / {abbrevPlanTriggers}{' '}
                {hasOverageTriggers && (
                  <span className="text-xs text-yellow-400">
                    ({abbrevOverageTriggers} triggers above plan limit)
                  </span>
                )}
              </p>
              <UsuageBar
                widthPercentage={planUsagePercentage}
                exceededPlanLimits={exceededPlanLimits}
              />
            </>
          }
        />

        {/* Overage Billing */}
        <UsageCard
          heading="Overage Billing"
          headingInfo="Over usage after plan limit"
          icon={SpeedoMeter}
          content={
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-2">
                <UsageHeading
                  className="text-sm"
                  heading={'Overage Triggers'}
                />
                <UsageData data={abbrevOverageTriggers} />
              </div>

              <div className="flex flex-col gap-2">
                <UsageHeading className="text-sm" heading={'Overage Charge'} />
                <UsageData
                  data={Boolean(overageCharge) ? `+$${overageCharge}` : '--'}
                />
              </div>
            </div>
          }
        />

        {/* Renew at */}
        {Boolean(cancellationDate) ? (
          <UsageCard
            heading="Cancels at"
            headingInfo="Plan Cancel Date"
            icon={Calendar}
            content={
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-2">
                  <UsageHeading
                    className="text-sm"
                    heading={'Cancellation Date'}
                  />
                  <UsageData
                    className="text-red-500"
                    data={formattedCancellationDate}
                  />
                </div>
              </div>
            }
          />
        ) : (
          <UsageCard
            heading="Renew at"
            headingInfo="Plan Renewal Date"
            icon={Calendar}
            content={
              <div className="flex justify-between items-center">
                <div className="flex flex-col gap-2">
                  <UsageHeading className="text-sm" heading={'Renew Date'} />
                  <UsageData data={formattedNextPaymentDate} />
                </div>

                <div className="flex flex-col gap-2">
                  <UsageHeading className="text-sm" heading={'Renew Amount'} />
                  <UsageData
                    data={
                      Boolean(amountRemaining) ? `$${amountRemaining}` : '--'
                    }
                  />
                </div>
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};
