import { useDisclosure } from '@chakra-ui/hooks';
import { useGetLayoutDetails } from 'api/layouts';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { Button } from 'components/shadcn/button';
import { CustomHoverCard } from 'components/shadcn/hover-card';
import {
  resetDocument,
  useDocument,
} from 'email-editor/documents/editor/EditorContext';
import EMPTY_EMAIL_MESSAGE from 'email-editor/getConfiguration/sample/empty-email-message';
import { ChevronLeft, CirclePlus, LoaderPinwheel, Save } from 'lucide-react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useLayoutStore } from 'store/layoutStore';
import { useTemplateStore } from 'store/templateStore';
import { cn } from 'utils/class-merge';
import EmailPreview from '../templateEditor/components/preview/EmailPreview';
import LayoutDiffModal from './LayoutDiffModal';
import { checkDiff, stringifyJson } from 'utils/check-diff';
import { getHashidsInstance } from 'utils/use-hash-id';

const LayoutEditor = () => {
  const params = useParams();
  const hashId = getHashidsInstance();
  const layoutId = hashId.decode(params.layoutId);
  const getLayoutDetails = useGetLayoutDetails(Number(layoutId));
  const { loading } = useTemplateStore(state => state);
  const navigate = useNavigate();
  const form = useForm({
    mode: 'onChange',
  });
  const { setValue } = form;
  const [, setIsEmailDirty] = React.useState(false);
  const saveRef = React.useRef<HTMLButtonElement>(null);
  const emailLayoutRef = React.useRef<HTMLButtonElement>(null);
  const document = useDocument();

  const { setLayoutIdentifier, setSavedLayout, savedLayout } = useLayoutStore(
    state => state,
  );
  const hasPublishChanges = getLayoutDetails.data?.hasUnpublishedChanges;
  const layoutDiffModalActions = useDisclosure();
  const emailEditorRef = React.useRef(null);

  // saved changes check
  const { oldJson, newJson } = stringifyJson({
    oldValue: savedLayout ?? {},
    newValue: document ?? {},
    jsonSpacing: 4,
  });
  const hasSavedChanges = checkDiff({
    oldValue: oldJson,
    newValue: newJson,
  });

  const handleGoBack = () => {
    navigate(`/admin/layout`);
  };

  React.useEffect(() => {
    if (getLayoutDetails.isLoading) return;
    setValue(
      'jsonSpec',
      JSON.stringify(getLayoutDetails?.data?.jsonSpec ?? {}, null, 4),
    );
  }, [getLayoutDetails?.data?.jsonSpec, getLayoutDetails.isLoading, setValue]);

  React.useEffect(() => {
    return () => {
      setSavedLayout(null);
      setLayoutIdentifier(null);
      resetDocument(EMPTY_EMAIL_MESSAGE);
    };
  }, [setLayoutIdentifier, setSavedLayout]);

  return (
    <>
      <LayoutDiffModal
        isOpen={layoutDiffModalActions.isOpen}
        onClose={layoutDiffModalActions.onClose}
        layout={getLayoutDetails.data}
      />

      {/* Header */}
      <div className="border-b flex justify-between border-[#ABB0B84D] px-7 h-16 items-center">
        <div className="flex items-center gap-4">
          <button
            type="button"
            className="outline outline-input"
            onClick={handleGoBack}
          >
            <ChevronLeft className="text-white" />
          </button>

          {getLayoutDetails?.data?.name && (
            <h4 className="text-gray-600">
              Editing layout &gt; {getLayoutDetails?.data?.name}
            </h4>
          )}
        </div>

        <div className="flex items-center gap-2 flex-nowrap">
          {hasPublishChanges && (
            <div className="text-gray-600 flex gap-2 items-center text-xs w-[145%] flex-nowrap">
              <CustomHoverCard
                children={
                  <div className="flex items-center gap-2 cursor-default">
                    Unpublished changes
                    <LoaderPinwheel className="w-4 h-4 cursor-pointer" />
                  </div>
                }
                content={
                  <span className="flex items-center h-2 text-sm text-gray-600 w-fit">
                    Layout has unpublished changes
                  </span>
                }
              />
            </div>
          )}

          <CustomHoverCard
            children={
              <Button
                className="flex items-center py-1 border border-muted w-max"
                disabled={loading || !hasSavedChanges}
                type="button"
                onClick={() => saveRef.current?.click()}
              >
                <Save className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
                Save As Draft
                {loading && (
                  <LoaderSpinner
                    strokeColor="black"
                    parentClass="relative left-2"
                  />
                )}
              </Button>
            }
            content={
              !hasSavedChanges ? 'No changes to save' : 'Save changes to draft'
            }
          />

          <CustomHoverCard
            children={
              <Button
                className={cn(
                  'flex items-center py-1 border border-muted disabled:cursor-not-allowed',
                )}
                onClick={layoutDiffModalActions.onOpen}
                disabled={!hasPublishChanges}
              >
                <CirclePlus className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
                Publish
              </Button>
            }
            content={
              !hasPublishChanges ? 'No changes to publish' : 'Publish changes'
            }
          />
        </div>
      </div>

      <div className="w-full  h-[calc(100vh-58px)] text-black">
        <EmailPreview
          setIsEmailDirty={setIsEmailDirty}
          form={form}
          saveRef={saveRef}
          emailLayoutRef={emailLayoutRef}
          actingAsLayoutEditor={true}
          emailEditorRef={emailEditorRef}
        />
      </div>
    </>
  );
};

export default LayoutEditor;
