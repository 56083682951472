import type { Logger } from '@engagespot/utils';

import { createDependencies, type Dependencies } from './dependencies';
import { serviceMapper } from './serviceMapper';
import type {
  GetRequiredServiceKeys,
  GetServiceInstance,
  ServiceKeys,
  ServicesInstances,
} from './serviceMapperUtils';
import type { InstanceOptions } from '../createInstance';

export type Services = ServicesInstances;

type InitService<T extends ServiceKeys> = {
  key: T;
  requiredServiceKeys: GetRequiredServiceKeys<T>;
};

type BaseServiceArgs = {
  dependencies: Dependencies;
  requiredServices: any;
};

type BaseServiceReturn = {
  api: object;
  publicApi: object;
} & Record<string, any>;

export type InitServiceFn = ReturnType<typeof createInitService>['initService'];

type ServiceFn<T = BaseServiceArgs, U = BaseServiceReturn> = (args: T) => U;

export type Service<
  T = BaseServiceArgs,
  U = BaseServiceReturn,
  V = string,
> = ServiceFn<T, U> & {
  key: V;
};

export type ServiceArgs<T extends keyof Services = any> = {
  dependencies: Dependencies;
  requiredServices: Pick<Services, T>;
};

export const createInitService = (options: InstanceOptions, log: Logger) => {
  const serviceMap = new Map();
  const dependencies = createDependencies(options, log);

  const initService = <T extends ServiceKeys>({
    key,
    requiredServiceKeys = [],
  }: InitService<T>): GetServiceInstance<T>['publicApi'] => {
    if (serviceMap.has(key)) return serviceMap.get(key);

    const requiredServicesMap = requiredServiceKeys.map(requiredServiceKey => {
      if (!serviceMap.has(requiredServiceKey)) {
        throw new Error(
          `Service ${String(requiredServiceKey)} is required by ${key} but it is not yet instantiated.`,
        );
      }

      return [requiredServiceKey, serviceMap.get(requiredServiceKey)];
    });
    const requiredServices = Object.fromEntries(requiredServicesMap);

    const service = serviceMapper[key].service;
    const serviceInstance = service({
      dependencies,
      requiredServices,
    }) as GetServiceInstance<T>;

    serviceMap.set(key, serviceInstance);

    // This is okay since we have already explicity asserted that the serviceInstance is of type GetServiceInstance<T>
    return serviceInstance?.publicApi;
  };

  // return initService;
  return { initService, dependencies, serviceMap };
};

/**
 * A no-op function just to validate whether the service implements the correct signature.
 */
export const service = <T extends Service>(service: T): T => {
  return service;
};
