import styled, { css } from 'styled-components';

export type ButtonTypes = 'normal' | 'floating';

export type NotificationButtonStyledProps = {
  buttonType: ButtonTypes;
  hasError?: boolean;
};

export const NotificationButtonStyled = styled.button.withConfig({
  shouldForwardProp: prop => !['buttonType', 'hasError'].includes(prop),
})<NotificationButtonStyledProps>`
  && {
    ${({ theme: { notificationButton }, buttonType, hasError }) => css`
      display: flex;
      justify-items: center;
      align-content: center;
      box-shadow: ${buttonType === 'floating' &&
      notificationButton.floatingButtonShadow};
      border-width: ${notificationButton.borderWidth};
      border-radius: ${buttonType === 'floating'
        ? notificationButton.floatingButtonRadius
        : notificationButton.normalButtonRadius};
      margin: ${notificationButton.margin};
      padding: ${notificationButton.padding};
      box-sizing: border-box;
      outline: ${notificationButton.outline};
      user-select: none;
      cursor: pointer;
      position: relative;
      background-color: ${notificationButton.background};
      transition: ${notificationButton.transition};

      &:hover {
        background-color: ${notificationButton.hoverBackground};
      }

      svg {
        height: ${notificationButton.iconSize};
        width: ${notificationButton.iconSize};
        stroke: ${hasError ? 'red' : notificationButton.iconFill};
      }
    `}
`;
