import type { EngagespotNotification } from '@engagespot/core';

import { Container } from './components/container';
import { Wrapper } from './components/wrapper';
import { styleOfSection } from './constants';
import { componentMapping } from './mapping';
import type {
  ActionableElements,
  changeNotificationStateFnType,
  EventListenersToRun,
  TemplateBlock,
} from './types';
import {
  getComponentProps,
  getConfigGroupingBasedOnElements,
  getImgSrc,
} from './utils';

export type TemplateBlocksRenderProps = {
  blocks: TemplateBlock[];
  eventListenersToRun?: EventListenersToRun[];
  changeNotificationState?: changeNotificationStateFnType;
  notification?: EngagespotNotification;
  actionableElementsProps?: Partial<ActionableElements>;
};

const TemplateBlocksRender = ({
  blocks,
  eventListenersToRun,
  changeNotificationState,
  notification,
  actionableElementsProps,
}: TemplateBlocksRenderProps) => {
  const configGroupingBasedOnElements =
    getConfigGroupingBasedOnElements(blocks);

  return (
    <Wrapper>
      {Object.keys(configGroupingBasedOnElements).map(section => {
        const currentSection = configGroupingBasedOnElements?.[section];
        const firstElementInSection = currentSection?.[0];
        const typeOfSection = firstElementInSection?.type;

        return (
          <Container
            key={section}
            flexDirection={
              styleOfSection?.[typeOfSection] === 'row' ? 'row' : 'column'
            }
            // If only image is in section, hide container if no src, since Wrapper has flex-gap css property
            hidden={
              currentSection &&
              currentSection?.length === 1 &&
              firstElementInSection?.type === 'image' &&
              !getImgSrc(firstElementInSection?.src)
            }
          >
            {currentSection.map((config, idx) => {
              const uniqueId = `${notification?.id ? notification?.id + '_' : ''}eng_block_${config?.type}_${idx}`;

              const Component = componentMapping?.[config.type];
              if (!Component) return null;

              const props = getComponentProps({
                config,
                changeNotificationState,
                eventListenersToRun,
                notification,
                blocks,
                actionableElementsProps,
              });

              if (
                config.type === 'textarea' ||
                config.type === 'rating-star' ||
                config.type === 'image'
              ) {
                return <Component key={uniqueId} {...props} />;
              }

              return (
                <Component key={uniqueId} {...props}>
                  {config.text}
                </Component>
              );
            })}
          </Container>
        );
      })}
    </Wrapper>
  );
};

export { TemplateBlocksRender };
