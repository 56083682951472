import ErrorBox from 'components/error/ErrorBox';
import CheckField from 'components/fields/CheckField';
import FileField from 'components/fields/FileField';
import { FileInputSelect } from 'components/fields/FileInputSelect';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { TextEditor } from 'components/text-editor';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { TemplateTabs } from 'store/templateStore';
import {
  TemplateEditorAvailabeChannels,
  TemplateInputs,
} from '../variables/inputs';
import { AdditionalSettings } from './AdditionalSettings';

const TemplateEditorInputFields = (props: {
  option: TemplateInputs;
  tab: TemplateTabs;
  isBatchingEnabled: any;
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues, any>;
  channel: TemplateEditorAvailabeChannels;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
}) => {
  const {
    option,
    control,
    isBatchingEnabled,
    setValue,
    tab,
    channel,
    errors,
    register,
    clearErrors,
    setError,
  } = props;

  const normalFileInputSelect = (option: TemplateInputs) =>
    option.type === 'input|file';

  return (
    <div className="w-full" key={option.name}>
      {option.children && (
        <div className="flex justify-between gap-8">
          {option.children.map((childOption, index) => (
            <TemplateEditorInputFields
              key={`${childOption.name}_${index}`}
              {...props}
              option={childOption}
            />
          ))}
        </div>
      )}

      {option.type === 'input' && (
        <div className="w-full">
          <InputField<any>
            variant="auth"
            label={option.label}
            placeholder={option.placeholder}
            type="text"
            showIsRequiredAsterisk={Boolean(option.required)}
            register={register}
            name={option.name}
            extraLabelClass={'font-medium'}
            extraInputClass={`border dark:bg-night-100 focus:border border-[#525151]`}
            required={Boolean(option.required)}
            disabled={
              tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false
            }
            extra={''}
            helperText={option.helperText}
          />
        </div>
      )}

      {option.type === 'textarea' && (
        <TextField<any>
          extraInputClass={
            'border dark:bg-night-100 focus:border border-[#525151]'
          }
          label={option.label}
          placeholder={option.placeholder}
          register={register}
          showIsRequiredAsterisk={Boolean(option.required)}
          required={Boolean(option.required)}
          name={option.name}
          extraLabelClass={'font-medium'}
          rows={option.rows ?? 3}
          disabled={
            tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false
          }
          helperText={option.helperText}
          extra={'mb-3'}
        />
      )}

      {option.type === 'checkbox' && (
        <CheckField<any>
          label={option.label}
          placeholder={option.placeholder}
          showIsRequiredAsterisk={Boolean(option.required)}
          register={register}
          name={option.name}
          required={Boolean(option.required)}
        />
      )}

      {option.type === 'file' && (
        <FileField<any>
          control={control}
          setValue={setValue}
          label={option.label}
          name={option.name}
          showIsRequiredAsterisk={Boolean(option.required)}
          required={Boolean(option.required)}
          extraInputClass={'dark:bg-night-100'}
          disabled={
            tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false
          }
        />
      )}

      {option.type === 'editor' && (
        <Controller
          name={option.name}
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => {
            return (
              <TextEditor
                {...field}
                label={option.label}
                setError={setError}
                clearErrors={clearErrors}
                error={error}
                className="border-2 border-[#525151] focus:border dark:bg-night-100"
                height={channel === 'slack' ? '385px' : '285px'}
                disabled={
                  tab === 'batched_template'
                    ? !Boolean(isBatchingEnabled)
                    : false
                }
              />
            );
          }}
        />
      )}

      {/* {option.type === "input|file"}  */}
      {normalFileInputSelect(option) && (
        <FileInputSelect
          name={option.name}
          placeholder={option.placeholder}
          required={option.required}
          register={register}
          control={control}
          setValue={setValue}
          label={option.label}
          disabled={
            tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false
          }
        ></FileInputSelect>
      )}

      {option?.additionalSettings && (
        <AdditionalSettings
          control={control}
          option={option}
          optionName={option.name}
          tab={tab}
          register={register}
        ></AdditionalSettings>
      )}

      {errors[option.name] && (
        <ErrorBox
          error={
            errors[option.name].type === 'required'
              ? `This field is required`
              : errors[option.name].type
          }
        />
      )}
    </div>
  );
};

export default TemplateEditorInputFields;
