import type { EngagespotNotification, Instance } from '@engagespot/core';
import { type Logger, relativeDateFormatter } from '@engagespot/utils';

import { getActionMutators } from './mutators';
import type { ChangeState, FetcherStore, Pagination } from './types';
import { defaults } from '../../data/defaults';
import type { Optional } from '../../utils/types';
import type { NotificationFeedService } from '../notificationFeedService';

type NotificationStore = NotificationFeedService['stores'];
type StoreKeysForResetObject = Pick<
  NotificationStore,
  '$notifications' | '$unreadCount'
>;
type StoreKeys = keyof StoreKeysForResetObject;

/*
 * This is a temporary to fix the issue of mutate not being called after the first time
 */
const stopLoading = ($store: any) => {
  $store.set({ loading: false });
};

export const getActions = ({
  createMutatorStore,
  instance,
  stores,
  log,
}: {
  createMutatorStore: FetcherStore['createMutatorStore'];
  instance: Instance;
  stores: NotificationFeedService['stores'];
  log: Logger;
}) => {
  const {
    $markAllAsReadMutator,
    $changeStateMutator,
    $deleteAllMutator,
    $markAsSeenMutator,
    $markAsReadMutator,
    $deleteMutator,
  } = getActionMutators({
    createMutatorStore,
    instance,
  });

  const checkIfNotification = () => {
    const notifications = stores.$notifications.value;
    return notifications;
  };

  const handleOptimisticUpdateFail = ({
    error,
    resetData,
  }: {
    error: unknown;
    resetData: {
      key: StoreKeys;
      data: EngagespotNotification[] | number;
    }[];
  }) => {
    if (error) {
      log.error(error);
      resetData.forEach(({ key, data }) => stores?.[key].set(data as any));
    }
  };

  const setUnreadCountIfNotRead = ({
    unreadCount,
    notifications,
    notificationId,
  }: {
    notifications: EngagespotNotification[];
    notificationId: number;
    unreadCount: number;
  }) => {
    const selectedNotification = notifications.find(
      notification => notification.id === notificationId,
    );

    if (!selectedNotification?.seenAt && !selectedNotification?.clickedAt) {
      stores.$unreadCount.set(Math.max(0, unreadCount));
    }
  };

  const deleteNotification = async (notificationId: number) => {
    const notifications = checkIfNotification();
    const unreadCount = stores.$unreadCount.value || 0;

    if (!notifications) return;

    const updatedNotifications = notifications.filter(
      notification => notification.id !== notificationId,
    );

    setUnreadCountIfNotRead({
      notificationId,
      notifications,
      unreadCount: unreadCount - 1,
    });

    stores.$notifications.set(updatedNotifications);
    stopLoading($deleteMutator);
    const { error } = await $deleteMutator.mutate({
      id: String(notificationId),
    });

    handleOptimisticUpdateFail({
      error,
      resetData: [
        { key: '$notifications', data: notifications },
        { key: '$unreadCount', data: unreadCount },
      ],
    });
  };

  const deleteAllNotifications = async () => {
    const notifications = checkIfNotification();
    const unreadCount = stores.$unreadCount.value || 0;

    if (!notifications) return;

    stores.$unreadCount.set(0);
    stores.$notifications.set([]);

    stopLoading($deleteAllMutator);
    const { error } = await $deleteAllMutator.mutate();

    handleOptimisticUpdateFail({
      error,
      resetData: [
        { key: '$notifications', data: notifications },
        { key: '$unreadCount', data: unreadCount },
      ],
    });
  };

  const markAsRead = async (notificationId: number) => {
    const notifications = checkIfNotification();
    const unreadCount = stores.$unreadCount.value || 0;

    if (!notifications) return;

    const updatedNotifications = notifications.map(notification => {
      if (notification?.id === notificationId) {
        return {
          ...notification,
          clickedAt: new Date().toUTCString(),
          clickedAtRelative: relativeDateFormatter(new Date().toUTCString()),
        };
      }

      return notification;
    });

    setUnreadCountIfNotRead({
      notificationId,
      notifications,
      unreadCount: unreadCount - 1,
    });

    stores.$notifications.set(updatedNotifications);

    stopLoading($markAsReadMutator);
    const { error } = await $markAsReadMutator.mutate({
      id: String(notificationId),
    });

    handleOptimisticUpdateFail({
      error,
      resetData: [
        { key: '$notifications', data: notifications },
        {
          key: '$unreadCount',
          data: unreadCount,
        },
      ],
    });
  };

  const markAllAsRead = async () => {
    const notifications = checkIfNotification();
    const unreadCount = stores.$unreadCount.value || 0;

    if (!notifications) return;

    const updatedNotifications = notifications.map(notification => {
      return {
        ...notification,
        clickedAt: new Date().toUTCString(),
        clickedAtRelative: relativeDateFormatter(new Date().toUTCString()),
      };
    });

    stores.$unreadCount.set(0);
    stores.$notifications.set(updatedNotifications);

    stopLoading($markAllAsReadMutator);
    const { error } = await $markAllAsReadMutator.mutate();

    handleOptimisticUpdateFail({
      error,
      resetData: [
        { key: '$notifications', data: notifications },
        { key: '$unreadCount', data: unreadCount },
      ],
    });
  };

  const changeState = async (
    values: Omit<ChangeState, 'id'> & {
      id: number;
    },
  ) => {
    const notifications = checkIfNotification();
    const unreadCount = stores.$unreadCount.value || 0;

    if (!notifications) return;

    stopLoading($changeStateMutator);
    const { error, data } = await $changeStateMutator.mutate({
      id: String(values.id),
      state: values.state,
    });

    if (data) {
      const updatedNotifications = notifications.map(notification => {
        const notificationId = notification.id;

        if (notificationId === Number(values.id)) {
          return { ...notification, ...data };
        }

        return notification;
      });

      setUnreadCountIfNotRead({
        notificationId: values.id,
        notifications,
        unreadCount: unreadCount - 1,
      });

      stores.$notifications.set(updatedNotifications);
    }

    handleOptimisticUpdateFail({
      error,
      resetData: [
        { key: '$notifications', data: notifications },
        { key: '$unreadCount', data: unreadCount },
      ],
    });
  };

  const markAsSeen = async (data: Optional<Pagination, 'limit'>) => {
    const notifications = stores.$notifications.value;
    const unreadCount = stores.$unreadCount.value;

    if (!notifications) return;

    const limit =
      data.limit || stores.$limit.value || defaults.defaultItemsPerPage;

    if (!unreadCount) return;

    const updatedNotifications = notifications?.map(notification => {
      return {
        ...notification,
        seenAt: new Date().toUTCString(),
        seenAtRelative: relativeDateFormatter(new Date().toUTCString()),
      };
    });

    stores.$notifications.set(updatedNotifications);
    stores.$unreadCount.set(Math.max(0, unreadCount - limit));

    stopLoading($markAsSeenMutator);

    const { error } = await $markAsSeenMutator.mutate({
      limit,
      pageNo: data.pageNo,
    });

    handleOptimisticUpdateFail({
      error,
      resetData: [
        { key: '$notifications', data: notifications },
        { key: '$unreadCount', data: unreadCount },
      ],
    });
  };

  return {
    deleteNotification,
    markAsRead,
    markAllAsRead,
    deleteAllNotifications,
    markAsSeen,
    changeState,
  };
};
