import { ElementType, ComponentPropsWithoutRef } from 'react';
import { cn } from 'utils/class-merge';

export type KeyProps<T extends ElementType = 'span'> = {
  name: string;
  className?: string;
  as?: T;
} & ComponentPropsWithoutRef<T>;

export function Key<T extends ElementType = 'span'>({
  name,
  className,
  as,
  ...props
}: KeyProps<T>) {
  const Component = as || 'span';
  return (
    <Component
      className={cn(
        'border border-input px-2 py-1 rounded-md text-gray-750',
        className,
      )}
      {...props}
    >
      {name}
    </Component>
  );
}
