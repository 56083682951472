import { useStore } from '@nanostores/react';

import { useInternalInstance } from './useInstance';

export function useErrors() {
  const {
    connect: { stores },
  } = useInternalInstance();
  const { $errors } = stores;
  const errors = useStore($errors);

  return errors;
}
