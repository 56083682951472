import type { ApiClient } from '@engagespot/api-client';
import type { Logger } from '@engagespot/utils';

import { type Events, initializeEvents } from './events';
import type { InstanceOptions } from '../createInstance';
import { API_BASE_URLS } from '../data/apiBaseUrls';
import { defaults } from '../data/defaults';
import {
  type DeviceMetadata,
  getDeviceMetadata,
} from '../helpers/deviceMetadata';
import { initializeApiClient } from '../modules/apiClient';

export type Dependencies = {
  log: Logger;
  apiClient: ApiClient;
  options: InstanceOptions;
  deviceMetadata: DeviceMetadata;
  events: Events;
};

export function createDependencies(
  options: InstanceOptions,
  log: Logger,
): Dependencies {
  const { apiKey, userId, userSignature, userToken, dataRegion } = options;

  const baseUrl =
    options.baseUrl ??
    (dataRegion && API_BASE_URLS[dataRegion]) ??
    defaults.apiBaseUrl;

  const deviceMetadata = getDeviceMetadata();

  const apiClient = initializeApiClient(
    {
      apiKey,
      userId,
      userSignature,
      userToken,
      baseUrl,
      deviceId: deviceMetadata.deviceId,
    },
    { log },
  );

  const events = initializeEvents();

  const deps = {
    log,
    apiClient,
    options,
    baseUrl,
    deviceMetadata,
    events,
  };
  return deps;
}
