export function EngagespotLogo() {
  return (
    <svg
      width="39"
      height="36"
      viewBox="0 0 39 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2451_653"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="39"
        height="36"
      >
        <path
          d="M37.5843 6.51325L28.8318 0.626797C27.7085 -0.13626 26.2575 -0.13626 25.1342 0.626797L19.424 4.4421L19.3304 4.38758H19.2836L13.6202 0.572292C12.4969 -0.190764 11.0459 -0.190764 9.92263 0.572292L1.17012 6.45872C0.421244 6.94928 0 7.87584 0 8.85692V20.0303C0 21.9379 0.889291 23.682 2.29344 24.6086L19.3304 36L36.4143 24.6086C37.8183 23.682 38.7078 21.8834 38.7078 19.9757V8.85692C38.7545 7.87584 38.3333 7.00377 37.5843 6.51325Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2451_653)">
        <path
          d="M12.7496 35.989L-1.5259 27.1594L-1.94714 8.19197L11.8603 -1.9458L26.1358 6.88387L26.557 25.8513L12.7496 35.989Z"
          fill="#BE185D"
        />
        <path
          d="M27.9027 36.8138L13.674 28.0388L13.2059 9.07134L27.0602 -1.06641L41.3357 7.76324L41.7569 26.6762L27.9027 36.8138Z"
          fill="#B22977"
        />
        <path
          d="M20.1242 42.3769L5.89552 33.6019L5.42749 14.6343L19.2817 4.49658L30.0124 12.2952L30.0124 32.0957L20.1242 42.3769Z"
          fill="#BE185D"
        />
        <path
          opacity="0.36"
          d="M41.2787 21.9691L18.4847 38.5383L18.0635 19.6254L41.7884 5.1333L41.2787 21.9691Z"
          fill="#A6256E"
        />
        <path
          d="M-3.97847 23.6365L19.4707 38.0255L19.0495 19.1127L-4.44653 4.72363L-3.97847 23.6365Z"
          fill="#D74078"
        />
      </g>
    </svg>
  );
}
