import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext } from 'react';
import { ThemeProvider } from 'styled-components';

import type { Route } from '@/components/engagespot/Engagespot';
import { getTheme } from '@/theme/theme';
import type { EngagespotInternalProps } from '@/types/panel';

type EngagespotContextProps = Partial<EngagespotInternalProps> & {
  route: Route;
  setRoute: Dispatch<SetStateAction<Route>>;
  category: string;
  setCategory: Dispatch<SetStateAction<string>>;
};

type EngagespotInternalProviderProps = EngagespotContextProps & {
  children: ReactNode;
};

const EngagespotContext = createContext<EngagespotContextProps>(
  {} as EngagespotContextProps,
);

export const useEngagespotContext = () => {
  return useContext(EngagespotContext);
};

export function EngagespotInternalProvider(
  props: EngagespotInternalProviderProps,
) {
  const { children, theme, ...state } = props;

  return (
    <EngagespotContext.Provider value={state}>
      <ThemeProvider theme={getTheme(theme)}>{children}</ThemeProvider>
    </EngagespotContext.Provider>
  );
}
