import useGetPlans from 'api/billing/get-plans';
import Card from 'components/card';
import { Button } from 'components/shadcn/button';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { isEnterpriseEdition } from 'utils/get-editions';
import { R } from 'utils/remeda-utils';
import { useGetCurrentBillingPlanDetails } from '../hooks';
import StopCancelationModal from './StopCancelationModal';
import { ACCOUNT_STATUS, USAGE_TYPE } from '../enums';
import { CustomHoverCard } from 'components/shadcn/hover-card';
import { Info } from 'lucide-react';
import { useSubscriptionStatus } from 'utils/use-subscription-status';
import CommentInfo from '@assets/svg/billing/comment-info.svg';

export const SubscriptionDetails = ({
  onUpgrade,
  onCancel,
}: {
  onUpgrade: () => void;
  onCancel: () => void;
}) => {
  const {
    remindAccountIsSuspended,
    accountSuspended,
    selectedWorkspaceSuspended,
  } = useSubscriptionStatus();
  const enterpriseEdition = isEnterpriseEdition();
  const getPlans = useGetPlans(enterpriseEdition);
  const {
    billingPriceAmount,
    abbreviatedBillingPricePerMonth,
    currentPlan,
    subscription,
  } = useGetCurrentBillingPlanDetails();

  const futurePlan = subscription?.futurePlan;
  const isActiveAccount = remindAccountIsSuspended
    ? false
    : subscription?.stripeStatus === ACCOUNT_STATUS['ACTIVE'];
  const stripeStatus = subscription?.stripeStatus;

  const isFreePlan = R.isTruthy(currentPlan?.isFree);
  const cancellationDate = subscription?.endsAt;
  const cancellationDateISO = Boolean(cancellationDate)
    ? parseISO(cancellationDate)
    : null;
  const formattedCancellationDate = Boolean(cancellationDateISO)
    ? format(cancellationDateISO, 'dd-MMM-yyyy')
    : '--';

  const [stopCancelationModalIsOpen, setStopCancelationModalIsOpen] =
    React.useState(false);

  // alternative paid plans
  const alternativePaidPlans = R.filter(
    getPlans.data ?? [],
    alternativePlans =>
      R.isNot(R.isTruthy)(alternativePlans.isFree) &&
      alternativePlans.identifier !== currentPlan?.identifier,
  );
  const alternativeUpgradePaidPlans = R.pipe(
    alternativePaidPlans ?? [],
    R.filter(plan => {
      const licensedPrice = R.find(
        plan.prices,
        price => price.usageType === USAGE_TYPE['LICENSED'],
      );

      return licensedPrice?.amount > billingPriceAmount;
    }),
  );
  const hasAlternativePaidPlans = R.length(alternativePaidPlans) > 0;
  const hasAlternativeUpgradePaidPlans =
    R.length(alternativeUpgradePaidPlans) > 0;

  return (
    <>
      <StopCancelationModal
        isOpen={stopCancelationModalIsOpen}
        onOpenChange={setStopCancelationModalIsOpen}
      />

      <div className="grid grid-cols-3 gap-6">
        <Card
          extra={'pb-8 px-6 pt-6 border border=[#3A3F47] rounded-[3px] mb-8'}
        >
          {/* Header */}
          <div className="flex items-center gap-3 mb-5">
            <h4 className="font-medium text-lg">{currentPlan?.name}</h4>

            <div className="flex justify-between gap-2">
              <div
                style={{
                  background: isActiveAccount ? '#00FF82' : '#FF2A2E',
                  color: isActiveAccount ? '#16171C' : '#FFF',
                }}
                className="rounded-[50px] flex gap-2 items-center py-1 px-2 text-[10px] font-semibold"
              >
                <span
                  style={{
                    background: isActiveAccount ? '#094B2F' : '#FFF',
                  }}
                  className="h-[6px] w-[6px] rounded-full"
                ></span>
                {/* {isActiveAccount ? 'Active' : 'Suspended'} */}
                {selectedWorkspaceSuspended ? 'suspended' : stripeStatus}
              </div>

              {futurePlan && (
                <CustomHoverCard
                  children={
                    <div className="bg-gray-300 text-xs flex items-center  rounded-lg text-black gap-1 py-1 px-2 cursor-help">
                      <Info className="w-3 h-3" />
                      Change Requested
                    </div>
                  }
                  content={
                    <p className="text-sm">
                      Your plan will be switched to {futurePlan?.name} in the
                      next billing cycle.
                    </p>
                  }
                />
              )}
            </div>
          </div>

          {/* Pricing */}
          <div className="flex items-end gap-2 mb-7">
            <div className="font-semibold mb-0 text-[2.5rem] leading-8">
              ${Number(billingPriceAmount).toFixed(2)}
            </div>
            <div className="font-normal text-xs text-[#ABB1B8]">/ Month</div>
            <CustomHoverCard
              children={
                <img className="cursor-pointer" src={CommentInfo} alt="" />
              }
              content={
                <p className="text-sm">
                  This shows the plan's base price before any discounts.
                </p>
              }
            />
          </div>

          {/* Upgrade and Cancel Button  */}
          <div className="flex items-center gap-3">
            <Button
              className="font-semibold text-sm w-min py-2 px-9"
              onClick={onUpgrade}
              disabled={!hasAlternativePaidPlans}
            >
              {hasAlternativeUpgradePaidPlans
                ? 'Upgrade'
                : hasAlternativePaidPlans && !hasAlternativeUpgradePaidPlans
                  ? // only need to show downgrade if there are alternative downgrade paid plans
                    'Downgrade'
                  : 'Upgrade'}
            </Button>

            {cancellationDate ? (
              <Button
                className="font-semibold text-sm w-fit py-2 px-4 bg-[#42C687E6] text-white hover:bg-[#42C687E6]/80 hover:opacity-95"
                onClick={() => setStopCancelationModalIsOpen(true)}
              >
                {'Stop Cancellation'}
              </Button>
            ) : (
              <>
                {isFreePlan ? null : (
                  <Button
                    className="font-semibold text-sm w-min py-2 px-9 text-[#ABB1B8]"
                    variant="outline"
                    onClick={onCancel}
                  >
                    {'Cancel'}
                  </Button>
                )}
              </>
            )}
          </div>

          {cancellationDate && (
            <div className="text-red-400 mt-4 text-sm font-semibold">
              Plan will be cancelled on {formattedCancellationDate}{' '}
            </div>
          )}
        </Card>
      </div>
    </>
  );
};
