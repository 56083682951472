import { CommandBarSearch } from 'commandbar';
import { EngagespotNavComponent } from 'components/engagespot';
import CustomErrorBoundary from 'components/error/ErrorBoundary';
import {
  renderThumb,
  renderTrack,
  renderView,
} from 'components/scrollbar/Scrollbar';
import { Button } from 'components/shadcn/button';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from 'components/shadcn/drawer';
import { SideBarNavigation } from 'components/sidebar/components/SideBarNavigation';
import WorkSpaceSwitcher from 'components/sidebar/components/WorkspaceSwitcher';
import { Menu, PanelsTopLeft, X } from 'lucide-react';
import Scrollbars from 'react-custom-scrollbars-2';
import { sidebarRoutes } from 'sidebar-routes';
import { cn } from 'utils/class-merge';
import { useSubscriptionStatus } from 'utils/use-subscription-status';
import AppSwitcher from './AppSwitcher';
import { SubscriptionReminder } from './SubscriptionReminder';
import { UserNav } from './UserNav';

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
}) => {
  const { subscriptionStatus, remindAccountIsSuspended } =
    useSubscriptionStatus();

  return (
    <nav className="fixed border-b xl:w-[calc(100vw-250px)] right-0 top-0 flex w-full flex-row flex-wrap items-center justify-end p-2 backdrop-blur-xl">
      {/* Mobile View Sidebar */}
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="sm" className="shrink-0 xl:hidden">
            <Menu className="h-5 w-5 text-white" />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>

        <SheetContent
          className="bg-background p-0  text-white  w-[250px] dark:bg-background 3xl:w-[285px] sm:mr-4 h-[100vh] !rounded-[0px] border-r"
          side="left"
        >
          <Scrollbars
            className="w-full"
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderView={renderView}
          >
            <div className="flex w-full justify-between items-center mb-1 pt-2 px-4">
              <PanelsTopLeft className="text-gray-10 relative top-0 left-1" />

              <SheetClose className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
                <X className="h-4 w-4" />
                <span className="sr-only">Close</span>
              </SheetClose>
            </div>

            <SideBarNavigation links={sidebarRoutes} />
          </Scrollbars>
        </SheetContent>
      </Sheet>

      <div className="mr-auto xl:hidden pl-4">
        <WorkSpaceSwitcher />
      </div>

      <div
        className={cn(
          'relative  mt-[3px] flex h-[45px]  flex-grow items-center justify-between gap-0 rounded-full  px-4 py-2 shadow-xl shadow-shadow-500  dark:shadow-none  md:flex-grow-0 md:gap-1 xl:gap-2',
          remindAccountIsSuspended ? 'w-full' : 'w-[520px]',
        )}
      >
        {remindAccountIsSuspended && (
          <SubscriptionReminder subscriptionStatus={subscriptionStatus} />
        )}

        <div className="hidden md:flex">
          <CommandBarSearch />
        </div>

        <AppSwitcher />

        <CustomErrorBoundary minimal={true}>
          <EngagespotNavComponent />
        </CustomErrorBoundary>

        <UserNav />
      </div>
    </nav>
  );
};

export default Navbar;
