import { useStore } from '@nanostores/react';
import React from 'react';
import { FieldValues, UseFormReturn, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTemplateStore } from 'store/templateStore';
import { allTemplateDetails } from 'templates';
import { $currentTemplate, $templateConfigCurrentState } from 'templates/store';
import { useGetTemplateEditorOnSubmit } from '../hooks/getTemplateEditorOnSubmit';
import { useRefillTemplateEditorData } from '../hooks/refillTemplateEditorData';
import {
  TemplateEditorAvailabeChannels,
  TemplateInputs,
  templateEditorInputConfig,
} from '../variables/inputs';
import TemplateEditorInputFields from './TemplateEditorInputFields';

const TemplateEditorInputs = ({
  saveRef,
  form,
}: {
  saveRef: React.MutableRefObject<HTMLButtonElement>;
  form: UseFormReturn<FieldValues, any>;
}) => {
  const params = useParams();
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const { loading, tab } = useTemplateStore(state => state);
  const templateConfigState = useStore($templateConfigCurrentState);
  const currentTemplate = useStore($currentTemplate);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    setError,
    clearErrors,
  } = form;
  const isBatchingEnabled = useWatch({
    control,
    name: 'batchingEnabled',
  });

  // This useEffect is for filling the inputs onLoad
  // This useEffect also fires after onSubmit and resets data to latest
  useRefillTemplateEditorData({ reset });

  /**
   * ******** IMPORTANT **************
   * After submit request, the form is reset to make sure isDirty is null,
   * so that unsaved modal does not popup. So make sure to invalidate and
   * refetch the get apis that is used to refill the form.
   * ******** IMPORTANT **************
   */
  const { onSubmit } = useGetTemplateEditorOnSubmit({
    reset,
    isBatchingEnabled,
  });

  const config =
    channel === 'inApp' && currentTemplate && tab !== 'batched_template'
      ? allTemplateDetails?.[currentTemplate]?.['consoleInputs']?.[
          templateConfigState
        ]?.[tab]
      : templateEditorInputConfig[channel][tab];

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-[55%] flex-col gap-3"
    >
      {(config as TemplateInputs[])?.map((option, index) => (
        <TemplateEditorInputFields
          key={index}
          channel={channel}
          control={control}
          register={register}
          errors={errors}
          isBatchingEnabled={isBatchingEnabled}
          option={option}
          setValue={setValue}
          tab={tab}
          clearErrors={clearErrors}
          setError={setError}
        ></TemplateEditorInputFields>
      ))}

      {/* submit */}
      <button disabled={loading} hidden={true} ref={saveRef}>
        submit
      </button>
    </form>
  );
};

export default TemplateEditorInputs;
