import { getOrCreateDeviceId } from '../utils/device';
import { findBrowser, getDeviceType } from '../utils/platform';

export type DeviceMetadata = ReturnType<typeof getDeviceMetadata>;

export function getDeviceMetadata() {
  const deviceId = getOrCreateDeviceId();
  const browserType = findBrowser();
  const deviceType = getDeviceType();
  return {
    deviceId,
    browserType,
    deviceType,
  };
}
