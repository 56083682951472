import styled, { css } from 'styled-components';

export const NotificationHeaderStyled = styled.div`
  && {
    ${({ theme: { header, colors } }) => css`
      display: flex;
      height: ${header.height};
      align-items: center;
      justify-content: flex-start;
      padding: ${header.padding};
      font-size: ${header.fontSize};
      color: ${header.fontColor};
      background: ${colors.brandingPrimary};
      box-sizing: border-box;
      border-bottom: ${header.borderBottom};
    `}
  }
`;

export const NotificationHeaderTextStyled = styled.h3.withConfig({
  shouldForwardProp: prop => !['isPreferenceView'].includes(prop),
})<{
  isPreferenceView: boolean;
}>`
  && {
    ${({ theme: { header }, isPreferenceView }) => css`
      font-size: ${header.fontSize};
      font-weight: ${header.fontWeight};
      color: ${header.fontColor};
      padding-left: ${() => (isPreferenceView ? '0rem' : '0.5rem')};
      position: relative;
      right: ${() => (isPreferenceView ? '0' : '1.58rem')};
      margin-right: auto;
    `}
  }
`;

export const NotificationHeaderCloseButtonStyled = styled.button`
  && {
    ${({ theme: { header } }) => css`
      border-width: 0;
      margin: ${header.closeButtonMargin};
      position: relative;
      padding: ${header.closeButtonPadding};
      color: ${header.fontColor};
      box-sizing: border-box;
      outline: none;
      user-select: none;
      cursor: pointer;
      background-color: ${header.closeButtonBackground};
      bottom: 1px;

      &:hover {
      }

      svg {
        fill: ${header.closeButtonColor};
        stroke: none;
        height: 12px;
        width: 12px;
      }
    `}
  }
`;
