export const englishFallbackText = {
  header: {
    headerText: 'Notifications',
    preferenceText: 'Preferences',
    dropdownItems: {
      preferences: 'Preferences',
      enableDesktopNotifications: 'Enable Desktop Notifications',
      markAllAsRead: 'Mark All As Read',
      deleteAll: 'Delete All',
    },
  },
  notification: {
    dropdownItems: {
      delete: 'Delete',
      markAsRead: 'Mark As Read',
    },
  },
  channels: {
    discord: 'Discord',
    email: 'Email',
    inApp: 'In-App',
    mobilePush: 'Mobile Push',
    slack: 'Slack',
    sms: 'SMS',
    webPush: 'Web Push',
    webhook: 'Webhook',
    whatsapp: 'WhatsApp',
    chat: 'Chat',
  },
  preferenceModal: {
    heading: 'Enable web push notifications',
    text: 'Would you like to enable web push notifications?',
    yesButtonText: 'Yes',
    noButtonText: 'Maybe later',
    secondaryText:
      'You can change your preferences in Settings later, if needed',
  },
  notificationFeed: {
    placeholderText: `Shh! It's quiet around here...`,
  },
};
