export function sanitizeHTML(htmlString?: string | null): string {
  if (!htmlString) return '';

  // Create a new DOM parser
  const parser = new DOMParser();
  // Parse the input HTML string into a document
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Custom placeholder for unsafe content
  const UNSAFE_CONTENT_PLACEHOLDER = '[unsafe content removed]';

  // Type-safe function to replace unsafe elements with a placeholder
  const replaceUnsafeElement = (element: Element) => {
    const placeholder = document.createTextNode(UNSAFE_CONTENT_PLACEHOLDER);
    element.parentNode?.replaceChild(placeholder, element);
  };

  // List of forbidden tags to be replaced
  const forbiddenTags: string[] = [
    'script',
    'iframe',
    'object',
    'embed',
    'link',
    'style',
  ];

  // Loop through and replace forbidden tags with the placeholder
  forbiddenTags.forEach(tag => {
    const elements = doc.querySelectorAll(tag);
    elements.forEach(element => replaceUnsafeElement(element));
  });

  // Remove or sanitize dangerous attributes (e.g., onclick, onerror)
  const elements = doc.body.getElementsByTagName('*');

  for (let i = 0; i < elements.length; i++) {
    const attributes = Array.from(elements[i].attributes);
    let isUnsafe = false;

    attributes.forEach(attr => {
      // Check for dangerous event-handling attributes (on* attributes) or other unsafe attributes
      if (attr.name.startsWith('on') || attr.name === 'srcdoc') {
        // If any unsafe attribute is found, mark the element as unsafe
        isUnsafe = true;
      }
    });

    // If the element is marked unsafe, replace the entire element with the placeholder
    if (isUnsafe) {
      replaceUnsafeElement(elements[i]);
    }
  }

  // Return the sanitized HTML as a string
  return doc.body.innerHTML;
}
