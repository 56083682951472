import { SubscriptionDetails } from 'api/billing/get-subscription-details/types';

export const subscriptionMessages: Record<
  SubscriptionDetails['subscription']['stripeStatus'],
  string
> = {
  incomplete:
    'Your subscription setup is incomplete. Please complete your payment to activate your subscription.',
  incomplete_expired:
    'Your subscription setup has expired. Please create a new subscription to continue.',
  trialing:
    'You are currently on a free trial. Enjoy exploring your subscription benefits!',
  active:
    "Your account is suspended because you've reached your subscription limit.",
  past_due:
    'Your payment is overdue. Update your payment method to avoid service interruption.',
  canceled:
    'Your subscription has been canceled. Renew now to regain access to your plan.',
  unpaid:
    'Your subscription is unpaid. Please settle the outstanding amount to continue using the service.',
  paused:
    'Your subscription is currently paused. Resume your subscription to enjoy uninterrupted service.',
};
