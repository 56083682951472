import React, { useEffect, useRef, useState } from 'react';

import {
  ScrollButton,
  TabButton,
  TabsContainer,
  TabsWrapper,
} from './Tab.styled';
import { Chevron } from '@/icons/Chevron';

export type TabsProps = {
  tabs: {
    identifier?: string;
    label?: string;
  }[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
};

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTab,
  setActiveTab,
}) => {
  const [scrollState, setScrollState] = useState({
    hasOverflow: false,
    canScrollLeft: false,
    canScrollRight: false,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (identifier: string) => {
    setActiveTab(identifier);
  };

  const scroll = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.offsetWidth / 2;
      containerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const checkScroll = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
      setScrollState({
        hasOverflow: scrollWidth > clientWidth,
        canScrollLeft: scrollLeft > 0,
        canScrollRight: scrollLeft < scrollWidth - clientWidth - 1,
      });
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(checkScroll);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <TabsWrapper>
      {scrollState.hasOverflow && (
        <ScrollButton
          onClick={() => scroll('left')}
          disabled={!scrollState.canScrollLeft}
          aria-label="Scroll left"
          direction="left"
        >
          <Chevron />
        </ScrollButton>
      )}
      <TabsContainer ref={containerRef} onScroll={checkScroll}>
        {tabs.map(tab => (
          <TabButton
            key={tab.identifier}
            isActive={tab.identifier === activeTab}
            onClick={() => handleTabClick(tab.identifier as string)}
          >
            {tab.label}
          </TabButton>
        ))}
      </TabsContainer>
      {scrollState.hasOverflow && (
        <ScrollButton
          onClick={() => scroll('right')}
          disabled={!scrollState.canScrollRight}
          aria-label="Scroll right"
          direction="right"
        >
          <Chevron />
        </ScrollButton>
      )}
    </TabsWrapper>
  );
};
