import { createLogger } from '@engagespot/utils';

import { errorCodes } from '../data/errorCodes';
import { createErrorHandlerWithErrorCodes } from '../utils/createErrorHandlerWithErrorCodes';

/**
 * We don't have the debug option yet from the createInstance() method here. So we need to create a logger instance manually.
 * Its still fine since this logger is used to print error or warning messages always.
 */
const log = createLogger({ debug: true, prefix: '[Engagespot' });

export const handleError = createErrorHandlerWithErrorCodes(errorCodes, log);
