import { Group } from './Group';
import { getGroup, renderLinkItems } from '../routes';

const { name, icon, children } = getGroup('Workspace Settings');

export function WorkspaceSettings() {
  return (
    <Group heading={name} icon={icon}>
      {renderLinkItems(children ?? [], name)}
    </Group>
  );
}
