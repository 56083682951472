import { useContext } from 'react';

import { EngagespotContext } from '../providers/EngagespotProvider';

/**
 * This should be used only internally
 */
export const useInternalInstance = () => {
  const storeInstance = useContext(EngagespotContext);
  return storeInstance;
};
