import { useDisclosure } from '@chakra-ui/hooks';
import { useQueryClient } from '@tanstack/react-query';
import useGetLicense from 'api/billing/get-license';
import usePostDefaultPaymentMethod from 'api/billing/post-default-payment-method';
import { routes } from 'api/routes';
import TableMenu from 'components/table/table-menu';
import React from 'react';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import DeleteCreditCardModal from './DeleteCreditCardModal';

type CategoryAction = 'make_default' | 'remove';

function CreditCardMenu(props: {
  paymentMethodId: string;
  isDefault: boolean;
}) {
  const deleteCreditCardModalActions = useDisclosure();
  const { paymentMethodId, isDefault } = props;
  const postDefaultPaymentMethod = usePostDefaultPaymentMethod();
  const { clientId } = useAuthStore(state => state);
  const license = useGetLicense(clientId);
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const handleMenuClick = (actionType: CategoryAction) => {
    if (isDefault) {
      if (actionType === 'remove') {
        toast.error('you cannot remove default payment method');
      }
    }

    if (actionType === 'make_default') {
      const data = {
        clientId: license.data?.InstanceClientIdentifer,
        paymentMethodId,
      };

      postDefaultPaymentMethod.mutate(data, {
        onSuccess: () => {
          toast.success('default payment method changed successfully');
          queryClient.invalidateQueries([
            `${routes['clients']}/paymentMethods`,
          ]);
        },
      });
    }

    if (actionType === 'remove') {
      deleteCreditCardModalActions.onOpen();
    }
  };

  return (
    <>
      <DeleteCreditCardModal
        isOpen={deleteCreditCardModalActions.isOpen}
        onClose={deleteCreditCardModalActions.onClose}
        paymentMethodId={paymentMethodId}
      />

      <TableMenu
        config={[
          {
            text: 'Make Default',
            action: () => handleMenuClick('make_default'),
          },
          {
            text: 'Remove',
            action: () => handleMenuClick('remove'),
          },
        ]}
      />
    </>
  );
}

export default CreditCardMenu;
