import { connectService } from '../services/connectService';
import { notificationActionsService } from '../services/notificationActionsService/index';
import { notificationFeedService } from '../services/notificationFeedService';
import { preferenceService } from '../services/preferenceService';
import { rtmService } from '../services/rtmService';
import { webPushService } from '../services/webPushService';

export const serviceMapper = {
  connectService: {
    key: 'connectService',
    service: connectService,
  },
  notificationFeedService: {
    key: notificationFeedService.key,
    service: notificationFeedService,
  },
  webPushService: {
    key: 'webPushService',
    service: webPushService,
  },
  notificationActionsService: {
    key: notificationActionsService.key,
    service: notificationActionsService,
  },
  rtmService: {
    key: rtmService.key,
    service: rtmService,
  },
  preferenceService: {
    key: preferenceService.key,
    service: preferenceService,
  },
};

export type ServiceMapper = typeof serviceMapper;
