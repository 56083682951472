import type { EngagespotNotification } from '@engagespot/core';

const defaultItemsPerPage = 15;

const ONE_SECOND_IN_MILLISECONDS = 1000;
const ONE_MINUTE_IN_MILLISECONDS = 60 * ONE_SECOND_IN_MILLISECONDS;
const defaultRelativeTimeUpdateInterval = ONE_MINUTE_IN_MILLISECONDS; // 1 minute

const defaultNotificationsValue: EngagespotNotification[] = [];

const defaultNotificationMetadata = {
  unreadCount: 0,
  totalCount: 0,
  totalPages: 0,
};

const defaultAppInfo = {};

const defaultStoreKey = 'default_store_key';

export const defaults = {
  defaultItemsPerPage,
  defaultNotificationsValue,
  defaultNotificationMetadata,
  defaultAppInfo,
  defaultRelativeTimeUpdateInterval,
  defaultStoreKey,
};
