import { PageRegistry, defaultPage, type Page } from 'commandbar/pages';

export type PageRouteProps = {
  activePage: Page;
};

export function PageRoute({ activePage }: PageRouteProps) {
  const activePageDefinition =
    PageRegistry.find(page => page.name === activePage) || defaultPage;

  return <activePageDefinition.component />;
}
