export function NotificationEmpty() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1600 1100"
      width={'50%'}
    >
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        <path
          d="M92.8 863.1S55.4 820 33.3 766.2c-22.1-53.8-10.8-102.6 6.2-113.4 17-10.8 57.3 45.4 59.5 95.8 2.3 50.5 0 114.5 0 114.5h-6.2Z"
          fill="#52CB96"
          fillRule="nonzero"
        />
        <path
          d="M96.2 863.1s-34-203.5-17.6-297.6c16.4-94.1 54.4-91.3 74.8-78.2 20.4 13.1 43.7 79.4 13.6 195.6-30 116.2-59 180.3-59 180.3H96.2v-.1Z"
          fill="#37B37F"
          fillRule="nonzero"
        />
        <path
          stroke="#FFF"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m104.7 863.1 23.1-273.7M96.2 863.1 60.5 727.8"
        />
        <path
          d="M643.6 307.8s39.1-96.6 174.1-60.9c135 35.7 144.5 221.8 208.9 277.3 64.4 55.4 205.9 56.8 196.8 189.3-9.1 132.5-216.3 18-320.8 23.7-317.8 17.3-387 177.4-599.5 87.1-142.2-60.4-100.1-229.2 35.6-279.5 135.7-50.3 240.3-88.8 304.9-237Z"
          fill="#F1F2F7"
          fillRule="nonzero"
        />
        <ellipse
          fill="#283444"
          fillRule="nonzero"
          cx={557.1}
          cy={933.1}
          rx={143.2}
          ry={61.8}
        />
        <path
          d="M568.4 921.7s-3.7 4.5-13.6 4.5c-9.9 0-13.6-4.5-13.6-4.5v-127h27.2v127ZM22.8 863.1C8.8 880.3.4 902.2.4 926.1c0 55 44.6 99.7 99.7 99.7s99.7-44.6 99.7-99.7c0-23.9-8.4-45.8-22.4-63H22.8Z"
          fill="#CED5E5"
          fillRule="nonzero"
        />
        <path
          d="M908.3 827.6v206.6c0 25.5 63.6 46.1 142 46.1s142-20.6 142-46.1V827.6h-284Z"
          fill="#FFB3DA"
          fillRule="nonzero"
        />
        <ellipse
          fill="#ED85C3"
          fillRule="nonzero"
          cx={1050.3}
          cy={827.6}
          rx={142}
          ry={67.2}
        />
        <path
          d="M267.1 513.8s-2.6-50.2 39.1-66.3c41.7-16.2 155.6-26.4 222-6.8 66.3 19.6-14.6 58 105.4 171.8C743 716.2 802 683.9 866.6 715.4S802 838.7 674.4 836.2s-198.1-28.1-261.1-70.6C350.5 723 262.9 615.9 267.1 513.8Z"
          fill="#283444"
          fillRule="nonzero"
        />
        <path
          d="M525.8 746.2s21.8 22.5 67 34.1c45.9 11.8 113.1 19.9 174.6 28.3 120.9 16.7 305.6 43.1 305.6 43.1l-2.8-32.3s-293-51.9-430.7-104.9L569 681.1s-26.3 7.2-43.2 65.1Z"
          fill="#5900CC"
          fillRule="nonzero"
        />
        <path
          d="m665.2 658.5 401 175.9-12.1 33.6-410.3-151.8-66.4-21.6 7.2-19.2c.1 0 53.7-28.7 80.6-16.9Z"
          fill="#5900CC"
          fillRule="nonzero"
        />
        <path
          d="m401.3 448.4-9.2 57s28.3 8.5 41.8 1.6c13.5-6.9 18.9-22.4 18.9-22.4s-10.3-1.4-23.2-31.3l-28.3-4.9Z"
          fill="#FFDFD7"
          fillRule="nonzero"
        />
        <path
          stroke="#CED5E5"
          strokeWidth={3}
          opacity={0.3}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1524.1 941.8 337.8 252.7v-72.8L1404.2 29.1"
        />
        <path
          d="M387.3 441.5s30.3 19 55.6 11.3c25.2-7.7-8.6-109.1-8.6-109.1s-14-22.6-49.5-14.1c-35.6 8.5-40.3 74.4 2.5 111.9Z"
          fill="#FFDFD7"
          fillRule="nonzero"
        />
        <path
          d="M394.7 489.7s-17.6 10.5-34.6 29.3c-8.7 9.6 29.3 74.4 73.5 130.7 42.1 53.7 90.3 99.1 90.3 99.1s31-44.6 69.5-63.9c38.6-19.3 74.5-27.2 74.5-27.2s-90.7-78.2-141-125.5l-33.6-53.3s-20.4-2.3-47.2 1.1c-.1.2-14.7 17.3-51.4 9.7Z"
          fill="#FFC933"
          fillRule="nonzero"
        />
        <path
          d="M473.4 597.9s18.1-8.1 36.2-10.6c18.1-2.6 12.3 20.4 6.2 29.1-6.1 8.7-48.7 12.3-48.7 12.3s-8.9-5.3-6.3-13.3c2.6-8 5.7-13.9 12.6-17.5Z"
          fill="#FFDFD7"
          fillRule="nonzero"
        />
        <path
          d="M493.2 479.1s35.5 2 89.1 30.7l16 10.5s33.6 21.4 39 34.2c5.4 12.8-.3 25.2-6.4 30.9l-33-28.8-69.9-22.5-34.8-55ZM360 519c-5.7 8.1-11.3 18.3-16.1 31.1-19.3 51-19.3 93-15.5 97.1 3.8 4.2 52.2 6 139.1-18.1 0 0-7.6-8.7 6.4-32.1 0 0-54.8 6.8-94.1 9.4l9.4-34s4.2-29.9-12.9-43.5L360 519Z"
          fill="#FFC933"
          fillRule="nonzero"
        />
        <path
          stroke="#FEAC00"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m547 550.9-31.2-28.5"
        />
        <path
          d="m491.9 654.7 12.8-81.1c.5-3.3 2.7-6.1 5.8-7.4l103.6-43.5c1.8-.8 5.4-.2 8.1 2.1 1 .9-1.6 1.2-1.8 2.7l-11.2 72c-.5 3.1-2.4 5.7-5.2 7.1l-102.1 54.8c-3.5 1.8-10.9-1.7-10-6.7Z"
          fill="#CED5E5"
          fillRule="nonzero"
        />
        <path
          d="m495.8 656.5 12.8-81.1c.5-3.3 2.7-6.1 5.8-7.4L618 524.5c3.3-1.4 6.8 1.4 6.2 4.9l-11.2 72c-.5 3.1-2.4 5.7-5.2 7.1l-105.5 52.7c-3.3 1.7-7-1.1-6.5-4.7Z"
          fill="#F1F2F7"
          fillRule="nonzero"
        />
        <ellipse
          fill="#CED5E5"
          fillRule="nonzero"
          transform="rotate(-67.61 568.994 588.443)"
          cx={568.994}
          cy={588.443}
          rx={13.6}
          ry={9.5}
        />
        <path
          d="M624.3 558.8s-22.2 6-27.2 8.7c-4.9 2.6-12.5 4.9-12.5 12.5s6.8 17.8 12.9 18.5c6 .8 28.2-6.3 33.4-13.2 5.2-6.9 4.5-28-6.6-26.5Z"
          fill="#FFDFD7"
          fillRule="nonzero"
        />
        <path
          d="M435.2 346.6s6.5-11.4-6.5-18.9c-13-7.5-54.2-16.6-72 24.8 0 0-16.7 3.7-11.3 34.9 5.4 31.2 42 54.1 42 54.1l-3.6-37 8.2-5.1-9.7-26.3c-.1.1 39.5-1.9 52.9-26.5Z"
          fill="#8B34FF"
          fillRule="nonzero"
        />
        <path
          d="M385.3 409.5s-.9-8.5-8.9-11.6c-8-3.1-15 4-13.3 13.6 1.7 9.7 14.9 18.7 26.5 13.2l-4.3-15.2Z"
          fill="#FFDFD7"
          fillRule="nonzero"
        />
        <path
          stroke="#FEAC00"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m474 597-94.2 9.4 9.5-34M467.5 629.1l-40.6 9.6"
        />
        <path
          d="M1070.2 819.4s1.9-22.9 11.7-40.3c9.8-17.4 23.6-20 23.6-20l-.8 92.6H1073l-2.8-32.3Z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M1065.5 836.4s12.2-21.2 29.4-33.6c17.2-12.4 31.4-8.8 31.4-8.8l-42.8 89-30-14.1 12-32.5Z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          stroke="#8B34FF"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m667.8 725.1 385.7 143.7 12.7-34.5-62.7-27.5"
        />
        <path
          d="M1070.9 828.1s16.6-26 34.6-31.4"
          stroke="#F1F2F7"
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M258.6 273.1H38.1c-15.3 0-27.6-12.4-27.6-27.6v-58.4c0-15.3 12.4-27.6 27.6-27.6h220.5c15.3 0 27.6 12.4 27.6 27.6v58.4c0 15.3-12.3 27.6-27.6 27.6ZM126.7.2h13.6v159.3h-13.6z"
          stroke="#FEAC00"
          fill="#FFB3DA"
          fillRule="nonzero"
        />
        <path
          d="M339 256.2h-6.3c-22.1 0-39.9-17.9-39.9-39.9 0-22.1 17.9-39.9 39.9-39.9h6.3v79.8Z"
          fill="#ED85C3"
          fillRule="nonzero"
        />
        <path
          d="m1524.1 941.8-118.1-153c-3.2-4.1-4.9-9.2-4.9-14.4v-740c0-4 3.8-6.9 7.7-5.8l112.8 32.5c5.7 1.6 9.6 6.8 9.6 12.7v865.6c-.1 3.7-4.8 5.3-7.1 2.4Z"
          fill="#F1F2F7"
          fillRule="nonzero"
        />
        <path fill="#ED85C3" fillRule="nonzero" d="M100.1.2h67.3v20h-67.3z" />
      </g>
    </svg>
  );
}
