import { SubscriptionDetails } from 'api/billing/get-subscription-details/types';
import { Alert, AlertDescription } from 'components/shadcn/alert';
import { AlertCircle } from 'lucide-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { subscriptionMessages } from 'views/admin/dashboards/billing/data';

export const SubscriptionReminder = ({
  subscriptionStatus,
}: {
  subscriptionStatus: SubscriptionDetails['subscription']['stripeStatus'];
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const isBillingPage = pathname === '/admin/settings/billing';

  return (
    // <div className="bg-[#FFB1A9] mr-auto relative left-2 rounded-md flex font-medium items-center font-inter px-3 py-[6px] gap-3 w-[75%]">
    //   <img src={DangerInfo} alt="" />
    //   <p>{subscriptionStatus && subscriptionMessages[subscriptionStatus]}</p>
    // </div>
    <Alert className="py-2 border-red-500" variant="destructive">
      <AlertDescription className="flex items-center gap-2 text-red-500">
        <AlertCircle className="w-5 h-5" />
        {subscriptionStatus && subscriptionMessages[subscriptionStatus]}

        {!isBillingPage && (
          <button
            onClick={() => {
              navigate('/admin/settings/billing');
            }}
            className="text-[#5469D4] hover:opacity-80"
          >
            Go to billing
          </button>
        )}
      </AlertDescription>
    </Alert>
  );
};
