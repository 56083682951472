import type { EngagespotNotification } from '@engagespot/react-hooks';
import { useActions } from '@engagespot/react-hooks';
import type {
  ChangeNotificationRequest,
  TemplateBlock,
} from '@engagespot/template-blocks';
import { TemplateBlocksRender } from '@engagespot/template-blocks';
import type { MouseEvent as ReactMouseEvent } from 'react';
import { Fragment, useCallback, useMemo, useRef, useState } from 'react';

import {
  FeedItemDescription,
  FeedItemDescriptionPlaceholder,
  FeedItemHeader,
  FeedItemHeaderPlaceholder,
  FeedItemImage,
  FeedItemMenu,
  FeedItemPlaceholderImage,
  FeedItemPlaceholderStyled,
  FeedItemStyled,
  FeedItemTextContent,
  FeedItemTextContentPlaceholder,
  FeedItemTimeAgo,
  PlaceholderAnimation,
  TemplateBlocksContainer,
} from './NotificationFeedItem.styled';
import { renderCustom } from '../../../src/utils/renderCustom';
import themeConfig from '../../theme/themeConfig';
import { DropdownMenu } from '../dropdownMenu';
import { PLACEHOLDER_DEFAULT } from '@/constants';
import { useEngagespotContext } from '@/engagespotProvider';
import { useIsMobile } from '@/hooks/useIsMobile';
import { useText } from '@/hooks/useText';
import { Circle as FeedItemReadDot } from '@/icons/Circle';
import { dateTransformer, defaultDateFormatter } from '@/utils/dateUtils';
import { sanitizeHTML } from '@/utils/sanitizeHtml';

type ClickableNotificationPayload = {
  url: string;
  id: string;
  markAsClicked: () => void;
};

export type onFeedItemClickType = (
  evt: ReactMouseEvent<HTMLDivElement, MouseEvent>,
  payload: ClickableNotificationPayload,
) => void;

export function FeedItemPlaceholder() {
  const { hideNotificationAvatar } = useEngagespotContext();
  return (
    <FeedItemPlaceholderStyled>
      {!hideNotificationAvatar && (
        <FeedItemPlaceholderImage>
          <PlaceholderAnimation circle={true} />
        </FeedItemPlaceholderImage>
      )}
      <FeedItemTextContentPlaceholder>
        <FeedItemHeaderPlaceholder>
          <PlaceholderAnimation>&nbsp;</PlaceholderAnimation>
        </FeedItemHeaderPlaceholder>
        <FeedItemDescriptionPlaceholder>
          <PlaceholderAnimation>&nbsp;</PlaceholderAnimation>
        </FeedItemDescriptionPlaceholder>
      </FeedItemTextContentPlaceholder>
    </FeedItemPlaceholderStyled>
  );
}

const FeedItemTime = ({ createdAt }: { createdAt?: string }) => {
  const { formatDate } = useEngagespotContext();
  const transformDate = dateTransformer(formatDate || defaultDateFormatter);
  const time = transformDate(createdAt);

  return <FeedItemTimeAgo>{time}</FeedItemTimeAgo>;
};

export type NotificationFeedItemProps = {
  notification: EngagespotNotification;
};

export function NotificationFeedItem({
  notification,
}: NotificationFeedItemProps) {
  const { t } = useText();
  const context = useEngagespotContext();
  const isMobile = useIsMobile();

  const {
    title: heading,
    message: description,
    icon: imageUrl,
    url: clickableUrl,
    clickedAt: read,
    createdAt,
    blocks,
  } = notification;

  const santizedDescription = sanitizeHTML(description);
  const santizedHeading = sanitizeHTML(heading);

  const {
    renderNotificationBody,
    onFeedItemClick,
    feedItemPlaceholderImage: placeholderImage = PLACEHOLDER_DEFAULT,
    eventListenersToRun,
    actionableElements,
    hideNotificationAvatar,
  } = context;

  // TODO : convert later
  const isDeleted = false; // find out why this is needed ?

  const actions = useActions();
  const {
    markAsRead,
    changeState,
    deleteNotification: handleDeleteNotification,
  } = actions;

  const changeNotificationState = (data: ChangeNotificationRequest) =>
    changeState({ id: notification.id, ...data });

  const [isMenuVisible, setMenuVisibility] = useState(false);
  const [isImageBroken, setImageBroken] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const markAsClicked = useCallback(
    () => markAsRead(notification.id),
    [markAsRead, notification.id],
  );

  const deleteNotification = useCallback(
    () => handleDeleteNotification(notification.id),
    [handleDeleteNotification, notification.id],
  );

  const dropDownItems = useMemo(() => {
    if (read) {
      return [
        {
          name: t('notification.dropdownItems.delete'),
          action: deleteNotification,
        },
      ];
    }

    return [
      {
        name: t('notification.dropdownItems.delete'),
        action: deleteNotification,
      },
      {
        name: t('notification.dropdownItems.markAsRead'),
        action: markAsClicked,
      },
    ];
  }, [deleteNotification, markAsClicked, read, t]);

  const onItemEnter = () => {
    setMenuVisibility(true);
  };

  const onItemLeave = () => {
    setMenuVisibility(false);
  };

  const onClick = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    if (dropdownRef.current?.contains(event.target as Node)) {
      return;
    }

    if (onFeedItemClick) {
      onFeedItemClick(event, {
        url: clickableUrl,
        ...notification,
        markAsClicked,
      });
      return;
    }

    if (!clickableUrl) {
      return;
    }

    markAsClicked();
    window.open(clickableUrl, '__blank');
  };

  const getSrc = () => {
    if (imageUrl) {
      return imageUrl;
    }
    if (isImageBroken) {
      return placeholderImage;
    }

    return placeholderImage;
  };

  return (
    <FeedItemStyled
      clickable={clickableUrl != null}
      onMouseEnter={onItemEnter}
      onMouseLeave={onItemLeave}
      onFocus={onItemEnter}
      onBlur={onItemLeave}
      onClick={onClick}
    >
      {!hideNotificationAvatar && (
        <FeedItemImage
          src={getSrc()}
          onError={() => {
            setImageBroken(true);
          }}
        />
      )}

      <FeedItemTextContent>
        {renderCustom(renderNotificationBody, notification) || (
          <Fragment>
            <FeedItemHeader
              dangerouslySetInnerHTML={{ __html: santizedHeading }}
            />

            {description && (
              <FeedItemDescription
                dangerouslySetInnerHTML={{ __html: santizedDescription }}
              />
            )}

            <FeedItemTime createdAt={createdAt} />

            {blocks && blocks.length > 0 && (
              <TemplateBlocksContainer>
                <TemplateBlocksRender
                  blocks={blocks as TemplateBlock[]}
                  changeNotificationState={changeNotificationState}
                  eventListenersToRun={eventListenersToRun}
                  notification={notification}
                  actionableElementsProps={actionableElements}
                ></TemplateBlocksRender>
              </TemplateBlocksContainer>
            )}
          </Fragment>
        )}
      </FeedItemTextContent>

      {!isDeleted && (
        <FeedItemMenu ref={dropdownRef}>
          <DropdownMenu
            items={dropDownItems}
            isVisible={isMobile || isMenuVisible}
            theme={themeConfig.dropdown}
          />
          <FeedItemReadDot
            style={{ visibility: read ? 'hidden' : 'visible' }}
          />
        </FeedItemMenu>
      )}
    </FeedItemStyled>
  );
}
