import { ActionItem } from 'commandbar/itemTypes';
import { switchAppAction, useApp } from 'components/navbar/AppProvider';
import { useIsEditorPage } from 'utils/use-is-editor-page';
import { CheckIcon } from '@radix-ui/react-icons';
import { cn } from 'utils/class-merge';

export function SwitchApp() {
  const { apps, selectedApp } = useApp();
  const isEditorPage = useIsEditorPage();

  return (
    <>
      {apps.map(app => {
        return (
          <ActionItem
            key={app.value}
            action={switchAppAction}
            args={[app]}
            path={isEditorPage ? '/admin/api-credentials' : ''}
          >
            <span>{app.label}</span>

            <CheckIcon
              className={cn(
                'ml-2 h-4 w-4',
                selectedApp.value === app.value ? 'opacity-100' : 'opacity-0',
              )}
            />
          </ActionItem>
        );
      })}
    </>
  );
}
