// import { Card, CardContent } from 'components/shadcn/card';
import Card from 'components/card';
import Skeleton from 'components/skeleton';

export default function StripePaymentSkeleton() {
  return (
    <Card className="w-full max-w-md mx-auto bg-black text-white">
      <div className="p-0">
        {/* Payment Methods Grid */}
        <div className="grid grid-cols-4 gap-4 mb-8">
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} className="h-24 bg-gray-800 rounded-lg" />
          ))}
        </div>

        {/* Secure Checkout Text */}
        <div className="mb-8">
          <Skeleton className="h-6 w-3/4 bg-gray-800" />
        </div>

        {/* Card Number Field */}
        <div className="space-y-2 mb-6">
          <Skeleton className="h-5 w-32 bg-gray-800" />
          <Skeleton className="h-12 w-full bg-gray-800" />
        </div>

        {/* Expiry and CVC */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="space-y-2">
            <Skeleton className="h-5 w-24 bg-gray-800" />
            <Skeleton className="h-12 w-full bg-gray-800" />
          </div>
          <div className="space-y-2">
            <Skeleton className="h-5 w-24 bg-gray-800" />
            <Skeleton className="h-12 w-full bg-gray-800" />
          </div>
        </div>

        {/* Country Selector */}
        <div className="space-y-2 mb-8">
          <Skeleton className="h-5 w-24 bg-gray-800" />
          <Skeleton className="h-12 w-full bg-gray-800" />
        </div>
      </div>
    </Card>
  );
}
