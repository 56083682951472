import React from 'react';

import type { ContainerStyledProps } from './Container.styled';
import { ContainerStyled } from './Container.styled';

export const Container = (
  props: React.PropsWithChildren<ContainerStyledProps>,
) => {
  return <ContainerStyled {...props} />;
};
