import {
  useActions,
  usePreferences,
  useWebPush,
} from '@engagespot/react-hooks';
import { Fragment, useState } from 'react';
import { useLocalStorage } from 'react-use';

import {
  NotificationPanelArrowStyled,
  NotificationPanelPopper,
  NotificationPanelStyled,
} from './NotificationPanel.styled';
import { DropdownMenu } from '../dropdownMenu';
import type { Route } from '../engagespot/Engagespot';
import { NotificationFeed } from '../notificationFeed';
import { NotificationFooter } from '../notificationFooter';
import {
  NotificationHeader,
  NotificationHeaderCloseButtonStyled,
  NotificationHeaderTextStyled,
} from '../notificationHeader';
import {
  NotificationPreference,
  NotificationPreferenceBackButton,
  NotificationPreferenceModal,
  NotificationPreferenceOverlay,
} from '../notificationPreference';
import { Tabs } from '../tab/index';
import { useEngagespotContext } from '@/engagespotProvider';
import { useHasPreferences } from '@/hooks/useHasCategories';
import type { usePopover } from '@/hooks/usePopover';
import { useText } from '@/hooks/useText';
import { Back } from '@/icons/Back';
import { Close } from '@/icons/Close';
import themeConfig from '@/theme/themeConfig';

export type usePopOverReturnType = ReturnType<typeof usePopover>;

export type NotificationPanelProps = {
  visible: boolean;
  setVisible: usePopOverReturnType['setIsOpen'];
  floatingProps: usePopOverReturnType['floatingProps'];
  middlewareProps: usePopOverReturnType['middlewareProps'];
  panelProps: usePopOverReturnType['panelProps'];
};

const RenderPreferenceModal = () => {
  const { subscribe } = useWebPush();
  const [preferenceModal, showPreferenceModal] = useState(true);
  const [showNotificationOverlay, setLocalStorageValue] = useLocalStorage(
    'showNotificationOverlay',
    'true',
  );

  const enableWebPush = () => {
    return subscribe();
  };

  const closeModal = () => {
    showPreferenceModal(false);
    setLocalStorageValue('false');
  };

  const allowNotifications = () => {
    showPreferenceModal(false);
    enableWebPush();
    setLocalStorageValue('false');
  };

  if (preferenceModal && showNotificationOverlay == 'true') {
    return (
      <Fragment>
        <NotificationPreferenceOverlay />
        <NotificationPreferenceModal
          allowNotifications={allowNotifications}
          closeModal={closeModal}
        />
      </Fragment>
    );
  }

  return null;
};

const RenderHeader = ({
  route,
  setVisible,
  visible,
}: {
  route: Route;
  setVisible: NotificationPanelProps['setVisible'];
  visible: NotificationPanelProps['visible'];
}) => {
  const { t } = useText();
  const { headerText, setRoute, headerDropdownItems } = useEngagespotContext();
  const { webPushState, subscribe } = useWebPush();
  const { deleteAllNotifications, markAllAsRead } = useActions();
  const hasPreferences = useHasPreferences();
  const label =
    route === 'home'
      ? headerText
        ? headerText
        : t('header.headerText')
      : t('header.preferenceText');

  const setRouteAsHome = () => {
    setRoute('home');
  };

  const setRouteAsPreferences = () => {
    setRoute('preference');
  };

  const togglePanelVisibility = () => {
    setVisible(!visible);
  };

  const preferencesDropdownItems = hasPreferences
    ? [
        {
          name: t('header.dropdownItems.preferences'),
          action: setRouteAsPreferences,
        },
      ]
    : webPushState === 'default'
      ? [
          {
            name: t('header.dropdownItems.enableDesktopNotifications'),
            action: () => subscribe(),
          },
        ]
      : [];

  const defaultDropDownItems = [
    ...preferencesDropdownItems,
    {
      name: t('header.dropdownItems.markAllAsRead'),
      action: () => markAllAsRead(),
    },
    {
      name: t('header.dropdownItems.deleteAll'),
      action: () => deleteAllNotifications(),
    },
  ];

  const dropDownItems = defaultDropDownItems.concat(headerDropdownItems ?? []);

  return (
    <NotificationHeader>
      <NotificationPreferenceBackButton
        onClick={setRouteAsHome}
        style={{
          visibility: route === 'preference' ? 'visible' : 'hidden',
        }}
      >
        <Back />
      </NotificationPreferenceBackButton>

      <NotificationHeaderTextStyled isPreferenceView={route === 'preference'}>
        {label}
      </NotificationHeaderTextStyled>
      {dropDownItems.length > 0 ? (
        <DropdownMenu
          items={dropDownItems}
          isVisible={true}
          theme={themeConfig.headerDropdown}
        />
      ) : null}

      <NotificationHeaderCloseButtonStyled
        onClick={() => {
          togglePanelVisibility?.();
        }}
      >
        <Close />
      </NotificationHeaderCloseButtonStyled>
    </NotificationHeader>
  );
};

const RenderTabs = ({ showTabs }: { showTabs?: boolean }) => {
  const engagespotContext = useEngagespotContext();
  const { category, setCategory } = engagespotContext;
  const { preferences } = usePreferences();
  const categories = preferences?.categories || [];
  const tabItems = categories?.reduce(
    (acc, category) => {
      const inAppChannel = category.channels?.find(
        channel => channel.id === 'inApp',
      );

      if (inAppChannel?.enabled === false) return acc;

      return [
        ...acc,
        {
          identifier: category.identifier as string,
          label: category.name as string,
        },
      ];
    },
    [
      {
        identifier: '',
        label: 'All',
      },
    ],
  );
  const hasTabItems = tabItems?.length > 1; // ignoring the default

  return (
    <>
      {showTabs && hasTabItems ? (
        <Tabs activeTab={category} setActiveTab={setCategory} tabs={tabItems} />
      ) : null}
    </>
  );
};

const RenderRoute = ({ route }: { route: Route }) => {
  const { webPushState, subscribe } = useWebPush();

  const enableWebPush = () => {
    return subscribe();
  };

  if (route === 'home') {
    return <NotificationFeed />;
  }

  if (route === 'preference') {
    return (
      <NotificationPreference
        enableWebPush={enableWebPush}
        webPushState={webPushState}
      />
    );
  }

  return null;
};

export function NotificationPanel({
  floatingProps,
  visible = false,
  middlewareProps,
  setVisible,
  panelProps,
}: NotificationPanelProps) {
  const { route, hideCategoryTabs } = useEngagespotContext();
  const hasPreferences = useHasPreferences();

  return (
    <>
      {visible && (
        <NotificationPanelPopper
          style={floatingProps.styles}
          ref={floatingProps.setRef}
          {...floatingProps.getFloatingProps()}
        >
          <NotificationPanelArrowStyled
            ref={middlewareProps.arrow.setRef}
            style={{
              left: middlewareProps.arrow.middlewareData?.x,
              top: middlewareProps.arrow.middlewareData?.y,
              ...(middlewareProps.arrow.styles || {}),
            }}
          />
          <NotificationPanelStyled style={panelProps.styles}>
            <RenderPreferenceModal />
            <RenderHeader
              route={route}
              setVisible={setVisible}
              visible={visible}
            />
            <RenderTabs
              showTabs={hasPreferences && route === 'home' && !hideCategoryTabs}
            />
            <RenderRoute route={route} />
            <NotificationFooter showPreferences={hasPreferences} />
          </NotificationPanelStyled>
        </NotificationPanelPopper>
      )}
    </>
  );
}
