import type React from 'react';
import styled, { css } from 'styled-components';

export type TextAreaStyledProps = {
  autoGrow?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  ref?: React.RefObject<HTMLTextAreaElement>;
};

export const TextAreaStyled = styled.textarea.withConfig({
  shouldForwardProp: prop => !['autoGrow', 'ref'].includes(prop),
})<TextAreaStyledProps>`
  && {
    ${({
      theme: {
        actionableElement: { textArea },
      },
    }) => css`
      resize: ${textArea.resize};
      width: ${textArea.width};
      outline: ${textArea.outline};
      border: ${textArea.border};
      font-size: ${textArea.fontSize};
      padding: ${textArea.padding};
      border-radius: ${textArea.borderRadius};
      max-height: ${textArea.maxHeight};
      overflow: ${textArea.overflow};
      color: ${textArea.color};
      background: ${textArea.background};
      line-height: ${textArea.lineHeight};

      &:focus {
        border-color: ${textArea.focusBorderColor};
      }
    `}
  }
`;
