import { useGetWorkflows } from 'api/workflows';
import { Tag } from 'commandbar/components';
import { LinkItem } from 'commandbar/itemTypes';

export function Workflows() {
  const workflows = useGetWorkflows();

  return (
    <>
      {workflows.data?.map(workflow => {
        return (
          <LinkItem path={`/admin/workflow-overview/${workflow.id}`}>
            <div className="flex items-center gap-10">
              <span>{workflow.name}</span>
              <Tag type={workflow.isActive ? 'success' : 'warn'}>
                {workflow.isActive ? 'Enabled' : 'Disabled'}
              </Tag>
            </div>
          </LinkItem>
        );
      })}
    </>
  );
}
