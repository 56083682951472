import {
  createStore,
  type StoreInstance,
  type StoreOptions,
} from '@engagespot/store';
import type { createLogger } from '@engagespot/utils';
import { useEffect, useState } from 'react';

type UseStoreInstanceOptions = {
  log: ReturnType<typeof createLogger>;
};

const connectInstance = (storeInstance: StoreInstance) => {
  storeInstance.instance.realTime.connect();
};

export function useStoreInstance(
  options: StoreOptions,
  _log: UseStoreInstanceOptions,
) {
  const [storeInstance, setStoreInstance] = useState<StoreInstance>(() =>
    createStore({ ...options, disableAutoStart: true }),
  );

  useEffect(() => {
    // Set up and connect a new instance
    const setUpAndConnectInstance = () => {
      // Ensure the current instance is disconnected
      storeInstance.instance.realTime.disconnect();

      // Create a new instance and connect it
      const newStoreInstance = createStore({
        ...options,
        disableAutoStart: true,
      });
      connectInstance(newStoreInstance);
      return newStoreInstance;
    };

    // Check if we need to create and connect a new instance
    if (
      storeInstance.instance.apiKey !== options.apiKey ||
      storeInstance.instance.userId !== options.userId
    ) {
      const newStoreInstance = setUpAndConnectInstance();
      setStoreInstance(newStoreInstance);
    } else {
      // If the options haven't changed, just connect the existing instance
      connectInstance(storeInstance);
    }

    // Clean up function to disconnect when the component unmounts or dependencies change
    return () => {
      storeInstance.instance.realTime.disconnect();
    };
  }, [options.apiKey, options.userId]); // Depend on apiKey and userId

  return storeInstance;
}
