export default {
  colors: {
    brandingPrimary: 'white',
    colorPrimary: '#282c34',
    colorSecondary: '#686868',
  },
  panel: {
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    width: '26.25rem',
    height: '40rem',
    borderBottomLeftRadius: '11px',
    borderBottomRightRadius: '11px',
    borderTopLeftRadius: '11px',
    borderTopRightRadius: '11px',
    arrowSize: '10px',
    arrowInset: '-5px auto auto 0',
    border: '',
  },
  feed: {
    background: 'white',
    placeholderTextColor: '#2c2c2c',
    height: '30rem',
    placeholderTextSize: '1rem',
    placeholderMargin: '1rem 0 0 0',
    placeholderFontWeight: '300',
  },
  feedItem: {
    border: '1px solid #edf4f2',
    background: 'white',
    notificationDot: 'green',
    hoverBackground: '#919eab14',
    headerColor: '#1c1c1c',
    descriptionColor: '#464646',
    dateColor: '#888888',
    placeHolderBackground: 'rgb(230, 230, 230)',
    placeHolderGradient:
      'linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%)',
    height: '30rem',
    padding: '1.25rem',
    placeholderTextSize: '1rem',
    notificationDotSize: '.5rem',
    imageSize: '2.5rem',
    imageRadius: '50%',
    hoverTransition: 'all 0.2s',
    textContentPadding: '0 .5rem 0 0',
    textContentMargin: '0 auto 0 1.2rem',
    headerFontWeight: '400',
    headerSize: '.875rem',
    headerPadding: '0 0 0.3rem 0',
    descriptionSize: '0.8rem',
    descriptionPadding: '0 0 0.3rem 0',
    dateSize: '.75rem',
    menuMargin: '0 1rem 0 0',
  },
  notificationButton: {
    background: 'transparent',
    hoverBackground: 'rgb(176 176 176 / 20%)',
    iconFill: '#999999',
    floatingButtonShadow:
      '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    iconSize: '1.5rem',
    outline: 'none',
    margin: '0',
    padding: '5px',
    borderWidth: '0',
    normalButtonRadius: '5px',
    floatingButtonRadius: '50%',
    transition: 'all .2s',
  },
  unreadBadgeCount: {
    background: 'red',
    color: 'white',
    borderRadius: '50%',
    fontSize: '.6rem',
    inset: '-4px -4px auto auto',
    size: '1rem',
  },
  headerDropdown: {
    iconFill: 'black',
    background: 'transparent',
    hoverBackground: '',
    menuBackground: 'white',
    menuShadow: '0 0 8px 0 rgba(0, 0, 0, 0.14)',
    menuItemTextColor: '#282c34',
    menuItemHoverBackground: '#edf4f2',
    iconWidth: '.6rem',
    iconHeight: '.7rem',
    margin: '0 .5rem 0 0',
    padding: '.5rem',
    borderWidth: '',
    outline: 'none',
    transition: 'all 0.2s',
    menuBorderRadius: '2px',
    menuBorder: '',
    menuItemPadding: '0.5rem 0.7rem',
  },
  dropdown: {
    iconFill: '#888888',
    background: 'transparent',
    hoverBackground: 'rgb(230, 230, 230)',
    menuBackground: 'white',
    menuShadow: '0 0 8px 0 rgba(0, 0, 0, 0.14)',
    menuItemTextColor: '#282c34',
    menuItemHoverBackground: '#edf4f2',
    iconWidth: '.5rem',
    iconHeight: '.6rem',
    margin: '0 .5rem 0 0',
    padding: '.5rem',
    borderWidth: '0',
    outline: 'none',
    transition: 'all 0.2s',
    menuBorderRadius: '2px',
    menuItemPadding: '0.5rem 0.7rem',
    menuBorder: '',
  },
  jumpToTop: {
    background: 'white',
    iconFill: '#888888',
    shadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    iconSize: '1rem',
    inset: '1.5rem 7rem auto auto',
    borderRadius: '50%',
    padding: '0.7rem',
    margin: '0',
    transition: 'all ease-in-out 0.5s',
  },
  header: {
    fontColor: '#212B36',
    closeButtonBackground: 'transparent',
    fontSize: '1.175rem',
    fontWeight: '700',
    height: '3rem',
    // padding: '0.5rem 1rem',
    closeButtonColor: 'black',
    backButtonColor: 'black',
    padding: '2rem 1rem',
    closeButtonOutline: 'none',
    closeButtonPadding: '5px',
    closeButtonFontSize: '1.6rem',
    closeButtonMargin: '0',
    lineHeight: '1.75rem',
    borderBottom: '1px solid #e9ecee',
  },
  footer: {
    background: 'white',
    fontColor: '#484848',
    preferenceButtonColor: '#484848',
    border: '1px solid rgb(230, 230, 230)',
    preferenceButtonHoverBackground: 'rgb(230, 230, 230)',
    fontWeight: '400',
    height: '3rem',
    padding: '0.5rem 1rem',
    fontSize: '0.8rem',
    linkMargin: '0 0.5rem 0 auto',
    linkSize: '1.5rem',
    preferenceButtonMargin: '0 0 0 auto',
    preferenceButtonSize: '1.2rem',
    preferenceButtonPadding: '.2rem',
    preferenceButtonHoverTransition: 'all 0.2s',
    linkRadius: '3px',
  },
  toggle: {
    background: 'rgba(196, 195, 195, 0.55)',
    activeColor: '#16a085',
    dotColor: 'rgb(255, 255, 255);',
  },
  preference: {
    fontColor: '#1c1c1c',
    background: 'white',
    height: '30rem',
    fontWeight: '400',
    padding: '1.2rem 1.5rem 1.2rem 1.5rem',
    fontSize: '1rem',
    itemBorder: '1px solid #d6d9db',
    itemBorderRadius: '13px',
    itemToggleColor: 'black',
  },
  preferenceModal: {
    overlayBackground: '#2b2b2b',
    headingColor: '#1c1c1c',
    background: 'rgba(255, 255, 255, 0.8)',
    closeButtonColor: '#bbb9b9',
    textPrimaryColor: '#1c1c1c',
    textSecondaryColor: '#525252',
    buttonPrimaryColor: 'white',
    buttonPrimaryBackgroundColor: '#1abc9c',
    buttonPrimaryHoverBackgroundColor: '#1a9c82',
    buttonSecondaryColor: '#4e4e4e',
    buttonSecondaryBackgroundColor: 'transparent',
    buttonSecondaryHoverBackgroundColor: '#cfcfcf',
    overlayOpacity: '.6',
    height: '35%',
    backdropFilter: 'blur(1px)',
    borderRadius: '1.5rem 1.5rem 0 0',
    padding: '1.2rem 2rem 1.2rem 2rem',
    textAlign: 'center',
    headerMargin: '0 2rem',
    headerFontSize: '1rem',
    closeButtonSize: '.6rem',
    textPrimaryMargin: '0.5rem 2rem',
    textPrimaryFontSize: '.9rem',
    textSecondaryMargin: '1rem 0 0 0',
    textSecondaryFontSize: '.8rem',
    primaryButtonFontWeight: '600',
    primaryButtonPadding: '0.6rem 4.5rem',
    primaryButtonBorderRadius: '10px',
    primaryButtonMargin: '0.6rem 0 0.2rem 0',
    primaryButtonTransition: 'all 0.2s',
    border: '',
  },
  tab: {
    gap: '1.3rem',
    background: '#ffffff',
    borderBottom: '1px solid  #e0e0e0',
    buttonColor: '#5f6368',
    buttonBackground: 'none',
    buttonBorder: 'none',
    buttonHoverColor: '#202124',
    buttonPadding: '1rem 0.75rem',
    buttonActiveColor: '#202124',
    buttonFontSize: '0.875rem',
    buttonFontWeight: '400',
    buttonActiveFontWeight: '500',
    buttonActiveIndicatorHeight: '0.24rem',
    buttonActiveIndicatorBackground: 'black',
    buttonActiveIndicatorTransition: 'transform 0.2s ease-in-out',
    scrollButtonWidth: '1.75rem',
    scrollButtonHeight: '1.75rem',
    scrollButtonBackground: '#f1f3f4',
    scrollButtonFontSize: '1.125rem',
    scrollButtonColor: '#5f6368',
    scrollButtonDisabledColor: '#bdc1c6',
    scrollButtonBorder: '1px solid #dadada',
    scrollButtonHoverColor: '#3c4043',
    scrollButtonHoverBackground: '#e0e3e5',
  },
  actionableElement: {
    button: {
      // primary
      primaryButtonBorderRadius: '8px',
      primaryButtonPadding: '0.575rem 0.9rem',
      primaryButtonFontWeight: '700',
      primaryButtonFontSize: '0.875rem',
      primaryButtonBackground: 'black',
      primaryButtonHoverBackground: 'rgba(0, 0, 0, 0.85)',
      primaryButtonColor: 'white',
      primaryButtonMargin: '0',
      primaryButtonBorder: '1px solid #212b36',
      primaryButtonTransition: 'all 0.2s',
      // secondary
      secondaryButtonBorderRadius: '8px',
      secondaryButtonPadding: '0.575rem 0.9rem',
      secondaryButtonFontWeight: '700',
      secondaryButtonFontSize: '0.875rem',
      secondaryButtonBackground: 'white',
      secondaryButtonHoverBackground: 'rgba(255, 255, 255, 0.85)',
      secondaryButtonColor: 'black',
      secondaryButtonMargin: '0',
      secondaryButtonBorder: '1px solid #919eab52',
      secondaryButtonTransition: 'all 0.2s',
    },
    image: {
      height: '136px',
      objectFit: 'cover',
    },
    ratingStar: {
      size: '23px',
    },
    textArea: {
      fontSize: '0.75rem',
      padding: '0.675rem 0.75rem',
      borderRadius: '6px',
      border: '1px solid #d9d9dc',
      maxHeight: '150px',
      resize: 'none',
      outline: 'none',
      width: '100%',
      overflow: 'auto',
      focusBorderColor: '#808080',
      background: 'white',
      color: 'black',
      lineHeight: 'normal',
    },
  },
};
