import styled, { css } from 'styled-components';

export const TabsWrapper = styled.div`
  && {
    ${({ theme: { tab } }) => css`
      display: flex;
      align-items: center;
      width: 100%;
      background-color: ${tab.background};
      border-bottom: ${tab.borderBottom};
      padding: 0 1rem;
    `}
  }
`;

export const TabsContainer = styled.div`
  && {
    ${({ theme: { tab } }) => css`
      display: flex;
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      flex-grow: 1;
      gap: ${tab.gap};

      &::-webkit-scrollbar {
        display: none;
      }
    `}
  }
`;

export const TabButton = styled.button.withConfig({
  shouldForwardProp: prop => !['isActive'].includes(prop),
})<{ isActive: boolean }>`
  && {
    ${({ theme: { tab }, isActive }) => css`
      padding: ${tab.buttonPadding};
      background: ${tab.buttonBackground};
      border: ${tab.buttonBorder};
      color: ${isActive ? tab.buttonActiveColor : tab.buttonColor};
      font-size: ${tab.buttonFontSize};
      font-weight: ${isActive
        ? tab.buttonActiveFontWeight
        : tab.buttonFontWeight};
      cursor: pointer;
      transition: color 0.2s ease-in-out;
      position: relative;
      flex-shrink: 0;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.0625rem;
        left: 0;
        width: 100%;
        height: ${tab.buttonActiveIndicatorHeight};
        background-color: ${tab.buttonActiveIndicatorBackground};
        transform: scaleX(${isActive ? 1 : 0});
        transition: ${tab.buttonActiveIndicatorTransition};
      }

      &:hover {
        color: ${tab.buttonHoverColor};
      }

      &:focus {
        outline: none;
      }
    `}
  }
`;

export const ScrollButton = styled.button.withConfig({
  shouldForwardProp: prop => !['direction'].includes(prop),
})<{
  disabled: boolean;
  direction: 'left' | 'right';
}>`
  && {
    ${({ theme: { tab }, disabled, direction }) => css`
      width: ${tab.scrollButtonWidth};
      height: ${tab.scrollButtonHeight};
      background-color: ${tab.scrollButtonBackground};
      cursor: ${disabled ? 'default' : 'pointer'};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${tab.scrollButtonFontSize};
      color: ${disabled
        ? tab.scrollButtonDisabledColor
        : tab.scrollButtonColor};
      transition: color 0.2s;
      flex-shrink: 0;
      opacity: ${disabled ? 0.5 : 1};
      border: ${tab.scrollButtonBorder};
      transform: ${direction === 'left' ? 'rotate(180deg)' : ''};

      &:focus {
        outline: none;
      }

      &:hover {
        color: ${disabled
          ? tab.scrollButtonDisabledColor
          : tab.scrollButtonHoverColor};
        background-color: ${disabled
          ? tab.scrollButtonBackground
          : tab.scrollButtonHoverBackground};
      }
    `}
  }
`;
