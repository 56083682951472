import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const initI18n = ({
  apiKey,
  userId,
  baseUrl,
  debug,
}: {
  apiKey: string;
  userId: string;
  baseUrl: string;
  debug?: boolean;
}) => {
  return i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
      debug,
      react: {
        useSuspense: false,
      },
      backend: {
        loadPath: `${baseUrl}v3/translations/inapp`,
        customHeaders: {
          'x-engagespot-api-key': apiKey,
          'x-engagespot-user-id': userId,
        },
      },
    });
};
