import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PutAddApps = {
  name?: string;
  categoryId: number;
  type: 'delete' | 'put';
  hideInPreferenceManager?: boolean;
  hideInInApp?: boolean;
};

const putOrDelete = async (values: PutAddApps) => {
  const { categoryId, type, ...data } = values;

  const url = `${routes['categories']}/${categoryId}`;

  if (type === 'delete') {
    return axiosInstance.delete(url);
  }

  return axiosInstance.put(url, data);
};

const usePutOrDeleteCategories = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putOrDelete,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/categories`]);
    },
  });
};

export default usePutOrDeleteCategories;
