import { defaults } from '../data/defaults';
import { handleError } from '../helpers/validateIncomingArgs';
import type { InitServiceFn, ServiceArgs } from '../modules/service';
import {
  attachPushSubscription,
  listenForWebPushPermissionChanges,
} from '../modules/webPushHelpers';
import {
  askWebPushPermission,
  canRegisterServiceWorker,
  clearWebPushSubscription,
  getWebPushRegistrationState,
  getWebPushSubscription,
  isWebPushSupported,
  registerServiceWorker,
} from '../utils/webPush';

type WebPushServiceArgs = ServiceArgs<'connectService'>;

export function webPushService({
  dependencies: {
    log,
    events,
    deviceMetadata: { deviceId },
    apiClient,
    options: {
      disableWebPush,
      disableAutoStart,
      allowNonHttpsWebPush,
      serviceWorkerRegistration,
    },
  },
  requiredServices: { connectService },
}: WebPushServiceArgs) {
  const isWebPushEnabled = async () => {
    await connectService.api.ready();
    const webpushEnabled = await connectService.api.isWebPushEnabled();
    return webpushEnabled;
  };

  const validateWebPush = async () => {
    const webpushEnabled = await isWebPushEnabled();

    if (!webpushEnabled) {
      log.info('Web push is disabled in the admin console.');
      return false;
    }

    if (disableWebPush) {
      log.info('Web push is disabled in the configuration.');
      return false;
    }

    if (allowNonHttpsWebPush) {
      log.warn('Webpush is currently enabled for non-https sites.');
    }

    if (!canRegisterServiceWorker()) {
      handleError('webPushNotSupported');
      return false;
    }
    return true;
  };

  const getServiceWorkerRegistration = async () => {
    try {
      if (serviceWorkerRegistration) {
        //If they passed their own serviceWorker, then wait until it's ready.
        return await window.navigator.serviceWorker.ready;
      }
      log.info('Registering service worker');
      const ready = await registerServiceWorker(defaults.serviceWorkerUrl);
      log.info('Service worker registered', ready);
      return ready;
    } catch (err) {
      handleError('serviceWorkerFileMissing');
      return;
    }
  };
  let workerRegistrationPromise: Promise<ServiceWorkerRegistration | void> | void;

  const connect = async () => {
    const isValid = await validateWebPush();

    if (isValid) {
      workerRegistrationPromise = getServiceWorkerRegistration();
      listenForWebPushPermissionChanges({ events });
      return workerRegistrationPromise;
    }
    return true;
  };

  if (!disableAutoStart) {
    connect();
  }

  const returnValues = {
    connect,
    isSupported: isWebPushSupported,
    getRegistrationState: getWebPushRegistrationState,
    clearWebPushSubscription,
    /**
     * Attach the push subscription for this device
     */

    async subscribe() {
      if (!isWebPushSupported()) {
        log.info('Web push not supported');
        return;
      }
      const webpushEnabled = await isWebPushEnabled();
      if (!webpushEnabled) {
        log.info('Web push is not enabled for this app');
        return;
      }

      //Check permission state
      const permissionState = getWebPushRegistrationState();
      const { data, error } = await connectService.api.getInfoCached();
      if (error) {
        log.error('Error getting app info', error);
        return;
      }
      const publicKey = data?.app?.publicKey;
      if (!publicKey) {
        log.error('Public key not found in app info');
        return;
      }

      if (permissionState != 'denied') {
        log.info('Requesting web push permission', permissionState);
        const permissionResult = await askWebPushPermission();
        if (permissionResult !== 'granted') {
          log.error('Web push permission was not granted.');
          return;
        }

        if (!workerRegistrationPromise) {
          log.info('Service worker registration not found.');
          return;
        }
        const swRegistration = await workerRegistrationPromise;
        if (!swRegistration) {
          log.info('Service worker registration not found.');
          return;
        }

        const subscription = await getWebPushSubscription(
          swRegistration,
          publicKey,
        );
        await attachPushSubscription(subscription, {
          apiClient,
          deviceId,
        });
      } else {
        log.errorAlways(
          'Web push permission was denied. Please enable it in your browser settings.',
        );
      }
    },
  };
  return {
    api: { ...returnValues },
    publicApi: { ...returnValues },
  };
}

webPushService.key = 'webPushService' as const;

export const initWebPushService = <T extends InitServiceFn>(initService: T) => {
  const webPushService = initService({
    key: 'webPushService',
    requiredServiceKeys: ['connectService'],
  });
  return webPushService;
};
