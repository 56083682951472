import type {
  EngagespotWebPushPermissionState,
  Instance,
} from '@engagespot/core';
import { atom, computed } from 'nanostores';

import { createWebPushFetchBuilder } from './webPushFetcher';
import type { InitServiceFn, ServiceArgs } from '../../utils/service';

type ConnectServiceArgs = ServiceArgs<'webPushService'>;

type ConnectFetcherStore = Awaited<ReturnType<Instance['app']['connect']>>;

export function webPushService({
  dependencies: {
    log,
    instance,
    options: { apiKey, userId, disableRealTimeStoreUpdates },
  },
}: ConnectServiceArgs) {
  const { createFetcherStore } = createWebPushFetchBuilder({
    instance,
    log,
  });

  const {
    subscribe,
    clearWebPushSubscription,
    getRegistrationState,
    isSupported,
  } = instance.webPush;

  const initialWebPushState = getRegistrationState();
  const $webPushState =
    atom<EngagespotWebPushPermissionState>(initialWebPushState);
  const $webPushFetcherStore = createFetcherStore<ConnectFetcherStore>([
    apiKey,
    userId,
  ]);

  /**
   * This is a hacky solution to cache the webPushFetcherStore.
   * Otherwise the webPushFetcherStore will be recreated on every render and
   * we'll get the “The result of getSnapshot should be cached”  error on console.
   */
  const $webPushFetcher = computed(
    $webPushFetcherStore,
    webPushFetcherStore => webPushFetcherStore,
  );

  if (!disableRealTimeStoreUpdates) {
    instance.events.webPushPermissionChange.subscribe(data => {
      $webPushState.set(data.permission);
    });
  }

  const stores = { $webPushFetcher, $webPushState };

  const actions = {
    subscribe,
    clearWebPushSubscription,
    isSupported,
  };

  return { stores, actions };
}

webPushService.key = 'webPushService' as const;

export const initWebPushService = <T extends InitServiceFn>(initService: T) => {
  const app = initService({
    key: 'webPushService',
    requiredServiceKeys: [],
  });
  return app;
};
