import { useStore } from '@nanostores/react';
import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import usePostTemplateState from 'api/templates/post-template-state';
import {
  UpdateTemplatePutReturnType,
  updateTemplates,
} from 'api/templates/update-templates';
import { useEditWorkflowJson, useSaveWorkflowStateSpec } from 'api/workflows';
import { AxiosResponse } from 'axios';
import { SubmitHandler, UseFormReset } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTemplateStore } from 'store/templateStore';
import { allTemplateDetails } from 'templates';
import { $currentTemplate, $templateConfigValues } from 'templates/store';
import { DEFAULT_TEMPLATE_STATE } from 'templates/utils';
import { R } from 'utils/remeda-utils';
import useCustomToast from 'utils/use-toast';
import { setUpdatedWorkflowJson } from '../../workflowEditor/functions';
import { getTemplateData, isFileThenConvertToUrl } from '../functions';
import { useGetEditorType } from '../hooks/getEditorType';
import { useGetFilteredTemplateConfigStates } from '../hooks/getFilteredTemplateConfigStates';
import { TemplateEditorAvailabeChannels } from '../variables/inputs';
import { getHashidsInstance } from 'utils/use-hash-id';

export const useGetTemplateEditorOnSubmit = ({
  isBatchingEnabled,
  reset,
}: {
  isBatchingEnabled: boolean;
  reset: UseFormReset<any>;
}) => {
  const params = useParams();
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const hashIds = getHashidsInstance();
  const templateId = String(hashIds.decode(params.templateId));
  const templateIdentifier = params.templateIdentifier;
  const { editorTypeTemplate, editorTypeWorkflow } = useGetEditorType();

  const { setLoading, tab } = useTemplateStore(state => state);
  const queryClient = useQueryClient();
  const toast = useCustomToast();
  const currentTemplate = useStore($currentTemplate);
  const templateConfigValues = useStore($templateConfigValues);
  const postTemplateState = usePostTemplateState();
  const saveWorkflowStateSpec = useSaveWorkflowStateSpec();
  const filteredTemplateConfigAllStates = useGetFilteredTemplateConfigStates();
  const editWorkflowJson = useEditWorkflowJson();

  const onSubmit: SubmitHandler<any> = async values => {
    try {
      setLoading(true);

      const getTemplateDataResponse = await getTemplateData({
        values,
        channel,
        tab,
        filteredTemplateConfigAllStates,
        templateIdentifier,
        templateConfigValues,
        currentTemplate,
        toast,
        setLoading,
      });
      const { templateData, batchedTemplateData } = getTemplateDataResponse;

      if (!templateData || !batchedTemplateData) {
        setLoading(false);
        return toast.error('channel not configured in frontend');
      }

      let editorTypeTemplateResponse;
      if (editorTypeTemplate) {
        editorTypeTemplateResponse = await updateTemplates({
          id: templateId,
          channelType: channel,
          data: templateData,
          type: 'put_content',
        });
      }

      // if (editorTypeWorkflow) {
      //   workflowTemplateEditorUpdateJson({
      //     templateIdentifier,
      //     template: templateData.content,
      //   });
      // }

      // also saving state of different template inputs
      if (channel === 'inApp') {
        const rawData = (getTemplateDataResponse as any)?.rawData;
        // we also need to handle the case where we go from using a template to default inApp
        // if no template is selected simply sent empty string or 0

        /**
         * all our inputs are of the format ${state}_inputName
         * eg: default_button_label, accept_button_label
         * so need need to seperate it into different keys and do additonal operations
         * simply store all values into "default" key and fetch it
         */
        const defaultTemplateValues =
          allTemplateDetails?.[currentTemplate]['defaultValues'];

        const updatedTemplateConfigValues = Object.keys(
          templateConfigValues,
        ).reduce((acc, curr) => {
          const value = R.isBoolean(rawData?.[curr])
            ? rawData?.[curr]
            : Boolean(rawData?.[curr])
              ? rawData?.[curr]
              : defaultTemplateValues[curr];

          return {
            ...acc,
            [curr]: value,
          };
        }, {});

        await isFileThenConvertToUrl({
          data: updatedTemplateConfigValues,
          toast,
          channel,
          setLoading,
        });

        if (editorTypeTemplate) {
          await postTemplateState.mutateAsync({
            templateId,
            state: DEFAULT_TEMPLATE_STATE,
            templateContentId: Number(
              (
                editorTypeTemplateResponse as AxiosResponse<UpdateTemplatePutReturnType>
              )?.data?.id ?? 0,
            ),
            stateSpec: {
              [DEFAULT_TEMPLATE_STATE]: updatedTemplateConfigValues,
              templateId: currentTemplate,
            },
          });
        }

        if (editorTypeWorkflow) {
          saveWorkflowStateSpec.mutate({
            workflowId: templateId,
            data: {
              ref: templateIdentifier,
              state: DEFAULT_TEMPLATE_STATE,
              stateSpec: {
                [DEFAULT_TEMPLATE_STATE]: updatedTemplateConfigValues,
                templateId: currentTemplate,
              },
            },
          });
        }
      }

      if (editorTypeTemplate) {
        await updateTemplates({
          id: templateId,
          channelType: channel,
          data: {
            batchingEnabled: isBatchingEnabled,
            batchingWindow: parseInt(values.batchingWindow),
          },
          type: 'patch_content',
        });

        if (isBatchingEnabled) {
          await updateTemplates({
            id: templateId,
            channelType: channel,
            data: batchedTemplateData,
            type: 'put_batching_content',
          });
        }

        // TODO: optimise this
        // Currently just quick fix for showing batching enabled green dot in templateHeader
        queryClient.invalidateQueries({
          queryKey: [`${routes['templates']}`],
        });
      }

      if (editorTypeWorkflow) {
        await setUpdatedWorkflowJson({
          templateIdentifier,
          template: templateData.content,
          editWorkflowJson,
          templateId,
        });
      }

      toast.success(
        'template saved successfully. But you must publish the workflow to make it live',
      );

      // form is reset, to make isDirty false
      // make sure to refetch get apis to refill form
      reset(undefined, { keepValues: true });

      setTimeout(() => {
        setLoading(false);
      }, 850);
    } catch (e) {
      toast.showError(e);
      setLoading(false);
    }
  };

  return {
    onSubmit,
  };
};
