import { useDisclosure } from '@chakra-ui/hooks';
import { useQueryClient } from '@tanstack/react-query';
import usePostCancelSubscription from 'api/billing/post-cancel-plan';
import { routes } from 'api/routes';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/shadcn/alert-dialog';
import { Button } from 'components/shadcn/button';
import { Crown, Heart, Rocket, Shield, Users } from 'lucide-react';
import useCustomToast from 'utils/use-toast';
import { useGetCurrentBillingPlanDetails } from '../hooks';
import CancelMessageModal from './CancelMessageModal';

function CancelPlanModal({
  isOpen,
  onOpenChange,
}: {
  onOpenChange?(open: boolean): void;
  isOpen: boolean;
}) {
  const { subscription } = useGetCurrentBillingPlanDetails();
  const postCancelPlan = usePostCancelSubscription();
  const toast = useCustomToast();
  const cancelPlanMessageModalActions = useDisclosure();
  const queryClient = useQueryClient();

  const handleClose = () => {
    onOpenChange(false);
  };

  const onSubmit = () => {
    const data = {
      subscription_id: subscription?.id,
    };

    postCancelPlan.mutate(data, {
      onSuccess: () => {
        handleClose();
        cancelPlanMessageModalActions.onOpen();

        // It takes some time to update the subscription details
        setTimeout(() => {
          queryClient.invalidateQueries([
            `${routes['v1_client']}/subscription`,
          ]);
        }, 1000);
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  return (
    <>
      <CancelMessageModal
        isOpen={cancelPlanMessageModalActions.isOpen}
        onClose={cancelPlanMessageModalActions.onClose}
      />

      <AlertDialog open={isOpen} onOpenChange={onOpenChange}>
        <AlertDialogContent className="max-w-2xl p-8">
          <AlertDialogHeader className="space-y-8">
            <AlertDialogTitle className="text-2xl text-white font-semibold">
              Are You Sure You Want to Cancel ?
            </AlertDialogTitle>

            <AlertDialogDescription className="space-y-6 text-base">
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <Crown className="h-6 w-6 shrink-0 text-primary" />
                  <p>
                    Premium Features: Say goodbye to exclusive features that
                    enhance your experience with us.
                  </p>
                </div>
                <div className="flex items-start gap-4">
                  <Shield className="h-6 w-6 shrink-0 text-primary" />
                  <p>
                    Priority Support: As a subscriber, you enjoy faster and
                    top-notch support from our team.
                  </p>
                </div>
                <div className="flex items-start gap-4">
                  <Rocket className="h-6 w-6 shrink-0 text-primary" />
                  <p>
                    Future Upgrades: We're continuously working on exciting
                    upgrades, and as a subscriber, you get early access.
                  </p>
                </div>
                <div className="flex items-start gap-4">
                  <Users className="h-6 w-6 shrink-0 text-primary" />
                  <p>
                    Engaging Community: Join our vibrant community to connect
                    with like-minded individuals and share insights.
                  </p>
                </div>
                <div className="flex items-start gap-4">
                  <Heart className="h-6 w-6 shrink-0 text-primary" />
                  <p>
                    Reliable Service: Trust in our service's stability and
                    reliability that subscribers appreciate.
                  </p>
                </div>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter className="mt-8 flex items-center justify-between">
            <Button
              variant="destructive"
              className="w-full text-base font-normal"
              disabled={postCancelPlan.isLoading}
              onClick={() => onSubmit()}
            >
              No, I still want to cancel
              {postCancelPlan.isLoading && (
                <LoaderSpinner
                  strokeColor={'#EE5D50'}
                  parentClass="relative left-1"
                  width="18"
                />
              )}
            </Button>

            <AlertDialogCancel className="w-full text-base font-normal text-white">
              Yes, keep my subscription
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}

export default CancelPlanModal;
