import { EngagespotStyled } from './components/engagespot/Engagespot.styled';
import * as themeConfig from './theme/themeConfig';

export const _internals = {
  EngagespotStyled,
  themeConfig: themeConfig.default,
};

export {
  Engagespot,
  EngagespotProvider,
  EngagespotCore,
  type EngagespotProps,
  type EngagespotCoreProps,
} from './components/engagespot/Engagespot';

export * from '@engagespot/react-hooks';
