import { EngagespotProps } from '@engagespot/react-component';

const bgPrimary = '#09090B';
const bgSecondary = '#101315';
const bgTertiary = '#313131';
const textPrimary = '#FFF';
const textSecondary = '#ABB0B9';

const border = '.5px solid hsl(var(--input))';
const borderThick = '1px solid hsl(var(--input))';

export const darkTheme: EngagespotProps['theme'] = {
  colors: {
    brandingPrimary: bgPrimary,
    colorSecondary: 'green',
    colorPrimary: textPrimary,
  },
  notificationButton: {
    iconFill: textPrimary,
    hoverBackground: bgSecondary,
  },
  panel: {
    width: '25rem',
    height: '500px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);',
    border: borderThick,
  },
  header: {
    fontColor: textPrimary,
    closeButtonColor: textSecondary,
    borderBottom: border,
    backButtonColor: textSecondary,
  },
  headerDropdown: {
    iconFill: textSecondary,
    background: bgPrimary,
    menuBackground: bgPrimary,
    menuBorder: border,
    menuItemTextColor: textPrimary,
    menuItemHoverBackground: bgSecondary,
  },
  tab: {
    background: bgPrimary,
    buttonActiveColor: textPrimary,
    borderBottom: borderThick,
    buttonHoverColor: textSecondary,
  },
  feed: {
    background: bgPrimary,
  },
  feedItem: {
    hoverBackground: bgSecondary,
    background: bgPrimary,
    border,
    headerColor: textPrimary,
    descriptionColor: textSecondary,
    placeHolderGradient: `linear-gradient(to right, ${bgSecondary} 10%, ${bgTertiary} 18%, ${bgSecondary} 33%)`,
  },
  footer: {
    background: bgPrimary,
    border: border,
    preferenceButtonHoverBackground: bgSecondary,
  },
  dropdown: {
    background: bgPrimary,
    hoverBackground: bgPrimary,
    menuItemTextColor: textPrimary,
    menuBackground: bgPrimary,
    menuBorder: border,
    menuItemHoverBackground: bgSecondary,
  },
  preference: {
    background: bgPrimary,
    fontColor: textPrimary,
    itemBorder: border,
    itemBorderRadius: '8px',
    itemToggleColor: textSecondary,
  },
  toggle: {
    background: textSecondary,
    dotColor: bgPrimary,
    activeColor: textPrimary,
  },
  preferenceModal: {
    background: bgPrimary,
    textPrimaryColor: textPrimary,
    closeButtonColor: textSecondary,
    textSecondaryColor: textSecondary,
    headingColor: textPrimary,
    overlayOpacity: '0',
    buttonPrimaryColor: bgPrimary,
    buttonPrimaryBackgroundColor: textPrimary,
    buttonPrimaryHoverBackgroundColor: textSecondary,
    buttonSecondaryColor: textSecondary,
    buttonSecondaryHoverBackgroundColor: 'transparent',
    border: borderThick,
    height: '200px',
  },
};
