import { X, CheckCircle2, AlertCircle, ArrowLeft } from 'lucide-react';
import { Dialog, DialogContent } from 'components/shadcn/dialog';
import { Button } from 'components/shadcn/button';
import { cn } from 'utils/class-merge';
import { useNavigate } from 'react-router-dom';
import { PAYMENT_STATUS } from '../enums';

export type PaymentStatus = 'success' | 'failure' | null;

interface PaymentStatusModalProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  paymentStatus: PaymentStatus;
}

export default function PaymentStatusModal({
  isOpen,
  onOpenChange,
  paymentStatus,
}: PaymentStatusModalProps) {
  const navigate = useNavigate();

  const handleOnOpenChange = () => {
    onOpenChange(false);
    navigate('/admin/settings/billing');
  };

  const isSuccess = paymentStatus === PAYMENT_STATUS['SUCCESS'];

  return (
    <Dialog open={isOpen} onOpenChange={handleOnOpenChange}>
      <DialogContent
        closeButtonClassName="text-white"
        className="sm:max-w-[500px] p-0"
      >
        <div className="relative flex flex-col items-center p-6 pt-12 sm:p-10">
          <button
            onClick={handleOnOpenChange}
            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>

          <div
            className={cn(
              'rounded-full p-3 mb-6',
              isSuccess
                ? 'bg-green-100 text-green-600'
                : 'bg-red-100 text-red-600',
            )}
          >
            {isSuccess ? (
              <CheckCircle2 className="h-12 w-12 sm:h-16 sm:w-16" />
            ) : (
              <AlertCircle className="h-12 w-12 sm:h-16 sm:w-16" />
            )}
          </div>

          <h2 className="text-2xl sm:text-3xl font-bold text-center mb-4 text-white">
            {isSuccess ? 'Payment Successful!' : 'Payment Failed'}
          </h2>

          <p className="text-muted-foreground text-center mb-6 max-w-[400px]">
            {isSuccess
              ? 'Your payment has been processed successfully. Thank you for your purchase!'
              : 'We were unable to process your payment. Please try again or contact support if the problem persists.'}
          </p>

          <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto text-white">
            <Button
              variant="outline"
              className="sm:w-[200px]"
              onClick={handleOnOpenChange}
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Return to Dashboard
            </Button>

            {!isSuccess && (
              <Button
                className="sm:w-[200px]"
                onClick={() => {
                  return window.open(
                    'https://docs.engagespot.co/docs/get-support/',
                    '_blank',
                  );
                }}
              >
                Contact Support
                <ArrowLeft className="ml-2 h-4 w-4 rotate-180" />
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
