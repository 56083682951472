import { Command } from 'cmdk';
import { type LucideIcon } from 'lucide-react';
import { cn } from 'utils/class-merge';

export type BaseItemProps = {
  children: React.ReactNode;
  onSelect?: (value: string) => void;
  icon?: LucideIcon;
  type?: 'Link' | 'Action' | 'List';
  group?: string;
  keywords?: string[];
};

export function BaseItem({
  children,
  onSelect = () => {},
  type = 'Link',
  group,
  keywords = [],
  ...props
}: BaseItemProps) {
  return (
    <Command.Item onSelect={onSelect} className="flex items-center">
      <div className="flex items-center gap-2 mr-auto">
        {props.icon && <props.icon className={cn('h-5 w-5')} />}
        <span className="contents">{children}</span>
      </div>
      {type && (
        <span className="border border-input px-1 py-0.5 rounded-md text-gray-750 text-sm">
          {type}
        </span>
      )}
      {group && (
        <span className="sr-only">
          {group}&nbsp;{keywords.join(' ')}
        </span>
      )}
    </Command.Item>
  );
}
