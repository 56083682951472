import { Command } from 'cmdk';
import { type LucideIcon } from 'lucide-react';
import { cn } from 'utils/class-merge';

export type GroupProps = {
  heading: string;
  children: React.ReactNode;
  icon?: LucideIcon;
};

export function Group({ heading, children, ...props }: GroupProps) {
  const renderHeading = () => {
    return (
      <div className="flex gap-4">
        {props.icon && <props.icon className={cn('h-4 w-4')} />}
        <span>{heading}</span>
      </div>
    );
  };

  return (
    <Command.Group heading={renderHeading()} className="my-2">
      {children}
    </Command.Group>
  );
}
