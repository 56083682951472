import {
  EngagespotProvider as EngagespotHooksProvider,
  type EngagespotProviderProps,
  useActions,
  useErrors,
  useEvent,
  useOptions,
  useUnseenCount,
} from '@engagespot/react-hooks';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { EngagespotStyled } from './Engagespot.styled';
import { ErrorFallBack } from '../errorFallback/ErrorFallBack';
import { NotificationButton } from '../notificationButton';
import { NotificationPanel } from '../notificationPanel';
import {
  EngagespotInternalProvider,
  useEngagespotContext,
} from '@/engagespotProvider/EngagespotProvider';
import { useGetBaseUrl } from '@/hooks/useGetBaseUrl';
import { useInitializeTranslations } from '@/hooks/useInitializeTranslations';
import { usePanelOpenByDefault } from '@/hooks/usePanelOpenByDefault';
import { usePopover } from '@/hooks/usePopover';
import { useSetProfileAttributes } from '@/hooks/useSetProfileAttributes';
import type { EngagespotInternalProps } from '@/types/panel';
import { defaultChimeSrc, playSound } from '@/utils/chime';

export type EngagespotProps = Partial<EngagespotInternalProps> &
  EngagespotProviderProps['options'];

export type EngagespotCoreProps = Partial<EngagespotInternalProps>;

export type Route = 'home' | 'preference';

const ErrorCaller = () => {
  const errors = useErrors();

  if (errors) {
    throw new Error(errors?.message ?? 'Something went wrong');
  }

  return null;
};

export const EngagespotProvider = (
  props: EngagespotProviderProps['options'] & {
    children: React.ReactNode;
  },
) => {
  const { children, ...options } = props;

  return (
    <EngagespotHooksProvider options={options}>
      {children}
    </EngagespotHooksProvider>
  );
};

const EngageSpotEventListener = ({
  popover,
}: {
  popover: ReturnType<typeof usePopover>;
}) => {
  const context = useEngagespotContext();
  const actions = useActions();
  const {
    disableNotificationChime = true,
    notificationChimeSrc,
    events,
  } = context || {};

  useEvent('notificationReceive', notificationReceiveEvent => {
    if (popover.isOpen) {
      /**
       * HACK for now
       * setTimeout(promise) is used because useEvent fires first before everything,
       * so if we try to update the count normally, it will be overwritten by the count from the event,
       * so we wrap it in a promise to make sure it is updated last
       */
      setTimeout(() => {
        actions.markAsSeen({
          pageNo: 1,
        });
      }, 0);
    }

    if (!disableNotificationChime) {
      playSound(notificationChimeSrc ?? defaultChimeSrc);
    }

    events?.notificationReceive?.(notificationReceiveEvent);
  });

  useEvent('notificationDelete', notificationDeleteEvent => {
    events?.notificationDelete?.(notificationDeleteEvent);
  });

  useEvent('notificationDeleteAll', notificationDeleteAllEvent => {
    events?.notificationDeleteAll?.(notificationDeleteAllEvent);
  });

  useEvent('notificationRead', notificationReadEvent => {
    events?.notificationRead?.(notificationReadEvent);
  });

  useEvent('notificationReadAll', notificationReadAllEvent => {
    events?.notificationReadAll?.(notificationReadAllEvent);
  });

  useEvent('notificationSeen', notificationSeenEvent => {
    events?.notificationSeen?.(notificationSeenEvent);
  });

  useEvent('notificationStateChange', notificationStateChangeEvent => {
    events?.notificationStateChange?.(notificationStateChangeEvent);
  });

  return null;
};

export function EngagespotCore(props: EngagespotCoreProps) {
  const options = useOptions();
  const baseUrl = useGetBaseUrl();
  const { disableTextTranslation } = props;
  const [route, setRoute] = useState<Route>('home');
  const [category, setCategory] = useState('');
  const internalProviderProps = {
    ...props,
    route,
    setRoute,
    category,
    setCategory,
  };

  const actions = useActions();
  const unseenCount = useUnseenCount();
  const { panelOnly, panel, renderNotificationIcon } = props ?? {};
  const [isOpen, setIsOpen] = React.useState(false);
  const popover = usePopover({
    isOpen: panel?.isOpen ?? isOpen,
    setIsOpen: panel?.setIsOpen ?? setIsOpen,
    onOpenChange: (isOpen: boolean) => {
      // on panel open, fire markAsSeen
      if (isOpen && unseenCount > 0) {
        /**
         * HACK for now
         * The notification feed API is called when the panel opens, which may update the unread count.
         * This needs to be triggered afterward.
         */
        setTimeout(() => {
          actions.markAsSeen({
            pageNo: 1,
          });
        }, 1000);
      }

      if (panel?.setIsOpen) {
        panel?.setIsOpen(isOpen);
      } else {
        setIsOpen(isOpen);
      }
    },
  });
  const notificationPanelProps = {
    visible: popover.isOpen,
    setVisible: popover.setIsOpen,
    floatingProps: popover.floatingProps,
    middlewareProps: popover.middlewareProps,
    panelProps: popover.panelProps,
  };

  useInitializeTranslations({
    apiKey: options?.apiKey,
    userId: options?.userId,
    disableTextTranslation: disableTextTranslation,
    debug: options.debug,
    baseUrl,
  });
  useSetProfileAttributes();
  usePanelOpenByDefault({ setVisible: popover.setIsOpen });

  return (
    <EngagespotInternalProvider {...internalProviderProps}>
      <ErrorBoundary FallbackComponent={ErrorFallBack}>
        <ErrorCaller />

        <EngageSpotEventListener popover={popover} />

        <EngagespotStyled>
          {!panelOnly && (
            <NotificationButton
              buttonProps={popover.referenceProps}
              panelOpen={popover.isOpen}
              renderNotificationIcon={renderNotificationIcon}
            />
          )}

          <NotificationPanel {...notificationPanelProps} />
        </EngagespotStyled>
      </ErrorBoundary>
    </EngagespotInternalProvider>
  );
}

export function Engagespot(props: EngagespotProps) {
  return (
    <EngagespotProvider {...props}>
      <EngagespotCore {...props} />
    </EngagespotProvider>
  );
}
