import {
  AccessorFnColumnDef,
  AccessorKeyColumnDef,
} from '@tanstack/react-table';
import Skeleton from 'components/skeleton';
import React from 'react';
import { LOADING_PAGE_SIZE } from './pagination-state';
import { cn } from 'utils/class-merge';

export const useParseTableData = ({
  loading,
  tableColumns,
  tableData,
}: {
  loading: boolean;
  tableColumns: (
    | AccessorFnColumnDef<any, unknown>
    | AccessorKeyColumnDef<any, unknown>
  )[];
  tableData: any;
}) => {
  const columns = React.useMemo(
    () =>
      loading
        ? tableColumns.map(column => ({
            ...column,
            cell: () => (
              <Skeleton
                className={cn(
                  'h-[7px]',
                  column.size && ` w-[${column.size}px]`,
                )}
              />
            ),
          }))
        : tableColumns,
    [loading, tableColumns],
  );

  const data = React.useMemo(
    () => (loading ? Array(LOADING_PAGE_SIZE).fill({}) : tableData),
    [loading, tableData],
  );

  return {
    columns,
    data,
  };
};
