import { useMutation } from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { routes } from 'api/routes';

type PostCancelPlan = {
  subscription_id: number;
};

const post = (values: PostCancelPlan) => {
  const { subscription_id } = values;
  return axiosLicensingInstance.post(
    `${routes['v1_client']}/${subscription_id}/cancel`,
    {},
  );
};

const usePostCancelSubscription = () => {
  return useMutation({
    mutationFn: post,
  });
};

export default usePostCancelSubscription;
