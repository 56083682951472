import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/shadcn/command';
import { Check, Plus, Search, Users } from 'lucide-react';
import { UseFormReturn } from 'react-hook-form';

import useGetUsers from 'api/users/get-users';
import { Button } from 'components/shadcn/button';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from 'components/shadcn/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';
import React from 'react';
import { cn } from 'utils/class-merge';
import useCustomToast from 'utils/use-toast';
import { LogFilterFormValues } from './index';

export const RecipientSelect = ({
  form,
}: {
  form: UseFormReturn<LogFilterFormValues>;
}) => {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [customRecipients, setCustomRecipients] = React.useState<string[]>([]);
  const toast = useCustomToast();
  const getUsers = useGetUsers({
    pageIndex: 1,
    pageSize: 10,
    filter: searchTerm,
    staleTime: Infinity,
  });

  const allRecipients = React.useMemo(() => {
    const predefinedRecipients =
      getUsers.data?.results?.map(user => ({
        value: user.identifier,
        label: user.identifier,
      })) || [];

    return [
      ...predefinedRecipients,
      ...customRecipients.map(recipient => ({
        value: recipient,
        label: recipient,
      })),
    ];
  }, [customRecipients, getUsers.data?.results]);

  const filteredRecipients = React.useMemo(() => {
    return allRecipients.filter(recipient =>
      recipient.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [allRecipients, searchTerm]);

  return (
    <div className="flex-1 min-w-[200px] max-w-[200px]">
      <FormField
        control={form.control}
        name="userIdentifier"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Recipients</FormLabel>
            <Popover open={open} onOpenChange={setOpen}>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-full justify-between"
                  >
                    <div className="flex items-center gap-2">
                      <Users className="w-4 h-4" />
                      {field.value?.length > 0
                        ? `${field.value.length} selected`
                        : 'Select recepients'}
                    </div>
                    <Search className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command shouldFilter={false}>
                  <CommandInput
                    placeholder="Search recepients..."
                    onValueChange={setSearchTerm}
                  />
                  <CommandList>
                    <CommandGroup>
                      {filteredRecipients.length === 0 ? (
                        <div className="py-4 text-center text-sm">
                          No recepients found
                        </div>
                      ) : (
                        filteredRecipients.map(recipient => {
                          const isSelected = field.value?.includes(
                            recipient.value,
                          );

                          return (
                            <CommandItem
                              key={recipient.value}
                              onSelect={() => {
                                const currentValue = field.value || [];
                                const newValue = isSelected
                                  ? currentValue.filter(
                                      v => v !== recipient.value,
                                    )
                                  : [...currentValue, recipient.value];
                                if (newValue.length > 5) {
                                  toast.error(
                                    'You can only select a maximum of 5 recepients.',
                                  );
                                  return;
                                }
                                form.setValue('userIdentifier', newValue, {
                                  shouldDirty: true,
                                });
                              }}
                            >
                              <div
                                className={cn(
                                  'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                  isSelected
                                    ? 'bg-primary text-primary-foreground'
                                    : 'opacity-50 [&_svg]:invisible',
                                )}
                              >
                                <Check className={cn('h-4 w-4')} />
                              </div>
                              <Users className="mr-2 h-4 w-4" />
                              {recipient.label}
                            </CommandItem>
                          );
                        })
                      )}
                    </CommandGroup>
                  </CommandList>

                  {filteredRecipients.length === 0 && searchTerm && (
                    <Button
                      type="button"
                      variant="ghost"
                      onClick={() => {
                        if (
                          searchTerm &&
                          !customRecipients.includes(searchTerm)
                        ) {
                          const newValue = [...field.value, searchTerm];
                          if (newValue.length > 5) {
                            toast.error(
                              'You can only select a maximum of 5 recepients.',
                            );
                            return;
                          }
                          setCustomRecipients([
                            ...customRecipients,
                            searchTerm,
                          ]);
                          form.setValue('userIdentifier', newValue, {
                            shouldDirty: true,
                          });
                        }
                      }}
                      className="w-full justify-start text-sm border-t"
                    >
                      <Plus className="mr-2 h-4 w-4" />
                      Add "{searchTerm}"
                    </Button>
                  )}
                  {field.value?.length > 0 && (
                    <div className="border-t p-2">
                      <Button
                        type="button"
                        variant="ghost"
                        onClick={() => form.setValue('userIdentifier', [])}
                        className="w-full justify-center text-sm"
                      >
                        Clear selection
                      </Button>
                    </div>
                  )}
                </Command>
              </PopoverContent>
            </Popover>
          </FormItem>
        )}
      />
    </div>
  );
};
