import { handleError } from './handleError';
import { type InstanceOptions } from '../createInstance';

export const validateIncomingArgs = (options: InstanceOptions) => {
  if (!options.apiKey) handleError('noApiKey');

  if (!options.userId) handleError('noUserId');

  if (!options.userSignature) handleError('noUserSignature');
};

export { handleError };
