import type { Instance } from '@engagespot/core';
import { atom, computed, onNotify } from 'nanostores';

import { createConnectFetcherBuilder } from './connectFetcher';
import type { InitServiceFn, ServiceArgs } from '../../utils/service';

type ConnectServiceArgs = ServiceArgs<'connectService'>;

type ConnectFetcherStore = Awaited<ReturnType<Instance['app']['connect']>>;

export function connectService({
  dependencies: { log, instance, options },
}: ConnectServiceArgs) {
  const { apiKey, userId } = options;
  const $unreadCount = atom(0);
  const $hideBranding = atom(false);
  const $errors = atom<null | Record<string, string>>(null);
  const $options = atom(options);

  const { createFetcherStore } = createConnectFetcherBuilder({
    instance,
    log,
  });

  const $connectFetcherStore = createFetcherStore<ConnectFetcherStore>([
    apiKey,
    userId,
  ]);

  /**
   * This is a hacky solution to cache the connectFetcherStore.
   * Otherwise the connectFetcherStore will be recreated on every render and
   * we'll get the “The result of getSnapshot should be cached”  error on console.
   */
  const $connectFetcher = computed(
    $connectFetcherStore,
    connectFetcherStore => connectFetcherStore,
  );

  onNotify($connectFetcher, () => {
    const unreadCount = $connectFetcher.value?.data?.data?.unreadCount;
    const hideBranding = $connectFetcher.value?.data?.data?.app?.hideBranding;
    const errors = $connectFetcher.value?.data?.error;

    $unreadCount.set(unreadCount || 0);
    $hideBranding.set(hideBranding || false);

    if (errors) {
      $errors.set(errors);
    }
  });

  const stores = {
    $connectFetcher,
    $unreadCount,
    $hideBranding,
    $errors,
    $options,
  };
  const actions = {};

  return { stores, actions };
}

connectService.key = 'connectService' as const;

export const initConnectService = <T extends InitServiceFn>(initService: T) => {
  const app = initService({
    key: 'connectService',
    requiredServiceKeys: [],
  });
  return app;
};
