import { useDisclosure } from '@chakra-ui/hooks';
import useGetInvoices from 'api/billing/get-invoices';
import useGetLicense from 'api/billing/get-license';
import PageHeader from 'components/header/PageHeader';
import { STRIPE_API_KEY } from 'config';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { isEnterpriseEdition } from 'utils/get-editions';
import CancelPlanModal from './components/CancelPlanModal';
import InvoiceTable from './components/InvoiceTable';
import PaymentMethod from './components/PaymentMethod';
import PaymentMethodStatus from './components/PaymentMethodStatus';
import PaymentStatusModal, {
  PaymentStatus as PaymentStatusType,
} from './components/PaymentStatusModal';
import PricingModal from './components/PricingModal';
import { SubscriptionDetails } from './components/SubscriptionDetails';
import { Usage } from './components/Usage';
import { useGetCurrentBillingPlanDetails } from './hooks';
import { StripePromise } from './types';

const enterpriseEdition = isEnterpriseEdition();
const location = window.location.href;
const isBillingPage = location.includes('billing');

let promise: StripePromise = null;

if (enterpriseEdition && isBillingPage) {
  promise = import('@stripe/stripe-js').then(stripe =>
    stripe.loadStripe(STRIPE_API_KEY),
  );
}

const Billing = () => {
  const [searchParams] = useSearchParams();
  const paymentStatus = new URLSearchParams(searchParams).get(
    'payment',
  ) as PaymentStatusType;
  const pricingModalActions = useDisclosure();
  const [cancelPlanModalOpen, setCancelPlanModalOpen] = React.useState(false);
  const [paymentStatusModalOpen, setPaymentStatusModalOpen] =
    React.useState(false);

  const enterpriseEdition = isEnterpriseEdition();
  const { clientId: clientUserId } = useAuthStore(state => state);
  const license = useGetLicense(clientUserId);
  const getInvoices = useGetInvoices(
    license.data?.InstanceClientIdentifer,
    enterpriseEdition,
  );
  const invoiceData = getInvoices.data?.data?.filter(invoice => {
    if (
      invoice.status === 'draft' ||
      invoice.status === 'void' ||
      invoice.amount_due === 0
    ) {
      return false;
    }

    return true;
  });
  const { currentPlan } = useGetCurrentBillingPlanDetails();
  const isFreePlan = currentPlan?.isFree;

  React.useEffect(() => {
    if (paymentStatus) {
      setPaymentStatusModalOpen(true);
    }
  }, [paymentStatus]);

  return (
    <div className="font-inter">
      {/* Checks if payment method add successfull or not */}
      <PaymentMethodStatus promise={promise} />

      <PaymentStatusModal
        isOpen={paymentStatusModalOpen}
        onOpenChange={setPaymentStatusModalOpen}
        paymentStatus={paymentStatus}
      />

      <PageHeader
        heading={'Plans and Billing'}
        text={'Manage your plans and payments'}
        showSearchBar={false}
      />

      <PricingModal
        isOpen={pricingModalActions.isOpen}
        onClose={pricingModalActions.onClose}
      />

      <CancelPlanModal
        isOpen={cancelPlanModalOpen}
        onOpenChange={setCancelPlanModalOpen}
      />

      <SubscriptionDetails
        onCancel={() => setCancelPlanModalOpen(true)}
        onUpgrade={pricingModalActions.onOpen}
      />

      <Usage />

      {!isFreePlan && <PaymentMethod promise={promise} />}

      {invoiceData?.length > 0 && (
        <InvoiceTable loading={getInvoices.isLoading} tableData={invoiceData} />
      )}
    </div>
  );
};

export default Billing;
