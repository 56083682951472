import { useParams, useSearchParams } from 'react-router-dom';
import { getHashidsInstance } from 'utils/use-hash-id';

export const useGetWorkflowOverviewPageParams = () => {
  const params = useParams();
  const hashId = getHashidsInstance();
  const hashedWorkflowId = params.workflowId;
  const workflowId = hashId.decode(hashedWorkflowId);

  const [searchParams] = useSearchParams();
  const currentView = new URLSearchParams(searchParams).get('view');
  const hashedWorkflowRunId = new URLSearchParams(searchParams).get(
    'workflowRunId',
  );
  const workflowRunId = hashId.decode(hashedWorkflowRunId);

  return {
    workflowId: Number(workflowId),
    hashedWorkflowId,
    currentView,
    workflowRunId: Number(workflowRunId),
  };
};
