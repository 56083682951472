import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import useDeleteTopicUserChannelSubscription from 'api/topics/delete-topic-user-subscription';
import useGetTopicUsers from 'api/topics/get-topic-users';
import { TopicSubscriber } from 'api/topics/get-topic-users/types';
import usePostTopicsUsers from 'api/topics/post-topics-users';
import useGetUsers from 'api/users/get-users';
import ErrorBox from 'components/error/ErrorBox';
import { CustomModal, CustomModalButtons } from 'components/modal';
import { MultiSelect } from 'components/shadcn/multi-select';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { FormAddTopicUsers } from '../types';
import { engagespotChannels } from '../variables';
import { getHashidsInstance } from 'utils/use-hash-id';

function TopicsUsersModal({
  isOpen,
  onClose,
  selectedTopicUser,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedTopicUser: TopicSubscriber;
}) {
  const hashIds = getHashidsInstance();
  const deleteTopicUserChannelSubscription =
    useDeleteTopicUserChannelSubscription();
  const postTopicsUsers = usePostTopicsUsers();
  const toast = useCustomToast();
  const editMode = Boolean(selectedTopicUser?.user?.id);
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const hashedTopicId = new URLSearchParams(searchParams).get('topicId');
  const topicId = String(hashIds.decode(hashedTopicId));
  const getTopicUsers = useGetTopicUsers(topicId);
  const [filter, setFilter] = React.useState('');
  const getUsers = useGetUsers({
    pageIndex: 1,
    pageSize: 10,
    filter,
  });

  const topicUserIds = getTopicUsers.data?.subscribers.map(
    subscriber => subscriber.user.identifier,
  );

  const filteredUserIds = getUsers.data?.results.filter(
    user => !topicUserIds?.includes(user?.identifier),
  );

  const defaultValues = React.useMemo(
    () => ({
      users: [],
      channels: [],
    }),
    [],
  );

  const schema = yup.object({
    ...(!editMode && {
      users: yup
        .array()
        .required('Please select atleast one users')
        .min(1, 'Please select atleast one users'),
    }),
    channels: yup
      .array()
      .required('Please select atleast one channel')
      .min(1, 'Please select atleast one channel'),
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<FormAddTopicUsers>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (!isOpen) return;

    if (editMode) {
      reset({
        channels: selectedTopicUser?.subscribedChannels.map(item => ({
          id: item.channel,
        })),
      });
    } else {
      reset(defaultValues);
    }
  }, [
    defaultValues,
    editMode,
    reset,
    selectedTopicUser?.subscribedChannels,
    isOpen,
  ]);

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: () => {
        toast.success(message);
        queryClient.invalidateQueries([`${routes['topics']}/${topicId}/users`]);
        handleClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormAddTopicUsers> = values => {
    const channelSubscriptions = values.channels?.map(item => item.id);
    const users = values.users;

    const finalActions = getFinalActions(
      `user ${editMode ? 'edited' : 'attached to topic'}  successfully`,
    );

    if (editMode) {
      const data = {
        users: [
          {
            user_id: selectedTopicUser?.user?.id,
            channels: channelSubscriptions,
          },
        ],
        topicId: topicId,
      };

      const previousSubscriptions = selectedTopicUser?.subscribedChannels.map(
        item => ({ id: item.subscriptionId, channel: item.channel }),
      );

      const deletedSubscriptions = previousSubscriptions?.filter(
        subscripion =>
          !channelSubscriptions.some(
            channel => channel === subscripion.channel,
          ),
      );

      const deletePromiseArray = deletedSubscriptions.map(
        async subscription => {
          return await deleteTopicUserChannelSubscription.mutateAsync({
            subscriptionId: subscription.id,
            topicId,
          });
        },
      );

      postTopicsUsers.mutate(data, finalActions);

      Promise.all(deletePromiseArray).catch(error => {
        toast.error(error);
      });
    } else {
      const data = {
        users: users?.map(user => ({
          user_id: user.id,
          channels: channelSubscriptions,
        })),
        topicId: topicId,
      };

      postTopicsUsers.mutate(data, finalActions);
    }
  };

  return (
    <CustomModal
      modalCardClassName="!max-w-[450px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-3 text-2xl font-bold">
        {editMode ? 'Edit' : 'Add'} Topic User{' '}
        {selectedTopicUser?.user?.identifier && (
          <>
            -{' '}
            <span className="text-gray-400">
              {selectedTopicUser?.user?.identifier}
            </span>
          </>
        )}
      </h1>
      {/* <p className="mb-6 text-sm text-[#BFBFBF]">
              Read docs to learn more about Topic
            </p> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        {!editMode && (
          <>
            <Controller
              name="users"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  data={filteredUserIds}
                  setFilter={setFilter}
                  isFetching={getUsers.isFetching}
                  filter={filter}
                  placeholder="Select one or more users"
                  label="Users"
                ></MultiSelect>
              )}
            />

            {errors.users && <ErrorBox error={errors.users} />}
          </>
        )}

        <>
          <Controller
            name="channels"
            control={control}
            render={({ field }) => (
              <MultiSelect
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                data={engagespotChannels}
                setFilter={setFilter}
                isFetching={getUsers.isFetching}
                filter={filter}
                placeholder="Select one or more channels"
                identifierKey="id"
                label="Channels"
                dropDownClass="!h-min !max-h-[80px]"
              ></MultiSelect>
            )}
          />

          {errors.channels && <ErrorBox error={errors.channels} />}
        </>

        <CustomModalButtons
          handleClose={handleClose}
          loading={postTopicsUsers.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default TopicsUsersModal;
