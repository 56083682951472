import Popular from '@assets/img/billing/popular.png';
import RightArrow from '@assets/svg/billing/right-arrow.svg';
import WhiteTick from '@assets/svg/billing/tick.svg';
import useGetPlans from 'api/billing/get-plans';
import { Plans } from 'api/billing/get-plans/types';
import usePutChangePlans from 'api/billing/put-change-plan';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import Skeleton from 'components/skeleton';
import { getAbbreviateNumber } from 'utils/get-abbreviate-format';
import { isEnterpriseEdition } from 'utils/get-editions';
import { R } from 'utils/remeda-utils';
import { useGetCurrentBillingPlanDetails } from '../hooks';
import { useNavigate } from 'react-router-dom';
import useCustomToast from 'utils/use-toast';
import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { PAYMENT_STATUS, USAGE_TYPE } from '../enums';

const PricingCards = ({
  featureList,
  highlighted = false,
  handleCloseModal,
  plan,
  tagline,
  currentPlanPrice,
}: {
  featureList?: string[];
  highlighted?: boolean;
  handleCloseModal: () => void;
  plan: Plans;
  tagline?: string;
  currentPlanPrice: string;
}) => {
  const putChangePlans = usePutChangePlans();
  const navigate = useNavigate();
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const overagePrice = plan.prices.find(
    price => price.usageType === USAGE_TYPE['METERED'],
  );
  const billingPrice = plan.prices.find(
    price => price.usageType === USAGE_TYPE['LICENSED'],
  );
  const overagePricePerUnit = overagePrice?.amount;
  const pricePerMonth = billingPrice?.amount;
  const abbreviatedPricePerMonth = getAbbreviateNumber(pricePerMonth);

  const monthlyUnitsIncluded = Number(plan?.metadata?.limits?.triggers);
  const abbreviatedMonthlyUnitsIncluded =
    getAbbreviateNumber(monthlyUnitsIncluded);

  const planChangeType =
    Number(currentPlanPrice) < Number(pricePerMonth) ? 'Upgrade' : 'Downgrade';

  const upgradePlan = (plan: Plans) => {
    putChangePlans.mutate(
      {
        plan_id: plan.id,
        price_id: billingPrice?.id,
      },
      {
        onSuccess: response => {
          queryClient.invalidateQueries([
            `${routes['v1_client']}/subscription`,
          ]);
          queryClient.invalidateQueries([`${routes['v1_client']}/usage`]);

          const stripeUrl = response.data.url;
          if (stripeUrl) {
            window.open(stripeUrl, '_blank');
          } else {
            navigate(
              `/admin/settings/billing?payment=${PAYMENT_STATUS['SUCCESS']}`,
            );
          }
          handleCloseModal();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <div
      className={`relative mt-5 flex  flex-col rounded-3xl border ${
        highlighted ? 'border-[#ff75bf]' : 'border-[#242424]'
      } bg-[#161616] px-8 pb-12 pt-14 xl:px-16`}
    >
      {/* absolute */}
      {highlighted && (
        <div className="absolute right-5 top-5 flex items-center gap-3 rounded-full bg-brand-600/20 px-4 py-1 font-medium text-[#c51258]">
          <img width={14} src={Popular} alt="" />
          Popular
        </div>
      )}

      <div className="mb-8 flex flex-col">
        <h5 className="text-[1.35rem] font-normal  text-white/70">
          {plan.name}
        </h5>
        <p className="text-[2.8rem] font-medium">
          ${abbreviatedPricePerMonth}{' '}
          <span className="relative right-2 text-base text-[#b6b0a6]">
            /month
          </span>
        </p>
        <p className="text-lg text-[#e29e37]">{tagline}</p>
      </div>

      <div className="mb-8 text-white">
        <p className="mb-1">
          Included {abbreviatedMonthlyUnitsIncluded} notifications
        </p>
        <span>(${overagePricePerUnit} per additional 1K notifications)</span>
      </div>

      <div className="mb-8 h-[1px] bg-[#242424]"></div>

      <div className="mb-8 flex flex-col gap-1">
        {featureList?.map(feature => (
          <div className="flex gap-2">
            <img width={18} src={WhiteTick} alt="" />
            <div>{feature}</div>
          </div>
        ))}
      </div>

      <button
        onClick={() => upgradePlan(plan)}
        disabled={putChangePlans.isLoading}
        className={`flex items-center justify-center gap-1 rounded-full ${
          highlighted
            ? 'group relative overflow-hidden  text-white  transition-colors  duration-200 hover:bg-brand-400 disabled:bg-brand-400/50 disabled:text-white/50'
            : 'bg-white text-black hover:bg-white/90 disabled:bg-white/70 disabled:text-black/50'
        }  px-7 py-4 `}
      >
        {/* shooting star animation */}
        {highlighted && (
          <>
            <span>
              <span className="spark mask-gradient absolute inset-0 h-[100%] w-[100%] animate-flip overflow-hidden rounded-full [mask:linear-gradient(white,_transparent_50%)] before:absolute before:aspect-square before:w-[200%] before:rotate-[-90deg] before:animate-rotate before:bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)] before:content-[''] before:[inset:0_auto_auto_50%] before:[translate:-50%_-15%]" />
            </span>
            <span className="backdrop group-hover:bg-slate-800 absolute inset-[2px] rounded-full bg-brand-850 transition-colors duration-200" />
          </>
        )}

        <span className="z-10">{planChangeType}</span>
        {!highlighted && <img width={20} src={RightArrow} alt="" />}

        {putChangePlans.isLoading && (
          <LoaderSpinner width="20" visible={true} />
        )}
      </button>
    </div>
  );
};

const PricingTable = (props: { handleCloseModal: () => void }) => {
  const enterpriseEdition = isEnterpriseEdition();
  const getPlans = useGetPlans(enterpriseEdition);
  const { currentPlan, billingPriceAmount } = useGetCurrentBillingPlanDetails();

  const alternativePaidPlans = R.filter(
    getPlans.data ?? [],
    alternativePlans =>
      R.isNot(R.isTruthy)(alternativePlans.isFree) &&
      alternativePlans.identifier !== currentPlan?.identifier,
  );

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      {getPlans.isLoading ? (
        <>
          {[1, 2, 3].map(item => (
            <Skeleton key={item} className="h-[423px] w-full mt-5" />
          ))}
        </>
      ) : (
        <>
          {alternativePaidPlans.map(plan => (
            <PricingCards
              plan={plan}
              featureList={[]}
              highlighted={plan.identifier === 'growth_plan'}
              tagline={plan.description}
              currentPlanPrice={billingPriceAmount}
              {...props}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default PricingTable;
