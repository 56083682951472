import type { FeedOptions } from '@engagespot/store';
import { useStore } from '@nanostores/react';

import { useInternalInstance } from './useInstance';

export type Feed = ReturnType<typeof useFeed>;

type FilteredFeedOptions = Required<
  NonNullable<Omit<FeedOptions, 'key' | 'tenantIdentifier'>>
> &
  NonNullable<Omit<FeedOptions, 'key' | 'category'>>;

/**
 *
 * @unstable
 * Create a filtered feed based on the category
 * This API is unstable and may change in the future
 */
function createFilteredFeed(feedOptions?: FilteredFeedOptions) {
  const useFilteredFeed = () => {
    const storeInstance = useInternalInstance();

    const {
      notificationFeed: { createFeedService },
    } = storeInstance;

    const { category, tenantIdentifier } = feedOptions ?? {};

    const { stores, actions } = createFeedService({
      category,
      tenantIdentifier,
      key: category,
    });

    const { $notifications, $loading, $hasMore } = stores;
    const { loadMore, refresh } = actions;
    const notifications = useStore($notifications);
    const loading = useStore($loading);
    const hasMore = useStore($hasMore);

    return { notifications, loading, loadMore, refresh, hasMore };
  };

  return useFilteredFeed;
}

const useFeed = createFilteredFeed();

export { useFeed, createFilteredFeed };
