import React from 'react';
import type { FallbackProps } from 'react-error-boundary';

import {
  ErrorBox,
  ErrorHeader,
  ErrorHeaderContainer,
  InfoMessage,
} from './ErrorFallBack.styled';
import { EngagespotStyled } from '../engagespot/Engagespot.styled';
import { NotificationButtonStyled } from '../notificationButton/NotificationButton.styled';
import {
  NotificationPanelArrowStyled,
  NotificationPanelPopper,
  NotificationPanelStyled,
} from '../notificationPanel/NotificationPanel.styled';
import { usePopover } from '@/hooks/usePopover';
import { CircleX } from '@/icons/CircleX';
import { ErrorBell } from '@/icons/ErrorBell';
import { Info } from '@/icons/Info';

export const ErrorFallBack = ({ error }: FallbackProps) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const { floatingProps, middlewareProps, panelProps, referenceProps } =
    usePopover({
      isOpen: isOpen,
      setIsOpen: setIsOpen,
      placement: 'bottom',
      onOpenChange: (isOpen: boolean) => {
        setIsOpen(isOpen);
      },
    });

  return (
    <EngagespotStyled>
      <NotificationButtonStyled
        buttonType="normal"
        aria-label="Notifications"
        hasError={true}
        ref={referenceProps.setRef}
        {...referenceProps.getReferenceProps()}
      >
        <ErrorBell />
      </NotificationButtonStyled>

      <>
        {isOpen && (
          <NotificationPanelPopper
            style={floatingProps.styles}
            ref={floatingProps.setRef}
            {...floatingProps.getFloatingProps()}
          >
            <NotificationPanelArrowStyled
              ref={middlewareProps.arrow.setRef}
              style={{
                left: middlewareProps.arrow.middlewareData?.x,
                top: middlewareProps.arrow.middlewareData?.y,
                ...(middlewareProps.arrow.styles || {}),
              }}
            />
            <NotificationPanelStyled
              style={{
                ...panelProps.styles,
                background: 'white',
                height: 'auto',
                width: '16rem',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '.8rem',
                padding: '1rem 1rem',
              }}
            >
              <ErrorHeaderContainer>
                <CircleX />
                <ErrorHeader>Something went wrong</ErrorHeader>
              </ErrorHeaderContainer>

              <ErrorBox>{error?.message ?? 'Something went wrong'}</ErrorBox>

              <InfoMessage>
                <Info />
                Check devtools console for more info
              </InfoMessage>
            </NotificationPanelStyled>
          </NotificationPanelPopper>
        )}
      </>
    </EngagespotStyled>
  );
};
