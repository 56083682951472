import type { EngagespotNotification } from '@engagespot/core';

import type { TemplateBlocksRenderProps } from './TemplateBlocksRender';
import type {
  changeNotificationStateFnType,
  EventListenersToRun,
  TemplateBlock,
} from './types';

/**
 * creates a grouping based on element type
 * Eg: {0:[image], 1: [button, button]}
 * Eg: {0:[form], 1: [input], 2: [button]}
 */
const getConfigGroupingBasedOnElements = (blocks: TemplateBlock[]) => {
  const configGroupingBasedOnElements = blocks?.reduce(
    (acc, curr, index) => {
      const previousItem = blocks?.[index - 1];

      // Start a new group if the current type is different from the previous type
      if (previousItem && previousItem?.type !== curr?.type) {
        const newGroupKey = Object.keys(acc).length;
        acc = { ...acc, [newGroupKey]: [] };
      }

      // The current group key is always the last index (Object.keys(acc).length - 1) as groups start with { 0: [] }.
      const currentGroupKey = Object.keys(acc).length - 1;

      return {
        ...acc,
        [currentGroupKey]: [...(acc?.[currentGroupKey] ?? []), curr],
      };
    },
    { 0: [] } as { [x: string]: TemplateBlock[] },
  );

  return configGroupingBasedOnElements;
};

const getValue = (id: string) => {
  const element = document.querySelector(`[data-block-id='${id}']`);

  return (
    (element as any)?.value ??
    (element as HTMLElement)?.getAttribute('data-value') // for rating-star
  );
};

const getComponentProps = ({
  eventListenersToRun,
  changeNotificationState,
  notification,
  config,
  actionableElementsProps,
}: TemplateBlocksRenderProps & {
  config: TemplateBlocksRenderProps['blocks'][number];
}) => {
  const eventListenerHandler =
    eventListenersToRun && changeNotificationState && notification
      ? getEventListenerHandler({
          eventListenersToRun,
          configId: config.id,
          changeNotificationState,
          notification,
        })
      : {};

  const props = {
    'data-block-id': config.id,
    placeholder: config.text,
    variant: config.variant as TemplateBlock['variant'],
    ...(config.type === 'button' && {
      onClick: getOnClickHandler(config.link, config.externalLink),
    }),
    ...(config.type === 'image' && {
      src: getImgSrc(config.src),
    }),
    ...(config.type &&
      (config.type === 'rating-star' || config.type === 'textarea') &&
      actionableElementsProps?.[config.type] && {
        ...actionableElementsProps?.[config.type],
      }),
    ...eventListenerHandler,
  };

  return props;
};

const getImgSrc = (src?: string | Blob[]) => {
  return typeof src === 'string'
    ? src
    : src
      ? URL.createObjectURL(src?.[0])
      : '';
};

const getOnClickHandler = (
  link?: string,
  externalLink?: boolean | undefined,
) => {
  if (!link) return null;

  return () => {
    window.open(link, externalLink ? '_blank' : '_self');
  };
};

const getEventListenerHandler = ({
  eventListenersToRun,
  configId,
  changeNotificationState,
  notification,
}: {
  eventListenersToRun: EventListenersToRun[];
  configId: string;
  changeNotificationState: changeNotificationStateFnType;
  notification: EngagespotNotification;
}) => {
  const selectedEventListenerToRun = eventListenersToRun?.find(
    listener => listener.blockId === configId,
  );

  if (!selectedEventListenerToRun) return {};

  return {
    [selectedEventListenerToRun.event]: (event: any) =>
      selectedEventListenerToRun.onEvent({
        event,
        changeNotificationState,
        getValue,
        notification,
      }),
  };
};

export {
  getConfigGroupingBasedOnElements,
  getComponentProps,
  getValue,
  getImgSrc,
};
