import { connectService } from '../services/connectService';
import { notificationService } from '../services/notificationService';
import { preferencesService } from '../services/preferencesService';
import { realTimeService } from '../services/realTimeService';
import { webPushService } from '../services/webPushService';

export const serviceMapper = {
  connectService: {
    key: connectService.key,
    service: connectService,
  },
  notificationService: {
    key: notificationService.key,
    service: notificationService,
  },
  preferencesService: {
    key: preferencesService.key,
    service: preferencesService,
  },
  realTimeService: {
    key: realTimeService.key,
    service: realTimeService,
  },
  webPushService: {
    key: webPushService.key,
    service: webPushService,
  },
};

export type ServiceMapper = typeof serviceMapper;
